import React, {Suspense} from 'react';
import {BounceLoader} from 'react-spinners';

function SuspenseLoader() {
  return (
    <div className="flex h-full items-center justify-center">
      <BounceLoader color="#ed8936" />
    </div>
  );
}

export function withSuspense<P extends object>(
  WrappedComponent: React.ComponentType<P>,
  FallbackComponent = SuspenseLoader(),
) {
  return function ComponentWithSuspense(props: P) {
    return (
      <Suspense fallback={FallbackComponent}>
        <WrappedComponent {...(props as P)} />
      </Suspense>
    );
  };
}

import React from 'react';

import DropdownInput from './DropdownInput';

type Props = {
  value: string;
  onChange: (value: string) => void;
};

function CompletedFilter(props: Props) {
  const {value, onChange} = props;

  const options = [
    {value: 'complete', label: 'Completed'},
    {value: 'not_complete', label: 'Uncompleted'},
    {value: 'all', label: 'All'},
  ];

  return (
    <DropdownInput
      label="Completed"
      value={value}
      onChange={onChange}
      options={options}
      name="completedFilter"
      placeholder=""
    />
  );
}

export default CompletedFilter;

import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useHistory} from 'react-router';
import ConnectionHandler from 'relay-connection-handler-plus';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/Form';
import {FormValues} from '../types';
import {createPageAssetQuery} from './__generated__/createPageAssetQuery.graphql';
import {createPageCreateJobWithTasksMutation} from './__generated__/createPageCreateJobWithTasksMutation.graphql';

function CreatePage() {
  const history = useHistory();
  const assetId = getAssetId();

  const [commit, isInFlight] = useMutation<createPageCreateJobWithTasksMutation>(
    createJobWithTasksMutation,
  );

  const data = useLazyLoadQuery<createPageAssetQuery>(
    assetQuery,
    {assetId: assetId || ''},
    {fetchPolicy: assetId ? 'store-and-network' : 'store-only'},
  );

  return (
    <Form
      title="Create Job"
      button="Create Job"
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
      selectedAsset={data.asset}
    />
  );

  function getAssetId() {
    const params = new URLSearchParams(window.location.search);
    return params.get('assetId');
  }

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          assetId: values.asset.id,
          dueAt: values.dueAt.toISOString(),
          assigneeId: values.assigneeId,
          tasksToAddByTemplateIds: values.taskTemplates.map(taskTemplate => taskTemplate.value),
        },
      },
      onCompleted: payload => {
        if (payload.createJobWithTasks?.errors[0] && payload.createJobWithTasks?.errors[0].fields) {
          payload.createJobWithTasks?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.createJobWithTasks?.job;

        if (entry) {
          showEventNotification('added', 'Job', '#' + entry.jobNumber);
          return history.push('/admin/jobs');
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connectionRecords = ConnectionHandler.getConnections(root, 'JobWithTasksList_jobs');

        if (!connectionRecords) {
          return;
        }

        const payload = store.getRootField('createJobWithTasks');

        if (!payload) {
          return;
        }

        const newRecord = payload.getLinkedRecord('job');

        if (!newRecord) {
          return;
        }

        connectionRecords.forEach(connectionRecord => {
          const newEdge = ConnectionHandler.createEdge(
            store,
            connectionRecord,
            newRecord,
            'jobEdge',
          );

          ConnectionHandler.insertEdgeAfter(connectionRecord, newEdge);
        });
      },
    });
  }
}

const assetQuery = graphql`
  query createPageAssetQuery($assetId: ID!) {
    asset(assetId: $assetId) {
      __typename
      id
      serial
      installedAt
      lastServicedAt
      coordinates
    }
  }
`;

const createJobWithTasksMutation = graphql`
  mutation createPageCreateJobWithTasksMutation($input: CreateJobWithTasksInput!) {
    createJobWithTasks(input: $input) {
      job {
        id
        jobNumber
        createdAt
        dueAt
        completedAt
        assignee {
          id
        }
        asset {
          id
        }
        notes
        tasks {
          edges {
            node {
              id
              template {
                id
                name
              }
            }
          }
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(CreatePage);

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {useAuthContext} from 'src/libs/authentication';
import {withSuspense} from 'src/utils/withSuspense';
import styled from 'styled-components';

import {pageLayoutCurrentUserQuery} from './__generated__/pageLayoutCurrentUserQuery.graphql';
import Header, {NAVBAR_HEIGHT, HeaderFallback} from './Header';
import OrganizatonSidebar from './OrganizatonSidebar';
import ProjectAdminSidebar from './ProjectAdminSidebar';

type Props = {
  children: React.ReactNode;
};

function PageLayout(props: Props) {
  const {isProjectAdmin} = useAuthContext();

  const query = useLazyLoadQuery<pageLayoutCurrentUserQuery>(
    graphql`
      query pageLayoutCurrentUserQuery {
        ...Header_currentUserQuery
      }
    `,
    {},
    {
      UNSTABLE_renderPolicy: 'partial',
    },
  );

  return (
    <React.Fragment>
      <Header query={query} />
      <div className="flex flex-grow">
        {isProjectAdmin ? <ProjectAdminSidebar /> : <OrganizatonSidebar />}

        <ContentContainer>{props.children}</ContentContainer>
      </div>
    </React.Fragment>
  );
}

function Fallback() {
  return (
    <div>
      <HeaderFallback />
    </div>
  );
}

const ContentContainer = styled.div.attrs({
  className: 'w-full overflow-auto flex flex-grow flex-col',
})`
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
`;

export default withSuspense(PageLayout, <Fallback />);

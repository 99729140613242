import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import CreatePage from './create-page';

function Router() {
  const {path} = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/create`} exact component={CreatePage} />
    </Switch>
  );
}

export default Router;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useFragment} from 'react-relay/hooks';
import {formatDateTimeUS} from 'src/utils/formatDate';

import {NoteRecordItem_noteRecord$key} from './__generated__/NoteRecordItem_noteRecord.graphql';

type Props = {record: NoteRecordItem_noteRecord$key};

export default function NoteRecordItem(props: Props) {
  const record = useFragment(
    graphql`
      fragment NoteRecordItem_noteRecord on NoteRecord {
        id
        createdAt
        author {
          id
          firstName
          lastName
        }
        notes
      }
    `,
    props.record,
  );

  const createdAt = formatDateTimeUS(record.createdAt);

  return (
    <div key={record.id} className="w-full border rounded shadow px-4 py-2 mb-4">
      <div>
        <p className="text-gray-700 text-sm">
          <span className="text-gray-700 font-semibold">
            {record.author.firstName} {record.author.lastName}
          </span>{' '}
          added a<span className="text-gray-700 font-semibold"> note</span>
        </p>
        <div className="mt-1 mb-1">
          <p className="text-gray-700">{record.notes}</p>
        </div>
      </div>
      <div className="mt-2">
        <p className="text-xs text-gray-600">{createdAt}</p>
      </div>
    </div>
  );
}

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {usePaginationFragment, useMutation} from 'react-relay/hooks';
import ConnectionHandler from 'relay-connection-handler-plus';
import AssetStatusCell from 'src/components/table/cell-types/AssetStatusCell';
import ImportantTextCell from 'src/components/table/cell-types/ImportantTextCell';
import useFilters from 'src/hooks/useFilters';
import {showEventNotification} from 'src/utils/notifications';

import Table from '../../../components/table';
import SecondaryTextCell from '../../../components/table/cell-types/SecondaryTextCell';
import {listPagevalvesQuery} from './__generated__/listPagevalvesQuery.graphql';
import {AssetsOrder} from './__generated__/ValvesPaginationQuery.graphql';
import {ValvesTable_valves$key} from './__generated__/ValvesTable_valves.graphql';
import {ValvesTableDeleteValveMutation} from './__generated__/ValvesTableDeleteValveMutation.graphql';
import TableFilters from './TableFilters';

type Props = {
  valves: ValvesTable_valves$key;
};

type Filters = {
  text?: string;
  orderBy?: AssetsOrder;
};

function ValvesTable(props: Props) {
  const {data, loadNext, hasNext, refetch} = usePaginationFragment<
    listPagevalvesQuery,
    ValvesTable_valves$key
  >(
    graphql`
      fragment ValvesTable_valves on Query
        @argumentDefinitions(
          first: {type: "Int!"}
          after: {type: "String"}
          text: {type: "String"}
          orderBy: {type: "AssetsOrder"}
        )
        @refetchable(queryName: "ValvesPaginationQuery") {
        valves(first: $first, after: $after, orderBy: $orderBy, text: $text)
          @connection(key: "ValvesList_valves") {
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              createdAt
              serial
              externalId
              status
              model {
                id
                name
              }
              family {
                id
                name
              }
              manufacturer {
                id
                name
              }
            }
          }
        }
      }
    `,
    props.valves,
  );

  const [commit] = useMutation<ValvesTableDeleteValveMutation>(deleteValveMutation);
  const {addFilters, setFilters, isTransitioning} = useFilters<Filters>({}, refetch);

  return (
    <Table
      filters={() => (
        <TableFilters onApplyFilters={handleOnApplyFilter} onResetFilters={handleOnResetFilter} />
      )}
      actions={{show: true, delete: true, edit: true, create: true}}
      totalDataLength={data.valves.totalCount}
      columns={columns}
      onPaginate={() => hasNext && loadNext(50)}
      onSort={handleOnSort}
      onDelete={handleDelete}
      isTransitioning={isTransitioning}
      data={data.valves.edges}
    />
  );

  function handleDelete(id: string) {
    commit({
      variables: {input: {valveId: id}},
      onCompleted: payload => {
        const entry = payload.deleteValve?.valve;

        if (entry) {
          showEventNotification('removed', 'Valve', entry.serial);
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connections = ConnectionHandler.getConnections(root, 'ValvesList_valves');

        if (!connections) {
          return;
        }

        connections.forEach(connection => {
          ConnectionHandler.deleteNode(connection, id);
        });
      },
    });
  }

  function handleOnApplyFilter(filters: {text: string}) {
    addFilters({text: filters.text});
  }

  function handleOnResetFilter() {
    setFilters({});
  }

  async function handleOnSort(sortByConfig: any[]) {
    if (!sortByConfig.length) {
      addFilters({orderBy: undefined});

      return;
    }

    const orderByDirection = sortByConfig[0].desc ? 'DESC' : 'ASC';
    const orderByField = sortByConfig[0].id.toUpperCase();

    addFilters({orderBy: {direction: orderByDirection, field: orderByField}});
  }
}

export default ValvesTable;

const deleteValveMutation = graphql`
  mutation ValvesTableDeleteValveMutation($input: DeleteValveInput!) {
    deleteValve(input: $input) {
      valve {
        id
        serial
      }
      errors {
        code
        message
      }
    }
  }
`;

const columns = [
  {
    Header: 'ID',
    accessor: 'node.externalId',
    id: 'external_id',
    Cell({cell: {value}}: any) {
      return <ImportantTextCell value={value} />;
    },
  },
  {
    Header: 'Serial',
    accessor: 'node.serial',
    id: 'serial',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'Status',
    accessor: 'node.status',
    id: 'status',
    Cell({cell: {value}}: any) {
      return <AssetStatusCell value={value} />;
    },
  },
  {
    Header: 'Manufacturer',
    accessor: 'node.manufacturer.name',
    id: 'manufacturer_name',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'Type',
    accessor: 'node.family.name',
    id: 'family_name',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'Model',
    accessor: 'node.model.name',
    id: 'model_name',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
];

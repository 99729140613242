import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useHistory, useParams} from 'react-router';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/Form';
import {FormValues} from '../types';
import {updatePageMeasurementTemplateQuery} from './__generated__/updatePageMeasurementTemplateQuery.graphql';
import {updatePageUpdateMeasurementTemplateMutation} from './__generated__/updatePageUpdateMeasurementTemplateMutation.graphql';

function UpdatePage() {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [commit, isInFlight] = useMutation<updatePageUpdateMeasurementTemplateMutation>(
    updateMeasurementTemplateMutation,
  );

  const data = useLazyLoadQuery<updatePageMeasurementTemplateQuery>(query, {
    measurementTemplateId: id,
  });

  if (!data.measurementTemplate) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">The item you wish to update could not be found.</h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      title="Update Measurement Template"
      button="Update Measurement Template"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleInitialValues() {
    if (!data || !data.measurementTemplate) {
      return;
    }

    const {measurementTemplate} = data;

    return {
      name: measurementTemplate.name,
      dataKind: measurementTemplate.dataKind,
      assetKind: measurementTemplate.assetKind,
    };
  }

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          measurementTemplateId: id,
          updateMeasurementTemplateData: values,
        },
      },
      onCompleted: payload => {
        if (
          payload.updateMeasurementTemplate?.errors[0] &&
          payload.updateMeasurementTemplate?.errors[0].fields
        ) {
          payload.updateMeasurementTemplate?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.updateMeasurementTemplate?.measurementTemplate;

        if (entry) {
          showEventNotification('updated', 'Template', entry.name);

          if (history.location.search.includes('newTab')) {
            window.close();
          }

          return history.push('/admin/measurement_templates');
        }
      },
    });
  }
}

const query = graphql`
  query updatePageMeasurementTemplateQuery($measurementTemplateId: ID!) {
    measurementTemplate(measurementTemplateId: $measurementTemplateId) {
      id
      createdAt
      name
      dataKind
      assetKind
    }
  }
`;

const updateMeasurementTemplateMutation = graphql`
  mutation updatePageUpdateMeasurementTemplateMutation($input: UpdateMeasurementTemplateInput!) {
    updateMeasurementTemplate(input: $input) {
      measurementTemplate {
        id
        createdAt
        name
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(UpdatePage);

/**
 * @generated SignedSource<<61fb7a06ee322c2b925743ab35705c43>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type updatePageHydrantModelQuery$variables = {
  hydrantModelId: string;
};
export type updatePageHydrantModelQueryVariables = updatePageHydrantModelQuery$variables;
export type updatePageHydrantModelQuery$data = {
  readonly hydrantModel: {
    readonly id: string;
    readonly createdAt: any;
    readonly name: string;
    readonly family: {
      readonly id: string;
      readonly name: string;
    };
    readonly manufacturer: {
      readonly id: string;
      readonly name: string;
    };
  } | null;
};
export type updatePageHydrantModelQueryResponse = updatePageHydrantModelQuery$data;
export type updatePageHydrantModelQuery = {
  variables: updatePageHydrantModelQueryVariables;
  response: updatePageHydrantModelQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hydrantModelId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "hydrantModelId",
    "variableName": "hydrantModelId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v4/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v2/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageHydrantModelQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HydrantModel",
        "kind": "LinkedField",
        "name": "hydrantModel",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "family",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "manufacturer",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageHydrantModelQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HydrantModel",
        "kind": "LinkedField",
        "name": "hydrantModel",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "family",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "manufacturer",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ddf92bda643e6bbab43e5debd6420d54",
    "id": null,
    "metadata": {},
    "name": "updatePageHydrantModelQuery",
    "operationKind": "query",
    "text": "query updatePageHydrantModelQuery(\n  $hydrantModelId: ID!\n) {\n  hydrantModel(hydrantModelId: $hydrantModelId) {\n    id\n    createdAt\n    name\n    family {\n      __typename\n      id\n      name\n    }\n    manufacturer {\n      __typename\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8e7eeb4fc152bd8a0e0a1bbee32c0b79";

export default node;

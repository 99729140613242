import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useFragment} from 'react-relay/hooks';

import {ManufacturingSection_asset$key} from './__generated__/ManufacturingSection_asset.graphql';

type Props = {
  asset: ManufacturingSection_asset$key;
};

export default function ManufacturingSection(props: Props) {
  const asset = useFragment(
    graphql`
      fragment ManufacturingSection_asset on Asset {
        id
        model {
          id
          name
        }
        family {
          id
          name
        }
        manufacturer {
          id
          name
        }
      }
    `,
    props.asset,
  );

  return (
    <div className="border-b pb-4 pt-4">
      <div className="flex">
        <div className="w-40">
          <h3 className="text-gray-700 text-sm uppercase font-semibold">Manufacturing</h3>
        </div>
        <div>
          <div className="flex items-end mb-2">
            <div className="w-40 flex justify-start mr-1">
              <h3 className="text-gray-600 text-sm">Manufacturer</h3>
            </div>
            <h3 className="text-gray-800 leading-snug text-base">{asset.manufacturer.name}</h3>
          </div>

          <div className="flex items-end mb-2">
            <div className="w-40 flex justify-start mr-1">
              <h3 className="text-gray-600 text-sm">Series</h3>
            </div>
            <h3 className="text-gray-800 leading-snug text-base">{asset.family.name}</h3>
          </div>
          <div className="flex items-end">
            <div className="w-40 flex justify-start mr-1">
              <h3 className="text-gray-600 text-sm">Model</h3>
            </div>
            <h3 className="text-gray-800 leading-snug text-base">{asset.model.name}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

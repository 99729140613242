/**
 * @generated SignedSource<<ef63973d64b9863c167b4821dff738ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type updatePageHydrantManufacturerQuery$variables = {
  hydrantManufacturerId: string;
};
export type updatePageHydrantManufacturerQueryVariables = updatePageHydrantManufacturerQuery$variables;
export type updatePageHydrantManufacturerQuery$data = {
  readonly hydrantManufacturer: {
    readonly id: string;
    readonly createdAt: any;
    readonly name: string;
  } | null;
};
export type updatePageHydrantManufacturerQueryResponse = updatePageHydrantManufacturerQuery$data;
export type updatePageHydrantManufacturerQuery = {
  variables: updatePageHydrantManufacturerQueryVariables;
  response: updatePageHydrantManufacturerQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hydrantManufacturerId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "hydrantManufacturerId",
        "variableName": "hydrantManufacturerId"
      }
    ],
    "concreteType": "HydrantManufacturer",
    "kind": "LinkedField",
    "name": "hydrantManufacturer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageHydrantManufacturerQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageHydrantManufacturerQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "442fd6e086890f21d1ac9eed97b0ae8e",
    "id": null,
    "metadata": {},
    "name": "updatePageHydrantManufacturerQuery",
    "operationKind": "query",
    "text": "query updatePageHydrantManufacturerQuery(\n  $hydrantManufacturerId: ID!\n) {\n  hydrantManufacturer(hydrantManufacturerId: $hydrantManufacturerId) {\n    id\n    createdAt\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "5648fd77f5272492b772c6de1f76a3c4";

export default node;

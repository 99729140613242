import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

import {AssetStatus} from '../../../generated/graphql';

type Props = {
  value: any;
};

const assetStatusMap: {[key: string]: {title: string; className: string}} = {
  overdue: {title: 'Overdue', className: 'text-red-500'},
  maintained: {title: 'Maintained', className: 'text-green-500'},
  due_soon: {title: 'Due Soon', className: 'text-orange-500'},
  out_of_service: {title: 'Not In Service', className: 'text-gray-500'},
};

function AssetStatusCell({value}: Props) {
  return (
    <CellContainer>
      <StatusIndicator icon={faCircle} value={value} />
      <span className="ml-1">{assetStatusMap[value].title}</span>
    </CellContainer>
  );
}

const StatusIndicator = styled(FontAwesomeIcon).attrs<{value: AssetStatus}>(({value}) => ({
  className: 'text-xs ' + assetStatusMap[value].className,
}))<{value: AssetStatus}>``;

const CellContainer = styled.div.attrs({
  className: 'text-sm font-semibold text-gray-700',
})`
  min-width: 8rem;
`;

export default AssetStatusCell;

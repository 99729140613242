import {useFormik, FormikHelpers} from 'formik';
import React from 'react';
import LocationTab from 'src/components/asset-form/LocationTab';
import {useQuery} from 'src/utils/useQuery';
import styled from 'styled-components';
import * as Yup from 'yup';

import {FormValues} from '../../types';
import GeneralTab from './GeneralTab';
import HistoryTab from './HistoryTab';
import ManufacturingTab from './ManufacturingTab';

type Props = {
  initialValues?: FormValues;
  onSubmit: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void;
  title: string;
  button: string;
  isCustomerInputDisabled?: boolean;
  isSubmitting: boolean;
};

function Form(props: Props) {
  const [activeFormStep, setActiveFormStep] = React.useState(1);

  const {initialValues, title, button, onSubmit, isCustomerInputDisabled} = props;

  const query = useQuery();

  const latitude = query.get('latitude');
  const longitude = query.get('longitude');

  const coordinatesProvided = !!latitude && !!longitude;

  const {
    submitCount,
    handleSubmit,
    errors,
    setFieldValue,
    values,
    validateForm,
    isValid,
  } = useFormik<FormValues>({
    initialValues: Object.assign(
      {},
      {
        assetStatus: 'maintained',
        externalId: '',
        installedAt: '',
        lastServicedAt: '',
        coordinates: {
          latitude: coordinatesProvided ? latitude : '',
          longitude: coordinatesProvided ? longitude : '',
        },
        manufacturerId: '',
        modelId: '',
        notes: '',
        serial: '',
        familyId: '',
        generalAssetName: '',
      },
      initialValues,
    ),
    isInitialValid: false,
    enableReinitialize: true,
    onSubmit,
    validationSchema,
  });

  React.useEffect(() => {
    validateForm();
  }, []);

  const isPage1Invalid =
    !!submitCount && (!!errors.serial || !!errors.externalId || !!errors.generalAssetName);

  const isPage2Invalid =
    !!submitCount &&
    (!!errors.lastServicedAt || !!errors.warrantyExpiresAt || !!errors.installedAt);
  const isPage3Invalid =
    !!submitCount && (!!errors.manufacturerId || !!errors.familyId || !!errors.modelId);
  const isPage4Invalid =
    !!submitCount && (!!errors.coordinates?.latitude || !!errors.coordinates?.latitude);

  return (
    <div className="p-8">
      <div className="mb-4 pb-1 border-b-2 border-gray-400">
        <h2 className="text-2xl text-gray-800 uppercase font-semibold">{title}</h2>
      </div>

      <div className="mt-2 flex items-center mb-4 pb-3 border-b-2 border-gray-400">
        <FormStepButton
          isInvalid={isPage1Invalid}
          onClick={() => setActiveFormStep(1)}
          isActive={activeFormStep >= 1}>
          General
        </FormStepButton>
        <FormStepLine isActive={activeFormStep >= 2} />
        <FormStepButton
          isInvalid={isPage2Invalid}
          onClick={() => setActiveFormStep(2)}
          isActive={activeFormStep >= 2}>
          History
        </FormStepButton>
        <FormStepLine isActive={activeFormStep >= 3} />
        <FormStepButton
          isInvalid={isPage3Invalid}
          onClick={() => setActiveFormStep(3)}
          isActive={activeFormStep >= 3}>
          Manufacturing
        </FormStepButton>
        <FormStepLine isActive={activeFormStep >= 4} />
        <FormStepButton
          isInvalid={isPage4Invalid}
          onClick={() => setActiveFormStep(4)}
          isActive={activeFormStep >= 4}>
          Location
        </FormStepButton>
      </div>

      <div>{renderFormPage()}</div>

      <div>
        {activeFormStep > 1 && (
          <button
            onClick={setPreviousFormTab}
            className={
              'px-5 py-2 text-sm rounded font-semibold text-white uppercase bg-blue-700 mr-4'
            }>
            previous
          </button>
        )}

        {activeFormStep < 4 && (
          <button
            onClick={setNextFormTab}
            className={
              'px-5 py-2 text-sm rounded font-semibold text-white uppercase bg-blue-700 mr-4'
            }>
            next
          </button>
        )}

        <button
          onClick={() => handleSubmit()}
          className={
            'px-5 py-2 text-sm rounded font-semibold text-white uppercase ' +
            (isValid ? 'bg-gray-700' : 'bg-gray-400')
          }>
          {button}
        </button>

        {(isPage1Invalid || isPage2Invalid || isPage3Invalid || isPage4Invalid) &&
          !!submitCount && (
            <p className="text-red-500 text-xs mt-2 italic">
              There are problems with some of the form fields.
            </p>
          )}
      </div>
    </div>
  );

  function setPreviousFormTab() {
    if (activeFormStep >= 1) {
      setActiveFormStep(activeFormStep - 1);
    }
  }

  function setNextFormTab() {
    if (activeFormStep <= 4) {
      setActiveFormStep(activeFormStep + 1);
    }
  }

  function renderFormPage() {
    switch (activeFormStep) {
      case 1: {
        return (
          <GeneralTab
            isCustomerInputDisabled={!!isCustomerInputDisabled}
            setFieldValue={setFieldValue}
            values={values}
            submitCount={submitCount}
            errors={errors}
          />
        );
      }
      case 2: {
        return (
          <HistoryTab
            setFieldValue={setFieldValue}
            values={values}
            submitCount={submitCount}
            errors={errors}
          />
        );
      }
      case 3: {
        return (
          <ManufacturingTab
            setFieldValue={setFieldValue}
            values={values}
            submitCount={submitCount}
            errors={errors}
          />
        );
      }
      case 4: {
        return (
          <LocationTab
            setFieldValue={setFieldValue}
            values={values}
            submitCount={submitCount}
            errors={errors}
          />
        );
      }
    }
  }
}

const FormStepButton = styled.button.attrs<{isInvalid: boolean; isActive: boolean}>(
  ({isActive, isInvalid}) => {
    let textStyle = 'text-gray-400';

    if (isActive) {
      textStyle = 'text-orange-500';
    }

    if (isInvalid) {
      textStyle = 'text-red-500';
    }

    return {
      className: 'uppercase focus:outline-none ' + textStyle,
    };
  },
)<{isActive: boolean; isInvalid: boolean}>``;

const FormStepLine = styled.div.attrs<{isActive: boolean}>(({isActive}) => {
  const activeStyle = isActive ? 'bg-orange-500' : 'bg-gray-400';

  return {
    className: 'w-12 mr-3 ml-3 h-px  ' + activeStyle,
  };
})<{isActive: boolean}>``;

const validationSchema = Yup.object({
  serial: Yup.string().required('Please provide a serial.'),
  externalId: Yup.string().required('Please provide an ID.'),
  installedAt: Yup.date().required('Please select an installation date.'),
  lastServicedAt: Yup.date().required('Please select a last serviced date.'),
  warrantyExpiresAt: Yup.date().required('Please select a warranty expiration date.'),
  generalAssetName: Yup.string().required('Please provide a general asset name.'),
  coordinates: Yup.object({
    latitude: Yup.string().required('Please provide a latitude.'),
    longitude: Yup.string().required('Please provide a longitude.'),
  }),
  manufacturerId: Yup.string().required('Please select a manufacturer.'),
  familyId: Yup.string().required('Please select a type.'),
  modelId: Yup.string().required('Please select a model.'),
});

export default Form;

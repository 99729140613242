import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';

import {MeasurementTemplatesInputQuery} from './__generated__/MeasurementTemplatesInputQuery.graphql';
import DropdownInput from './DropdownInput';

type Props = {
  value: string;
  onChange: (value: string) => void;
  error?: string;
};

function MeasurementTemplatesInput(props: Props) {
  const {value, onChange, error} = props;

  const data = useLazyLoadQuery<MeasurementTemplatesInputQuery>(
    templatesQuery,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  const options = !!data
    ? data.measurementTemplates.edges.map(templatesEdge => ({
        label: templatesEdge.node.name,
        value: templatesEdge.node.id,
      }))
    : [];

  return (
    <DropdownInput
      name="measurementTemplateInput"
      onChange={onChange}
      options={options}
      placeholder={'E.g. Residual Pressure'}
      label="Measurable Template"
      value={value}
      error={error}
    />
  );
}

const templatesQuery = graphql`
  query MeasurementTemplatesInputQuery {
    measurementTemplates {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default MeasurementTemplatesInput;

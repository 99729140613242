import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useMutation} from 'react-relay/hooks';
import {useHistory} from 'react-router';
import ConnectionHandler from 'relay-connection-handler-plus';
import {showEventNotification} from 'src/utils/notifications';
import * as Yup from 'yup';

import Form from '../components/Form';
import {FormValues} from '../types';
import {createPageCreateOrganizationUserMutation} from './__generated__/createPageCreateOrganizationUserMutation.graphql';

function CreatePage() {
  const history = useHistory();

  const [commit, isInFlight] = useMutation<createPageCreateOrganizationUserMutation>(
    createUserMutation,
  );

  return (
    <Form
      title="Create User"
      button="Create User"
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
      validationSchema={validationSchema}
    />
  );

  function handleSubmit(input: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          firstName: input.firstName,
          lastName: input.lastName,
          email: input.email,
          phone: input.phone,
          password: input.password,
          role: input.role,
        },
      },
      onCompleted: payload => {
        if (
          payload.createOrganizationUser?.errors[0] &&
          payload.createOrganizationUser?.errors[0].fields
        ) {
          payload.createOrganizationUser?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.createOrganizationUser?.organizationUser;

        if (entry) {
          showEventNotification('added', 'User', entry.firstName);
          return history.push('/admin/users');
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connectionRecords = ConnectionHandler.getConnections(
          root,
          'UsersList_organizationUsers',
        );

        if (!connectionRecords) {
          return;
        }

        const payload = store.getRootField('createOrganizationUser');

        if (!payload) {
          return;
        }

        const newRecord = payload.getLinkedRecord('organizationUser');

        if (!newRecord) {
          return;
        }

        connectionRecords.forEach(connectionRecord => {
          const newEdge = ConnectionHandler.createEdge(
            store,
            connectionRecord,
            newRecord,
            'organizationUserEdge',
          );

          ConnectionHandler.insertEdgeAfter(connectionRecord, newEdge);
        });
      },
    });
  }
}

const validationSchema = Yup.object({
  firstName: Yup.string().required('Please provide a first name.'),
  lastName: Yup.string().required('Please provide a last name.'),
  role: Yup.string().required('Please select a user role.'),
  phone: Yup.string().required('Please provide a phone number.'),
  email: Yup.string()
    .email('Please provide a valid email address.')
    .required('Please provide a valid email address.'),
  password: Yup.string()
    .required('Please provide a password.')
    .min(6, 'Your password must contain at least 6 characters.'),
  confirmPassword: Yup.string()
    .required('Please confirm your password.')
    .oneOf([Yup.ref('password')], 'Your passwords do not match.'),
});

const createUserMutation = graphql`
  mutation createPageCreateOrganizationUserMutation($input: CreateOrganizationUserInput!) {
    createOrganizationUser(input: $input) {
      organizationUser {
        id
        createdAt
        phone
        email
        firstName
        lastName
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default CreatePage;

import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';

import CreatePage from './create-page';
import ListPage from './list-page';
import UpdatePage from './update-page';

function Router() {
  const {path} = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact component={ListPage} />
      <Route path={`${path}/create`} exact component={CreatePage} />
      <Route path={`${path}/:id/edit`} exact component={UpdatePage} />
    </Switch>
  );
}

export default Router;

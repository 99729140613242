/**
 * @generated SignedSource<<9a56e64ac25e93cce4075ffea351019d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type UpdateHydrantFamilyInput = {
  hydrantFamilyId: string;
  updateHydrantFamilyData: UpdateHydrantFamilyData;
  clientMutationId?: string | null;
};
export type UpdateHydrantFamilyData = {
  name?: string | null;
  manufacturerId?: string | null;
};
export type updatePageUpdateHydrantFamilyMutation$variables = {
  input: UpdateHydrantFamilyInput;
};
export type updatePageUpdateHydrantFamilyMutationVariables = updatePageUpdateHydrantFamilyMutation$variables;
export type updatePageUpdateHydrantFamilyMutation$data = {
  readonly updateHydrantFamily: {
    readonly hydrantFamily: {
      readonly id: string;
      readonly createdAt: any;
      readonly name: string;
      readonly manufacturer: {
        readonly id: string;
        readonly name: string;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly fields: ReadonlyArray<{
        readonly fieldName: string;
        readonly message: string;
      }> | null;
    }>;
  } | null;
};
export type updatePageUpdateHydrantFamilyMutationResponse = updatePageUpdateHydrantFamilyMutation$data;
export type updatePageUpdateHydrantFamilyMutation = {
  variables: updatePageUpdateHydrantFamilyMutationVariables;
  response: updatePageUpdateHydrantFamilyMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "UserError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserFieldError",
      "kind": "LinkedField",
      "name": "fields",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageUpdateHydrantFamilyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateHydrantFamilyPayload",
        "kind": "LinkedField",
        "name": "updateHydrantFamily",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HydrantFamily",
            "kind": "LinkedField",
            "name": "hydrantFamily",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "manufacturer",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageUpdateHydrantFamilyMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateHydrantFamilyPayload",
        "kind": "LinkedField",
        "name": "updateHydrantFamily",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HydrantFamily",
            "kind": "LinkedField",
            "name": "hydrantFamily",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "manufacturer",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cd804b664533811347c9c3f77256b419",
    "id": null,
    "metadata": {},
    "name": "updatePageUpdateHydrantFamilyMutation",
    "operationKind": "mutation",
    "text": "mutation updatePageUpdateHydrantFamilyMutation(\n  $input: UpdateHydrantFamilyInput!\n) {\n  updateHydrantFamily(input: $input) {\n    hydrantFamily {\n      id\n      createdAt\n      name\n      manufacturer {\n        __typename\n        id\n        name\n      }\n    }\n    errors {\n      code\n      fields {\n        fieldName\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c40869a993ec9c7665f60ea3d59d543d";

export default node;

/**
 * @generated SignedSource<<7cda21d4b08f0fd535a2600a046c1405>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type detailsPageValveQuery$variables = {
  valveId: string;
};
export type detailsPageValveQueryVariables = detailsPageValveQuery$variables;
export type detailsPageValveQuery$data = {
  readonly valve: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"GeneralTab_valve" | "LocationTab_asset">;
  } | null;
};
export type detailsPageValveQueryResponse = detailsPageValveQuery$data;
export type detailsPageValveQuery = {
  variables: detailsPageValveQueryVariables;
  response: detailsPageValveQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "valveId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "valveId",
    "variableName": "valveId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v2/*: any*/),
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "detailsPageValveQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Valve",
        "kind": "LinkedField",
        "name": "valve",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GeneralTab_valve"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LocationTab_asset"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "detailsPageValveQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Valve",
        "kind": "LinkedField",
        "name": "valve",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "turnsToOpenClose",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ValveConnector",
            "kind": "LinkedField",
            "name": "valveConnector",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MeasurementConnection",
            "kind": "LinkedField",
            "name": "currentMeasurements",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MeasurementEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Measurement",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MeasurementTemplate",
                        "kind": "LinkedField",
                        "name": "template",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serial",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "externalId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "installedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "warrantyExpiresAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastServicedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "model",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "family",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "manufacturer",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coordinates",
                "storageKey": null
              }
            ],
            "type": "Asset",
            "abstractKey": "__isAsset"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b3c1fccde92392ea586ec9291d7c07ba",
    "id": null,
    "metadata": {},
    "name": "detailsPageValveQuery",
    "operationKind": "query",
    "text": "query detailsPageValveQuery(\n  $valveId: ID!\n) {\n  valve(valveId: $valveId) {\n    id\n    ...GeneralTab_valve\n    ...LocationTab_asset\n  }\n}\n\nfragment DetailsSection_valve on Valve {\n  id\n  status\n  turnsToOpenClose\n  valveConnector {\n    id\n    name\n  }\n}\n\nfragment GeneralTab_valve on Valve {\n  id\n  ...IdentificationSection_asset\n  ...HistorySection_asset\n  ...ManufacturingSection_asset\n  ...DetailsSection_valve\n  currentMeasurements {\n    ...MeasurementsSection_currentMeasurements\n  }\n}\n\nfragment HistorySection_asset on Asset {\n  __isAsset: __typename\n  id\n  installedAt\n  warrantyExpiresAt\n  lastServicedAt\n}\n\nfragment IdentificationSection_asset on Asset {\n  __isAsset: __typename\n  id\n  serial\n  externalId\n}\n\nfragment LocationTab_asset on Asset {\n  __isAsset: __typename\n  id\n  coordinates\n}\n\nfragment ManufacturingSection_asset on Asset {\n  __isAsset: __typename\n  id\n  model {\n    __typename\n    id\n    name\n  }\n  family {\n    __typename\n    id\n    name\n  }\n  manufacturer {\n    __typename\n    id\n    name\n  }\n}\n\nfragment MeasurementsSection_currentMeasurements on MeasurementConnection {\n  edges {\n    node {\n      id\n      value\n      createdAt\n      template {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd754cdca49986caba476a5de924d2e9";

export default node;

import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

type Props = {
  status: string;
};

const assetStatusMap: {[key: string]: {title: string; className: string}} = {
  overdue: {title: 'Overdue', className: 'text-red-500'},
  maintained: {title: 'Maintained', className: 'text-green-500'},
  due_soon: {title: 'Due Soon', className: 'text-orange-500'},
  out_of_service: {title: 'Not In Service', className: 'text-gray-500'},
};

function AssetStatusLabel({status}: Props) {
  return (
    <LabelContainer>
      <StatusIndicator icon={faCircle} value={status} />
      <span className="ml-1">{assetStatusMap[status].title}</span>
    </LabelContainer>
  );
}

const StatusIndicator = styled(FontAwesomeIcon).attrs<{value: string}>(({value}) => ({
  className: 'text-xs ' + assetStatusMap[value].className,
}))<{value: string}>``;

const LabelContainer = styled.div.attrs({
  className: 'text-gray-800 text-base flex items-center',
})``;

export default AssetStatusLabel;

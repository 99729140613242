/**
 * @generated SignedSource<<172c9c0883062ebfec83247e8ffbd556>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type settingsPageQuery$variables = {};
export type settingsPageQueryVariables = settingsPageQuery$variables;
export type settingsPageQuery$data = {
  readonly settings: {
    readonly id: string;
    readonly minimumAppVersionIos: string | null;
    readonly minimumAppVersionAndroid: string | null;
  } | null;
};
export type settingsPageQueryResponse = settingsPageQuery$data;
export type settingsPageQuery = {
  variables: settingsPageQueryVariables;
  response: settingsPageQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Setting",
    "kind": "LinkedField",
    "name": "settings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "minimumAppVersionIos",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "minimumAppVersionAndroid",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingsPageQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingsPageQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ad004ab82b1274f8c5eaeafd4859c6d0",
    "id": null,
    "metadata": {},
    "name": "settingsPageQuery",
    "operationKind": "query",
    "text": "query settingsPageQuery {\n  settings {\n    id\n    minimumAppVersionIos\n    minimumAppVersionAndroid\n  }\n}\n"
  }
};
})();

(node as any).hash = "a34a76dc8057ee41b042f237fba54453";

export default node;

import {useFormik, FormikHelpers} from 'formik';
import React from 'react';
import * as Yup from 'yup';

import AssetKindInput from '../../../components/inputs/AssetKindInput';
import DataKindInput from '../../../components/inputs/DataKindInput';
import TextInput from '../../../components/inputs/TextInput';
import {FormValues} from '../types';

type Props = {
  initialValues?: FormValues;
  onSubmit: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void;
  title: string;
  button: string;
  isSubmitting: boolean;
};

function Form(props: Props) {
  const {isSubmitting, initialValues, title, button, onSubmit} = props;

  const {submitCount, handleSubmit, errors, setFieldValue, values, isValid} = useFormik<FormValues>(
    {
      initialValues: Object.assign(
        {},
        {
          name: '',
          dataKind: 'percentage',
          assetKind: 'hydrant',
        },
        initialValues,
      ),
      isInitialValid: false,
      enableReinitialize: true,
      onSubmit,
      validationSchema,
    },
  );

  return (
    <div className="p-8">
      <div className="mb-6 pb-1 border-b-2 border-gray-400">
        <h2 className="text-2xl text-gray-800 uppercase font-semibold">{title}</h2>
      </div>

      <TextInput
        name="name"
        label="Name"
        placeholder="E.g. Residual Pressure"
        handleChange={value => setFieldValue('name', value)}
        value={values.name}
        error={!!submitCount && !!errors.name ? errors.name : ''}
      />

      <AssetKindInput
        value={values.assetKind}
        onChange={assetKind => setFieldValue('assetKind', assetKind)}
      />

      <DataKindInput
        value={values.dataKind}
        onChange={dataKind => setFieldValue('dataKind', dataKind)}
      />

      <button
        disabled={isSubmitting}
        onClick={() => handleSubmit()}
        className={
          'px-5 py-2 text-sm rounded font-semibold text-white uppercase ' +
          (isValid ? 'bg-gray-700' : 'bg-gray-400')
        }>
        {button}
      </button>
    </div>
  );
}

const validationSchema = Yup.object({
  name: Yup.string().required('Please provide a manufacturer name.'),
});

export default Form;

/**
 * @generated SignedSource<<7dc7974764b3d964697b3069628b5427>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ImageRecordListPaginationQuery$variables = {
  after?: string | null;
  assetId: string;
  first: number;
};
export type ImageRecordListPaginationQueryVariables = ImageRecordListPaginationQuery$variables;
export type ImageRecordListPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ImagesTabImagesList_imageRecords">;
};
export type ImageRecordListPaginationQueryResponse = ImageRecordListPaginationQuery$data;
export type ImageRecordListPaginationQuery = {
  variables: ImageRecordListPaginationQueryVariables;
  response: ImageRecordListPaginationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assetId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "assetId",
    "variableName": "assetId"
  },
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImageRecordListPaginationQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ImagesTabImagesList_imageRecords"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImageRecordListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ImageRecordConnection",
        "kind": "LinkedField",
        "name": "imageRecordsByAsset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ImageRecordEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ImageRecord",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationUser",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "imageUrl",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": [
          "assetId"
        ],
        "handle": "connection",
        "key": "ImageRecordList_imageRecordsByAsset",
        "kind": "LinkedHandle",
        "name": "imageRecordsByAsset"
      }
    ]
  },
  "params": {
    "cacheID": "1edf1241f1058074b750b7a20438cd57",
    "id": null,
    "metadata": {},
    "name": "ImageRecordListPaginationQuery",
    "operationKind": "query",
    "text": "query ImageRecordListPaginationQuery(\n  $after: String\n  $assetId: ID!\n  $first: Int!\n) {\n  ...ImagesTabImagesList_imageRecords_2HEEH6\n}\n\nfragment ImageItem_imageRecord on ImageRecord {\n  id\n  createdAt\n  author {\n    id\n    firstName\n    lastName\n  }\n  imageUrl\n}\n\nfragment ImagesTabImagesList_imageRecords_2HEEH6 on Query {\n  imageRecordsByAsset(assetId: $assetId, first: $first, after: $after) {\n    edges {\n      node {\n        id\n        ...ImageItem_imageRecord\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ad024f185809d5c5d7c733ea3ae3291b";

export default node;

import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useHistory, useParams} from 'react-router';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/Form';
import {FormValues} from '../types';
import {updatePageTaskTemplateQuery} from './__generated__/updatePageTaskTemplateQuery.graphql';
import {updatePageUpdateTaskTemplateMutation} from './__generated__/updatePageUpdateTaskTemplateMutation.graphql';

function UpdatePage() {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [commit, isInFlight] = useMutation<updatePageUpdateTaskTemplateMutation>(
    updateTaskTemplateMutation,
  );

  const data = useLazyLoadQuery<updatePageTaskTemplateQuery>(query, {
    taskTemplateId: id,
  });

  if (!data.taskTemplate) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">The item you wish to update could not be found.</h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      title="Update General Asset Manufacturer"
      button="Update General Asset Manufacturer"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleInitialValues() {
    if (!data || !data.taskTemplate) {
      return;
    }

    const {taskTemplate} = data;

    return {
      name: taskTemplate.name,
      taskKind: taskTemplate.taskKind,
      measurementTemplateId: taskTemplate.measurementTemplate?.id || '',
    };
  }

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    const input = {
      name: values.name,
      taskKind: values.taskKind,
      measurementTemplateId: values.measurementTemplateId || null,
    };

    commit({
      variables: {
        input: {
          taskTemplateId: id,
          updateTaskTemplateData: input,
        },
      },
      onCompleted: payload => {
        if (payload.updateTaskTemplate?.errors[0] && payload.updateTaskTemplate?.errors[0].fields) {
          payload.updateTaskTemplate?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.updateTaskTemplate?.taskTemplate;

        if (entry) {
          showEventNotification('updated', 'Template', entry.name);

          if (history.location.search.includes('newTab')) {
            window.close();
          }

          return history.push('/admin/task_templates');
        }
      },
    });
  }
}

const query = graphql`
  query updatePageTaskTemplateQuery($taskTemplateId: ID!) {
    taskTemplate(taskTemplateId: $taskTemplateId) {
      id
      createdAt
      name
      taskKind
      measurementTemplate {
        id
      }
    }
  }
`;

const updateTaskTemplateMutation = graphql`
  mutation updatePageUpdateTaskTemplateMutation($input: UpdateTaskTemplateInput!) {
    updateTaskTemplate(input: $input) {
      taskTemplate {
        id
        createdAt
        name
        taskKind
        measurementTemplate {
          id
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(UpdatePage);

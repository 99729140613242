import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useFragment} from 'react-relay/hooks';

import {IdentificationSection_asset$key} from './__generated__/IdentificationSection_asset.graphql';

type Props = {
  asset: IdentificationSection_asset$key;
};

export default function IdentificationSection(props: Props) {
  const asset = useFragment(
    graphql`
      fragment IdentificationSection_asset on Asset {
        id
        serial
        externalId
      }
    `,
    props.asset,
  );

  return (
    <div className="border-b border-t pb-4 pt-4">
      <div className="flex">
        <div className="w-40">
          <h3 className="text-gray-700 text-sm uppercase font-semibold">Identification</h3>
        </div>
        <div>
          <div className="flex items-end mb-2">
            <div className="w-40 flex justify-start mr-1">
              <h3 className="text-gray-600 text-sm">Serial Number</h3>
            </div>
            <h3 className="text-gray-800 uppercase leading-snug text-base">{asset.serial}</h3>
          </div>

          <div className="flex items-end">
            <div className="w-40 flex justify-start mr-1">
              <h3 className="text-gray-600 text-sm">ID Number</h3>
            </div>
            <h3 className="text-gray-800 uppercase leading-snug text-base">{asset.externalId}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

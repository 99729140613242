import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {usePerPage} from 'src/hooks/usePerPage';
import {useQuery} from 'src/utils/useQuery';
import {withSuspense} from 'src/utils/withSuspense';

import {listPageJobsQuery} from './__generated__/listPageJobsQuery.graphql';
import JobsTable from './JobsTable';

function ListPage() {
  const query = useQuery();
  const {perPage} = usePerPage();

  const assetSerial = query.get('asset_serial');

  const data = useLazyLoadQuery<listPageJobsQuery>(
    jobs,
    {assetSerial: assetSerial, first: perPage * 2},
    {fetchPolicy: 'store-and-network'},
  );

  return <JobsTable jobs={data} />;
}

export default withSuspense(ListPage);

const jobs = graphql`
  query listPageJobsQuery($assetSerial: String, $first: Int!) {
    ...JobsTable_jobs @arguments(first: $first, assetSerial: $assetSerial)
  }
`;

import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useHistory, useParams} from 'react-router';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/Form';
import {FormValues} from '../types';
import {updatePageUpdateValveFamilyMutation} from './__generated__/updatePageUpdateValveFamilyMutation.graphql';
import {updatePageValveFamilyQuery} from './__generated__/updatePageValveFamilyQuery.graphql';

function UpdatePage() {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [commit, isInFlight] = useMutation<updatePageUpdateValveFamilyMutation>(
    updateValveFamilyMutation,
  );

  const data = useLazyLoadQuery<updatePageValveFamilyQuery>(query, {
    valveFamilyId: id,
  });

  if (!data.valveFamily) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">The item you wish to update could not be found.</h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      title="Update Valve Type"
      button="Update Valve Type"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleInitialValues() {
    if (!data || !data.valveFamily) {
      return;
    }

    const {valveFamily} = data;

    return {
      name: valveFamily.name,
      manufacturerId: valveFamily.manufacturer.id,
    };
  }

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          valveFamilyId: id,
          updateValveFamilyData: values,
        },
      },
      onCompleted: payload => {
        if (payload.updateValveFamily?.errors[0] && payload.updateValveFamily?.errors[0].fields) {
          payload.updateValveFamily?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.updateValveFamily?.valveFamily;

        if (entry) {
          showEventNotification('updated', 'Type', entry.name);

          if (history.location.search.includes('newTab')) {
            window.close();
          }

          return history.push('/admin/valve_types');
        }
      },
    });
  }
}

const query = graphql`
  query updatePageValveFamilyQuery($valveFamilyId: ID!) {
    valveFamily(valveFamilyId: $valveFamilyId) {
      id
      createdAt
      name
      manufacturer {
        id
        name
      }
    }
  }
`;

const updateValveFamilyMutation = graphql`
  mutation updatePageUpdateValveFamilyMutation($input: UpdateValveFamilyInput!) {
    updateValveFamily(input: $input) {
      valveFamily {
        id
        createdAt
        name
        manufacturer {
          id
          name
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(UpdatePage);

/**
 * @generated SignedSource<<5bad43983d6c24f9b202aee0886bd27f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type NavigationUserActionsQuery$variables = {};
export type NavigationUserActionsQueryVariables = NavigationUserActionsQuery$variables;
export type NavigationUserActionsQuery$data = {
  readonly currentUser: {
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
  };
};
export type NavigationUserActionsQueryResponse = NavigationUserActionsQuery$data;
export type NavigationUserActionsQuery = {
  variables: NavigationUserActionsQueryVariables;
  response: NavigationUserActionsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigationUserActionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NavigationUserActionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4b21c638cc97c01854b37251c07c5d07",
    "id": null,
    "metadata": {},
    "name": "NavigationUserActionsQuery",
    "operationKind": "query",
    "text": "query NavigationUserActionsQuery {\n  currentUser {\n    __typename\n    id\n    firstName\n    lastName\n  }\n}\n"
  }
};
})();

(node as any).hash = "0545b89e9cd2409ed484bbaab7e788c9";

export default node;

import React from 'react';
import {useHistory, useRouteMatch} from 'react-router';
import {BarLoader} from 'react-spinners';
import {useTable, useSortBy, usePagination} from 'react-table';
import {usePerPage} from 'src/hooks/usePerPage';

import Body from './Body';
import Header from './Header';
import Pagination from './Pagination';

type Props = {
  actions: {show: boolean; delete: boolean; edit: boolean; create: boolean};
  columns: any;
  data: any;
  isTransitioning?: boolean;
  filters?: () => React.ReactNode;
  onDelete?: (id: string, sortByConfig?: any) => void;
  onPaginate: (offset: number) => void;
  onSort: (sortByConfig: any) => void;
  totalDataLength: number;
};

function Table(props: Props) {
  const history = useHistory();
  const {path} = useRouteMatch();
  const {perPage} = usePerPage();

  const {
    actions: {create, ...otherActions},
    columns,
    data,
    filters,
    onPaginate,
    onDelete,
    onSort,
    totalDataLength,
  } = props;

  const [isInitialSortLoaded, setIsInitialSortLoaded] = React.useState(false);

  const pageCount = Math.ceil(totalDataLength / perPage);

  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    prepareRow,
    previousPage,
    setPageSize,
    state: {pageIndex, pageSize, sortBy},
  } = useTable(
    {
      autoResetPage: false,
      columns,
      data,
      initialState: {pageSize: perPage},
      manualSortBy: true,
    },
    useSortBy,
    usePagination,
  );

  React.useEffect(() => {
    if (!sortBy.length && !isInitialSortLoaded) {
      setIsInitialSortLoaded(true);
      return;
    }

    onSort(sortBy);
    gotoPage(0);
  }, [sortBy]);

  React.useEffect(() => {
    gotoPage(0);
  }, [totalDataLength]);

  React.useEffect(() => {
    gotoPage(0);
    setPageSize(perPage);
  }, [perPage]);

  return (
    <div className="h-full w-full">
      <BarLoader color={props.isTransitioning ? '#ed8936' : 'white'} width="100%" />
      <div className="p-4 pt-4 pb-1 flex items-baseline justify-between">
        {filters && filters()}

        {create && (
          <button
            onClick={navigateToCreate}
            className="bg-blue-800 text-white uppercase font-medium text-sm leading-loose rounded shadow mt-auto mb-6 focus:outline-none px-4 py-2">
            Create
          </button>
        )}
      </div>

      <Pagination
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        nextPage={handleNextPageLoad}
        pageCount={pageCount}
        pageIndex={pageIndex}
        previousPage={previousPage}
      />

      <div className="overflow-auto">
        <table className="w-full border-collapse">
          <Header headerGroups={headerGroups} />
          <Body
            actions={otherActions}
            onDelete={handleDelete}
            page={page}
            prepareRow={prepareRow}
            tableBodyProps={getTableBodyProps()}
          />
        </table>
      </div>

      <Pagination
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        nextPage={handleNextPageLoad}
        pageCount={pageCount}
        pageIndex={pageIndex}
        previousPage={previousPage}
      />
    </div>
  );

  function handleDelete(id: string) {
    onDelete?.(id, sortBy);
  }

  function navigateToCreate() {
    history.push(`${path}/create`);
  }

  function handleNextPageLoad() {
    const fetchedDataLength = data.length;

    const nextDataPosition = (pageIndex + 1) * pageSize + pageSize;

    const dataFetchIsRequiredTresshold = fetchedDataLength / 2;

    const nextDataPositionExceedsTresshold = nextDataPosition >= dataFetchIsRequiredTresshold;

    const dataIsStillFetchable = data.length < totalDataLength;
    const dataIsLargerThanInitialFetch = data.length >= perPage * 2;

    if (nextDataPositionExceedsTresshold && dataIsStillFetchable && dataIsLargerThanInitialFetch) {
      onPaginate(1);
    }

    nextPage();
  }
}

export default Table;

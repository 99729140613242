import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useFragment} from 'react-relay/hooks';
import AssetStatusLabel from 'src/components/AssetStatusLabel';

import {DetailsSection_generalAsset$key} from './__generated__/DetailsSection_generalAsset.graphql';

type Props = {
  generalAsset: DetailsSection_generalAsset$key;
};

export default function DetailsSection(props: Props) {
  const generalAsset = useFragment(
    graphql`
      fragment DetailsSection_generalAsset on GeneralAsset {
        id
        status
        generalAssetName
      }
    `,
    props.generalAsset,
  );

  return (
    <div className="border-b pb-4 pt-4">
      <div className="flex">
        <div className="w-40">
          <h3 className="text-gray-700 text-sm uppercase font-semibold">Details</h3>
        </div>
        <div>
          <div className="flex items-end mb-2">
            <div className="w-40 flex justify-start mr-1">
              <h3 className="text-gray-600 text-sm">Status</h3>
            </div>
            <AssetStatusLabel status={generalAsset.status} />
          </div>
          <div className="flex items-end mb-2">
            <div className="w-40 flex justify-start mr-1">
              <h3 className="text-gray-600 text-sm">Name</h3>
            </div>
            <h3 className="text-gray-800 leading-snug text-base">
              {generalAsset.generalAssetName}
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {Lightbox} from 'react-modal-image';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {useParams} from 'react-router';
import {withSuspense} from 'src/utils/withSuspense';
import styled from 'styled-components';

import HistoryTab from '../../../components/HistoryTab';
import ImagesTab from '../../../components/ImagesTab';
import LocationTab from '../../../components/LocationTab';
import {detailsPageHydrantQuery} from './__generated__/detailsPageHydrantQuery.graphql';
import GeneralTab from './GeneralTab';

function DetailsPage() {
  const {id} = useParams();

  const data = useLazyLoadQuery<detailsPageHydrantQuery>(
    graphql`
      query detailsPageHydrantQuery($hydrantId: ID!) {
        hydrant(hydrantId: $hydrantId) {
          id
          ...GeneralTab_hydrant
          ...LocationTab_asset
        }
      }
    `,
    {hydrantId: id},
    {fetchPolicy: 'store-and-network'},
  );

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = React.useState(false);
  const [selectedImage, setselectedImage] = React.useState('');

  return (
    <div className="flex flex-col flex-1 flex-grow bg-white">
      <div className="bg-gray-200 flex border-b border-orange-500">
        <DetailsTab onClick={() => setSelectedIndex(0)} selected={selectedIndex === 0}>
          General
        </DetailsTab>
        <DetailsTab onClick={() => setSelectedIndex(1)} selected={selectedIndex === 1}>
          History
        </DetailsTab>
        <DetailsTab onClick={() => setSelectedIndex(2)} selected={selectedIndex === 2}>
          Images
        </DetailsTab>
        <DetailsTab onClick={() => setSelectedIndex(3)} selected={selectedIndex === 3}>
          Location
        </DetailsTab>
      </div>

      {renderTab()}

      {!!isLightboxOpen && !!selectedImage && (
        <Lightbox
          medium={selectedImage}
          large={selectedImage}
          onClose={() => setIsLightboxOpen(false)}
        />
      )}
    </div>
  );

  function handleImageSelect(imageUrl: string) {
    setselectedImage(imageUrl);
    setIsLightboxOpen(true);
  }

  function renderTab() {
    if (!data.hydrant) {
      return;
    }

    switch (selectedIndex) {
      case 0: {
        return <GeneralTab hydrant={data.hydrant} />;
      }
      case 1: {
        return <HistoryTab onImageClick={handleImageSelect} assetId={data.hydrant.id} />;
      }
      case 2: {
        return <ImagesTab onImageClick={handleImageSelect} assetId={data.hydrant.id} />;
      }
      case 3: {
        return <LocationTab asset={data.hydrant} />;
      }
    }
  }
}

const DetailsTab = styled.button.attrs<{selected: boolean}>(({selected}) => {
  const selectedStyle = selected ? 'text-orange-500 border-orange-500' : 'border-gray-200';

  return {
    className:
      'px-6 py-4 focus:outline-none cursor-pointer text-sm text-gray-600 uppercase font-semibold border-b-4 ' +
      selectedStyle,
  };
})<{selected: boolean}>``;

export default withSuspense(DetailsPage);

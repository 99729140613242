/**
 * @generated SignedSource<<0fd8acc44aed977c100dff865017f9d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type updatePageJobQuery$variables = {
  jobId: string;
};
export type updatePageJobQueryVariables = updatePageJobQuery$variables;
export type updatePageJobQuery$data = {
  readonly job: {
    readonly id: string;
    readonly createdAt: any;
    readonly dueAt: any;
    readonly completedAt: any | null;
    readonly jobNumber: number;
    readonly assignee: {
      readonly id: string;
      readonly firstName: string;
      readonly lastName: string;
    };
    readonly asset: {
      readonly id: string;
      readonly serial: string;
    };
    readonly notes: string | null;
    readonly tasks: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly template: {
            readonly id: string;
            readonly name: string;
          };
        };
      }>;
    };
  } | null;
};
export type updatePageJobQueryResponse = updatePageJobQuery$data;
export type updatePageJobQuery = {
  variables: updatePageJobQueryVariables;
  response: updatePageJobQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "jobId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "jobId",
    "variableName": "jobId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobNumber",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationUser",
  "kind": "LinkedField",
  "name": "assignee",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serial",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "TaskConnection",
  "kind": "LinkedField",
  "name": "tasks",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Task",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskTemplate",
              "kind": "LinkedField",
              "name": "template",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageJobQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Job",
        "kind": "LinkedField",
        "name": "job",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "asset",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageJobQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Job",
        "kind": "LinkedField",
        "name": "job",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "asset",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5e671f035adcc6b6527bf06bfef1a251",
    "id": null,
    "metadata": {},
    "name": "updatePageJobQuery",
    "operationKind": "query",
    "text": "query updatePageJobQuery(\n  $jobId: ID!\n) {\n  job(jobId: $jobId) {\n    id\n    createdAt\n    dueAt\n    completedAt\n    jobNumber\n    assignee {\n      id\n      firstName\n      lastName\n    }\n    asset {\n      __typename\n      id\n      serial\n    }\n    notes\n    tasks {\n      edges {\n        node {\n          id\n          template {\n            id\n            name\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b3a05e2ab64ba41743c70c6720ccb02b";

export default node;

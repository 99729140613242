/**
 * @generated SignedSource<<790682a9fe8bf7cc63c1e19e9dd531f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type UpdateAdminUserInput = {
  adminUserId: string;
  updateAdminUserData: UpdateAdminUserData;
  clientMutationId?: string | null;
};
export type UpdateAdminUserData = {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  phone?: string | null;
};
export type updatePageUpdateAdminUserMutation$variables = {
  input: UpdateAdminUserInput;
};
export type updatePageUpdateAdminUserMutationVariables = updatePageUpdateAdminUserMutation$variables;
export type updatePageUpdateAdminUserMutation$data = {
  readonly updateAdminUser: {
    readonly adminUser: {
      readonly id: string;
      readonly createdAt: any;
      readonly firstName: string;
      readonly lastName: string;
      readonly phone: string;
      readonly email: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly fields: ReadonlyArray<{
        readonly fieldName: string;
        readonly message: string;
      }> | null;
    }>;
  } | null;
};
export type updatePageUpdateAdminUserMutationResponse = updatePageUpdateAdminUserMutation$data;
export type updatePageUpdateAdminUserMutation = {
  variables: updatePageUpdateAdminUserMutationVariables;
  response: updatePageUpdateAdminUserMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateAdminUserPayload",
    "kind": "LinkedField",
    "name": "updateAdminUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminUser",
        "kind": "LinkedField",
        "name": "adminUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFieldError",
            "kind": "LinkedField",
            "name": "fields",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fieldName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageUpdateAdminUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageUpdateAdminUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "da3d0ba44784c052ace4eea2acffd841",
    "id": null,
    "metadata": {},
    "name": "updatePageUpdateAdminUserMutation",
    "operationKind": "mutation",
    "text": "mutation updatePageUpdateAdminUserMutation(\n  $input: UpdateAdminUserInput!\n) {\n  updateAdminUser(input: $input) {\n    adminUser {\n      id\n      createdAt\n      firstName\n      lastName\n      phone\n      email\n    }\n    errors {\n      code\n      fields {\n        fieldName\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a4955822207b378928e17c18a28bb2a0";

export default node;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useFragment} from 'react-relay/hooks';
import {formatDateTimeUS} from 'src/utils/formatDate';

import {MeasurementRecordItem_measurementRecord$key} from './__generated__/MeasurementRecordItem_measurementRecord.graphql';

type Props = {record: MeasurementRecordItem_measurementRecord$key};

export default function MeasurementRecordItem(props: Props) {
  const record = useFragment(
    graphql`
      fragment MeasurementRecordItem_measurementRecord on MeasurementRecord {
        id
        createdAt
        author {
          id
          firstName
          lastName
        }
        measurement {
          id
          value
          template {
            id
            name
          }
        }
      }
    `,
    props.record,
  );

  const createdAt = formatDateTimeUS(record.createdAt);

  return (
    <div key={record.id} className="w-full border rounded shadow px-4 py-2 mb-4">
      <div style={{flexDirection: 'column', alignItems: 'flex-start'}}>
        <p className="text-gray-700 text-sm">
          <span className="text-gray-700 font-semibold">
            {record.author.firstName} {record.author.lastName}
          </span>{' '}
          added a<span className="text-gray-700 font-semibold"> measurement</span>
        </p>
        <div className="mt-1 mb-1">
          <p className="text-gray-700 text-sm">
            Type:
            <b> {record.measurement.template.name}</b>
          </p>
          <p className="text-gray-700 text-sm">
            Value:
            <b> {record.measurement.value}</b>
          </p>
        </div>
      </div>
      <div className="mt-2">
        <p className="text-xs text-gray-600">{createdAt}</p>
      </div>
    </div>
  );
}

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useFragment} from 'react-relay/hooks';
import {formatDateUS} from 'src/utils/formatDate';

import {HistorySection_asset$key} from './__generated__/HistorySection_asset.graphql';

type Props = {
  asset: HistorySection_asset$key;
};

export default function HistorySection(props: Props) {
  const asset = useFragment(
    graphql`
      fragment HistorySection_asset on Asset {
        id
        installedAt
        warrantyExpiresAt
        lastServicedAt
      }
    `,
    props.asset,
  );

  const installedAt = formatDateUS(asset.installedAt);
  const lastServicedAt = formatDateUS(asset.lastServicedAt);
  const warrantyExpiresAt = formatDateUS(asset.warrantyExpiresAt);

  return (
    <div className="border-b pb-4 pt-4">
      <div className="flex">
        <div className="w-40">
          <h3 className="text-gray-700 text-sm uppercase font-semibold">History</h3>
        </div>
        <div>
          <div className="flex items-end mb-2">
            <div className="w-40 flex justify-start mr-1">
              <h3 className="text-gray-600 text-sm">Installed</h3>
            </div>
            <h3 className="text-gray-800 uppercase leading-snug text-base">{installedAt}</h3>
          </div>

          <div className="flex items-end mb-2">
            <div className="w-40 flex justify-start mr-1">
              <h3 className="text-gray-600 text-sm">Last Serviced</h3>
            </div>
            <h3 className="text-gray-800 uppercase leading-snug text-base">{lastServicedAt}</h3>
          </div>
          <div className="flex items-end">
            <div className="w-40 flex justify-start mr-1">
              <h3 className="text-gray-600 text-sm">Warranty Expires</h3>
            </div>
            <h3 className="text-gray-800 uppercase leading-snug text-base">{warrantyExpiresAt}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

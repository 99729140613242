/**
 * @generated SignedSource<<1376bed2d85e1aed4586e32d70c036ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type CreateGeneralAssetFamilyInput = {
  name: string;
  manufacturerId: string;
  clientMutationId?: string | null;
};
export type createPageCreateGeneralAssetFamilyMutation$variables = {
  input: CreateGeneralAssetFamilyInput;
};
export type createPageCreateGeneralAssetFamilyMutationVariables = createPageCreateGeneralAssetFamilyMutation$variables;
export type createPageCreateGeneralAssetFamilyMutation$data = {
  readonly createGeneralAssetFamily: {
    readonly generalAssetFamily: {
      readonly id: string;
      readonly createdAt: any;
      readonly name: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly fields: ReadonlyArray<{
        readonly fieldName: string;
        readonly message: string;
      }> | null;
    }>;
  } | null;
};
export type createPageCreateGeneralAssetFamilyMutationResponse = createPageCreateGeneralAssetFamilyMutation$data;
export type createPageCreateGeneralAssetFamilyMutation = {
  variables: createPageCreateGeneralAssetFamilyMutationVariables;
  response: createPageCreateGeneralAssetFamilyMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateGeneralAssetFamilyPayload",
    "kind": "LinkedField",
    "name": "createGeneralAssetFamily",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GeneralAssetFamily",
        "kind": "LinkedField",
        "name": "generalAssetFamily",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFieldError",
            "kind": "LinkedField",
            "name": "fields",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fieldName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createPageCreateGeneralAssetFamilyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createPageCreateGeneralAssetFamilyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b489397d25086a74232010e882fd3883",
    "id": null,
    "metadata": {},
    "name": "createPageCreateGeneralAssetFamilyMutation",
    "operationKind": "mutation",
    "text": "mutation createPageCreateGeneralAssetFamilyMutation(\n  $input: CreateGeneralAssetFamilyInput!\n) {\n  createGeneralAssetFamily(input: $input) {\n    generalAssetFamily {\n      id\n      createdAt\n      name\n    }\n    errors {\n      code\n      fields {\n        fieldName\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd70d7b3d42f579c9ae5568d14e4049c";

export default node;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {withSuspense} from 'src/utils/withSuspense';

import {valveMeasurementReportPageassetsQuery} from './__generated__/valveMeasurementReportPageassetsQuery.graphql';
import {valveMeasurementReportPagemeasurementTemplatesQuery} from './__generated__/valveMeasurementReportPagemeasurementTemplatesQuery.graphql';
import ValvesTable from './ValvesTable';

function ValveMeasurementReportPage() {
  const templatesData = useLazyLoadQuery<valveMeasurementReportPagemeasurementTemplatesQuery>(
    measurementTemplates,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  const data = useLazyLoadQuery<valveMeasurementReportPageassetsQuery>(
    valves,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  const templates = templatesData?.measurementTemplates.edges || [];

  return <ValvesTable measurementTemplates={templates} assets={data} />;
}

export default withSuspense(ValveMeasurementReportPage);

const valves = graphql`
  query valveMeasurementReportPageassetsQuery(
    $first: Int
    $createdBefore: ISO8601DateTime
    $status: AssetStatus
    $text: String
  ) {
    ...ValvesTable_assets
      @arguments(first: $first, createdBefore: $createdBefore, status: $status, text: $text)
  }
`;

const measurementTemplates = graphql`
  query valveMeasurementReportPagemeasurementTemplatesQuery {
    measurementTemplates(assetType: valve) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {withSuspense} from 'src/utils/withSuspense';

import {dashboardPageAssetStatisticsQuery} from './__generated__/dashboardPageAssetStatisticsQuery.graphql';
import AssetCountWidget from './widgets/AssetCountWidget';
import DelayedJobsWidget from './widgets/DelayedJobsWidget';
import HydrantGpmCountWidget from './widgets/HydrantGpmCountWidget';
import JobsAssignedWidget from './widgets/JobsAssignedWidget';
import ShortcutsWidget from './widgets/ShortcutsWidget';
import TasksOverviewWidget from './widgets/TasksOverviewWidget';

function DashboardPage() {
  const data = useLazyLoadQuery<dashboardPageAssetStatisticsQuery>(
    graphql`
      query dashboardPageAssetStatisticsQuery {
        assetStatistics {
          valves {
            ...AssetCountWidget_assetStatistics
          }
          hydrants {
            ...AssetCountWidget_assetStatistics
          }
          generalAssets {
            ...AssetCountWidget_assetStatistics
          }
        }
        hydrantStatistics {
          ...HydrantGpmCountWidget_assetStatistics
        }
      }
    `,
    {},
  );

  return (
    <div className="p-4 xl:p-8 flex flex-col">
      <div className="grid grid-cols lg:grid-cols-3 gap-5 mb-5">
        <div className="lg:col-span-2">
          <TasksOverviewWidget />
        </div>

        <ShortcutsWidget />
      </div>

      <div className="grid grid-cols lg:grid-cols-3 gap-5 mb-5">
        <AssetCountWidget title="Hydrants" assetStatistics={data.assetStatistics.hydrants} />
        <AssetCountWidget title="Valves" assetStatistics={data.assetStatistics.valves} />
        <AssetCountWidget
          title="General Assets"
          assetStatistics={data.assetStatistics.generalAssets}
        />
      </div>

      <div className="grid grid-cols lg:grid-cols-3 gap-5 mb-5">
        <HydrantGpmCountWidget title="Hydrant GPM" hydrantGpmStatistics={data.hydrantStatistics} />
        <JobsAssignedWidget />
        <DelayedJobsWidget />
      </div>
    </div>
  );
}

export default withSuspense(DashboardPage);

/**
 * @generated SignedSource<<673eee3c5764a20dc6b99779c1a9fb8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdminUsersOrderField = "CREATED_AT" | "FIRST_NAME" | "LAST_NAME" | "PHONE" | "EMAIL" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type AdminUsersOrder = {
  direction: OrderDirection;
  field: AdminUsersOrderField;
};
export type AdminUsersPaginationQuery$variables = {
  after?: string | null;
  first: number;
  orderBy?: AdminUsersOrder | null;
  text?: string | null;
};
export type AdminUsersPaginationQueryVariables = AdminUsersPaginationQuery$variables;
export type AdminUsersPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UsersTable_adminUsers">;
};
export type AdminUsersPaginationQueryResponse = AdminUsersPaginationQuery$data;
export type AdminUsersPaginationQuery = {
  variables: AdminUsersPaginationQueryVariables;
  response: AdminUsersPaginationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderBy"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "text"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  },
  {
    "kind": "Variable",
    "name": "text",
    "variableName": "text"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AdminUsersPaginationQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "UsersTable_adminUsers"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AdminUsersPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AdminUserConnection",
        "kind": "LinkedField",
        "name": "adminUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AdminUserEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AdminUser",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "orderBy",
          "text"
        ],
        "handle": "connection",
        "key": "UsersList_adminUsers",
        "kind": "LinkedHandle",
        "name": "adminUsers"
      }
    ]
  },
  "params": {
    "cacheID": "8d29215cd627d11d88bfd2337c4e2ce2",
    "id": null,
    "metadata": {},
    "name": "AdminUsersPaginationQuery",
    "operationKind": "query",
    "text": "query AdminUsersPaginationQuery(\n  $after: String\n  $first: Int!\n  $orderBy: AdminUsersOrder\n  $text: String\n) {\n  ...UsersTable_adminUsers_1kpX0h\n}\n\nfragment UsersTable_adminUsers_1kpX0h on Query {\n  adminUsers(first: $first, after: $after, orderBy: $orderBy, text: $text) {\n    totalCount\n    pageInfo {\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        id\n        createdAt\n        firstName\n        lastName\n        phone\n        email\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6ff16dad894fbebd26e374aa3ac8a26f";

export default node;

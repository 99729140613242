/**
 * @generated SignedSource<<6f400f0161349a5581a4dd614d6204be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AssetStatus = "maintained" | "due_soon" | "overdue" | "out_of_service" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DetailsSection_generalAsset$data = {
  readonly id: string;
  readonly status: AssetStatus;
  readonly generalAssetName: string;
  readonly " $fragmentType": "DetailsSection_generalAsset";
};
export type DetailsSection_generalAsset = DetailsSection_generalAsset$data;
export type DetailsSection_generalAsset$key = {
  readonly " $data"?: DetailsSection_generalAsset$data;
  readonly " $fragmentSpreads": FragmentRefs<"DetailsSection_generalAsset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DetailsSection_generalAsset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "generalAssetName",
      "storageKey": null
    }
  ],
  "type": "GeneralAsset",
  "abstractKey": null
};

(node as any).hash = "a128ef84de8e107392eb3dd875124166";

export default node;

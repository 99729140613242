/**
 * @generated SignedSource<<59ee11629fd9fa400327b7d2d1f6d68a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Header_currentUserQuery$data = {
  readonly currentUser: {
    readonly id: string;
    readonly organization?: {
      readonly name: string;
    };
  };
  readonly " $fragmentType": "Header_currentUserQuery";
};
export type Header_currentUserQuery = Header_currentUserQuery$data;
export type Header_currentUserQuery$key = {
  readonly " $data"?: Header_currentUserQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"Header_currentUserQuery">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Header_currentUserQuery",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Organization",
              "kind": "LinkedField",
              "name": "organization",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "OrganizationUser",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "00a002c2d9eb9ce2e5989fb477c73780";

export default node;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {withSuspense} from 'src/utils/withSuspense';

import {listPageAdminUsersQuery} from './__generated__/listPageAdminUsersQuery.graphql';
import UsersTable from './UsersTable';

function ListPage() {
  const data = useLazyLoadQuery<listPageAdminUsersQuery>(
    users,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  return <UsersTable users={data} />;
}

export default withSuspense(ListPage);

const users = graphql`
  query listPageAdminUsersQuery {
    ...UsersTable_adminUsers @arguments(first: 60)
  }
`;

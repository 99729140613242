import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useHistory, useParams} from 'react-router';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/form';
import {FormValues} from '../types';
import {updatePageUpdateValveMutation} from './__generated__/updatePageUpdateValveMutation.graphql';
import {updatePageValveQuery} from './__generated__/updatePageValveQuery.graphql';

function UpdatePage() {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [commit, isInFlight] = useMutation<updatePageUpdateValveMutation>(updateValveMutation);

  const data = useLazyLoadQuery<updatePageValveQuery>(
    query,
    {
      valveId: id,
    },
    {fetchPolicy: 'store-and-network'},
  );

  if (!data.valve) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">The item you wish to update could not be found.</h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      title="Update Valve "
      button="Update Valve "
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleInitialValues() {
    if (!data || !data.valve) {
      return;
    }

    const {valve} = data;

    return {
      manufacturerId: valve.manufacturer.id,
      familyId: valve.family.id,
      coordinates: {
        longitude: valve.coordinates[0].toString(),
        latitude: valve.coordinates[1].toString(),
      },
      modelId: valve.model.id,
      serial: valve.serial,
      valveConnectorId: valve.valveConnector.id,
      externalId: valve.externalId,
      turnsToOpenClose: valve.turnsToOpenClose?.toString() || '',
      status: valve.status,
      installedAt: new Date(valve.installedAt),
      notes: valve.notes || '',
      warrantyExpiresAt: new Date(valve.warrantyExpiresAt),
      lastServicedAt: new Date(valve.lastServicedAt),
    };
  }

  function handleSubmit(input: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          valveId: id,
          updateValveData: {
            coordinates: [
              parseFloat(input.coordinates.longitude),
              parseFloat(input.coordinates.latitude),
            ],
            modelId: input.modelId,
            serial: input.serial,
            externalId: input.externalId,
            valveConnectorId: input.valveConnectorId,
            status: input.status,
            turnsToOpenClose: input.turnsToOpenClose ? Number(input.turnsToOpenClose) : undefined,
            installedAt: input.installedAt.toISOString(),
            warrantyExpiresAt: input.warrantyExpiresAt.toISOString(),
            lastServicedAt: input.lastServicedAt.toISOString(),
            ...(input.notes ? {notes: input.notes} : {}),
          },
        },
      },
      onCompleted: payload => {
        if (payload.updateValve?.errors[0] && payload.updateValve?.errors[0].fields) {
          payload.updateValve?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.updateValve?.valve;

        if (entry) {
          showEventNotification('updated', 'Valve', entry.serial);

          if (history.location.search.includes('newTab')) {
            window.close();
          }

          return history.push('/admin/valves');
        }
      },
    });
  }
}

const query = graphql`
  query updatePageValveQuery($valveId: ID!) {
    valve(valveId: $valveId) {
      id
      createdAt
      serial
      externalId
      coordinates
      lastServicedAt
      warrantyExpiresAt
      installedAt
      status
      turnsToOpenClose
      externalId
      notes
      valveConnector {
        id
        name
      }
      model {
        id
        name
      }
      family {
        id
        name
      }
      manufacturer {
        id
        name
      }
    }
  }
`;

const updateValveMutation = graphql`
  mutation updatePageUpdateValveMutation($input: UpdateValveInput!) {
    updateValve(input: $input) {
      valve {
        id
        createdAt
        serial
        externalId
        turnsToOpenClose
        model {
          id
          name
        }
        family {
          id
          name
        }
        manufacturer {
          id
          name
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(UpdatePage);

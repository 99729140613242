/**
 * @generated SignedSource<<4970923da38fcd976b778400d7926eb3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetFamiliesOrderField = "CREATED_AT" | "NAME" | "MANUFACTURER_NAME" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type AssetFamiliesOrder = {
  direction: OrderDirection;
  field: AssetFamiliesOrderField;
};
export type HydrantFamiliesPaginationQuery$variables = {
  after?: string | null;
  first: number;
  name?: string | null;
  orderBy?: AssetFamiliesOrder | null;
};
export type HydrantFamiliesPaginationQueryVariables = HydrantFamiliesPaginationQuery$variables;
export type HydrantFamiliesPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HydrantFamiliesTable_hydrantFamilies">;
};
export type HydrantFamiliesPaginationQueryResponse = HydrantFamiliesPaginationQuery$data;
export type HydrantFamiliesPaginationQuery = {
  variables: HydrantFamiliesPaginationQueryVariables;
  response: HydrantFamiliesPaginationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderBy"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HydrantFamiliesPaginationQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "HydrantFamiliesTable_hydrantFamilies"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HydrantFamiliesPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HydrantFamilyConnection",
        "kind": "LinkedField",
        "name": "hydrantFamilies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HydrantFamilyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HydrantFamily",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "manufacturer",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "orderBy",
          "name"
        ],
        "handle": "connection",
        "key": "HydrantFamiliesList_hydrantFamilies",
        "kind": "LinkedHandle",
        "name": "hydrantFamilies"
      }
    ]
  },
  "params": {
    "cacheID": "6b3a748e4db8401efcc4ca107982edb2",
    "id": null,
    "metadata": {},
    "name": "HydrantFamiliesPaginationQuery",
    "operationKind": "query",
    "text": "query HydrantFamiliesPaginationQuery(\n  $after: String\n  $first: Int!\n  $name: String\n  $orderBy: AssetFamiliesOrder\n) {\n  ...HydrantFamiliesTable_hydrantFamilies_4DjD4v\n}\n\nfragment HydrantFamiliesTable_hydrantFamilies_4DjD4v on Query {\n  hydrantFamilies(first: $first, after: $after, orderBy: $orderBy, name: $name) {\n    totalCount\n    pageInfo {\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        id\n        createdAt\n        name\n        manufacturer {\n          __typename\n          id\n          name\n        }\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bb7f99b08697c945a594d087a5cd4df3";

export default node;

import * as React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {useAuthContext} from 'src/libs/authentication';

import RequestPasswordReset from '../pages/request-password-reset';
import ResetPassword from '../pages/reset-password';
import SignInPage from '../pages/sign-in-page';
import {AuthRoute, ProtectedRoute} from './customRoutes';
import OrganizationRouter from './OrganizationRouter';
import ProjectAdminRouter from './ProjectAdminRouter';

function MainRouter(): React.ReactElement {
  const {isProjectAdmin} = useAuthContext();

  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          <Redirect to={isProjectAdmin ? '/admin/organizations' : '/admin/dashboard'} />
        )}
      />
      <AuthRoute exact path="/signin" component={SignInPage} />
      <AuthRoute exact path="/request-password-reset" component={RequestPasswordReset} />
      <AuthRoute exact path="/reset-password" component={ResetPassword} />

      <ProtectedRoute
        path="/admin"
        component={isProjectAdmin ? ProjectAdminRouter : OrganizationRouter}
      />
    </Switch>
  );
}

export default MainRouter;

/**
 * @generated SignedSource<<cbbdb83b19674d4005a93075fcc776d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type CreateMeasurementInput = {
  value: string;
  templateId: string;
  assetId: string;
  taskId?: string | null;
  clientMutationId?: string | null;
};
export type createPageCreateMeasurementMutation$variables = {
  input: CreateMeasurementInput;
};
export type createPageCreateMeasurementMutationVariables = createPageCreateMeasurementMutation$variables;
export type createPageCreateMeasurementMutation$data = {
  readonly createMeasurement: {
    readonly measurement: {
      readonly id: string;
      readonly createdAt: any;
      readonly value: string;
      readonly template: {
        readonly id: string;
        readonly name: string;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly fields: ReadonlyArray<{
        readonly fieldName: string;
        readonly message: string;
      }> | null;
    }>;
  } | null;
};
export type createPageCreateMeasurementMutationResponse = createPageCreateMeasurementMutation$data;
export type createPageCreateMeasurementMutation = {
  variables: createPageCreateMeasurementMutationVariables;
  response: createPageCreateMeasurementMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateMeasurementPayload",
    "kind": "LinkedField",
    "name": "createMeasurement",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Measurement",
        "kind": "LinkedField",
        "name": "measurement",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MeasurementTemplate",
            "kind": "LinkedField",
            "name": "template",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFieldError",
            "kind": "LinkedField",
            "name": "fields",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fieldName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createPageCreateMeasurementMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createPageCreateMeasurementMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "a7174f1655f5919cfde3600bad073da7",
    "id": null,
    "metadata": {},
    "name": "createPageCreateMeasurementMutation",
    "operationKind": "mutation",
    "text": "mutation createPageCreateMeasurementMutation(\n  $input: CreateMeasurementInput!\n) {\n  createMeasurement(input: $input) {\n    measurement {\n      id\n      createdAt\n      value\n      template {\n        id\n        name\n      }\n    }\n    errors {\n      code\n      fields {\n        fieldName\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8f2d57b861d60e40959f7a8af1f726fe";

export default node;

import React from 'react';
import {formatCurrency} from 'src/utils/formatCurrency';
import styled from 'styled-components';

type Props = {
  name: string;
  price: number;
};

function PlanCell({name, price}: Props) {
  return (
    <CellContainer>
      <PlanName>{name}</PlanName>
      <PlanPrice>{formatCurrency(price)}</PlanPrice>
    </CellContainer>
  );
}

const CellContainer = styled.div.attrs({
  className: 'flex flex-col text-gray-700',
})``;

const PlanName = styled.span.attrs({
  className: 'font-semibold',
})``;

const PlanPrice = styled.span.attrs({
  className: 'text-xs text-gray-600',
})``;

export default PlanCell;

import {useFormik, FormikHelpers} from 'formik';
import React from 'react';
import DropdownInput from 'src/components/inputs/DropdownInput';
import * as Yup from 'yup';

import TextInput from '../../../components/inputs/TextInput';
import {createPageMeasurementTemplatesQueryResponse} from '../create-page/__generated__/createPageMeasurementTemplatesQuery.graphql';
import {FormValues} from '../types';

type Props = {
  measurementTemplates: createPageMeasurementTemplatesQueryResponse['measurementTemplates']['edges'];
  initialValues?: FormValues;
  onSubmit: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void;
  title: string;
  button: string;
  isSubmitting: boolean;
};

function Form(props: Props) {
  const {measurementTemplates, isSubmitting, initialValues, title, button, onSubmit} = props;

  const {submitCount, handleSubmit, errors, setFieldValue, values, isValid} = useFormik<FormValues>(
    {
      initialValues: Object.assign(
        {},
        {value: '', templateId: '', addAnother: false},
        initialValues,
      ),
      isInitialValid: false,
      enableReinitialize: true,
      onSubmit,
      validationSchema,
    },
  );

  return (
    <div className="p-8">
      <div className="mb-6 pb-1 border-b-2 border-gray-400">
        <h2 className="text-2xl text-gray-800 uppercase font-semibold">{title}</h2>
      </div>

      <DropdownInput
        name="templateId"
        onChange={value => setFieldValue('templateId', value)}
        options={measurementTemplates.map(({node}) => ({label: node.name, value: node.id}))}
        placeholder={'E.g. Residual Pressure'}
        value={values.templateId}
        label="Measurament type"
        error={!!submitCount && !!errors.templateId ? (errors.templateId as string) : ''}
      />

      <TextInput
        name="value"
        label="Value"
        placeholder=""
        handleChange={value => setFieldValue('value', value)}
        value={values.value}
        error={!!submitCount && !!errors.value ? (errors.value as string) : ''}
      />

      <div className="space-x-24">
        <button
          disabled={isSubmitting}
          onClick={() => handleSubmit()}
          className={
            'px-5 py-2 text-sm rounded font-semibold text-white uppercase ' +
            (isValid ? 'bg-gray-700' : 'bg-gray-400')
          }>
          {button}
        </button>

        <label className="space-x-2 uppercase tracking-wide text-gray-800 text-xs font-bold">
          <input
            type="checkbox"
            onChange={({target}) => setFieldValue('addAnother', target.checked)}
            checked={values.addAnother}
          />
          <span>Add another</span>
        </label>
      </div>
    </div>
  );
}

const validationSchema = Yup.object({
  value: Yup.string().required('Please provide a value.'),
  templateId: Yup.string().required('Please select a measurement type.'),
});

export default Form;

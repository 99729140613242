import React from 'react';

import TextInput from '../../../components/inputs/TextInput';
import FilterWrapper from '../../../components/table/Filters';

type Props = {
  onApplyFilters: (filters: {text: string}) => void;
  onResetFilters: () => void;
};

function TableFilters(props: Props) {
  const {onApplyFilters, onResetFilters} = props;

  const [searchQuery, setSearchQuery] = React.useState('');

  return (
    <FilterWrapper
      onResetFilters={handleOnResetFilters}
      onApplyFilters={handleOnApplyFilters}
      areFiltersApplicable={!!searchQuery}>
      <TextInput
        label="Search text fields"
        placeholder="E.g. name, ..."
        value={searchQuery}
        handleChange={value => setSearchQuery(value)}
        name="searchInput"
      />
    </FilterWrapper>
  );

  async function handleOnResetFilters() {
    setSearchQuery('');

    onResetFilters();
  }

  async function handleOnApplyFilters() {
    onApplyFilters({text: searchQuery});
  }
}

export default TableFilters;

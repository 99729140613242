/**
 * @generated SignedSource<<ad1b09b1a1c64646a7ab113347ca0777>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type listPageHydrantManufacturersQuery$variables = {
  first: number;
};
export type listPageHydrantManufacturersQueryVariables = listPageHydrantManufacturersQuery$variables;
export type listPageHydrantManufacturersQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HydrantManufacturersTable_hydrantManufacturers">;
};
export type listPageHydrantManufacturersQueryResponse = listPageHydrantManufacturersQuery$data;
export type listPageHydrantManufacturersQuery = {
  variables: listPageHydrantManufacturersQueryVariables;
  response: listPageHydrantManufacturersQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "listPageHydrantManufacturersQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "HydrantManufacturersTable_hydrantManufacturers"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "listPageHydrantManufacturersQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "HydrantManufacturerConnection",
        "kind": "LinkedField",
        "name": "hydrantManufacturers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "HydrantManufacturerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "HydrantManufacturer",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "orderBy",
          "name"
        ],
        "handle": "connection",
        "key": "HydrantManufacturersList_hydrantManufacturers",
        "kind": "LinkedHandle",
        "name": "hydrantManufacturers"
      }
    ]
  },
  "params": {
    "cacheID": "382b851852c2334c51671e8b05d5f0eb",
    "id": null,
    "metadata": {},
    "name": "listPageHydrantManufacturersQuery",
    "operationKind": "query",
    "text": "query listPageHydrantManufacturersQuery(\n  $first: Int!\n) {\n  ...HydrantManufacturersTable_hydrantManufacturers_3ASum4\n}\n\nfragment HydrantManufacturersTable_hydrantManufacturers_3ASum4 on Query {\n  hydrantManufacturers(first: $first) {\n    totalCount\n    pageInfo {\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        id\n        createdAt\n        name\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e15cd6c509d2c0efed0138d7e1b1409c";

export default node;

/**
 * @generated SignedSource<<4bcd0001ae1a031c63cef344056d79c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TasksOverviewWidgettaskStatsQuery$variables = {};
export type TasksOverviewWidgettaskStatsQueryVariables = TasksOverviewWidgettaskStatsQuery$variables;
export type TasksOverviewWidgettaskStatsQuery$data = {
  readonly taskStats: ReadonlyArray<{
    readonly date: any;
    readonly createdCount: number;
    readonly completedCount: number | null;
  }> | null;
};
export type TasksOverviewWidgettaskStatsQueryResponse = TasksOverviewWidgettaskStatsQuery$data;
export type TasksOverviewWidgettaskStatsQuery = {
  variables: TasksOverviewWidgettaskStatsQueryVariables;
  response: TasksOverviewWidgettaskStatsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TaskStats",
    "kind": "LinkedField",
    "name": "taskStats",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "completedCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TasksOverviewWidgettaskStatsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TasksOverviewWidgettaskStatsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "93057941a3317587efd00fd714e7bdb2",
    "id": null,
    "metadata": {},
    "name": "TasksOverviewWidgettaskStatsQuery",
    "operationKind": "query",
    "text": "query TasksOverviewWidgettaskStatsQuery {\n  taskStats {\n    date\n    createdCount\n    completedCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "5708065f886806d1be07d34f64475ee3";

export default node;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {usePerPage} from 'src/hooks/usePerPage';
import {withSuspense} from 'src/utils/withSuspense';

import {listPagevalvesQuery} from './__generated__/listPagevalvesQuery.graphql';
import ValvesTable from './ValvesTable';

function ListPage() {
  const {perPage} = usePerPage();
  const data = useLazyLoadQuery<listPagevalvesQuery>(
    valves,
    {first: perPage * 2},
    {fetchPolicy: 'store-and-network'},
  );

  return <ValvesTable valves={data} />;
}

export default withSuspense(ListPage);

const valves = graphql`
  query listPagevalvesQuery($first: Int!) {
    ...ValvesTable_valves @arguments(first: $first)
  }
`;

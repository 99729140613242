/**
 * @generated SignedSource<<7649c8d812d17acebc88208fcb635ec0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ManufacturingSection_asset$data = {
  readonly id: string;
  readonly model: {
    readonly id: string;
    readonly name: string;
  };
  readonly family: {
    readonly id: string;
    readonly name: string;
  };
  readonly manufacturer: {
    readonly id: string;
    readonly name: string;
  };
  readonly " $fragmentType": "ManufacturingSection_asset";
};
export type ManufacturingSection_asset = ManufacturingSection_asset$data;
export type ManufacturingSection_asset$key = {
  readonly " $data"?: ManufacturingSection_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"ManufacturingSection_asset">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ManufacturingSection_asset",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "model",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "family",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "manufacturer",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Asset",
  "abstractKey": "__isAsset"
};
})();

(node as any).hash = "306f4025891eec3045e595b44f0af58a";

export default node;

import {faPencilRuler, faLayerGroup, faCubes, faToolbox} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {useAuthContext} from 'src/libs/authentication';

import {ReactComponent as DashboardIcon} from '../../assets/icons/dashboard.svg';
import {ReactComponent as HydrantIcon} from '../../assets/icons/hydrant.svg';
import {ReactComponent as JobsIcon} from '../../assets/icons/jobs.svg';
import {ReactComponent as TaskIcon} from '../../assets/icons/list.svg';
import {ReactComponent as MapIcon} from '../../assets/icons/pin.svg';
import {ReactComponent as ValveIcon} from '../../assets/icons/pipe2.svg';
import {ReactComponent as MeasurementIcon} from '../../assets/icons/pipe3.svg';
import {ReactComponent as GeneralAssetIcon} from '../../assets/icons/settings.svg';
import {ReactComponent as SettingsIcon} from '../../assets/icons/sliders.svg';
import {ReactComponent as UserIcon} from '../../assets/icons/user.svg';
import NavigationLinkWithOptions from './sidebar/NavigationLinkWithOptions';
import NavigationUserActions from './sidebar/NavigationUserActions';
import {
  NavigationContainer,
  NavigationSectionContainer,
  NavigationSection,
  NavigationLink,
  NavigationActionContainer,
} from './sidebar/styledComponents';

function Sidebar() {
  const {isOrganizationAdmin} = useAuthContext();

  return (
    <NavigationContainer>
      <NavigationSectionContainer>
        <NavigationSection>
          <NavigationLink to="/admin/dashboard">
            <DashboardIcon fill="currentColor" width="1.4em" height="1.4em" className="mr-2" />
            Dashboard
          </NavigationLink>
          <NavigationLink to="/admin/jobs">
            <JobsIcon fill="currentColor" width="1.4em" height="1.4em" className="mr-2" />
            Jobs
          </NavigationLink>
          <NavigationLink to="/admin/asset_map">
            <MapIcon fill="currentColor" width="1.4em" height="1.4em" className="mr-2" />
            Asset Map
          </NavigationLink>
          {isOrganizationAdmin && (
            <>
              <NavigationLink to="/admin/users">
                <UserIcon fill="currentColor" width="1.4em" height="1.2em" className="mr-2" />
                Users
              </NavigationLink>
              <NavigationLink to="/admin/settings">
                <SettingsIcon fill="currentColor" width="1.4em" height="1.2em" className="mr-2" />
                Settings
              </NavigationLink>
            </>
          )}
        </NavigationSection>

        <NavigationSection>
          <NavigationLinkWithOptions
            Icon={
              <FontAwesomeIcon
                icon={faPencilRuler}
                className="mr-2"
                style={{width: '1.4em', height: '1.4em'}}
              />
            }
            title="Measurement Reports"
            options={[
              {title: 'Hydrants', to: '/admin/hydrant_measurement_report'},
              {title: 'Valves', to: '/admin/valve_measurement_report'},
              {title: 'General Assets', to: '/admin/general_asset_measurement_report'},
            ]}
          />
        </NavigationSection>

        <NavigationSection>
          <NavigationLink to="/admin/hydrants">
            <HydrantIcon fill="currentColor" width="1.4em" height="1.4em" className="mr-2" />
            Hydrants
          </NavigationLink>

          <NavigationLink to="/admin/valves">
            <ValveIcon fill="currentColor" width="1.4em" height="1.4em" className="mr-2" />
            Valves
          </NavigationLink>

          <NavigationLink to="/admin/general_assets">
            <GeneralAssetIcon fill="currentColor" width="1.4em" height="1.4em" className="mr-2" />
            General Assets
          </NavigationLink>
        </NavigationSection>

        <NavigationSection>
          <NavigationLinkWithOptions
            Icon={
              <FontAwesomeIcon
                icon={faToolbox}
                className="mr-2"
                style={{width: '1.4em', height: '1.4em'}}
              />
            }
            title="Manufacturers"
            options={[
              {title: 'Hydrants', to: '/admin/hydrant_manufacturers'},
              {title: 'Valves', to: '/admin/valve_manufacturers'},
              {title: 'General Assets', to: '/admin/general_asset_manufacturers'},
            ]}
          />

          <NavigationLinkWithOptions
            Icon={
              <FontAwesomeIcon
                icon={faLayerGroup}
                className="mr-2"
                style={{width: '1.4em', height: '1.4em'}}
              />
            }
            title="Asset Types"
            options={[
              {title: 'Hydrants', to: '/admin/hydrant_types'},
              {title: 'Valves', to: '/admin/valve_types'},
              {title: 'Valve Connections', to: '/admin/valve_connection_types'},
              {title: 'General Assets', to: '/admin/general_asset_types'},
            ]}
          />

          <NavigationLinkWithOptions
            Icon={
              <FontAwesomeIcon
                icon={faCubes}
                className="mr-2"
                style={{width: '1.4em', height: '1.4em'}}
              />
            }
            title="Asset Models"
            options={[
              {title: 'Hydrants', to: '/admin/hydrant_models'},
              {title: 'Valves', to: '/admin/valve_models'},
              {title: 'General Assets', to: '/admin/general_asset_models'},
            ]}
          />
        </NavigationSection>

        <NavigationSection>
          <NavigationLink to="/admin/task_templates">
            <TaskIcon fill="currentColor" width="1.4em" height="1.4em" className="mr-2" />
            Task Templates
          </NavigationLink>
          <NavigationLink to="/admin/measurement_templates">
            <MeasurementIcon fill="currentColor" width="1.4em" height="1.4em" className="mr-2" />
            Measurable Templates
          </NavigationLink>
        </NavigationSection>
      </NavigationSectionContainer>

      <NavigationActionContainer>
        <NavigationUserActions />
      </NavigationActionContainer>
    </NavigationContainer>
  );
}

export default Sidebar;

import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useHistory, useParams} from 'react-router';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';
import * as Yup from 'yup';

import Form from '../components/Form';
import {FormValues} from '../types';
import {updatePageAdminUserQuery} from './__generated__/updatePageAdminUserQuery.graphql';
import {updatePageUpdateAdminUserMutation} from './__generated__/updatePageUpdateAdminUserMutation.graphql';

function UpdatePage() {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [commit, isInFlight] = useMutation<updatePageUpdateAdminUserMutation>(updateUserMutation);

  const data = useLazyLoadQuery<updatePageAdminUserQuery>(query, {
    adminUserId: id,
  });

  if (!data.adminUser) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">The item you wish to update could not be found.</h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      title="Update User"
      button="Update User"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
      validationSchema={validationSchema}
    />
  );

  function handleInitialValues() {
    if (!data || !data.adminUser) {
      return;
    }

    const {adminUser} = data;

    return {
      firstName: adminUser.firstName,
      lastName: adminUser.lastName,
      email: adminUser.email,
      phone: adminUser.phone,
      password: '',
      confirmPassword: '',
    };
  }

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          adminUserId: id,
          updateAdminUserData: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            password: values.password,
          },
        },
      },
      onCompleted: payload => {
        if (payload.updateAdminUser?.errors[0] && payload.updateAdminUser?.errors[0].fields) {
          payload.updateAdminUser?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.updateAdminUser?.adminUser;

        if (entry) {
          showEventNotification('updated', 'User', entry.firstName);
          return history.push('/admin/users');
        }
      },
    });
  }
}

const query = graphql`
  query updatePageAdminUserQuery($adminUserId: ID!) {
    adminUser(adminUserId: $adminUserId) {
      id
      createdAt
      firstName
      lastName
      phone
      email
    }
  }
`;

const updateUserMutation = graphql`
  mutation updatePageUpdateAdminUserMutation($input: UpdateAdminUserInput!) {
    updateAdminUser(input: $input) {
      adminUser {
        id
        createdAt
        firstName
        lastName
        phone
        email
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

const validationSchema = Yup.object({
  firstName: Yup.string().required('Please provide a first name.'),
  lastName: Yup.string().required('Please provide a last name.'),
  phone: Yup.string().required('Please provide a phone number.'),
  email: Yup.string()
    .email('Please provide a valid email address.')
    .required('Please provide a valid email address.'),
  password: Yup.string().min(6, 'Your password must contain at least 6 characters.'),
  confirmPassword: Yup.string().when(['password'], {
    is: val => !!val,
    then: Yup.string()
      .required('Please confirm your password.')
      .oneOf([Yup.ref('password')], 'Your passwords do not match.'),
    otherwise: Yup.string().max(0),
  }),
});

export default withSuspense(UpdatePage);

import React from 'react';
import {RouteComponentProps} from 'react-router';
import {Route, Switch} from 'react-router-dom';

import PageLayout from '../components/page-layout';
import UserRouter from '../pages/admin-users';
import OrganizationRouter from '../pages/organizations';
import SettingsPage from '../pages/settings';
import UserSettingsPage from '../pages/user-settings';

export default function ProjectAdminRouter({match}: RouteComponentProps): React.ReactElement {
  return (
    <PageLayout>
      <Switch>
        <Route exact path={`${match.path}/user_settings`} component={UserSettingsPage} />
        <Route path={`${match.path}/organizations`} component={OrganizationRouter} />
        <Route path={`${match.path}/users`} component={UserRouter} />
        <Route path={`${match.path}/settings`} component={SettingsPage} />
      </Switch>
    </PageLayout>
  );
}

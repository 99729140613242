import React from 'react';
import GeneralAssetFamilyInput from 'src/components/inputs/GeneralAssetFamilyInput';
import GeneralAssetManufacturerInput from 'src/components/inputs/GeneralAssetManufacturerInput';
import GeneralAssetModelInput from 'src/components/inputs/GeneralAssetModelInput';

type Props = {
  values: any;
  errors: any;
  setFieldValue: any;
  submitCount: number;
};

function ManufacturingTab(props: Props) {
  const {values, setFieldValue, submitCount, errors} = props;

  return (
    <div>
      <GeneralAssetManufacturerInput
        value={values.manufacturerId}
        onChange={value => setFieldValue('manufacturerId', value)}
        error={!!submitCount && errors.manufacturerId ? errors.manufacturerId : ''}
      />

      <GeneralAssetFamilyInput
        manufacturerId={values.manufacturerId}
        value={values.familyId}
        onChange={value => setFieldValue('familyId', value)}
        error={!!submitCount && errors.familyId ? errors.familyId : ''}
      />

      <GeneralAssetModelInput
        familyId={values.familyId}
        value={values.modelId}
        onChange={value => setFieldValue('modelId', value)}
        error={!!submitCount && errors.modelId ? errors.modelId : ''}
      />
    </div>
  );
}

export default ManufacturingTab;

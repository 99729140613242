import React from 'react';
import styled from 'styled-components';

type Props = {
  value: any;
};

function ImportantTextCell({value}: Props) {
  return <CellContainer>{value}</CellContainer>;
}

const CellContainer = styled.div.attrs({
  className: 'text-sm font-bold text-gray-700 uppercase',
})``;

export default ImportantTextCell;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {usePerPage} from 'src/hooks/usePerPage';
import {withSuspense} from 'src/utils/withSuspense';

import {listPageGeneralAssetManufacturersQuery} from './__generated__/listPageGeneralAssetManufacturersQuery.graphql';
import GeneralAssetManufacturersTable from './GeneralAssetManufacturersTable';

function ListPage() {
  const {perPage} = usePerPage();
  const data = useLazyLoadQuery<listPageGeneralAssetManufacturersQuery>(
    generalAssetManufacturers,
    {first: perPage * 2},
    {fetchPolicy: 'store-and-network'},
  );

  return <GeneralAssetManufacturersTable generalAssetManufacturers={data} />;
}

export default withSuspense(ListPage);

const generalAssetManufacturers = graphql`
  query listPageGeneralAssetManufacturersQuery($first: Int!) {
    ...GeneralAssetManufacturersTable_generalAssetManufacturers @arguments(first: $first)
  }
`;

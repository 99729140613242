import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useHistory, useParams} from 'react-router';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/Form';
import {FormValues} from '../types';
import {updatePageGeneralAssetModelQuery} from './__generated__/updatePageGeneralAssetModelQuery.graphql';
import {updatePageUpdateGeneralAssetModelMutation} from './__generated__/updatePageUpdateGeneralAssetModelMutation.graphql';

function UpdatePage() {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [commit, isInFlight] = useMutation<updatePageUpdateGeneralAssetModelMutation>(
    updateGeneralAssetModelMutation,
  );

  const data = useLazyLoadQuery<updatePageGeneralAssetModelQuery>(query, {
    generalAssetModelId: id,
  });

  if (!data.generalAssetModel) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">The item you wish to update could not be found.</h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      title="Update General Asset Model"
      button="Update General Asset Model"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleInitialValues() {
    if (!data || !data.generalAssetModel) {
      return;
    }

    const {generalAssetModel} = data;

    return {
      name: generalAssetModel.name,
      manufacturerId: generalAssetModel.manufacturer.id,
      familyId: generalAssetModel.family.id,
    };
  }

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    const {name, familyId} = values;

    commit({
      variables: {
        input: {
          generalAssetModelId: id,
          updateGeneralAssetModelData: {name, familyId},
        },
      },
      onCompleted: payload => {
        if (
          payload.updateGeneralAssetModel?.errors[0] &&
          payload.updateGeneralAssetModel?.errors[0].fields
        ) {
          payload.updateGeneralAssetModel?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.updateGeneralAssetModel?.generalAssetModel;

        if (entry) {
          showEventNotification('updated', 'Model', entry.name);

          if (history.location.search.includes('newTab')) {
            window.close();
          }

          return history.push('/admin/general_asset_models');
        }
      },
    });
  }
}

const query = graphql`
  query updatePageGeneralAssetModelQuery($generalAssetModelId: ID!) {
    generalAssetModel(generalAssetModelId: $generalAssetModelId) {
      id
      createdAt
      name
      family {
        id
        name
      }
      manufacturer {
        id
        name
      }
    }
  }
`;

const updateGeneralAssetModelMutation = graphql`
  mutation updatePageUpdateGeneralAssetModelMutation($input: UpdateGeneralAssetModelInput!) {
    updateGeneralAssetModel(input: $input) {
      generalAssetModel {
        id
        createdAt
        name
        family {
          id
          name
        }
        manufacturer {
          id
          name
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(UpdatePage);

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {usePerPage} from 'src/hooks/usePerPage';
import {withSuspense} from 'src/utils/withSuspense';

import {listPagehydrantModelsQuery} from './__generated__/listPagehydrantModelsQuery.graphql';
import HydrantModelsTable from './HydrantModelsTable';

function ListPage() {
  const {perPage} = usePerPage();
  const data = useLazyLoadQuery<listPagehydrantModelsQuery>(
    hydrantModels,
    {first: perPage * 2},
    {fetchPolicy: 'store-and-network'},
  );

  return <HydrantModelsTable hydrantModels={data} />;
}

export default withSuspense(ListPage);

const hydrantModels = graphql`
  query listPagehydrantModelsQuery($first: Int!) {
    ...HydrantModelsTable_hydrantModels @arguments(first: $first)
  }
`;

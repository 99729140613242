/**
 * @generated SignedSource<<759d19176adfa13d40e5635d55c291c9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type DeleteGeneralAssetInput = {
  generalAssetId: string;
  clientMutationId?: string | null;
};
export type GeneralAssetsTableDeleteGeneralAssetMutation$variables = {
  input: DeleteGeneralAssetInput;
};
export type GeneralAssetsTableDeleteGeneralAssetMutationVariables = GeneralAssetsTableDeleteGeneralAssetMutation$variables;
export type GeneralAssetsTableDeleteGeneralAssetMutation$data = {
  readonly deleteGeneralAsset: {
    readonly generalAsset: {
      readonly id: string;
      readonly serial: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly message: string;
    }>;
  } | null;
};
export type GeneralAssetsTableDeleteGeneralAssetMutationResponse = GeneralAssetsTableDeleteGeneralAssetMutation$data;
export type GeneralAssetsTableDeleteGeneralAssetMutation = {
  variables: GeneralAssetsTableDeleteGeneralAssetMutationVariables;
  response: GeneralAssetsTableDeleteGeneralAssetMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteGeneralAssetPayload",
    "kind": "LinkedField",
    "name": "deleteGeneralAsset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GeneralAsset",
        "kind": "LinkedField",
        "name": "generalAsset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serial",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GeneralAssetsTableDeleteGeneralAssetMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GeneralAssetsTableDeleteGeneralAssetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d08476fb2f7ad8d998333b1dcaaddc1e",
    "id": null,
    "metadata": {},
    "name": "GeneralAssetsTableDeleteGeneralAssetMutation",
    "operationKind": "mutation",
    "text": "mutation GeneralAssetsTableDeleteGeneralAssetMutation(\n  $input: DeleteGeneralAssetInput!\n) {\n  deleteGeneralAsset(input: $input) {\n    generalAsset {\n      id\n      serial\n    }\n    errors {\n      code\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "874ecb934e18a7655561c0cdcf676333";

export default node;

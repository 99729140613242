/**
 * @generated SignedSource<<2b5dffb0addd46cb9b14a2e49b10b497>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type assetMapAssetsAsGeoJsonQuery$variables = {};
export type assetMapAssetsAsGeoJsonQueryVariables = assetMapAssetsAsGeoJsonQuery$variables;
export type assetMapAssetsAsGeoJsonQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Map_assetsAsGeoJson">;
};
export type assetMapAssetsAsGeoJsonQueryResponse = assetMapAssetsAsGeoJsonQuery$data;
export type assetMapAssetsAsGeoJsonQuery = {
  variables: assetMapAssetsAsGeoJsonQueryVariables;
  response: assetMapAssetsAsGeoJsonQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coordinates",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "assetMapAssetsAsGeoJsonQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Map_assetsAsGeoJson"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "assetMapAssetsAsGeoJsonQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssetGeoJsonConnection",
        "kind": "LinkedField",
        "name": "assetsAsGeoJson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetGeoJson",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetGeoJsonGeometry",
                "kind": "LinkedField",
                "name": "geometry",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetGeoJsonProperties",
                "kind": "LinkedField",
                "name": "properties",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "asset",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "serial",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastServicedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "jobCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "icon",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "041a91c5fce7389a4299b4af61a463c2",
    "id": null,
    "metadata": {},
    "name": "assetMapAssetsAsGeoJsonQuery",
    "operationKind": "query",
    "text": "query assetMapAssetsAsGeoJsonQuery {\n  ...Map_assetsAsGeoJson\n}\n\nfragment Map_assetsAsGeoJson on Query {\n  assetsAsGeoJson {\n    nodes {\n      type\n      geometry {\n        type\n        coordinates\n      }\n      properties {\n        asset {\n          __typename\n          id\n          serial\n          status\n          coordinates\n          lastServicedAt\n          jobCount\n        }\n        icon\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "df43d73e052414a112a2d291cb323642";

export default node;

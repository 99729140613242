import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useMutation} from 'react-relay/hooks';
import {useHistory} from 'react-router';
import ConnectionHandler from 'relay-connection-handler-plus';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/Form';
import {FormValues} from '../types';
import {createPageCreateValveFamilyMutation} from './__generated__/createPageCreateValveFamilyMutation.graphql';

function CreatePage() {
  const history = useHistory();

  const [commit, isInFlight] = useMutation<createPageCreateValveFamilyMutation>(
    createValveFamilyMutation,
  );

  return (
    <Form
      title="Create Valve Type"
      button="Create Valve Type"
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleSubmit(input: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {input},
      onCompleted: payload => {
        if (payload.createValveFamily?.errors[0] && payload.createValveFamily?.errors[0].fields) {
          payload.createValveFamily?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.createValveFamily?.valveFamily;

        if (entry) {
          showEventNotification('added', 'Type', entry.name);
          return history.push('/admin/valve_types');
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connectionRecords = ConnectionHandler.getConnections(
          root,
          'ValveFamiliesList_valveFamilies',
        );

        if (!connectionRecords) {
          return;
        }

        const payload = store.getRootField('createValveFamily');

        if (!payload) {
          return;
        }

        const newRecord = payload.getLinkedRecord('valveFamily');

        if (!newRecord) {
          return;
        }

        connectionRecords.forEach(connectionRecord => {
          const newEdge = ConnectionHandler.createEdge(
            store,
            connectionRecord,
            newRecord,
            'valveFamilyEdge',
          );

          ConnectionHandler.insertEdgeAfter(connectionRecord, newEdge);
        });
      },
    });
  }
}

const createValveFamilyMutation = graphql`
  mutation createPageCreateValveFamilyMutation($input: CreateValveFamilyInput!) {
    createValveFamily(input: $input) {
      valveFamily {
        id
        createdAt
        name
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(CreatePage);

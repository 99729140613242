import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';

import {ValveModelInputValveModelsQuery} from './__generated__/ValveModelInputValveModelsQuery.graphql';
import DropdownInput from './DropdownInput';

type Props = {
  value: string;
  familyId: string;
  onChange: (value: string) => void;
  error?: string;
};

function ValveModelInput(props: Props) {
  const {value, familyId, onChange, error} = props;

  const data = useLazyLoadQuery<ValveModelInputValveModelsQuery>(
    valveModelsQuery,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  const options =
    !!data && familyId
      ? data.valveModels.edges
          .filter(valveModelEdge => valveModelEdge.node.family.id === familyId)
          .map(valveModelEdge => ({
            label: valveModelEdge.node.name,
            value: valveModelEdge.node.id,
          }))
      : [];

  return (
    <DropdownInput
      name="valveModelInput"
      onChange={onChange}
      options={options}
      placeholder="E.g. Series 2500 RWGV"
      label="Model"
      value={value}
      error={error}
    />
  );
}

const valveModelsQuery = graphql`
  query ValveModelInputValveModelsQuery {
    valveModels {
      edges {
        node {
          id
          name
          family {
            id
          }
        }
      }
    }
  }
`;

export default ValveModelInput;

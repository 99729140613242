/**
 * @generated SignedSource<<7892e1b71a3189a423278004fcb4493f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type DeleteOrganizationUserInput = {
  organizationUserId: string;
  clientMutationId?: string | null;
};
export type UsersTableDeleteOrganizationUserMutation$variables = {
  input: DeleteOrganizationUserInput;
};
export type UsersTableDeleteOrganizationUserMutationVariables = UsersTableDeleteOrganizationUserMutation$variables;
export type UsersTableDeleteOrganizationUserMutation$data = {
  readonly deleteOrganizationUser: {
    readonly organizationUser: {
      readonly id: string;
      readonly firstName: string;
      readonly createdAt: any;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly message: string;
    }>;
  } | null;
};
export type UsersTableDeleteOrganizationUserMutationResponse = UsersTableDeleteOrganizationUserMutation$data;
export type UsersTableDeleteOrganizationUserMutation = {
  variables: UsersTableDeleteOrganizationUserMutationVariables;
  response: UsersTableDeleteOrganizationUserMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteOrganizationUserPayload",
    "kind": "LinkedField",
    "name": "deleteOrganizationUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationUser",
        "kind": "LinkedField",
        "name": "organizationUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UsersTableDeleteOrganizationUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UsersTableDeleteOrganizationUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "42e9864a73af9f04d90b1225936b9155",
    "id": null,
    "metadata": {},
    "name": "UsersTableDeleteOrganizationUserMutation",
    "operationKind": "mutation",
    "text": "mutation UsersTableDeleteOrganizationUserMutation(\n  $input: DeleteOrganizationUserInput!\n) {\n  deleteOrganizationUser(input: $input) {\n    organizationUser {\n      id\n      firstName\n      createdAt\n    }\n    errors {\n      code\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2060e836a87d21ea5f73938358e91f4a";

export default node;

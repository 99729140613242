import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useMutation} from 'react-relay/hooks';
import {useHistory} from 'react-router';
import ConnectionHandler from 'relay-connection-handler-plus';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/Form';
import {FormValues} from '../types';
import {createPageCreateGeneralAssetModelMutation} from './__generated__/createPageCreateGeneralAssetModelMutation.graphql';

function CreatePage() {
  const history = useHistory();

  const [commit, isInFlight] = useMutation<createPageCreateGeneralAssetModelMutation>(
    createGeneralAssetModelMutation,
  );

  return (
    <Form
      title="Create General Asset Model"
      button="Create General Asset Model"
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleSubmit(input: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          name: input.name,
          familyId: input.familyId,
        },
      },
      onCompleted: payload => {
        if (
          payload.createGeneralAssetModel?.errors[0] &&
          payload.createGeneralAssetModel?.errors[0].fields
        ) {
          payload.createGeneralAssetModel?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.createGeneralAssetModel?.generalAssetModel;

        if (entry) {
          showEventNotification('added', 'Model', entry.name);
          return history.push('/admin/general_asset_models');
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connectionRecords = ConnectionHandler.getConnections(
          root,
          'GeneralAssetModelsList_generalAssetModels',
        );

        if (!connectionRecords) {
          return;
        }

        const payload = store.getRootField('createGeneralAssetModel');

        if (!payload) {
          return;
        }

        const newRecord = payload.getLinkedRecord('generalAssetModel');

        if (!newRecord) {
          return;
        }

        connectionRecords.forEach(connectionRecord => {
          const newEdge = ConnectionHandler.createEdge(
            store,
            connectionRecord,
            newRecord,
            'generalAssetModelEdge',
          );

          ConnectionHandler.insertEdgeAfter(connectionRecord, newEdge);
        });
      },
    });
  }
}

const createGeneralAssetModelMutation = graphql`
  mutation createPageCreateGeneralAssetModelMutation($input: CreateGeneralAssetModelInput!) {
    createGeneralAssetModel(input: $input) {
      generalAssetModel {
        id
        createdAt
        name
        manufacturer {
          id
          name
        }
        family {
          id
          name
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(CreatePage);

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useFragment, useLazyLoadQuery} from 'react-relay/hooks';
import {formatDateUS} from 'src/utils/formatDate';

import {MeasurementsSection_currentMeasurements$key} from './__generated__/MeasurementsSection_currentMeasurements.graphql';
import {MeasurementsSectionMeasurementTemplatesQuery} from './__generated__/MeasurementsSectionMeasurementTemplatesQuery.graphql';

type Props = {
  currentMeasurements: MeasurementsSection_currentMeasurements$key;
};

export default function MeasurementsSection(props: Props) {
  const [measurements, setMeasurements] = React.useState<
    Array<{
      id: string;
      name: string;
      dataKind: string;
      createdAt: string | null;
      value: string | null;
    }>
  >([]);

  const data = useLazyLoadQuery<MeasurementsSectionMeasurementTemplatesQuery>(
    graphql`
      query MeasurementsSectionMeasurementTemplatesQuery {
        measurementTemplates {
          edges {
            node {
              id
              name
              dataKind
            }
          }
        }
      }
    `,
    {},
  );

  const currentMeasurements = useFragment(
    graphql`
      fragment MeasurementsSection_currentMeasurements on MeasurementConnection {
        edges {
          node {
            id
            value
            createdAt
            template {
              id
            }
          }
        }
      }
    `,
    props.currentMeasurements,
  );

  React.useEffect(() => {
    const newMeasurements: Array<{
      id: string;
      name: string;
      dataKind: string;
      createdAt: string | null;
      value: string | null;
    }> = [];

    if (data.measurementTemplates && currentMeasurements) {
      data.measurementTemplates.edges.forEach(templateEdge => {
        const measurementForTemplate = currentMeasurements.edges.find(
          measurementEdge => measurementEdge.node.template.id === templateEdge.node.id,
        );

        newMeasurements.push({
          id: templateEdge.node.id,
          name: templateEdge.node.name,
          dataKind: templateEdge.node.dataKind,
          value: measurementForTemplate ? measurementForTemplate.node.value : null,
          createdAt: measurementForTemplate
            ? formatDateUS(measurementForTemplate.node.createdAt)
            : null,
        });
      });
    }

    setMeasurements(newMeasurements);
  }, [data.measurementTemplates, currentMeasurements]);

  if (!data.measurementTemplates.edges.length) {
    return (
      <h3 className="text-sm text-gray-600">
        {' '}
        No measurement templates have been added for this asset type.
      </h3>
    );
  }

  return (
    <div className="border-b pb-4 pt-4">
      <div className="flex">
        <div className="w-40">
          <h3 className="text-gray-700 text-sm uppercase font-semibold">MEASUREMENTS</h3>
        </div>

        <div>
          {measurements.map(measurement => {
            return (
              <div key={measurement.id} className="flex items-end mb-2">
                <div className="w-40 flex justify-start mr-1">
                  <h3 className="text-gray-600 text-sm">{measurement.name}</h3>
                </div>
                {measurement.value ? (
                  <h3 className="text-gray-800 uppercase leading-snug text-base">
                    {measurement.value}{' '}
                    <span className="text-xs text-gray-600">({measurement.createdAt})</span>
                  </h3>
                ) : (
                  <h3 className="text-gray-800 uppercase leading-snug text-base">N/A</h3>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

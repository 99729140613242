import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useHistory, useParams} from 'react-router';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/Form';
import {FormValues} from '../types';
import {updatePageHydrantModelQuery} from './__generated__/updatePageHydrantModelQuery.graphql';
import {updatePageUpdateHydrantModelMutation} from './__generated__/updatePageUpdateHydrantModelMutation.graphql';

function UpdatePage() {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [commit, isInFlight] = useMutation<updatePageUpdateHydrantModelMutation>(
    updateHydrantModelMutation,
  );

  const data = useLazyLoadQuery<updatePageHydrantModelQuery>(query, {
    hydrantModelId: id,
  });

  if (!data.hydrantModel) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">The item you wish to update could not be found.</h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      title="Update Hydrant Model"
      button="Update Hydrant Model"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleInitialValues() {
    if (!data || !data.hydrantModel) {
      return;
    }

    const {hydrantModel} = data;

    return {
      name: hydrantModel.name,
      manufacturerId: hydrantModel.manufacturer.id,
      familyId: hydrantModel.family.id,
    };
  }

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    const {name, familyId} = values;

    commit({
      variables: {
        input: {
          hydrantModelId: id,
          updateHydrantModelData: {name, familyId},
        },
      },
      onCompleted: payload => {
        if (payload.updateHydrantModel?.errors[0] && payload.updateHydrantModel?.errors[0].fields) {
          payload.updateHydrantModel?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.updateHydrantModel?.hydrantModel;

        if (entry) {
          showEventNotification('updated', 'Model', entry.name);

          if (history.location.search.includes('newTab')) {
            window.close();
          }

          return history.push('/admin/hydrant_models');
        }
      },
    });
  }
}

const query = graphql`
  query updatePageHydrantModelQuery($hydrantModelId: ID!) {
    hydrantModel(hydrantModelId: $hydrantModelId) {
      id
      createdAt
      name
      family {
        id
        name
      }
      manufacturer {
        id
        name
      }
    }
  }
`;

const updateHydrantModelMutation = graphql`
  mutation updatePageUpdateHydrantModelMutation($input: UpdateHydrantModelInput!) {
    updateHydrantModel(input: $input) {
      hydrantModel {
        id
        createdAt
        name
        family {
          id
          name
        }
        manufacturer {
          id
          name
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(UpdatePage);

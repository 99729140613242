/**
 * @generated SignedSource<<3f16669af70dab6e9783a466f0732b1f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TaskKind = "other" | "measurement" | "cleaning" | "%future added value";
export type updatePageTaskTemplateQuery$variables = {
  taskTemplateId: string;
};
export type updatePageTaskTemplateQueryVariables = updatePageTaskTemplateQuery$variables;
export type updatePageTaskTemplateQuery$data = {
  readonly taskTemplate: {
    readonly id: string;
    readonly createdAt: any;
    readonly name: string;
    readonly taskKind: TaskKind;
    readonly measurementTemplate: {
      readonly id: string;
    } | null;
  } | null;
};
export type updatePageTaskTemplateQueryResponse = updatePageTaskTemplateQuery$data;
export type updatePageTaskTemplateQuery = {
  variables: updatePageTaskTemplateQueryVariables;
  response: updatePageTaskTemplateQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "taskTemplateId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "taskTemplateId",
        "variableName": "taskTemplateId"
      }
    ],
    "concreteType": "TaskTemplate",
    "kind": "LinkedField",
    "name": "taskTemplate",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taskKind",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "MeasurementTemplate",
        "kind": "LinkedField",
        "name": "measurementTemplate",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageTaskTemplateQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageTaskTemplateQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "ee585d54a33c65f6c2e873432fe8c3a0",
    "id": null,
    "metadata": {},
    "name": "updatePageTaskTemplateQuery",
    "operationKind": "query",
    "text": "query updatePageTaskTemplateQuery(\n  $taskTemplateId: ID!\n) {\n  taskTemplate(taskTemplateId: $taskTemplateId) {\n    id\n    createdAt\n    name\n    taskKind\n    measurementTemplate {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7ef0f915c44db9a34f300cf5ff8febe2";

export default node;

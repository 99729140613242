/**
 * @generated SignedSource<<a6f6a7f4c63bdf2566a9f08f8443e5bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type AssetGeoJsonIcon = "hydrant_maintained" | "hydrant_overdue" | "hydrant_due_soon" | "hydrant_out_of_service" | "valve_maintained" | "valve_due_soon" | "valve_overdue" | "valve_out_of_service" | "general_asset_maintained" | "general_asset_due_soon" | "general_asset_overdue" | "general_asset_out_of_service" | "%future added value";
export type AssetStatus = "maintained" | "due_soon" | "overdue" | "out_of_service" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Map_assetsAsGeoJson$data = {
  readonly assetsAsGeoJson: {
    readonly nodes: ReadonlyArray<{
      readonly type: string;
      readonly geometry: {
        readonly type: string;
        readonly coordinates: ReadonlyArray<number>;
      };
      readonly properties: {
        readonly asset: {
          readonly __typename: string;
          readonly id: string;
          readonly serial: string;
          readonly status: AssetStatus;
          readonly coordinates: ReadonlyArray<number>;
          readonly lastServicedAt: any;
          readonly jobCount: number | null;
        };
        readonly icon: AssetGeoJsonIcon;
      };
    }>;
  };
  readonly " $fragmentType": "Map_assetsAsGeoJson";
};
export type Map_assetsAsGeoJson = Map_assetsAsGeoJson$data;
export type Map_assetsAsGeoJson$key = {
  readonly " $data"?: Map_assetsAsGeoJson$data;
  readonly " $fragmentSpreads": FragmentRefs<"Map_assetsAsGeoJson">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coordinates",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "kind"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "status"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "text"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./MapAssetsAsGeoJsonRefetchQuery.graphql')
    }
  },
  "name": "Map_assetsAsGeoJson",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "kind",
          "variableName": "kind"
        },
        {
          "kind": "Variable",
          "name": "status",
          "variableName": "status"
        },
        {
          "kind": "Variable",
          "name": "text",
          "variableName": "text"
        }
      ],
      "concreteType": "AssetGeoJsonConnection",
      "kind": "LinkedField",
      "name": "assetsAsGeoJson",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetGeoJson",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetGeoJsonGeometry",
              "kind": "LinkedField",
              "name": "geometry",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetGeoJsonProperties",
              "kind": "LinkedField",
              "name": "properties",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "asset",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "serial",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "status",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastServicedAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "jobCount",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "icon",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "0acf1716ddc7fe9c080796783ccb7db7";

export default node;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useFragment} from 'react-relay/hooks';
import AssetStatusLabel from 'src/components/AssetStatusLabel';
import GpmLabel from 'src/components/GpmLabel';

import {DetailsSection_hydrant$key} from './__generated__/DetailsSection_hydrant.graphql';

type Props = {
  hydrant: DetailsSection_hydrant$key;
};

export default function DetailsSection(props: Props) {
  const hydrant = useFragment(
    graphql`
      fragment DetailsSection_hydrant on Hydrant {
        id
        status
        hydrantTurnsToOpenClose
        hydrantWaterMainDiameter
        hydrantWaterMainDepth
        hydrantGallonsPerMinute
      }
    `,
    props.hydrant,
  );

  return (
    <div className="border-b pb-4 pt-4">
      <div className="flex">
        <div className="w-40">
          <h3 className="text-gray-700 text-sm uppercase font-semibold">Details</h3>
        </div>
        <div>
          <div className="flex items-end mb-2">
            <div className="w-40 flex justify-start mr-1">
              <h3 className="text-gray-600 text-sm">Status</h3>
            </div>
            <AssetStatusLabel status={hydrant.status} />
          </div>
          <div className="flex items-end mb-2">
            <div className="w-40 flex justify-start mr-1">
              <h3 className="text-gray-600 text-sm">Turns To Open/Close</h3>
            </div>
            <h3 className="text-gray-800 uppercase leading-snug text-base">
              {hydrant.hydrantTurnsToOpenClose}
            </h3>
          </div>

          <div className="flex items-end mb-2">
            <div className="w-40 flex justify-start mr-1">
              <h3 className="text-gray-600 text-sm">Water Main Depth (feet)</h3>
            </div>
            <h3 className="text-gray-800 uppercase leading-snug text-base">
              {hydrant.hydrantWaterMainDepth}
            </h3>
          </div>
          <div className="flex items-end mb-2">
            <div className="w-40 flex justify-start mr-1">
              <h3 className="text-gray-600 text-sm">Water Main Diameter (inches)</h3>
            </div>
            <h3 className="text-gray-800 uppercase leading-snug text-base">
              {hydrant.hydrantWaterMainDiameter}
            </h3>
          </div>
          <div className="flex items-end">
            <div className="w-40 flex justify-start mr-1">
              <h3 className="text-gray-600 text-sm">Gallons Per Minute</h3>
            </div>
            <GpmLabel gpm={hydrant.hydrantGallonsPerMinute} />
          </div>
        </div>
      </div>
    </div>
  );
}

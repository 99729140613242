import {useFormik, FormikHelpers} from 'formik';
import React, {Suspense} from 'react';
import MeasurementTemplatesInput from 'src/components/inputs/MeasurementTemplatesInput';
import TaskKindInput from 'src/components/inputs/TaskKindInput';
import * as Yup from 'yup';

import TextInput from '../../../components/inputs/TextInput';
import {FormValues} from '../types';

type Props = {
  initialValues?: FormValues;
  onSubmit: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void;
  title: string;
  button: string;
  isSubmitting: boolean;
};

function Form(props: Props) {
  const {isSubmitting, initialValues, title, button, onSubmit} = props;

  const {submitCount, handleSubmit, errors, setFieldValue, values, isValid} = useFormik<FormValues>(
    {
      initialValues: Object.assign(
        {},
        {
          name: '',
          taskKind: 'other',
          measurementTemplateId: '',
        },
        initialValues,
      ),
      isInitialValid: false,
      enableReinitialize: true,
      onSubmit,
      validationSchema,
    },
  );

  return (
    <div className="p-8">
      <div className="mb-6 pb-1 border-b-2 border-gray-400">
        <h2 className="text-2xl text-gray-800 uppercase font-semibold">{title}</h2>
      </div>

      <TextInput
        name="name"
        label="Name"
        placeholder="E.g. Lubricate the hydrant"
        handleChange={value => setFieldValue('name', value)}
        value={values.name}
        error={!!submitCount && !!errors.name ? errors.name : ''}
      />

      <TaskKindInput
        value={values.taskKind}
        onChange={taskKind => setFieldValue('taskKind', taskKind)}
      />

      {values.taskKind === 'measurement' && (
        <Suspense fallback={null}>
          <MeasurementTemplatesInput
            value={values.measurementTemplateId}
            onChange={measurementTemplateId =>
              setFieldValue('measurementTemplateId', measurementTemplateId)
            }
          />
        </Suspense>
      )}

      <button
        disabled={isSubmitting}
        onClick={() => handleSubmit()}
        className={
          'px-5 py-2 text-sm rounded font-semibold text-white uppercase ' +
          (isValid ? 'bg-gray-700' : 'bg-gray-400')
        }>
        {button}
      </button>
    </div>
  );
}

const validationSchema = Yup.object({
  name: Yup.string().required('Please provide a manufacturer name.'),
});

export default Form;

import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useMutation} from 'react-relay/hooks';
import {useHistory} from 'react-router';
import ConnectionHandler from 'relay-connection-handler-plus';
import {showEventNotification} from 'src/utils/notifications';
import * as Yup from 'yup';

import Form from '../components/Form';
import {FormValues} from '../types';
import {createPageCreateAdminUserMutation} from './__generated__/createPageCreateAdminUserMutation.graphql';

function CreatePage() {
  const history = useHistory();

  const [commit, isInFlight] = useMutation<createPageCreateAdminUserMutation>(createUserMutation);

  return (
    <Form
      title="Create User"
      button="Create User"
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
      validationSchema={validationSchema}
    />
  );

  function handleSubmit(input: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          firstName: input.firstName,
          lastName: input.lastName,
          email: input.email,
          phone: input.phone,
          password: input.password,
        },
      },
      onCompleted: payload => {
        if (payload.createAdminUser?.errors[0] && payload.createAdminUser?.errors[0].fields) {
          payload.createAdminUser?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.createAdminUser?.adminUser;

        if (entry) {
          showEventNotification('added', 'User', entry.firstName);
          return history.push('/admin/users');
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connectionRecords = ConnectionHandler.getConnections(root, 'UsersList_adminUsers');

        if (!connectionRecords) {
          return;
        }

        const payload = store.getRootField('createAdminUser');

        if (!payload) {
          return;
        }

        const newRecord = payload.getLinkedRecord('adminUser');

        if (!newRecord) {
          return;
        }

        connectionRecords.forEach(connectionRecord => {
          const newEdge = ConnectionHandler.createEdge(
            store,
            connectionRecord,
            newRecord,
            'adminUserEdge',
          );

          ConnectionHandler.insertEdgeAfter(connectionRecord, newEdge);
        });
      },
    });
  }
}

const validationSchema = Yup.object({
  firstName: Yup.string().required('Please provide a first name.'),
  lastName: Yup.string().required('Please provide a last name.'),
  phone: Yup.string().required('Please provide a phone number.'),
  email: Yup.string()
    .email('Please provide a valid email address.')
    .required('Please provide a valid email address.'),
  password: Yup.string()
    .required('Please provide a password.')
    .min(6, 'Your password must contain at least 6 characters.'),
  confirmPassword: Yup.string()
    .required('Please confirm your password.')
    .oneOf([Yup.ref('password')], 'Your passwords do not match.'),
});

const createUserMutation = graphql`
  mutation createPageCreateAdminUserMutation($input: CreateAdminUserInput!) {
    createAdminUser(input: $input) {
      adminUser {
        id
        createdAt
        phone
        email
        firstName
        lastName
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default CreatePage;

import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import Modal from 'react-modal';
import AssetMap from 'src/components/asset-map';
import {formatDateUS} from 'src/utils/formatDate';
import styled from 'styled-components';

type Props = {
  value: any;
  handleChange: (value: any) => void;
  error?: string;
};

Modal.setAppElement('#root');

export default function AssetInput(props: Props) {
  const {value, error, handleChange} = props;

  const [isAssetInputShown, setIsAssetInputShown] = React.useState(false);
  const [unconfirmedSelectedAsset, setUnconfirmedSelectedAsset] = React.useState<any>(value);

  return (
    <div className="w-96 mb-6">
      <label
        className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
        htmlFor="taskInput">
        Asset
      </label>
      <div className="rounded border border-gray-500 h-32 bg-white p-4">
        {value ? (
          <div>
            <h3 className="text-gray-800">{value.__typename}</h3>
            <h3 className="text-gray-800">{value.externalId}</h3>
            <h3 className="text-gray-800">{value.serial}</h3>
            <button className="text-orange-400 text-sm" onClick={() => setIsAssetInputShown(true)}>
              Select different asset
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-center h-full">
            <button
              className="text-orange-400 uppercase font-semibold text-sm"
              onClick={() => setIsAssetInputShown(true)}>
              Toggle asset input
            </button>
          </div>
        )}
      </div>

      {error && <p className="text-red-500 text-xs mt-1 italic">{error}</p>}
      <Modal isOpen={isAssetInputShown} onRequestClose={() => setIsAssetInputShown(false)}>
        <AssetMap onIconClick={asset => setUnconfirmedSelectedAsset(asset)}>
          {unconfirmedSelectedAsset && (
            <ConfirmAssetPanelContainer>
              <div>
                <h3 className="text-gray-100 text-lg font-semibold">
                  {unconfirmedSelectedAsset.externalId}
                </h3>
                <h3 className="text-gray-100 text-lg font-semibold">
                  {unconfirmedSelectedAsset.serial}
                </h3>
                <h3 className="text-gray-100 text-lg font-semibold">
                  <span className="font-normal">Last serviced at </span>
                  {formatDateUS(unconfirmedSelectedAsset.lastServicedAt)}
                </h3>

                <button
                  className="mt-4 bg-orange-600 text-white font-semibold uppercase px-4 py-2 rounded"
                  onClick={selectAsset}>
                  Select Asset
                </button>
              </div>
              <div>
                <CloseIcon onClick={() => setUnconfirmedSelectedAsset(null)} />
              </div>
            </ConfirmAssetPanelContainer>
          )}
        </AssetMap>
      </Modal>
    </div>
  );

  function selectAsset() {
    handleChange(unconfirmedSelectedAsset);
    setUnconfirmedSelectedAsset(null);
    setIsAssetInputShown(false);
  }
}

const ConfirmAssetPanelContainer = styled.div.attrs({
  className: 'absolute justify-between flex shadow h-48 bg-gray-800 p-6',
})`
  left: 50%;
  transform: translate(-50%);
  width: 30rem;
  bottom: 4rem;
`;

const CloseIcon = styled(FontAwesomeIcon).attrs({
  icon: faTimes,
  className: 'text-2xl text-white hover:text-orange-500',
})``;

import React from 'react';
import Analytics from 'react-router-ga';

const TRACKING_CODE = process.env.REACT_APP_TRACKING_CODE;

function AnalyticsProvider({children}: React.PropsWithChildren<unknown>) {
  if (!TRACKING_CODE) {
    return <>{children}</>;
  }

  return <Analytics id={TRACKING_CODE}>{children}</Analytics>;
}

export default AnalyticsProvider;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {usePaginationFragment, useMutation} from 'react-relay/hooks';
import ConnectionHandler from 'relay-connection-handler-plus';
import useFilters from 'src/hooks/useFilters';
import {showEventNotification} from 'src/utils/notifications';

import Table from '../../../components/table';
import DateCell from '../../../components/table/cell-types/DateCell';
import SecondaryTextCell from '../../../components/table/cell-types/SecondaryTextCell';
import {AssetManufacturersOrder} from './__generated__/GeneralAssetManufacturersPaginationQuery.graphql';
import {GeneralAssetManufacturersTable_generalAssetManufacturers$key} from './__generated__/GeneralAssetManufacturersTable_generalAssetManufacturers.graphql';
import {GeneralAssetManufacturersTableDeleteGeneralAssetManufacturerMutation} from './__generated__/GeneralAssetManufacturersTableDeleteGeneralAssetManufacturerMutation.graphql';
import {listPageGeneralAssetManufacturersQuery} from './__generated__/listPageGeneralAssetManufacturersQuery.graphql';
import TableFilters from './TableFilters';

type Props = {
  generalAssetManufacturers: GeneralAssetManufacturersTable_generalAssetManufacturers$key;
};

type Filters = {
  name?: string;
  orderBy?: AssetManufacturersOrder;
};

function GeneralAssetManufacturersTable(props: Props) {
  const {data, loadNext, hasNext, refetch} = usePaginationFragment<
    listPageGeneralAssetManufacturersQuery,
    GeneralAssetManufacturersTable_generalAssetManufacturers$key
  >(
    graphql`
      fragment GeneralAssetManufacturersTable_generalAssetManufacturers on Query
        @argumentDefinitions(
          first: {type: "Int!"}
          after: {type: "String"}
          name: {type: "String"}
          orderBy: {type: "AssetManufacturersOrder"}
        )
        @refetchable(queryName: "GeneralAssetManufacturersPaginationQuery") {
        generalAssetManufacturers(first: $first, after: $after, orderBy: $orderBy, name: $name)
          @connection(key: "GeneralAssetManufacturersList_generalAssetManufacturers") {
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              createdAt
              name
            }
          }
        }
      }
    `,
    props.generalAssetManufacturers,
  );

  const [commit] = useMutation<
    GeneralAssetManufacturersTableDeleteGeneralAssetManufacturerMutation
  >(deleteGeneralAssetManufacturerMutation);

  const {addFilters, setFilters, isTransitioning} = useFilters<Filters>({}, refetch);

  return (
    <Table
      filters={() => (
        <TableFilters onApplyFilters={handleOnApplyFilter} onResetFilters={handleOnResetFilter} />
      )}
      actions={{show: false, delete: true, edit: true, create: true}}
      totalDataLength={data.generalAssetManufacturers.totalCount}
      columns={columns}
      isTransitioning={isTransitioning}
      onPaginate={() => hasNext && loadNext(50)}
      onSort={handleOnSort}
      onDelete={handleDelete}
      data={data.generalAssetManufacturers.edges}
    />
  );

  function handleOnApplyFilter(filters: {text: string}) {
    addFilters({name: filters.text});
  }

  function handleOnResetFilter() {
    setFilters({});
  }

  async function handleOnSort(sortByConfig: any[]) {
    if (!sortByConfig.length) {
      addFilters({orderBy: undefined});

      return;
    }

    const orderByDirection = sortByConfig[0].desc ? 'DESC' : 'ASC';
    const orderByField = sortByConfig[0].id.toUpperCase();

    addFilters({orderBy: {direction: orderByDirection, field: orderByField}});
  }

  function handleDelete(id: string) {
    commit({
      variables: {input: {generalAssetManufacturerId: id}},
      onCompleted: payload => {
        const entry = payload.deleteGeneralAssetManufacturer?.generalAssetManufacturer;

        if (entry) {
          showEventNotification('removed', 'Manufacturer', entry.name);
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connections = ConnectionHandler.getConnections(
          root,
          'GeneralAssetManufacturersList_generalAssetManufacturers',
        );

        if (!connections) {
          return;
        }

        connections.forEach(connection => {
          ConnectionHandler.deleteNode(connection, id);
        });
      },
    });
  }
}

export default GeneralAssetManufacturersTable;

const deleteGeneralAssetManufacturerMutation = graphql`
  mutation GeneralAssetManufacturersTableDeleteGeneralAssetManufacturerMutation(
    $input: DeleteGeneralAssetManufacturerInput!
  ) {
    deleteGeneralAssetManufacturer(input: $input) {
      generalAssetManufacturer {
        id
        name
      }
      errors {
        code
        message
      }
    }
  }
`;

const columns = [
  {
    Header: 'Name',
    accessor: 'node.name',
    id: 'name',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'Created On',
    accessor: 'node.createdAt',
    id: 'created_at',
    Cell({cell: {value}}: any) {
      return <DateCell value={value} />;
    },
  },
];

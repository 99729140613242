import React from 'react';
import ValveFamilyInput from 'src/components/inputs/ValveFamilyInput';
import ValveManufacturerInput from 'src/components/inputs/ValveManufacturerInput';
import ValveModelInput from 'src/components/inputs/ValveModelInput';

type Props = {
  values: any;
  errors: any;
  setFieldValue: any;
  submitCount: number;
};

function ManufacturingTab(props: Props) {
  const {values, setFieldValue, submitCount, errors} = props;

  return (
    <div>
      <ValveManufacturerInput
        value={values.manufacturerId}
        onChange={value => setFieldValue('manufacturerId', value)}
        error={!!submitCount && errors.manufacturerId ? errors.manufacturerId : ''}
      />

      <ValveFamilyInput
        manufacturerId={values.manufacturerId}
        value={values.familyId}
        onChange={value => setFieldValue('familyId', value)}
        error={!!submitCount && errors.familyId ? errors.familyId : ''}
      />

      <ValveModelInput
        familyId={values.familyId}
        value={values.modelId}
        onChange={value => setFieldValue('modelId', value)}
        error={!!submitCount && errors.modelId ? errors.modelId : ''}
      />
    </div>
  );
}

export default ManufacturingTab;

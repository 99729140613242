import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useMutation} from 'react-relay/hooks';
import {useHistory} from 'react-router';
import ConnectionHandler from 'relay-connection-handler-plus';
import {showEventNotification} from 'src/utils/notifications';

import Form from '../components/Form';
import {FormValues} from '../types';
import {createPageCreateMeasurementTemplateMutation} from './__generated__/createPageCreateMeasurementTemplateMutation.graphql';

function CreatePage() {
  const history = useHistory();

  const [commit, isInFlight] = useMutation<createPageCreateMeasurementTemplateMutation>(
    createMeasurementTemplateMutation,
  );

  return (
    <Form
      title="Create Measurement Template"
      button="Create Measurement Template"
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleSubmit(input: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {input},
      onCompleted: payload => {
        if (
          payload.createMeasurementTemplate?.errors[0] &&
          payload.createMeasurementTemplate?.errors[0].fields
        ) {
          payload.createMeasurementTemplate?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.createMeasurementTemplate?.measurementTemplate;

        if (entry) {
          showEventNotification('added', 'Template', entry.name);
          return history.push('/admin/measurement_templates');
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connectionRecords = ConnectionHandler.getConnections(
          root,
          'MeasurementTemplatesList_measurementTemplates',
        );

        if (!connectionRecords) {
          return;
        }

        const payload = store.getRootField('createMeasurementTemplate');

        if (!payload) {
          return;
        }

        const newRecord = payload.getLinkedRecord('measurementTemplate');

        if (!newRecord) {
          return;
        }

        connectionRecords.forEach(connectionRecord => {
          const newEdge = ConnectionHandler.createEdge(
            store,
            connectionRecord,
            newRecord,
            'measurementTemplateEdge',
          );

          ConnectionHandler.insertEdgeAfter(connectionRecord, newEdge);
        });
      },
    });
  }
}

const createMeasurementTemplateMutation = graphql`
  mutation createPageCreateMeasurementTemplateMutation($input: CreateMeasurementTemplateInput!) {
    createMeasurementTemplate(input: $input) {
      measurementTemplate {
        id
        createdAt
        name
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default CreatePage;

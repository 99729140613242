import {useFormik, FormikHelpers} from 'formik';
import React from 'react';
import * as Yup from 'yup';

import GeneralAssetFamilyInput from '../../../components/inputs/GeneralAssetFamilyInput';
import GeneralAssetManufacturerInput from '../../../components/inputs/GeneralAssetManufacturerInput';
import TextInput from '../../../components/inputs/TextInput';
import {FormValues} from '../types';

type Props = {
  initialValues?: FormValues;
  onSubmit: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void;
  title: string;
  button: string;
  isSubmitting: boolean;
};

function Form(props: Props) {
  const {initialValues, title, button, onSubmit, isSubmitting} = props;

  const {submitCount, handleSubmit, errors, setFieldValue, values, isValid} = useFormik<FormValues>(
    {
      initialValues: Object.assign(
        {},
        {
          name: '',
          manufacturerId: '',
          familyId: '',
        },
        initialValues,
      ),
      isInitialValid: false,
      enableReinitialize: true,
      onSubmit,
      validationSchema,
    },
  );

  return (
    <div className="p-8">
      <div className="mb-6 pb-1 border-b-2 border-gray-400">
        <h2 className="text-2xl text-gray-800 uppercase font-semibold">{title}</h2>
      </div>

      <GeneralAssetManufacturerInput
        value={values.manufacturerId}
        onChange={manufacturerId => setFieldValue('manufacturerId', manufacturerId)}
        error={!!submitCount && !!errors.manufacturerId ? errors.manufacturerId : ''}
      />

      <GeneralAssetFamilyInput
        manufacturerId={values.manufacturerId}
        value={values.familyId}
        onChange={familyId => setFieldValue('familyId', familyId)}
        error={!!submitCount && !!errors.familyId ? errors.familyId : ''}
      />

      <TextInput
        name="name"
        label="Name"
        placeholder="E.g. Backflow Preventer"
        handleChange={value => setFieldValue('name', value)}
        value={values.name}
        error={!!submitCount && !!errors.name ? errors.name : ''}
      />

      <button
        disabled={isSubmitting}
        onClick={() => handleSubmit()}
        className={
          'px-5 py-2 text-sm rounded font-semibold text-white uppercase ' +
          (isValid ? 'bg-gray-700' : 'bg-gray-400')
        }>
        {button}
      </button>
    </div>
  );
}

const validationSchema = Yup.object({
  name: Yup.string().required('Please provide a model name.'),
  manufacturerId: Yup.string().required('Please select a manufacturer'),
  familyId: Yup.string().required('Please select a type.'),
});

export default Form;

/**
 * @generated SignedSource<<b942a48b20843918a56af15980ebece0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AssetStatus = "maintained" | "due_soon" | "overdue" | "out_of_service" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DetailsSection_hydrant$data = {
  readonly id: string;
  readonly status: AssetStatus;
  readonly hydrantTurnsToOpenClose: number;
  readonly hydrantWaterMainDiameter: number;
  readonly hydrantWaterMainDepth: number;
  readonly hydrantGallonsPerMinute: number;
  readonly " $fragmentType": "DetailsSection_hydrant";
};
export type DetailsSection_hydrant = DetailsSection_hydrant$data;
export type DetailsSection_hydrant$key = {
  readonly " $data"?: DetailsSection_hydrant$data;
  readonly " $fragmentSpreads": FragmentRefs<"DetailsSection_hydrant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DetailsSection_hydrant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hydrantTurnsToOpenClose",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hydrantWaterMainDiameter",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hydrantWaterMainDepth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hydrantGallonsPerMinute",
      "storageKey": null
    }
  ],
  "type": "Hydrant",
  "abstractKey": null
};

(node as any).hash = "294f327cf31d417199b621c5743f6c33";

export default node;

/**
 * @generated SignedSource<<a0018693cf93c53706422640856cc242>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type dashboardPageAssetStatisticsQuery$variables = {};
export type dashboardPageAssetStatisticsQueryVariables = dashboardPageAssetStatisticsQuery$variables;
export type dashboardPageAssetStatisticsQuery$data = {
  readonly assetStatistics: {
    readonly valves: {
      readonly " $fragmentSpreads": FragmentRefs<"AssetCountWidget_assetStatistics">;
    };
    readonly hydrants: {
      readonly " $fragmentSpreads": FragmentRefs<"AssetCountWidget_assetStatistics">;
    };
    readonly generalAssets: {
      readonly " $fragmentSpreads": FragmentRefs<"AssetCountWidget_assetStatistics">;
    };
  };
  readonly hydrantStatistics: {
    readonly " $fragmentSpreads": FragmentRefs<"HydrantGpmCountWidget_assetStatistics">;
  };
};
export type dashboardPageAssetStatisticsQueryResponse = dashboardPageAssetStatisticsQuery$data;
export type dashboardPageAssetStatisticsQuery = {
  variables: dashboardPageAssetStatisticsQueryVariables;
  response: dashboardPageAssetStatisticsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "AssetCountWidget_assetStatistics"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "maintainedCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dueSoonCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "overdueCount",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "outOfServiceCount",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "dashboardPageAssetStatisticsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssetStatisticsPayload",
        "kind": "LinkedField",
        "name": "assetStatistics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetStatistics",
            "kind": "LinkedField",
            "name": "valves",
            "plural": false,
            "selections": (v0/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetStatistics",
            "kind": "LinkedField",
            "name": "hydrants",
            "plural": false,
            "selections": (v0/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetStatistics",
            "kind": "LinkedField",
            "name": "generalAssets",
            "plural": false,
            "selections": (v0/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "HydrantStatistics",
        "kind": "LinkedField",
        "name": "hydrantStatistics",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "HydrantGpmCountWidget_assetStatistics"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "dashboardPageAssetStatisticsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AssetStatisticsPayload",
        "kind": "LinkedField",
        "name": "assetStatistics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetStatistics",
            "kind": "LinkedField",
            "name": "valves",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetStatistics",
            "kind": "LinkedField",
            "name": "hydrants",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetStatistics",
            "kind": "LinkedField",
            "name": "generalAssets",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "HydrantStatistics",
        "kind": "LinkedField",
        "name": "hydrantStatistics",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gpmVeryGoodCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gpmGoodCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gpmAdequateCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gpmInadequateCount",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aec7c48d94c062772d76f20e2987ed11",
    "id": null,
    "metadata": {},
    "name": "dashboardPageAssetStatisticsQuery",
    "operationKind": "query",
    "text": "query dashboardPageAssetStatisticsQuery {\n  assetStatistics {\n    valves {\n      ...AssetCountWidget_assetStatistics\n    }\n    hydrants {\n      ...AssetCountWidget_assetStatistics\n    }\n    generalAssets {\n      ...AssetCountWidget_assetStatistics\n    }\n  }\n  hydrantStatistics {\n    ...HydrantGpmCountWidget_assetStatistics\n  }\n}\n\nfragment AssetCountWidget_assetStatistics on AssetStatistics {\n  totalCount\n  maintainedCount\n  dueSoonCount\n  overdueCount\n  outOfServiceCount\n}\n\nfragment HydrantGpmCountWidget_assetStatistics on HydrantStatistics {\n  gpmVeryGoodCount\n  gpmGoodCount\n  gpmAdequateCount\n  gpmInadequateCount\n}\n"
  }
};
})();

(node as any).hash = "afe6d4886c96986656c1d1d46380f8dd";

export default node;

/**
 * @generated SignedSource<<dfcef0df2fe4430da6a4ff8a59300294>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type IdentificationSection_asset$data = {
  readonly id: string;
  readonly serial: string;
  readonly externalId: string;
  readonly " $fragmentType": "IdentificationSection_asset";
};
export type IdentificationSection_asset = IdentificationSection_asset$data;
export type IdentificationSection_asset$key = {
  readonly " $data"?: IdentificationSection_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"IdentificationSection_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IdentificationSection_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serial",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalId",
      "storageKey": null
    }
  ],
  "type": "Asset",
  "abstractKey": "__isAsset"
};

(node as any).hash = "d3a9aa3a673db14aaaf057de48f7235d";

export default node;

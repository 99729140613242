import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';
import * as Yup from 'yup';

import {userSettingsPageCurrentUserQuery} from './__generated__/userSettingsPageCurrentUserQuery.graphql';
import {userSettingsUpdateUserMutation} from './__generated__/userSettingsUpdateUserMutation.graphql';
import Form from './Form';
import {FormValues} from './types';

function UserSettingsPage() {
  const [commit, isInFlight] = useMutation<userSettingsUpdateUserMutation>(updateUserMutation);

  const data = useLazyLoadQuery<userSettingsPageCurrentUserQuery>(query, {});

  if (!data.currentUser) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">
          Something went wrong while fetching your settings.
        </h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      button="Update Settings"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
      validationSchema={validationSchema}
    />
  );

  function handleInitialValues() {
    if (!data || !data.currentUser) {
      return {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
      };
    }

    const {currentUser} = data;

    return {
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      email: currentUser.email,
      phone: currentUser.phone,
      password: '',
      confirmPassword: '',
    };
  }

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          userId: data.currentUser.id,
          updateUserData: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            password: values.password,
          },
        },
      },
      onCompleted: payload => {
        if (payload.updateUser?.errors[0] && payload.updateUser?.errors[0].fields) {
          payload.updateUser?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        } else {
          formikHelpers.resetForm();
        }

        const entry = payload.updateUser?.user;

        if (entry) {
          showEventNotification('updated', 'User Settings');
        }
      },
    });
  }
}

const query = graphql`
  query userSettingsPageCurrentUserQuery {
    currentUser {
      id
      firstName
      lastName
      phone
      email
    }
  }
`;

const updateUserMutation = graphql`
  mutation userSettingsUpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        id
        createdAt
        firstName
        lastName
        phone
        email
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

const validationSchema = Yup.object({
  firstName: Yup.string().required('Please provide a first name.'),
  lastName: Yup.string().required('Please provide a last name.'),
  phone: Yup.string().required('Please provide a phone number.'),
  email: Yup.string()
    .email('Please provide a valid email address.')
    .required('Please provide a valid email address.'),
  password: Yup.string().min(6, 'Your password must contain at least 6 characters.'),
  confirmPassword: Yup.string().when(['password'], {
    is: val => !!val,
    then: Yup.string()
      .required('Please confirm your password.')
      .oneOf([Yup.ref('password')], 'Your passwords do not match.'),
    otherwise: Yup.string().max(0),
  }),
});

export default withSuspense(UserSettingsPage);

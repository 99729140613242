import React from 'react';
import Select, {components} from 'react-select';

type Props = {
  value: string;
  name: string;
  placeholder: string;
  onChange: (value: string) => void;
  options: {label: string; value: string}[];
  isDisabled?: boolean;
};

function SelectInput(props: Props) {
  const {value, isDisabled, name, placeholder, onChange, options} = props;

  const selectedOption = options.find((option: any) => option.value === value);

  return (
    <Select
      id={name}
      isDisabled={isDisabled}
      name={name}
      placeholder={placeholder}
      value={selectedOption ? selectedOption : null}
      onChange={(option: {label: string; value: string}) => onChange(option.value)}
      options={options}
      components={{DropdownIndicator}}
      styles={customStyles}
      theme={(theme: any) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#f6ad55',
          primary25: '#feebc8',
          primary50: '#fbd38d',
          primary75: '#f6ad55',
        },
      })}
    />
  );
}

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        className="fill-current h-4 w-4 hover:text-orange-600"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20">
        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
      </svg>
    </components.DropdownIndicator>
  );
};

const customStyles = {
  container: (provided: any) => ({
    ...provided,
    width: '20rem',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    paddingRight: '0.5rem',
    paddingLeft: '0.75rem',
  }),
  control: (provided: any) => ({
    ...provided,
    borderColor: '#a0aec0',
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    width: '0px',
  }),
  input: (provided: any) => ({
    ...provided,
    lineHeight: '1.25',
    color: '#4a5568',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: '#a0aec0',
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: '#4a5568',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isSelected ? '#ffffff' : '#4a5568',
  }),
};

export default SelectInput;

/**
 * @generated SignedSource<<be162c6b85ac866f48a3480050d74c15>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type DeleteMeasurementTemplateInput = {
  measurementTemplateId: string;
  clientMutationId?: string | null;
};
export type MeasurementTemplatesTableDeleteMeasurementTemplateMutation$variables = {
  input: DeleteMeasurementTemplateInput;
};
export type MeasurementTemplatesTableDeleteMeasurementTemplateMutationVariables = MeasurementTemplatesTableDeleteMeasurementTemplateMutation$variables;
export type MeasurementTemplatesTableDeleteMeasurementTemplateMutation$data = {
  readonly deleteMeasurementTemplate: {
    readonly measurementTemplate: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly message: string;
    }>;
  } | null;
};
export type MeasurementTemplatesTableDeleteMeasurementTemplateMutationResponse = MeasurementTemplatesTableDeleteMeasurementTemplateMutation$data;
export type MeasurementTemplatesTableDeleteMeasurementTemplateMutation = {
  variables: MeasurementTemplatesTableDeleteMeasurementTemplateMutationVariables;
  response: MeasurementTemplatesTableDeleteMeasurementTemplateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteMeasurementTemplatePayload",
    "kind": "LinkedField",
    "name": "deleteMeasurementTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MeasurementTemplate",
        "kind": "LinkedField",
        "name": "measurementTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MeasurementTemplatesTableDeleteMeasurementTemplateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MeasurementTemplatesTableDeleteMeasurementTemplateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a496412843ab5030588c8c42761bbbc0",
    "id": null,
    "metadata": {},
    "name": "MeasurementTemplatesTableDeleteMeasurementTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation MeasurementTemplatesTableDeleteMeasurementTemplateMutation(\n  $input: DeleteMeasurementTemplateInput!\n) {\n  deleteMeasurementTemplate(input: $input) {\n    measurementTemplate {\n      id\n      name\n    }\n    errors {\n      code\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6a7f9e2c61d43d118b9300e0efb8abbf";

export default node;

import React from 'react';

type Props = {
  children: React.ReactNode;
  areFiltersApplicable: boolean;
  onApplyFilters: () => void;
  onResetFilters: () => void;
};

function Filters(props: Props) {
  const {children, areFiltersApplicable, onApplyFilters, onResetFilters} = props;

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    onApplyFilters();
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex items-center">
        <div className="flex flex-row">{children}</div>

        <div className="flex ml-4">
          <button
            className="text-white uppercase font-medium text-sm leading-loose rounded bg-gray-700 hover:bg-gray-800 mr-2 focus:outline-none px-4 py-2"
            disabled={!areFiltersApplicable}
            onClick={onApplyFilters}>
            FILTER
          </button>
          <button
            className="text-gray-600 uppercase font-medium text-sm leading-loose rounded border border-gray-500 hover:border-gray-700 hover:text-gray-800 focus:outline-none px-4 py-2"
            onClick={onResetFilters}>
            RESET
          </button>
        </div>
      </div>
    </form>
  );
}

export default Filters;

/**
 * @generated SignedSource<<6cfff29e22e4b282620f85cea9ef00df>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type updatePageGeneralAssetManufacturerQuery$variables = {
  generalAssetManufacturerId: string;
};
export type updatePageGeneralAssetManufacturerQueryVariables = updatePageGeneralAssetManufacturerQuery$variables;
export type updatePageGeneralAssetManufacturerQuery$data = {
  readonly generalAssetManufacturer: {
    readonly id: string;
    readonly createdAt: any;
    readonly name: string;
  } | null;
};
export type updatePageGeneralAssetManufacturerQueryResponse = updatePageGeneralAssetManufacturerQuery$data;
export type updatePageGeneralAssetManufacturerQuery = {
  variables: updatePageGeneralAssetManufacturerQueryVariables;
  response: updatePageGeneralAssetManufacturerQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "generalAssetManufacturerId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "generalAssetManufacturerId",
        "variableName": "generalAssetManufacturerId"
      }
    ],
    "concreteType": "GeneralAssetManufacturer",
    "kind": "LinkedField",
    "name": "generalAssetManufacturer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageGeneralAssetManufacturerQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageGeneralAssetManufacturerQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e476fb72a4993b48db4e99474128020e",
    "id": null,
    "metadata": {},
    "name": "updatePageGeneralAssetManufacturerQuery",
    "operationKind": "query",
    "text": "query updatePageGeneralAssetManufacturerQuery(\n  $generalAssetManufacturerId: ID!\n) {\n  generalAssetManufacturer(generalAssetManufacturerId: $generalAssetManufacturerId) {\n    id\n    createdAt\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "85064b5c046d48da6313c24c37e75eca";

export default node;

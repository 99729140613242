import React from 'react';

import TextInput from '../../../../components/inputs/TextInput';

type Props = {
  values: any;
  errors: any;
  setFieldValue: any;
  submitCount: number;
  isCustomerInputDisabled: boolean;
};

function GeneralTab(props: Props) {
  const {values, setFieldValue, submitCount, errors} = props;

  return (
    <div>
      <TextInput
        label="Serial"
        placeholder="E.g. XS45HY78"
        value={values.serial}
        handleChange={value => setFieldValue('serial', value)}
        name="serial"
        error={!!submitCount && errors.serial ? errors.serial : ''}
      />

      <TextInput
        label="Id"
        placeholder="E.g. A0012"
        value={values.externalId}
        handleChange={value => setFieldValue('externalId', value)}
        name="externalId"
        error={!!submitCount && errors.externalId ? errors.externalId : ''}
      />

      <TextInput
        label="Name"
        placeholder="E.g. TX-200"
        value={values.generalAssetName}
        handleChange={value => setFieldValue('generalAssetName', value)}
        name="generalAssetName"
        error={!!submitCount && errors.generalAssetName ? errors.generalAssetName : ''}
      />

      <TextInput
        label="Notes (Optional)"
        placeholder="E.g. The top nut is rusted."
        value={values.notes}
        handleChange={value => setFieldValue('notes', value)}
        name="notes"
      />
    </div>
  );
}

export default GeneralTab;

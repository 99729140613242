/**
 * @generated SignedSource<<42b1e65403bbc9893e42a40f247e5bcd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MeasurementsSection_currentMeasurements$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly id: string;
      readonly value: string;
      readonly createdAt: any;
      readonly template: {
        readonly id: string;
      };
    };
  }>;
  readonly " $fragmentType": "MeasurementsSection_currentMeasurements";
};
export type MeasurementsSection_currentMeasurements = MeasurementsSection_currentMeasurements$data;
export type MeasurementsSection_currentMeasurements$key = {
  readonly " $data"?: MeasurementsSection_currentMeasurements$data;
  readonly " $fragmentSpreads": FragmentRefs<"MeasurementsSection_currentMeasurements">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MeasurementsSection_currentMeasurements",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MeasurementEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Measurement",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MeasurementTemplate",
              "kind": "LinkedField",
              "name": "template",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MeasurementConnection",
  "abstractKey": null
};
})();

(node as any).hash = "30b1e370d1fe2a52abc81ab0684457d2";

export default node;

import React from 'react';

import RadioInput from './RadioInput';

type Props = {
  onChange: (value: string) => void;
  value: string;
  isFilter?: boolean;
};

const assetStatuses = [
  {
    value: 'maintained',
    label: 'Maintained',
  },
  {
    value: 'due_soon',
    label: 'Due Soon',
  },
  {
    value: 'overdue',
    label: 'Overdue',
  },
  {
    value: 'out_of_service',
    label: 'Not In Service',
  },
];

function AssetStatusInput({onChange, value, isFilter}: Props) {
  const options = isFilter ? [{value: 'ALL', label: 'All'}, ...assetStatuses] : assetStatuses;

  return (
    <RadioInput
      title="Asset Status"
      name="assetStatus"
      onChange={onChange}
      value={value}
      options={options}
    />
  );
}

export default AssetStatusInput;

import React from 'react';
import MapGL from 'react-map-gl';
import Geocoder from 'react-map-gl-geocoder';

type Props = {
  mapRef: React.Ref<MapGL>;
  onViewStateChange: (viewState: any) => void;
};

function GeocoderPanel({mapRef, onViewStateChange}: Props) {
  return (
    <Geocoder
      mapRef={mapRef}
      placeholder="Street Address"
      countries="US,BE"
      onViewportChange={onViewStateChange}
      mapboxApiAccessToken="pk.eyJ1IjoiY2FsbGFyaWRlIiwiYSI6ImNqeWlvZ2ljODA0YWgzbm82d2FxM3BoeGIifQ.VgvqmFDyg4joDqMGtQJaDQ"
    />
  );
}

export default GeocoderPanel;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useFragment} from 'react-relay/hooks';
import {formatDateUS} from 'src/utils/formatDate';

import {ImageItem_imageRecord$key} from './__generated__/ImageItem_imageRecord.graphql';

type Props = {record: ImageItem_imageRecord$key; onImageClick: (imageUrl: string) => void};

export default function ImageItem(props: Props) {
  const record = useFragment(
    graphql`
      fragment ImageItem_imageRecord on ImageRecord {
        id
        createdAt
        author {
          id
          firstName
          lastName
        }
        imageUrl
      }
    `,
    props.record,
  );

  const createdAt = formatDateUS(record.createdAt);

  return (
    <div className="w-56 border rounded shadow overflow-hidden border-gray-500 m-4">
      <img
        className="cursor-pointer w-56 h-56"
        onClick={() => props.onImageClick(record.imageUrl)}
        src={record.imageUrl}
        alt=""
      />
      <div className="px-2 py-1">
        <p className="text-sm text-gray-700">
          Added by {record.author.firstName[0]}. {record.author.lastName}
        </p>
        <p className="text-xs text-gray-500">{createdAt}</p>
      </div>
    </div>
  );
}

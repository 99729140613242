import React from 'react';

import RadioInput from './RadioInput';

type Props = {
  onChange: (value: string) => void;
  value: string;
  isFilter?: boolean;
};

const assetTypes = [
  {
    value: 'hydrant',
    label: 'Hydrant',
  },
  {
    value: 'valve',
    label: 'Valve',
  },
  {
    value: 'general_asset',
    label: 'General Asset',
  },
];

function AssetTypeInput({onChange, value, isFilter}: Props) {
  const options = isFilter ? [{value: 'ALL', label: 'All'}, ...assetTypes] : assetTypes;

  return (
    <RadioInput
      title="Asset Type"
      name="assetType"
      onChange={onChange}
      value={value}
      options={options}
    />
  );
}

export default AssetTypeInput;

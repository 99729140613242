import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';

import {GeneralAssetModelInputGeneralAssetModelsQuery} from './__generated__/GeneralAssetModelInputGeneralAssetModelsQuery.graphql';
import DropdownInput from './DropdownInput';

type Props = {
  value: string;
  familyId: string;
  onChange: (value: string) => void;
  error?: string;
};

function GeneralAssetModelInput(props: Props) {
  const {value, familyId, onChange, error} = props;

  const data = useLazyLoadQuery<GeneralAssetModelInputGeneralAssetModelsQuery>(
    generalAssetModelsQuery,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  const options =
    !!data && familyId
      ? data.generalAssetModels.edges
          .filter(generalAssetModelEdge => generalAssetModelEdge.node.family.id === familyId)
          .map(generalAssetModelEdge => ({
            label: generalAssetModelEdge.node.name,
            value: generalAssetModelEdge.node.id,
          }))
      : [];

  return (
    <DropdownInput
      name="generalAssetModelInput"
      onChange={onChange}
      options={options}
      placeholder="E.g. Backflow Preventer"
      label="Model"
      value={value}
      error={error}
    />
  );
}

const generalAssetModelsQuery = graphql`
  query GeneralAssetModelInputGeneralAssetModelsQuery {
    generalAssetModels {
      edges {
        node {
          id
          name
          family {
            id
          }
        }
      }
    }
  }
`;

export default GeneralAssetModelInput;

import {faPlus, faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import graphql from 'babel-plugin-relay/macro';
import React, {useEffect} from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import styled from 'styled-components';

import {TaskInputTaskTemplatesQuery} from './__generated__/TaskInputTaskTemplatesQuery.graphql';
import SelectInput from './SelectInput';

type Props = {
  value: {value: string; label: string}[];
  handleChange: (taskTemplates: {value: string; label: string}[]) => void;
  error?: string;
};

function TaskInput(props: Props) {
  const [selectedTask, setSelectedTask] = React.useState('');

  const {value, error, handleChange} = props;

  const data = useLazyLoadQuery<TaskInputTaskTemplatesQuery>(
    taskTemplatesQuery,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  useEffect(() => {
    addTask();
  }, [selectedTask]);

  const selectableTasks = data
    ? data.taskTemplates.edges
        .filter(
          templateEdge =>
            !value.length ||
            !value.some(taskTemplate => taskTemplate.value === templateEdge.node.id),
        )
        .map(templateEdge => ({label: templateEdge.node.name, value: templateEdge.node.id}))
    : [];

  return (
    <div className="w-96 mb-6">
      <label
        className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
        htmlFor="taskInput">
        Tasks
      </label>
      <TasksContainer>
        <SelectInput
          name="taskInput"
          placeholder="Select task"
          value={''}
          onChange={task => setSelectedTask(task)}
          options={selectableTasks}
        />

        <div className="mt-3">
          <ul>
            {value.map(task => {
              return (
                <li
                  className="mb-3 flex justify-between items-center text-gray-800 font-semibold"
                  key={task.value}>
                  {task.label}
                  <RemoveIcon onClick={() => removeTask(task.value)} />
                </li>
              );
            })}
          </ul>
        </div>

        <p className="mt-3 text-gray-600 text-sm">{value.length} task(s) selected</p>
      </TasksContainer>
      {error && <p className="text-red-500 text-xs mt-1 italic">{error}</p>}
    </div>
  );

  function addTask() {
    if (!selectedTask) {
      return;
    }

    const taskIndex = selectableTasks.findIndex(
      taskTemplate => taskTemplate.value === selectedTask,
    );

    const updatedTaskList = (value || []).concat([selectableTasks[taskIndex]]);

    handleChange(updatedTaskList);

    setSelectedTask('');
  }

  function removeTask(taskTemplateId: string) {
    const updatedTaskList = value.filter(taskTemplate => taskTemplate.value !== taskTemplateId);

    handleChange(updatedTaskList);

    setSelectedTask('');
  }
}

const TasksContainer = styled.div.attrs({
  className: 'rounded border border-gray-500 bg-white p-4',
})`
  #taskInput {
    width: 100%;
  }
`;

const RemoveIcon = styled(FontAwesomeIcon).attrs({
  icon: faTimes,
  className: 'text-xl text-gray-700 hover:text-orange-500 cursor-pointer',
})``;

const taskTemplatesQuery = graphql`
  query TaskInputTaskTemplatesQuery {
    taskTemplates {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default TaskInput;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useFragment} from 'react-relay/hooks';
import {withSuspense} from 'src/utils/withSuspense';
import styled from 'styled-components';

import logo from '../../assets/img/AF-Logo-Mark.png';
import {Header_currentUserQuery$key} from './__generated__/Header_currentUserQuery.graphql';

export const NAVBAR_HEIGHT = 64;

type Props = {
  query: Header_currentUserQuery$key;
};

function Header(props: Props) {
  const query = useFragment(
    graphql`
      fragment Header_currentUserQuery on Query {
        currentUser {
          id
          ... on OrganizationUser {
            organization {
              name
            }
          }
        }
      }
    `,
    props.query,
  );

  const organizationLabel = query.currentUser?.organization
    ? query.currentUser.organization.name
    : 'Administration';

  return (
    <Navbar>
      <div className="pl-6 py-4 flex-grow flex items-center">
        <div>
          <img className="h-8" src={logo} alt="header logo" />
        </div>
        <div className="ml-6 text-white">{organizationLabel}</div>
      </div>
    </Navbar>
  );
}

function HeaderFallback() {
  return (
    <Navbar>
      <div className="pl-6 py-4 flex-grow flex justify-between items-center">
        <div>
          <img className="h-8" src={logo} alt="header logo" />
        </div>
      </div>
    </Navbar>
  );
}

const Navbar = styled.div.attrs({
  className: 'bg-gray-900 flex',
})`
  height: ${NAVBAR_HEIGHT}px;
`;

export default withSuspense(Header, <HeaderFallback />);
export {HeaderFallback};

import React from 'react';
import styled from 'styled-components';

type Props = {
  value: any;
};

function CoordinateCell({value}: Props) {
  return <CellContainer>{value}</CellContainer>;
}

const CellContainer = styled.div.attrs({
  className: 'text-sm font-semibold text-gray-500',
})``;

export default CoordinateCell;

/**
 * @generated SignedSource<<35b8f44efbf997c82bb85367d86ecb2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HistoryTabAssetsRecordsByAssetQuery$variables = {
  assetId: string;
};
export type HistoryTabAssetsRecordsByAssetQueryVariables = HistoryTabAssetsRecordsByAssetQuery$variables;
export type HistoryTabAssetsRecordsByAssetQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HistoryTabAssetList_assetRecords">;
};
export type HistoryTabAssetsRecordsByAssetQueryResponse = HistoryTabAssetsRecordsByAssetQuery$data;
export type HistoryTabAssetsRecordsByAssetQuery = {
  variables: HistoryTabAssetsRecordsByAssetQueryVariables;
  response: HistoryTabAssetsRecordsByAssetQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assetId"
  }
],
v1 = {
  "kind": "Literal",
  "name": "first",
  "value": 10
},
v2 = [
  {
    "kind": "Variable",
    "name": "assetId",
    "variableName": "assetId"
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationUser",
  "kind": "LinkedField",
  "name": "author",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HistoryTabAssetsRecordsByAssetQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "HistoryTabAssetList_assetRecords"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HistoryTabAssetsRecordsByAssetQuery",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AssetRecordConnection",
        "kind": "LinkedField",
        "name": "assetRecordsByAsset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetRecordEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "notes",
                        "storageKey": null
                      }
                    ],
                    "type": "NoteRecord",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "imageUrl",
                        "storageKey": null
                      }
                    ],
                    "type": "ImageRecord",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Task",
                        "kind": "LinkedField",
                        "name": "task",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TaskTemplate",
                            "kind": "LinkedField",
                            "name": "template",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "TaskCompletionRecord",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Measurement",
                        "kind": "LinkedField",
                        "name": "measurement",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MeasurementTemplate",
                            "kind": "LinkedField",
                            "name": "template",
                            "plural": false,
                            "selections": (v6/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "MeasurementRecord",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v2/*: any*/),
        "filters": [
          "assetId"
        ],
        "handle": "connection",
        "key": "AssetRecordList_assetRecordsByAsset",
        "kind": "LinkedHandle",
        "name": "assetRecordsByAsset"
      }
    ]
  },
  "params": {
    "cacheID": "c17c36aa3304788f7dc82079e8fae802",
    "id": null,
    "metadata": {},
    "name": "HistoryTabAssetsRecordsByAssetQuery",
    "operationKind": "query",
    "text": "query HistoryTabAssetsRecordsByAssetQuery(\n  $assetId: ID!\n) {\n  ...HistoryTabAssetList_assetRecords_4i7Unr\n}\n\nfragment HistoryTabAssetList_assetRecords_4i7Unr on Query {\n  assetRecordsByAsset(assetId: $assetId, first: 10) {\n    edges {\n      node {\n        id\n        __typename\n        ...NoteRecordItem_noteRecord\n        ...ImageRecordItem_imageRecord\n        ...TaskCompletionRecordItem_taskCompletionRecord\n        ...MeasurementRecordItem_measurementRecord\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ImageRecordItem_imageRecord on ImageRecord {\n  id\n  createdAt\n  author {\n    id\n    firstName\n    lastName\n  }\n  imageUrl\n}\n\nfragment MeasurementRecordItem_measurementRecord on MeasurementRecord {\n  id\n  createdAt\n  author {\n    id\n    firstName\n    lastName\n  }\n  measurement {\n    id\n    value\n    template {\n      id\n      name\n    }\n  }\n}\n\nfragment NoteRecordItem_noteRecord on NoteRecord {\n  id\n  createdAt\n  author {\n    id\n    firstName\n    lastName\n  }\n  notes\n}\n\nfragment TaskCompletionRecordItem_taskCompletionRecord on TaskCompletionRecord {\n  id\n  createdAt\n  author {\n    id\n    firstName\n    lastName\n  }\n  task {\n    id\n    template {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dffc149f25b7ab5b54ec082f99b3365f";

export default node;

/**
 * @generated SignedSource<<da992cc20464134932d4db2cf8a9b092>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type UserRole = "project_admin" | "organization_admin" | "foreman" | "project_manager" | "superintendent" | "other" | "dispatcher" | "operations_manager" | "technician" | "%future added value";
export type CreateOrganizationUserInput = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
  role: UserRole;
  clientMutationId?: string | null;
};
export type createPageCreateOrganizationUserMutation$variables = {
  input: CreateOrganizationUserInput;
};
export type createPageCreateOrganizationUserMutationVariables = createPageCreateOrganizationUserMutation$variables;
export type createPageCreateOrganizationUserMutation$data = {
  readonly createOrganizationUser: {
    readonly organizationUser: {
      readonly id: string;
      readonly createdAt: any;
      readonly phone: string;
      readonly email: string;
      readonly firstName: string;
      readonly lastName: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly fields: ReadonlyArray<{
        readonly fieldName: string;
        readonly message: string;
      }> | null;
    }>;
  } | null;
};
export type createPageCreateOrganizationUserMutationResponse = createPageCreateOrganizationUserMutation$data;
export type createPageCreateOrganizationUserMutation = {
  variables: createPageCreateOrganizationUserMutationVariables;
  response: createPageCreateOrganizationUserMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateOrganizationUserPayload",
    "kind": "LinkedField",
    "name": "createOrganizationUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationUser",
        "kind": "LinkedField",
        "name": "organizationUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFieldError",
            "kind": "LinkedField",
            "name": "fields",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fieldName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createPageCreateOrganizationUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createPageCreateOrganizationUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b4b5e69b9b0d6e30c0208c01b277af8e",
    "id": null,
    "metadata": {},
    "name": "createPageCreateOrganizationUserMutation",
    "operationKind": "mutation",
    "text": "mutation createPageCreateOrganizationUserMutation(\n  $input: CreateOrganizationUserInput!\n) {\n  createOrganizationUser(input: $input) {\n    organizationUser {\n      id\n      createdAt\n      phone\n      email\n      firstName\n      lastName\n    }\n    errors {\n      code\n      fields {\n        fieldName\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "60caefb21b7971e3844e64b39d1963b5";

export default node;

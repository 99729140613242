import {useFormik, FormikHelpers} from 'formik';
import React from 'react';

import TextInput from '../../components/inputs/TextInput';
import {FormValues} from './types';

type Props = {
  initialValues: FormValues;
  onSubmit: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void;
  button: string;
  validationSchema: object;
  isSubmitting: boolean;
};

function Form(props: Props) {
  const {initialValues, button, onSubmit, validationSchema} = props;

  const {submitCount, handleSubmit, errors, setFieldValue, values, isValid} = useFormik<FormValues>(
    {
      initialValues: Object.assign(
        {},
        {minimumAppVersionIos: '', minimumAppVersionAndroid: ''},
        initialValues,
      ),
      isInitialValid: false,
      enableReinitialize: true,
      onSubmit,
      validationSchema,
    },
  );

  return (
    <div className="p-8">
      <div className="mb-6 pb-1 border-b-2 border-gray-400">
        <h2 className="text-2xl text-gray-800 uppercase font-semibold">Settings</h2>
      </div>

      <TextInput
        name="minimumAppVersionIos"
        label="Minimum App Version (iOS)"
        placeholder="4.2.0"
        handleChange={value => setFieldValue('minimumAppVersionIos', value)}
        value={values.minimumAppVersionIos}
        error={!!submitCount && !!errors.minimumAppVersionIos ? errors.minimumAppVersionIos : ''}
      />

      <TextInput
        name="minimumAppVersionAndroid"
        label="Minimum App Version (Android)"
        placeholder="4.2.0"
        handleChange={value => setFieldValue('minimumAppVersionAndroid', value)}
        value={values.minimumAppVersionAndroid}
        error={
          !!submitCount && !!errors.minimumAppVersionAndroid ? errors.minimumAppVersionAndroid : ''
        }
      />

      <button
        type="submit"
        onClick={() => handleSubmit()}
        disabled={props.isSubmitting}
        className={
          'px-5 py-2 text-sm rounded font-semibold text-white uppercase ' +
          (isValid ? 'bg-gray-700' : 'bg-gray-400')
        }>
        {button}
      </button>
    </div>
  );
}

export default Form;

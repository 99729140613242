import React from 'react';

import AssetMap from '../../components/asset-map';
import ActiveAssetPanel from './ActiveAssetPanel';

function AssetMapPage() {
  const [activeAsset, setActiveAsset] = React.useState<any>(null);

  return (
    <div className="relative flex flex-col h-full">
      <AssetMap onIconClick={onClickData => setActiveAsset(onClickData)}></AssetMap>
      <ActiveAssetPanel activeAsset={activeAsset} resetActiveAsset={() => setActiveAsset(null)} />
    </div>
  );
}

export default AssetMapPage;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {usePerPage} from 'src/hooks/usePerPage';
import {withSuspense} from 'src/utils/withSuspense';

import {listPageValveManufacturersQuery} from './__generated__/listPageValveManufacturersQuery.graphql';
import ValveManufacturersTable from './ValveManufacturersTable';

function ListPage() {
  const {perPage} = usePerPage();
  const data = useLazyLoadQuery<listPageValveManufacturersQuery>(
    valveManufacturers,
    {first: perPage * 2},
    {fetchPolicy: 'store-and-network'},
  );

  return <ValveManufacturersTable valveManufacturers={data} />;
}

export default withSuspense(ListPage);

const valveManufacturers = graphql`
  query listPageValveManufacturersQuery($first: Int!) {
    ...ValveManufacturersTable_valveManufacturers @arguments(first: $first)
  }
`;

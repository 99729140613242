import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useFragment} from 'react-relay/hooks';
import {PieChart, Pie, Cell} from 'recharts';

import {AssetCountWidget_assetStatistics$key} from './__generated__/AssetCountWidget_assetStatistics.graphql';

type Props = {
  assetStatistics: AssetCountWidget_assetStatistics$key;
  title: string;
};

export default function AssetCountWidget(props: Props) {
  const assetStatistics = useFragment(
    graphql`
      fragment AssetCountWidget_assetStatistics on AssetStatistics {
        totalCount
        maintainedCount
        dueSoonCount
        overdueCount
        outOfServiceCount
      }
    `,
    props.assetStatistics,
  );

  const pieChartData = [
    {name: 'Maintained', value: assetStatistics.maintainedCount},
    {name: 'Due Soon', value: assetStatistics.dueSoonCount},
    {name: 'Overdue', value: assetStatistics.overdueCount},
    {name: 'Not In Service', value: assetStatistics.outOfServiceCount},
  ];

  const hasStats = pieChartData.reduce((acc, {value}) => acc + value, 0) > 0;

  return (
    <div className="bg-white rounded shadow border-gray-500 p-6">
      <div className="border-b border-gray-300 pb-2">
        <h2 className="text-xl text-gray-800 font-semibold ">{props.title}</h2>
      </div>
      <div className="flex mt-4">
        <div className="pr-4 w-40 mr-4">
          <div>
            <h3 className="text-gray-700 text-lg mb-4">{assetStatistics.totalCount} Total</h3>
          </div>

          <h3 className="text-gray-700 text-lg font-semibold mb-1">
            <span className="text-green-500">{assetStatistics.maintainedCount}</span>
            <span className="text-xs uppercase font-normal"> maintained</span>
          </h3>
          <h3 className="text-gray-700 text-lg font-semibold mb-1">
            <span className="text-orange-500">{assetStatistics.dueSoonCount}</span>
            <span className="text-xs uppercase font-normal"> due soon</span>
          </h3>
          <h3 className="text-gray-700 text-lg font-semibold mb-1">
            <span className="text-red-500">{assetStatistics.overdueCount}</span>
            <span className="text-xs uppercase font-normal"> overdue</span>
          </h3>
          <h3 className="text-gray-700 text-lg font-semibold mb-1">
            <span className="text-gray-500">{assetStatistics.outOfServiceCount}</span>
            <span className="text-xs uppercase font-normal"> out of service</span>
          </h3>
        </div>

        <div className="flex flex-1 items-center justify-center">
          {hasStats ? (
            <PieChart width={160} height={160}>
              <Pie
                data={pieChartData}
                outerRadius={80}
                innerRadius={60}
                paddingAngle={2}
                dataKey="value">
                <Cell key="hydrant-maintained" fill="#48bb78" />
                <Cell key="hydrant-due-soon" fill="#f6ad55" />
                <Cell key="hydrant-overdue" fill="#f56565" />
                <Cell key="hydrant-no-in-service" fill="#cbd5e0" />
              </Pie>
            </PieChart>
          ) : (
            <div className="bg-gray-200 rounded-full" style={{width: 160, height: 160}} />
          )}
        </div>
      </div>
    </div>
  );
}

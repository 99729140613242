/**
 * @generated SignedSource<<a4c43979e19a1acdfe13f72dd5992c64>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type CreateValveFamilyInput = {
  name: string;
  manufacturerId: string;
  clientMutationId?: string | null;
};
export type createPageCreateValveFamilyMutation$variables = {
  input: CreateValveFamilyInput;
};
export type createPageCreateValveFamilyMutationVariables = createPageCreateValveFamilyMutation$variables;
export type createPageCreateValveFamilyMutation$data = {
  readonly createValveFamily: {
    readonly valveFamily: {
      readonly id: string;
      readonly createdAt: any;
      readonly name: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly fields: ReadonlyArray<{
        readonly fieldName: string;
        readonly message: string;
      }> | null;
    }>;
  } | null;
};
export type createPageCreateValveFamilyMutationResponse = createPageCreateValveFamilyMutation$data;
export type createPageCreateValveFamilyMutation = {
  variables: createPageCreateValveFamilyMutationVariables;
  response: createPageCreateValveFamilyMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateValveFamilyPayload",
    "kind": "LinkedField",
    "name": "createValveFamily",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ValveFamily",
        "kind": "LinkedField",
        "name": "valveFamily",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFieldError",
            "kind": "LinkedField",
            "name": "fields",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fieldName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createPageCreateValveFamilyMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createPageCreateValveFamilyMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8ca40819fd99e3ea8cbe5f2316292875",
    "id": null,
    "metadata": {},
    "name": "createPageCreateValveFamilyMutation",
    "operationKind": "mutation",
    "text": "mutation createPageCreateValveFamilyMutation(\n  $input: CreateValveFamilyInput!\n) {\n  createValveFamily(input: $input) {\n    valveFamily {\n      id\n      createdAt\n      name\n    }\n    errors {\n      code\n      fields {\n        fieldName\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "21ed3a443f01726f1de139e726ffc759";

export default node;

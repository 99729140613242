import React, {useState, useContext, createContext} from 'react';

interface PerPageContext {
  perPage: number;
  setPerPage?: (value: number) => void;
}

const perPageContext = createContext<PerPageContext>({perPage: 25});

export function ProvidePerPage({children}: React.PropsWithChildren<unknown>) {
  const value = useProvidePerPage();

  return <perPageContext.Provider value={value}>{children}</perPageContext.Provider>;
}

export const usePerPage = () => {
  return useContext(perPageContext);
};

function useProvidePerPage() {
  const [perPage, setPerPage] = useState(25);

  return {
    perPage,
    setPerPage,
  };
}

/**
 * @generated SignedSource<<75cf3351e761d681fb6529297fb16bd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type UpdateSettingsInput = {
  updateSettingsData: UpdateSettingsData;
  clientMutationId?: string | null;
};
export type UpdateSettingsData = {
  minimumAppVersionIos?: string | null;
  minimumAppVersionAndroid?: string | null;
};
export type settingsUpdateSettingsMutation$variables = {
  input: UpdateSettingsInput;
};
export type settingsUpdateSettingsMutationVariables = settingsUpdateSettingsMutation$variables;
export type settingsUpdateSettingsMutation$data = {
  readonly updateSettings: {
    readonly settings: {
      readonly id: string;
      readonly minimumAppVersionIos: string | null;
      readonly minimumAppVersionAndroid: string | null;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly fields: ReadonlyArray<{
        readonly fieldName: string;
        readonly message: string;
      }> | null;
    }>;
  } | null;
};
export type settingsUpdateSettingsMutationResponse = settingsUpdateSettingsMutation$data;
export type settingsUpdateSettingsMutation = {
  variables: settingsUpdateSettingsMutationVariables;
  response: settingsUpdateSettingsMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateSettingsPayload",
    "kind": "LinkedField",
    "name": "updateSettings",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Setting",
        "kind": "LinkedField",
        "name": "settings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minimumAppVersionIos",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minimumAppVersionAndroid",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFieldError",
            "kind": "LinkedField",
            "name": "fields",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fieldName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "settingsUpdateSettingsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "settingsUpdateSettingsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4ee4b268237470ed9aefef47af5f616c",
    "id": null,
    "metadata": {},
    "name": "settingsUpdateSettingsMutation",
    "operationKind": "mutation",
    "text": "mutation settingsUpdateSettingsMutation(\n  $input: UpdateSettingsInput!\n) {\n  updateSettings(input: $input) {\n    settings {\n      id\n      minimumAppVersionIos\n      minimumAppVersionAndroid\n    }\n    errors {\n      code\n      fields {\n        fieldName\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fc91e9ad6bbb28f88e50341b87f1bf09";

export default node;

import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useMutation} from 'react-relay/hooks';
import {useHistory} from 'react-router';
import ConnectionHandler from 'relay-connection-handler-plus';

import Form from '../components/CreateForm';
import {CreateFormValues} from '../types';
import {createPageCreateOrganizationAsProjectAdminMutation} from './__generated__/createPageCreateOrganizationAsProjectAdminMutation.graphql';

function CreatePage() {
  const history = useHistory();

  const [commit, isInFlight] = useMutation<createPageCreateOrganizationAsProjectAdminMutation>(
    createOrganizationAsProjectAdminMutation,
  );

  return (
    <Form
      title="Create Organization"
      button="Create Organization"
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleSubmit(input: CreateFormValues, formikHelpers: FormikHelpers<CreateFormValues>) {
    commit({
      variables: {
        input: {
          name: input.name,
          state: input.state,
          city: input.city,
          email: input.email,
          organizationConfigAttributes: input.organizationConfigAttributes,
          firstName: input.firstName,
          lastName: input.lastName,
          adminUserEmail: input.adminUserEmail,
          phone: input.phone,
          password: input.password,
        },
      },
      onCompleted: payload => {
        if (
          payload.createOrganizationAsProjectAdmin?.errors[0] &&
          payload.createOrganizationAsProjectAdmin?.errors[0].fields
        ) {
          payload.createOrganizationAsProjectAdmin?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        if (payload.createOrganizationAsProjectAdmin?.organization) {
          return history.push('/admin/organizations');
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connectionRecords = ConnectionHandler.getConnections(
          root,
          'OrganizationsList_organizations',
        );

        if (!connectionRecords) {
          return;
        }

        const payload = store.getRootField('createOrganizationAsProjectAdmin');

        if (!payload) {
          return;
        }

        const newRecord = payload.getLinkedRecord('organization');

        if (!newRecord) {
          return;
        }

        connectionRecords.forEach(connectionRecord => {
          const newEdge = ConnectionHandler.createEdge(
            store,
            connectionRecord,
            newRecord,
            'organizationEdge',
          );

          ConnectionHandler.insertEdgeAfter(connectionRecord, newEdge);
        });
      },
    });
  }
}

const createOrganizationAsProjectAdminMutation = graphql`
  mutation createPageCreateOrganizationAsProjectAdminMutation(
    $input: CreateOrganizationAsProjectAdminInput!
  ) {
    createOrganizationAsProjectAdmin(input: $input) {
      organization {
        id
        createdAt
        name
        email
        city
        state
        organizationConfig {
          daysBeforeDueSoon
          daysBeforeOverdue
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default CreatePage;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {usePaginationFragment, useMutation} from 'react-relay/hooks';
import ConnectionHandler from 'relay-connection-handler-plus';
import ImportantTextCell from 'src/components/table/cell-types/ImportantTextCell';
import useFilters from 'src/hooks/useFilters';
import {showEventNotification} from 'src/utils/notifications';

import Table from '../../../components/table';
import SecondaryTextCell from '../../../components/table/cell-types/SecondaryTextCell';
import {listPageUsersQuery} from './__generated__/listPageUsersQuery.graphql';
import {OrganizationUsersOrder} from './__generated__/UsersPaginationQuery.graphql';
import {UsersTable_organizationUsers$key} from './__generated__/UsersTable_organizationUsers.graphql';
import {UsersTableDeleteOrganizationUserMutation} from './__generated__/UsersTableDeleteOrganizationUserMutation.graphql';
import TableFilters from './TableFilters';

type Props = {
  users: UsersTable_organizationUsers$key;
};

type Filters = {
  text?: string;
  orderBy?: OrganizationUsersOrder;
};

function UsersTable(props: Props) {
  const {data, loadNext, hasNext, refetch} = usePaginationFragment<
    listPageUsersQuery,
    UsersTable_organizationUsers$key
  >(
    graphql`
      fragment UsersTable_organizationUsers on Query
        @argumentDefinitions(
          first: {type: "Int!"}
          after: {type: "String"}
          text: {type: "String"}
          orderBy: {type: "OrganizationUsersOrder"}
        )
        @refetchable(queryName: "UsersPaginationQuery") {
        organizationUsers(first: $first, after: $after, orderBy: $orderBy, text: $text)
          @connection(key: "UsersList_organizationUsers") {
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              createdAt
              firstName
              lastName
              phone
              email
              role
            }
          }
        }
      }
    `,
    props.users,
  );

  const [commit] = useMutation<UsersTableDeleteOrganizationUserMutation>(deleteUserMutation);
  const {addFilters, setFilters, isTransitioning} = useFilters<Filters>({}, refetch);

  return (
    <Table
      filters={() => (
        <TableFilters onApplyFilters={handleOnApplyFilter} onResetFilters={handleOnResetFilter} />
      )}
      actions={{show: false, delete: true, edit: true, create: true}}
      totalDataLength={data.organizationUsers.totalCount}
      columns={columns}
      isTransitioning={isTransitioning}
      onPaginate={() => hasNext && loadNext(50)}
      onSort={handleOnSort}
      onDelete={handleDelete}
      data={data.organizationUsers.edges}
    />
  );

  function handleOnApplyFilter(selectedFilters: {text: string}) {
    addFilters({text: selectedFilters.text});
  }

  function handleOnResetFilter() {
    setFilters({});
  }

  async function handleOnSort(sortByConfig: any[]) {
    if (!sortByConfig.length) {
      addFilters({orderBy: undefined});

      return;
    }

    const orderByDirection = sortByConfig[0].desc ? 'DESC' : 'ASC';
    const orderByField = sortByConfig[0].id.toUpperCase();

    addFilters({
      orderBy: {direction: orderByDirection, field: orderByField},
    });
  }

  function handleDelete(id: string) {
    commit({
      variables: {input: {organizationUserId: id}},
      onCompleted: payload => {
        const entry = payload.deleteOrganizationUser?.organizationUser;

        if (entry) {
          showEventNotification('removed', 'User', entry.firstName);
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connections = ConnectionHandler.getConnections(root, 'UsersList_organizationUsers');

        if (!connections) {
          return;
        }

        connections.forEach(connection => {
          ConnectionHandler.deleteNode(connection, id);
        });
      },
    });
  }
}

export default UsersTable;

const deleteUserMutation = graphql`
  mutation UsersTableDeleteOrganizationUserMutation($input: DeleteOrganizationUserInput!) {
    deleteOrganizationUser(input: $input) {
      organizationUser {
        id
        firstName
        createdAt
      }
      errors {
        code
        message
      }
    }
  }
`;

const columns = [
  {
    Header: 'First Name',
    accessor: 'node.firstName',
    id: 'first_name',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'Last Name',
    accessor: 'node.lastName',
    id: 'last_name',
    Cell({cell: {value}}: any) {
      return <ImportantTextCell value={value} />;
    },
  },
  {
    Header: 'Email',
    accessor: 'node.email',
    id: 'email',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'Phone',
    accessor: 'node.phone',
    id: 'phone',
    Cell({cell: {value}}: any) {
      return <ImportantTextCell value={value} />;
    },
  },
  {
    Header: 'Role',
    accessor: 'node.role',
    id: 'role',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
];

import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useHistory, useParams} from 'react-router';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/form';
import {FormValues} from '../types';
import {updatePageHydrantQuery} from './__generated__/updatePageHydrantQuery.graphql';
import {updatePageUpdateHydrantMutation} from './__generated__/updatePageUpdateHydrantMutation.graphql';

function UpdatePage() {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [commit, isInFlight] = useMutation<updatePageUpdateHydrantMutation>(updateHydrantMutation);

  const data = useLazyLoadQuery<updatePageHydrantQuery>(
    query,
    {
      hydrantId: id,
    },
    {fetchPolicy: 'store-and-network'},
  );

  if (!data.hydrant) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">The item you wish to update could not be found.</h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      title="Update Hydrant "
      button="Update Hydrant "
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleInitialValues() {
    if (!data || !data.hydrant) {
      return;
    }

    const {hydrant} = data;

    return {
      manufacturerId: hydrant.manufacturer.id,
      familyId: hydrant.family.id,
      coordinates: {
        longitude: hydrant.coordinates[0].toString(),
        latitude: hydrant.coordinates[1].toString(),
      },
      modelId: hydrant.model.id,
      serial: hydrant.serial,
      externalId: hydrant.externalId,
      status: hydrant.status,
      installedAt: new Date(hydrant.installedAt),
      notes: hydrant.notes || '',
      warrantyExpiresAt: new Date(hydrant.warrantyExpiresAt),
      lastServicedAt: new Date(hydrant.lastServicedAt),
      hydrantGallonsPerMinute: hydrant.hydrantGallonsPerMinute.toString(),
      hydrantWaterMainDepth: hydrant.hydrantWaterMainDepth.toString(),
      hydrantWaterMainDiameter: hydrant.hydrantWaterMainDiameter.toString(),
      hydrantTurnsToOpenClose: hydrant.hydrantTurnsToOpenClose.toString(),
    };
  }

  function handleSubmit(input: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          hydrantId: id,
          updateHydrantData: {
            coordinates: [
              parseFloat(input.coordinates.longitude),
              parseFloat(input.coordinates.latitude),
            ],
            modelId: input.modelId,
            serial: input.serial,
            externalId: input.externalId,
            status: input.status,
            installedAt: input.installedAt.toISOString(),
            warrantyExpiresAt: input.warrantyExpiresAt.toISOString(),
            lastServicedAt: input.lastServicedAt.toISOString(),
            hydrantGallonsPerMinute: parseInt(input.hydrantGallonsPerMinute, 10),
            hydrantWaterMainDepth: parseInt(input.hydrantWaterMainDepth, 10),
            hydrantWaterMainDiameter: parseInt(input.hydrantWaterMainDiameter, 10),
            hydrantTurnsToOpenClose: parseInt(input.hydrantTurnsToOpenClose, 10),
            ...(input.notes ? {notes: input.notes} : {}),
          },
        },
      },
      onCompleted: payload => {
        if (payload.updateHydrant?.errors[0] && payload.updateHydrant?.errors[0].fields) {
          payload.updateHydrant?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.updateHydrant?.hydrant;

        if (entry) {
          showEventNotification('updated', 'Hydrant', entry.serial);

          if (history.location.search.includes('newTab')) {
            window.close();
          }

          return history.push('/admin/hydrants');
        }
      },
    });
  }
}

const query = graphql`
  query updatePageHydrantQuery($hydrantId: ID!) {
    hydrant(hydrantId: $hydrantId) {
      id
      createdAt
      serial
      externalId
      coordinates
      lastServicedAt
      warrantyExpiresAt
      installedAt
      status
      externalId
      hydrantGallonsPerMinute
      hydrantWaterMainDepth
      hydrantWaterMainDiameter
      hydrantTurnsToOpenClose
      notes
      model {
        id
        name
      }
      family {
        id
        name
      }
      manufacturer {
        id
        name
      }
    }
  }
`;

const updateHydrantMutation = graphql`
  mutation updatePageUpdateHydrantMutation($input: UpdateHydrantInput!) {
    updateHydrant(input: $input) {
      hydrant {
        id
        createdAt
        serial
        externalId
        model {
          id
          name
        }
        family {
          id
          name
        }
        manufacturer {
          id
          name
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(UpdatePage);

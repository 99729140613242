import styled from 'styled-components';

export const Widget = styled.div.attrs({className: 'rounded-md shadow p-6'})``;
export const WidgetTitle = styled.h3.attrs({
  className: 'text-xl text-gray-800 font-semibold mb-4',
})``;

export const WidgetBlankContainer = styled.div.attrs({
  className: 'flex flex-col items-center text-center py-16',
})``;

export const WidgetBlankIconContainer = styled.div.attrs({
  className: 'bg-gray-200 text-gray-700 rounded-full p-6 mb-4',
})`
  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const WidgetBlankTitle = styled.h3.attrs({
  className: 'font-medium text-xl text-gray-700',
})``;

export const WidgetBlankDescription = styled.p.attrs({
  className: 'text-gray-600',
})``;

/**
 * @generated SignedSource<<2cab689976bb574513613213f0f2aed9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type detailsPageHydrantQuery$variables = {
  hydrantId: string;
};
export type detailsPageHydrantQueryVariables = detailsPageHydrantQuery$variables;
export type detailsPageHydrantQuery$data = {
  readonly hydrant: {
    readonly id: string;
    readonly " $fragmentSpreads": FragmentRefs<"GeneralTab_hydrant" | "LocationTab_asset">;
  } | null;
};
export type detailsPageHydrantQueryResponse = detailsPageHydrantQuery$data;
export type detailsPageHydrantQuery = {
  variables: detailsPageHydrantQueryVariables;
  response: detailsPageHydrantQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hydrantId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "hydrantId",
    "variableName": "hydrantId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "detailsPageHydrantQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Hydrant",
        "kind": "LinkedField",
        "name": "hydrant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "GeneralTab_hydrant"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LocationTab_asset"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "detailsPageHydrantQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Hydrant",
        "kind": "LinkedField",
        "name": "hydrant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hydrantTurnsToOpenClose",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hydrantWaterMainDiameter",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hydrantWaterMainDepth",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hydrantGallonsPerMinute",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MeasurementConnection",
            "kind": "LinkedField",
            "name": "currentMeasurements",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MeasurementEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Measurement",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MeasurementTemplate",
                        "kind": "LinkedField",
                        "name": "template",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serial",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "externalId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "installedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "warrantyExpiresAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastServicedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "model",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "family",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "manufacturer",
                "plural": false,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "coordinates",
                "storageKey": null
              }
            ],
            "type": "Asset",
            "abstractKey": "__isAsset"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "610c94c93d0e04fb24a1a19bf3119b5a",
    "id": null,
    "metadata": {},
    "name": "detailsPageHydrantQuery",
    "operationKind": "query",
    "text": "query detailsPageHydrantQuery(\n  $hydrantId: ID!\n) {\n  hydrant(hydrantId: $hydrantId) {\n    id\n    ...GeneralTab_hydrant\n    ...LocationTab_asset\n  }\n}\n\nfragment DetailsSection_hydrant on Hydrant {\n  id\n  status\n  hydrantTurnsToOpenClose\n  hydrantWaterMainDiameter\n  hydrantWaterMainDepth\n  hydrantGallonsPerMinute\n}\n\nfragment GeneralTab_hydrant on Hydrant {\n  id\n  ...IdentificationSection_asset\n  ...HistorySection_asset\n  ...ManufacturingSection_asset\n  ...DetailsSection_hydrant\n  currentMeasurements {\n    ...MeasurementsSection_currentMeasurements\n  }\n}\n\nfragment HistorySection_asset on Asset {\n  __isAsset: __typename\n  id\n  installedAt\n  warrantyExpiresAt\n  lastServicedAt\n}\n\nfragment IdentificationSection_asset on Asset {\n  __isAsset: __typename\n  id\n  serial\n  externalId\n}\n\nfragment LocationTab_asset on Asset {\n  __isAsset: __typename\n  id\n  coordinates\n}\n\nfragment ManufacturingSection_asset on Asset {\n  __isAsset: __typename\n  id\n  model {\n    __typename\n    id\n    name\n  }\n  family {\n    __typename\n    id\n    name\n  }\n  manufacturer {\n    __typename\n    id\n    name\n  }\n}\n\nfragment MeasurementsSection_currentMeasurements on MeasurementConnection {\n  edges {\n    node {\n      id\n      value\n      createdAt\n      template {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9a53b360ec7f10b391a35676118d9660";

export default node;

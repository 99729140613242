import {useFormik, FormikHelpers} from 'formik';
import React from 'react';
import StateInput from 'src/components/inputs/StateInput';
import {
  DEFAULT_DAYS_BEFORE_DUE_SOON,
  DEFAULT_DAYS_BEFORE_OVERDUE,
} from 'src/constants/organization';
import * as Yup from 'yup';

import TextInput from '../../../components/inputs/TextInput';
import {CreateFormValues} from '../types';

type Props = {
  isCreating?: boolean;
  initialValues?: CreateFormValues;
  onSubmit: (values: CreateFormValues, formikHelpers: FormikHelpers<CreateFormValues>) => void;
  title: string;
  button: string;
  isSubmitting: boolean;
};

function Form(props: Props) {
  const {isSubmitting, initialValues, title, button, onSubmit} = props;

  const {submitCount, handleSubmit, errors, setFieldValue, values, isValid} = useFormik<
    CreateFormValues
  >({
    initialValues: Object.assign(
      {},
      {
        name: '',
        email: '',
        city: '',
        state: '',
        firstName: '',
        lastName: '',
        adminUserEmail: '',
        phone: '',
        password: '',
        confirmPassword: '',
        organizationConfigAttributes: {
          daysBeforeDueSoon: DEFAULT_DAYS_BEFORE_DUE_SOON,
          daysBeforeOverdue: DEFAULT_DAYS_BEFORE_OVERDUE,
        },
      },
      initialValues,
    ),
    isInitialValid: false,
    enableReinitialize: true,
    onSubmit,
    validationSchema,
  });

  return (
    <div className="p-8">
      <div className="mb-6 pb-1 border-b-2 border-gray-400">
        <h2 className="text-2xl text-gray-800 uppercase font-semibold">{title}</h2>
      </div>

      <div className="mb-4 pb-1 border-b border-gray-300">
        <h3 className="text-lg text-orange-600">Organization Details</h3>
      </div>

      <TextInput
        name="name"
        label="Name"
        placeholder="E.g. Maple Falls Waterworks"
        handleChange={value => setFieldValue('name', value)}
        value={values.name}
        error={!!submitCount && !!errors.name ? errors.name : ''}
      />

      <TextInput
        name="email"
        label="Email"
        placeholder="E.g. waterworks@maplefalls.gov"
        handleChange={value => setFieldValue('email', value)}
        value={values.email}
        error={!!submitCount && !!errors.email ? errors.email : ''}
      />

      <TextInput
        name="city"
        label="City"
        placeholder="E.g. Maple Falls"
        handleChange={value => setFieldValue('city', value)}
        value={values.city}
        error={!!submitCount && !!errors.city ? errors.city : ''}
      />

      <StateInput value={values.state} onChange={value => setFieldValue('state', value)} />

      <TextInput
        name="organizationConfigAttributes.daysBeforeDueSoon"
        type="number"
        label="# Days before due soon"
        placeholder={String(DEFAULT_DAYS_BEFORE_DUE_SOON)}
        handleChange={value =>
          setFieldValue('organizationConfigAttributes.daysBeforeDueSoon', parseInt(value))
        }
        value={String(values.organizationConfigAttributes?.daysBeforeDueSoon)}
      />

      <TextInput
        name="organizationConfigAttributes.daysBeforeOverdue"
        type="number"
        label="# Days before Overdue"
        placeholder={String(DEFAULT_DAYS_BEFORE_OVERDUE)}
        handleChange={value =>
          setFieldValue('organizationConfigAttributes.daysBeforeOverdue', parseInt(value))
        }
        value={String(values.organizationConfigAttributes?.daysBeforeOverdue)}
      />

      <div className="mb-4 pb-1 border-b border-gray-300">
        <h3 className="text-lg text-orange-600">Admin User Details</h3>
      </div>

      <TextInput
        name="firstName"
        label="First Name"
        placeholder="E.g. Benjamin"
        handleChange={value => setFieldValue('firstName', value)}
        value={values.firstName}
        error={!!submitCount && !!errors.firstName ? errors.firstName : ''}
      />

      <TextInput
        name="lastName"
        label="Last Name"
        placeholder="E.g. Franklin"
        handleChange={value => setFieldValue('lastName', value)}
        value={values.lastName}
        error={!!submitCount && !!errors.lastName ? errors.lastName : ''}
      />

      <TextInput
        name="adminUserEmail"
        label="Email"
        placeholder="E.g. bfranklin@example.com"
        handleChange={value => setFieldValue('adminUserEmail', value)}
        value={values.adminUserEmail}
        type="email"
        error={!!submitCount && !!errors.adminUserEmail ? errors.adminUserEmail : ''}
      />

      <TextInput
        name="phone"
        label="Phone"
        placeholder="E.g. 7543010"
        handleChange={value => setFieldValue('phone', value)}
        handleBlur={() => setFieldValue('phone', values.phone.replace(/\D/g, ''))}
        value={values.phone}
        error={!!submitCount && !!errors.phone ? errors.phone : ''}
        helperText="Phone numers are automatically formatted."
      />

      <TextInput
        name="password"
        label="Password"
        placeholder="Pick something secure!"
        handleChange={value => setFieldValue('password', value)}
        value={values.password}
        type="password"
        error={!!submitCount && !!errors.password ? errors.password : ''}
      />

      <TextInput
        name="confirmPassword"
        label="Confirm Password"
        placeholder=""
        handleChange={value => setFieldValue('confirmPassword', value)}
        value={values.confirmPassword}
        type="password"
        error={!!submitCount && !!errors.confirmPassword ? errors.confirmPassword : ''}
      />

      <button
        disabled={isSubmitting}
        onClick={() => handleSubmit()}
        className={
          'px-5 py-2 text-sm rounded font-semibold text-white uppercase ' +
          (isValid ? 'bg-gray-700' : 'bg-gray-400')
        }>
        {button}
      </button>
    </div>
  );
}

const validationSchema = Yup.object({
  name: Yup.string().required('Please provide a manufacturer name.'),
});

export default Form;

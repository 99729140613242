/**
 * @generated SignedSource<<a3df50659f5ebd90fa3377031ccd48be>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeneralTab_generalAsset$data = {
  readonly id: string;
  readonly currentMeasurements: {
    readonly " $fragmentSpreads": FragmentRefs<"MeasurementsSection_currentMeasurements">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"IdentificationSection_asset" | "HistorySection_asset" | "ManufacturingSection_asset" | "DetailsSection_generalAsset">;
  readonly " $fragmentType": "GeneralTab_generalAsset";
};
export type GeneralTab_generalAsset = GeneralTab_generalAsset$data;
export type GeneralTab_generalAsset$key = {
  readonly " $data"?: GeneralTab_generalAsset$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeneralTab_generalAsset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeneralTab_generalAsset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IdentificationSection_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistorySection_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ManufacturingSection_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DetailsSection_generalAsset"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MeasurementConnection",
      "kind": "LinkedField",
      "name": "currentMeasurements",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MeasurementsSection_currentMeasurements"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GeneralAsset",
  "abstractKey": null
};

(node as any).hash = "56ce8972fd3842f3fbc91dad3847944f";

export default node;

import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime'; // load on demand
import React from 'react';
import styled from 'styled-components';

dayjs.extend(RelativeTime); // use plugin

type Props = {
  value: any;
};

function DateCell({value}: Props) {
  if (!value) {
    return <CellContainer />;
  }

  const dateFormatted = dayjs(value).format('MM/DD/YY');
  const fromNowFormatted = dayjs(value).fromNow();

  return (
    <CellContainer>
      <p className="font-bold text-sm leading-relaxed text-gray-700">{dateFormatted}</p>
      <p className="text-xs text-gray-600">{fromNowFormatted}</p>
    </CellContainer>
  );
}

const CellContainer = styled.div.attrs({
  className: 'flex flex-col',
})``;

export default DateCell;

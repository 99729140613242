/**
 * @generated SignedSource<<d7b8ea0dbe1aac392935992db0508145>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type ResetPasswordInput = {
  token: string;
  password: string;
  clientMutationId?: string | null;
};
export type resetPasswordPageResetPasswordMutation$variables = {
  input: ResetPasswordInput;
};
export type resetPasswordPageResetPasswordMutationVariables = resetPasswordPageResetPasswordMutation$variables;
export type resetPasswordPageResetPasswordMutation$data = {
  readonly resetPassword: {
    readonly updated: boolean | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
    }>;
  } | null;
};
export type resetPasswordPageResetPasswordMutationResponse = resetPasswordPageResetPasswordMutation$data;
export type resetPasswordPageResetPasswordMutation = {
  variables: resetPasswordPageResetPasswordMutationVariables;
  response: resetPasswordPageResetPasswordMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ResetPasswordPayload",
    "kind": "LinkedField",
    "name": "resetPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updated",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "resetPasswordPageResetPasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "resetPasswordPageResetPasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "500cd7f7db8e6edfdf517af1135e684e",
    "id": null,
    "metadata": {},
    "name": "resetPasswordPageResetPasswordMutation",
    "operationKind": "mutation",
    "text": "mutation resetPasswordPageResetPasswordMutation(\n  $input: ResetPasswordInput!\n) {\n  resetPassword(input: $input) {\n    updated\n    errors {\n      code\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "20859d4132de78c864f0f53f9b3eb603";

export default node;

import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useMutation} from 'react-relay/hooks';
import {useHistory} from 'react-router';
import ConnectionHandler from 'relay-connection-handler-plus';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/Form';
import {FormValues} from '../types';
import {createPageCreateHydrantFamilyMutation} from './__generated__/createPageCreateHydrantFamilyMutation.graphql';

function CreatePage() {
  const history = useHistory();

  const [commit, isInFlight] = useMutation<createPageCreateHydrantFamilyMutation>(
    createHydrantFamilyMutation,
  );

  return (
    <Form
      title="Create Hydrant Type"
      button="Create Hydrant Type"
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleSubmit(input: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {input},
      onCompleted: payload => {
        if (
          payload.createHydrantFamily?.errors[0] &&
          payload.createHydrantFamily?.errors[0].fields
        ) {
          payload.createHydrantFamily?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.createHydrantFamily?.hydrantFamily;

        if (entry) {
          showEventNotification('added', 'Type', entry.name);
          return history.push('/admin/hydrant_types');
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connectionRecords = ConnectionHandler.getConnections(
          root,
          'HydrantFamiliesList_hydrantFamilies',
        );

        if (!connectionRecords) {
          return;
        }

        const payload = store.getRootField('createHydrantFamily');

        if (!payload) {
          return;
        }

        const newRecord = payload.getLinkedRecord('hydrantFamily');

        if (!newRecord) {
          return;
        }

        connectionRecords.forEach(connectionRecord => {
          const newEdge = ConnectionHandler.createEdge(
            store,
            connectionRecord,
            newRecord,
            'hydrantFamilyEdge',
          );

          ConnectionHandler.insertEdgeAfter(connectionRecord, newEdge);
        });
      },
    });
  }
}

const createHydrantFamilyMutation = graphql`
  mutation createPageCreateHydrantFamilyMutation($input: CreateHydrantFamilyInput!) {
    createHydrantFamily(input: $input) {
      hydrantFamily {
        id
        createdAt
        name
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(CreatePage);

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {withSuspense} from 'src/utils/withSuspense';

import {generalAssetMeasurementReportPageassetsQuery} from './__generated__/generalAssetMeasurementReportPageassetsQuery.graphql';
import {generalAssetMeasurementReportPagemeasurementTemplatesQuery} from './__generated__/generalAssetMeasurementReportPagemeasurementTemplatesQuery.graphql';
import GeneralAssetsTable from './GeneralAssetsTable';

function GeneralAssetMeasurementReportPage() {
  const templatesData = useLazyLoadQuery<
    generalAssetMeasurementReportPagemeasurementTemplatesQuery
  >(measurementTemplates, {}, {fetchPolicy: 'store-and-network'});

  const data = useLazyLoadQuery<generalAssetMeasurementReportPageassetsQuery>(
    generalAssets,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  const templates = templatesData?.measurementTemplates.edges || [];

  return <GeneralAssetsTable measurementTemplates={templates} assets={data} />;
}

export default withSuspense(GeneralAssetMeasurementReportPage);

const generalAssets = graphql`
  query generalAssetMeasurementReportPageassetsQuery(
    $first: Int
    $createdBefore: ISO8601DateTime
    $status: AssetStatus
    $text: String
  ) {
    ...GeneralAssetsTable_assets
      @arguments(first: $first, createdBefore: $createdBefore, status: $status, text: $text)
  }
`;

const measurementTemplates = graphql`
  query generalAssetMeasurementReportPagemeasurementTemplatesQuery {
    measurementTemplates(assetType: general_asset) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

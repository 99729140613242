import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';

import {GeneralAssetFamilyInputGeneralAssetFamiliesQuery} from './__generated__/GeneralAssetFamilyInputGeneralAssetFamiliesQuery.graphql';
import DropdownInput from './DropdownInput';

type Props = {
  value: string;
  manufacturerId: string;
  onChange: (value: string) => void;
  error?: string;
};

function GeneralAssetFamilyInput(props: Props) {
  const {value, manufacturerId, onChange, error} = props;

  const data = useLazyLoadQuery<GeneralAssetFamilyInputGeneralAssetFamiliesQuery>(
    generalAssetFamiliesQuery,
    {manufacturerId: props.manufacturerId},
    {fetchPolicy: 'store-and-network'},
  );

  const options =
    !!data && manufacturerId
      ? data.generalAssetFamilies.edges
          .filter(
            generalAssetFamilyEdge =>
              generalAssetFamilyEdge.node.manufacturer.id === manufacturerId,
          )
          .map(generalAssetFamilyEdge => ({
            label: generalAssetFamilyEdge.node.name,
            value: generalAssetFamilyEdge.node.id,
          }))
      : [];

  return (
    <DropdownInput
      name="generalAssetFamilyInput"
      onChange={onChange}
      options={options}
      placeholder="E.g. K81A"
      label="Type"
      value={value}
      error={error}
    />
  );
}

const generalAssetFamiliesQuery = graphql`
  query GeneralAssetFamilyInputGeneralAssetFamiliesQuery {
    generalAssetFamilies {
      edges {
        node {
          id
          name
          manufacturer {
            id
          }
        }
      }
    }
  }
`;

export default GeneralAssetFamilyInput;

import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useMutation} from 'react-relay/hooks';
import {useHistory} from 'react-router';
import ConnectionHandler from 'relay-connection-handler-plus';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/form';
import {FormValues} from '../types';
import {createPageCreateHydrantMutation} from './__generated__/createPageCreateHydrantMutation.graphql';

function CreatePage() {
  const history = useHistory();

  const [commit, isInFlight] = useMutation<createPageCreateHydrantMutation>(createHydrantMutation);

  return (
    <Form
      title="Create Hydrant"
      button="Create Hydrant"
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleSubmit(input: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          coordinates: [
            parseFloat(input.coordinates.longitude),
            parseFloat(input.coordinates.latitude),
          ],
          modelId: input.modelId,
          serial: input.serial,
          externalId: input.externalId,
          status: input.status,
          installedAt: input.installedAt.toISOString(),
          warrantyExpiresAt: input.warrantyExpiresAt.toISOString(),
          lastServicedAt: input.lastServicedAt.toISOString(),
          hydrantGallonsPerMinute: parseInt(input.hydrantGallonsPerMinute, 10),
          hydrantWaterMainDepth: parseInt(input.hydrantWaterMainDepth, 10),
          hydrantWaterMainDiameter: parseInt(input.hydrantWaterMainDiameter, 10),
          hydrantTurnsToOpenClose: parseInt(input.hydrantTurnsToOpenClose, 10),
          ...(input.notes ? {notes: input.notes} : {}),
        },
      },
      onCompleted: payload => {
        if (payload.createHydrant?.errors[0] && payload.createHydrant?.errors[0].fields) {
          payload.createHydrant?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.createHydrant?.hydrant;

        if (entry) {
          showEventNotification('added', 'Hydrant', entry.serial);
          return history.push('/admin/hydrants');
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connectionRecords = ConnectionHandler.getConnections(root, 'HydrantsList_hydrants');

        if (!connectionRecords) {
          return;
        }

        const payload = store.getRootField('createHydrant');

        if (!payload) {
          return;
        }

        const newRecord = payload.getLinkedRecord('hydrant');

        if (!newRecord) {
          return;
        }

        connectionRecords.forEach(connectionRecord => {
          const newEdge = ConnectionHandler.createEdge(
            store,
            connectionRecord,
            newRecord,
            'hydrantEdge',
          );

          ConnectionHandler.insertEdgeAfter(connectionRecord, newEdge);
        });
      },
    });
  }
}

const createHydrantMutation = graphql`
  mutation createPageCreateHydrantMutation($input: CreateHydrantInput!) {
    createHydrant(input: $input) {
      hydrant {
        id
        createdAt
        serial
        externalId
        manufacturer {
          id
          name
        }
        model {
          id
          name
        }
        family {
          id
          name
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(CreatePage);

import React from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import 'react-datepicker/dist/react-datepicker.css';

type Props = {
  name: string;
  label: string;
  placeholder: string;
  value: Date;
  onChange: (value: Date) => void;
  range?: 'future' | 'past';
  error?: string;
};

export default function DateInput(props: Props) {
  const {name, label, placeholder, value, onChange, error, range} = props;

  return (
    <div className="w-50 mb-6">
      <label
        className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
        htmlFor={name}>
        {label}
      </label>

      <DateSelect
        selected={value}
        className="block w-full appearance-none w-full border border-gray-500 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
        onChange={(newDate: Date) => onChange(newDate)}
        isInvalid={false}
        name={name}
        placeholderText={placeholder}
        {...(range === 'future' && {minDate: new Date()})}
        {...(range === 'past' && {maxDate: new Date()})}
      />
      {error && <p className="text-red-500 text-xs mt-1 italic">{error}</p>}
    </div>
  );
}

const DateSelect = styled(DatePicker)<{isInvalid: boolean}>``;

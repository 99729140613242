import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {usePaginationFragment, useMutation} from 'react-relay/hooks';
import ConnectionHandler from 'relay-connection-handler-plus';
import useFilters from 'src/hooks/useFilters';
import {showEventNotification} from 'src/utils/notifications';

import Table from '../../../components/table';
import DateCell from '../../../components/table/cell-types/DateCell';
import SecondaryTextCell from '../../../components/table/cell-types/SecondaryTextCell';
import {AssetFamiliesOrder} from './__generated__/HydrantFamiliesPaginationQuery.graphql';
import {HydrantFamiliesTable_hydrantFamilies$key} from './__generated__/HydrantFamiliesTable_hydrantFamilies.graphql';
import {HydrantFamiliesTableDeleteHydrantFamilyMutation} from './__generated__/HydrantFamiliesTableDeleteHydrantFamilyMutation.graphql';
import {listPageHydrantFamiliesQuery} from './__generated__/listPageHydrantFamiliesQuery.graphql';
import TableFilters from './TableFilters';

type Props = {
  hydrantFamilies: HydrantFamiliesTable_hydrantFamilies$key;
};

type Filters = {
  name?: string;
  orderBy?: AssetFamiliesOrder;
};

function HydrantFamiliesTable(props: Props) {
  const {data, loadNext, hasNext, refetch} = usePaginationFragment<
    listPageHydrantFamiliesQuery,
    HydrantFamiliesTable_hydrantFamilies$key
  >(
    graphql`
      fragment HydrantFamiliesTable_hydrantFamilies on Query
        @argumentDefinitions(
          first: {type: "Int!"}
          after: {type: "String"}
          name: {type: "String"}
          orderBy: {type: "AssetFamiliesOrder"}
        )
        @refetchable(queryName: "HydrantFamiliesPaginationQuery") {
        hydrantFamilies(first: $first, after: $after, orderBy: $orderBy, name: $name)
          @connection(key: "HydrantFamiliesList_hydrantFamilies") {
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              createdAt
              name
              manufacturer {
                id
                name
              }
            }
          }
        }
      }
    `,
    props.hydrantFamilies,
  );

  const [commit] = useMutation<HydrantFamiliesTableDeleteHydrantFamilyMutation>(
    deleteHydrantFamilyMutation,
  );

  const {addFilters, setFilters, isTransitioning} = useFilters<Filters>({}, refetch);

  return (
    <Table
      filters={() => (
        <TableFilters onApplyFilters={handleOnApplyFilter} onResetFilters={handleOnResetFilter} />
      )}
      actions={{show: false, delete: true, edit: true, create: true}}
      totalDataLength={data.hydrantFamilies.totalCount}
      columns={columns}
      onPaginate={() => hasNext && loadNext(50)}
      onSort={handleOnSort}
      onDelete={handleDelete}
      isTransitioning={isTransitioning}
      data={data.hydrantFamilies.edges}
    />
  );

  function handleOnApplyFilter(filters: {text: string}) {
    addFilters({name: filters.text});
  }

  function handleOnResetFilter() {
    setFilters({});
  }

  async function handleOnSort(sortByConfig: any[]) {
    if (!sortByConfig.length) {
      addFilters({orderBy: undefined});

      return;
    }

    const orderByDirection = sortByConfig[0].desc ? 'DESC' : 'ASC';
    const orderByField = sortByConfig[0].id.toUpperCase();

    addFilters({orderBy: {direction: orderByDirection, field: orderByField}});
  }

  function handleDelete(id: string) {
    commit({
      variables: {input: {hydrantFamilyId: id}},
      onCompleted: payload => {
        const entry = payload.deleteHydrantFamily?.hydrantFamily;

        if (entry) {
          showEventNotification('removed', 'Type', entry.name);
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connections = ConnectionHandler.getConnections(
          root,
          'HydrantFamiliesList_hydrantFamilies',
        );

        if (!connections) {
          return;
        }

        connections.forEach(connection => {
          ConnectionHandler.deleteNode(connection, id);
        });
      },
    });
  }
}

export default HydrantFamiliesTable;

const deleteHydrantFamilyMutation = graphql`
  mutation HydrantFamiliesTableDeleteHydrantFamilyMutation($input: DeleteHydrantFamilyInput!) {
    deleteHydrantFamily(input: $input) {
      hydrantFamily {
        id
        name
      }
      errors {
        code
        message
      }
    }
  }
`;

const columns = [
  {
    Header: 'Name',
    accessor: 'node.name',
    id: 'name',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'Created On',
    accessor: 'node.createdAt',
    id: 'created_at',
    Cell({cell: {value}}: any) {
      return <DateCell value={value} />;
    },
  },
  {
    Header: 'Manufacturer',
    accessor: 'node.manufacturer.name',
    id: 'manufacturer_name',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
];

/**
 * @generated SignedSource<<9baedcca4cca0e7680eb5651844de8aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type DeleteOrganizationAsProjectAdminInput = {
  organizationId: string;
  clientMutationId?: string | null;
};
export type OrganizationsTableDeleteOrganizationAsProjectAdminMutation$variables = {
  input: DeleteOrganizationAsProjectAdminInput;
};
export type OrganizationsTableDeleteOrganizationAsProjectAdminMutationVariables = OrganizationsTableDeleteOrganizationAsProjectAdminMutation$variables;
export type OrganizationsTableDeleteOrganizationAsProjectAdminMutation$data = {
  readonly deleteOrganizationAsProjectAdmin: {
    readonly organization: {
      readonly id: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly message: string;
    }>;
  } | null;
};
export type OrganizationsTableDeleteOrganizationAsProjectAdminMutationResponse = OrganizationsTableDeleteOrganizationAsProjectAdminMutation$data;
export type OrganizationsTableDeleteOrganizationAsProjectAdminMutation = {
  variables: OrganizationsTableDeleteOrganizationAsProjectAdminMutationVariables;
  response: OrganizationsTableDeleteOrganizationAsProjectAdminMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteOrganizationAsProjectAdminPayload",
    "kind": "LinkedField",
    "name": "deleteOrganizationAsProjectAdmin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OrganizationsTableDeleteOrganizationAsProjectAdminMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OrganizationsTableDeleteOrganizationAsProjectAdminMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "98cf1332b3552b00ef4f3b64af1543e1",
    "id": null,
    "metadata": {},
    "name": "OrganizationsTableDeleteOrganizationAsProjectAdminMutation",
    "operationKind": "mutation",
    "text": "mutation OrganizationsTableDeleteOrganizationAsProjectAdminMutation(\n  $input: DeleteOrganizationAsProjectAdminInput!\n) {\n  deleteOrganizationAsProjectAdmin(input: $input) {\n    organization {\n      id\n    }\n    errors {\n      code\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "17f866ec46b107807e8e65828f7a021d";

export default node;

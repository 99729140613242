/**
 * @generated SignedSource<<c11187a321cc9bc1e8bc07addc4f580f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type CreateGeneralAssetManufacturerInput = {
  name: string;
  clientMutationId?: string | null;
};
export type createPageCreateGeneralAssetManufacturerMutation$variables = {
  input: CreateGeneralAssetManufacturerInput;
};
export type createPageCreateGeneralAssetManufacturerMutationVariables = createPageCreateGeneralAssetManufacturerMutation$variables;
export type createPageCreateGeneralAssetManufacturerMutation$data = {
  readonly createGeneralAssetManufacturer: {
    readonly generalAssetManufacturer: {
      readonly id: string;
      readonly createdAt: any;
      readonly name: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly fields: ReadonlyArray<{
        readonly fieldName: string;
        readonly message: string;
      }> | null;
    }>;
  } | null;
};
export type createPageCreateGeneralAssetManufacturerMutationResponse = createPageCreateGeneralAssetManufacturerMutation$data;
export type createPageCreateGeneralAssetManufacturerMutation = {
  variables: createPageCreateGeneralAssetManufacturerMutationVariables;
  response: createPageCreateGeneralAssetManufacturerMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateGeneralAssetManufacturerPayload",
    "kind": "LinkedField",
    "name": "createGeneralAssetManufacturer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GeneralAssetManufacturer",
        "kind": "LinkedField",
        "name": "generalAssetManufacturer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFieldError",
            "kind": "LinkedField",
            "name": "fields",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fieldName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createPageCreateGeneralAssetManufacturerMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createPageCreateGeneralAssetManufacturerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "72043eba5f2e47ce1fc22cb848ff6956",
    "id": null,
    "metadata": {},
    "name": "createPageCreateGeneralAssetManufacturerMutation",
    "operationKind": "mutation",
    "text": "mutation createPageCreateGeneralAssetManufacturerMutation(\n  $input: CreateGeneralAssetManufacturerInput!\n) {\n  createGeneralAssetManufacturer(input: $input) {\n    generalAssetManufacturer {\n      id\n      createdAt\n      name\n    }\n    errors {\n      code\n      fields {\n        fieldName\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cefc4f014bedc068d882ad15349315a8";

export default node;

/**
 * @generated SignedSource<<f24c6366a2699e37cb98a141f33990fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type JobsAssignedWidgetorganizationUsersWithJobsCountQuery$variables = {};
export type JobsAssignedWidgetorganizationUsersWithJobsCountQueryVariables = JobsAssignedWidgetorganizationUsersWithJobsCountQuery$variables;
export type JobsAssignedWidgetorganizationUsersWithJobsCountQuery$data = {
  readonly organizationUsersWithJobsCount: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly assignedJobsCount: number;
        readonly completedJobsCount: number;
      };
    }>;
  };
};
export type JobsAssignedWidgetorganizationUsersWithJobsCountQueryResponse = JobsAssignedWidgetorganizationUsersWithJobsCountQuery$data;
export type JobsAssignedWidgetorganizationUsersWithJobsCountQuery = {
  variables: JobsAssignedWidgetorganizationUsersWithJobsCountQueryVariables;
  response: JobsAssignedWidgetorganizationUsersWithJobsCountQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OrganizationUserWithJobsCountConnection",
    "kind": "LinkedField",
    "name": "organizationUsersWithJobsCount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationUserWithJobsCountEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationUserWithJobsCount",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assignedJobsCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "completedJobsCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "JobsAssignedWidgetorganizationUsersWithJobsCountQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "JobsAssignedWidgetorganizationUsersWithJobsCountQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "415ae652595a770ad9019f96b0f81dbd",
    "id": null,
    "metadata": {},
    "name": "JobsAssignedWidgetorganizationUsersWithJobsCountQuery",
    "operationKind": "query",
    "text": "query JobsAssignedWidgetorganizationUsersWithJobsCountQuery {\n  organizationUsersWithJobsCount {\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        assignedJobsCount\n        completedJobsCount\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0fe549da1e1fcff6d49538726e5f6749";

export default node;

import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {Link} from 'react-router-dom';
import {formatDateUS} from 'src/utils/formatDate';
import styled from 'styled-components';

type Props = {
  activeAsset: any;
  resetActiveAsset: () => void;
};

const assetLinkMap: {[key: string]: string} = {
  Hydrant: 'hydrants',
  GeneralAsset: 'general_assets',
  Valve: 'valves',
};

function ActiveAssetPanel({activeAsset, resetActiveAsset}: Props) {
  if (!activeAsset) {
    return null;
  }

  const lastServicedAt = formatDateUS(activeAsset.lastServicedAt);

  return (
    <ActiveAssetPanelContainer>
      <div className="w-full">
        <h3 className="text-gray-100 text-lg font-semibold">{activeAsset.externalId}</h3>
        <h3 className="text-gray-100 text-lg font-semibold">{activeAsset.serial}</h3>
        <h3 className="text-gray-100 text-lg font-semibold">
          <span className="font-normal">Last serviced at </span>
          {lastServicedAt}
        </h3>

        <Link to={`/admin/jobs?asset_serial=${activeAsset.serial}`}>
          <h3 className="text-orange-500 underline text-lg font-semibold">
            {activeAsset.jobCount} linked jobs
          </h3>
        </Link>

        {/* {!!activeAsset.pendingJobs.length && (
          <div className="mt-2 mb-4">
            <h4 className="text-orange-300 font-semibold">Jobs</h4>

            <div className="bg-white px-4 py-2 rounded shadow">
              <ul>
                {activeAsset.pendingJobs.map((job: any) => {
                  const dueAtFormatted = dayjs(job.dueAt).format('MM/DD/YY');
                  return (
                    <li className="mb-2 text-sm text-gray-700" key={job.id}>
                      <p>
                        <span className="font-semibold">#{job.jobNumber} - </span>
                        Due at {dueAtFormatted} - Assigned to {job.assignedTo.firstName[0]}.{' '}
                        {job.assignedTo.lastName}
                        <span>
                          {' '}
                          -{' '}
                          <Link
                            className="font-semibold text-orange-600"
                            to={`/admin/jobs/${job.id}/edit`}>
                            Edit
                          </Link>
                        </span>
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )} */}
        <div className="mt-3">
          <Link to={`/admin/${assetLinkMap[activeAsset.__typename]}/${activeAsset.id}/details`}>
            <button className="bg-orange-700 px-2 py-1 rounded text-white uppercase text-sm uppercase font-semibold focus:outline-none hover:bg-orange-600 cursor-pointer">
              VIEW ASSET DETAILS
            </button>
          </Link>
        </div>
      </div>
      <div>
        <CloseIcon onClick={resetActiveAsset} />
      </div>
    </ActiveAssetPanelContainer>
  );
}

const ActiveAssetPanelContainer = styled.div.attrs({
  className: 'absolute justify-between flex shadow bg-gray-800 p-6',
})`
  left: 50%;
  transform: translate(-50%);
  width: 35rem;
  bottom: 4rem;
`;

const CloseIcon = styled(FontAwesomeIcon).attrs({
  icon: faTimes,
  className: 'text-2xl text-white hover:text-orange-500',
})``;

export default ActiveAssetPanel;

import React from 'react';
import {useRouteMatch} from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  actions: {show: boolean; delete: boolean; edit: boolean};
  onDelete: (id: string) => void;
  prepareRow: (row: any) => void;
  tableBodyProps: any;
  page: any;
};

function Body(props: Props) {
  const {path} = useRouteMatch();

  const {actions, onDelete, page, prepareRow, tableBodyProps} = props;

  return (
    <tbody {...tableBodyProps}>
      {page.map((row: any, rowIndex: any) => {
        prepareRow(row);

        const showLink = `${path}/${row.original.node.id}/details`;
        const editLink = `${path}/${row.original.node.id}/edit?newTab=true`;
        const hasActions = actions.show || actions.edit || actions.delete;

        return (
          <tr key={rowIndex} {...row.getRowProps()} className="border-b">
            {row.cells.map((cell: any, cellIndex: any) => {
              return (
                <Td key={cellIndex} {...cell.getCellProps()}>
                  {cell.render('Cell')}
                </Td>
              );
            })}
            {hasActions && (
              <TableData>
                <div className="flex justify-center">
                  {actions.show && (
                    <a href={showLink} target="_blank" rel="noreferrer" className="mr-1">
                      Show
                    </a>
                  )}
                  {actions.show && actions.edit && '|'}
                  {actions.edit && (
                    <a href={editLink} target="_blank" rel="noreferrer" className="mr-1 ml-1">
                      Edit
                    </a>
                  )}
                  {actions.edit && actions.delete && '|'}
                  {actions.delete && (
                    <p
                      className="ml-1 cursor-pointer"
                      onClick={() => confirmDeletion(row.original.node.id)}>
                      Delete
                    </p>
                  )}
                </div>
              </TableData>
            )}
          </tr>
        );
      })}
    </tbody>
  );

  function confirmDeletion(id: string) {
    const isDeletionConfirmed = window.confirm('Are you sure you want to delete this item?');

    isDeletionConfirmed && onDelete(id);
  }
}

const Td = styled.td.attrs({
  className: 'px-3 py-4',
})`
  &:first-child {
    padding-left: 2rem;
  }
`;

const TableData = styled.td.attrs({
  className: 'px-3 py-6 text-blue-800 text-sm',
})`
  min-width: 8.5rem;
`;

export default Body;

import React from 'react';

import {ReactComponent as OrganizationIcon} from '../../assets/icons/organisation.svg';
import {ReactComponent as SettingsIcon} from '../../assets/icons/settings.svg';
import {ReactComponent as UserIcon} from '../../assets/icons/user.svg';
import NavigationUserActions from './sidebar/NavigationUserActions';
import {
  NavigationContainer,
  NavigationSectionContainer,
  NavigationSection,
  NavigationLink,
  NavigationActionContainer,
} from './sidebar/styledComponents';

export default function ProjectAdminSidebar() {
  return (
    <NavigationContainer>
      <NavigationSectionContainer>
        <NavigationSection>
          <NavigationLink to="/admin/organizations">
            <OrganizationIcon fill="currentColor" width="1.4em" height="1.4em" className="mr-2" />
            Organizations
          </NavigationLink>
          <NavigationLink to="/admin/users">
            <UserIcon fill="currentColor" width="1.4em" height="1.2em" className="mr-2" />
            Users
          </NavigationLink>

          <NavigationLink to="/admin/settings">
            <SettingsIcon fill="currentColor" width="1.4em" height="1.2em" className="mr-2" />
            Settings
          </NavigationLink>
        </NavigationSection>
      </NavigationSectionContainer>

      <NavigationActionContainer>
        <NavigationUserActions />
      </NavigationActionContainer>
    </NavigationContainer>
  );
}

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useFragment} from 'react-relay/hooks';
import {formatDateTimeUS} from 'src/utils/formatDate';

import {TaskCompletionRecordItem_taskCompletionRecord$key} from './__generated__/TaskCompletionRecordItem_taskCompletionRecord.graphql';

type Props = {record: TaskCompletionRecordItem_taskCompletionRecord$key};

export default function TaskCompletionRecordItem(props: Props) {
  const record = useFragment(
    graphql`
      fragment TaskCompletionRecordItem_taskCompletionRecord on TaskCompletionRecord {
        id
        createdAt
        author {
          id
          firstName
          lastName
        }
        task {
          id
          template {
            id
            name
          }
        }
      }
    `,
    props.record,
  );

  const createdAt = formatDateTimeUS(record.createdAt);

  return (
    <div key={record.id} className="w-full border rounded shadow px-4 py-2 mb-4">
      <div style={{flexDirection: 'column', alignItems: 'flex-start'}}>
        <p className="text-gray-700 text-sm">
          <span className="text-gray-700 font-semibold">
            {record.author.firstName} {record.author.lastName}
          </span>{' '}
          completed
          <span className="text-gray-700 font-semibold"> a task</span>
        </p>
        <div>
          <p>
            <b>{record.task.template.name}</b>
          </p>
        </div>
      </div>
      <div className="mt-2">
        <p className="text-xs text-gray-600">{createdAt}</p>
      </div>
    </div>
  );
}

export type RouteState = {
  currentRoute: string;
};

export type RouteActions = {
  type: 'setCurrentRoute';
  payload: {currentRoute: string};
};

export const initialRouteState: RouteState = {
  currentRoute: 'dashboard',
};

export const routeReducer = (
  state: RouteState,
  action: RouteActions,
): RouteState => {
  switch (action.type) {
    case 'setCurrentRoute':
      return {
        currentRoute: action.payload.currentRoute,
      };
    default:
      return state;
  }
};

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {withSuspense} from 'src/utils/withSuspense';

import {listPageOrganizationsQuery} from './__generated__/listPageOrganizationsQuery.graphql';
import OrganizationsTable from './OrganizationsTable';

function ListPage() {
  const data = useLazyLoadQuery<listPageOrganizationsQuery>(
    organizations,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  return <OrganizationsTable organizations={data} />;
}

export default withSuspense(ListPage);

const organizations = graphql`
  query listPageOrganizationsQuery {
    ...OrganizationsTable_organizations @arguments(first: 60)
  }
`;

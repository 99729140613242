import React from 'react';
import {Marker, DragEvent} from 'react-map-gl';

import MapLayer from '../map-layer';
import TextInput from './TextInput';

type Props = {
  latitude: string;
  longitude: string;
  setCoordinates: (value: {latitude: string; longitude: string}) => void;
  error?: string;
};

function AssetLocationInput(props: Props) {
  const {latitude, error, longitude, setCoordinates} = props;

  const [isMarkerMoved, setIsMarkerMoved] = React.useState(false);

  const [viewport, setViewport] = React.useState<any>({latitude: 0, longitude: 0, zoom: 15});

  const [transitionDuration, setTransitionDuration] = React.useState<number>(0);

  const [markerCoordinates, setMarkerCoordinates] = React.useState<{
    latitude: number;
    longitude: number;
  }>({
    latitude: 0,
    longitude: 0,
  });

  React.useEffect(() => {
    if (!!props.latitude && !!props.longitude) {
      setIsMarkerMoved(true);
    } else {
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(pos => {
          const {
            coords: {latitude: lat, longitude: lng},
          } = pos;

          setViewport({...viewport, latitude: lat, longitude: lng});
          setMarkerCoordinates({latitude: lat, longitude: lng});
        });
      }
    }
  }, []);

  React.useEffect(() => {
    if (
      props.latitude &&
      props.longitude &&
      (parseFloat(props.latitude) !== markerCoordinates.latitude ||
        parseFloat(props.longitude) !== markerCoordinates.longitude)
    ) {
      setTransitionDuration(800);
      setIsMarkerMoved(true);
      setViewport({
        ...viewport,
        latitude: parseFloat(props.latitude),
        longitude: parseFloat(props.longitude),
      });
      setMarkerCoordinates({
        latitude: parseFloat(props.latitude),
        longitude: parseFloat(props.longitude),
      });
    }
  }, [props.latitude, props.longitude]);

  return (
    <div className="mb-6">
      <TextInput
        label="Longitude"
        name="longitude"
        placeholder="33.132567"
        value={longitude}
        handleChange={value => setCoordinates({latitude, longitude: value})}
      />

      <TextInput
        label="Latitude"
        name="latitude"
        placeholder="-115.132567"
        value={latitude}
        handleChange={value => setCoordinates({latitude: value, longitude})}
        error={error}
      />

      <button
        onClick={resetMarker}
        className="px-4 py-2 bg-blue-800 text-white rounded hover:bg-blue-600 font-semibold mb-3 focus:outline-none">
        Reset marker
      </button>

      <div className="w-144 h-144 border border-gray-500 rounded">
        <MapLayer
          setTransitionDuration={setTransitionDuration}
          setViewport={setViewport}
          transitionDuration={0}
          viewport={viewport}>
          <Marker
            latitude={markerCoordinates.latitude}
            longitude={markerCoordinates.longitude}
            offsetTop={-20}
            offsetLeft={-10}
            draggable
            onDragEnd={onMarkerDragEnd}>
            <svg
              height={30}
              stroke="none"
              viewBox="0 0 24 24"
              fill={isMarkerMoved ? '#00e639' : '#d00'}>
              <path
                d="M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
            c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
            C20.1,15.8,20.2,15.8,20.2,15.7z"
              />
            </svg>
          </Marker>
        </MapLayer>
      </div>
    </div>
  );

  function resetMarker() {
    setIsMarkerMoved(false);
    setMarkerCoordinates({
      latitude: viewport.latitude,
      longitude: viewport.longitude,
    });

    setCoordinates({latitude: '', longitude: ''});
  }

  function onMarkerDragEnd(event: DragEvent) {
    const [lng, lat] = event.lngLat;

    setIsMarkerMoved(true);
    setTransitionDuration(800);

    setMarkerCoordinates({longitude: lng, latitude: lat});
    setViewport({...viewport, longitude: lng, latitude: lat});

    setCoordinates({latitude: lat.toString(), longitude: lng.toString()});
  }
}

export default AssetLocationInput;

import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {useAuthContext} from 'src/libs/authentication';

import {ACCESS_TOKEN} from '../constants';

export function OrganizationAdminRoute({component, location, ...rest}: any): React.ReactElement {
  const {isOrganizationAdmin} = useAuthContext();

  return (
    <Route
      {...rest}
      render={props =>
        isOrganizationAdmin && component ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            push
            to={{
              pathname: '/admin/dashboard',
              state: {redirectPath: location?.pathname},
            }}
          />
        )
      }
    />
  );
}

export function ProtectedRoute({component, location, ...rest}: any): React.ReactElement {
  const isAuthenticated = localStorage.getItem(ACCESS_TOKEN);

  return (
    <Route
      {...rest}
      render={props =>
        !!isAuthenticated && component ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            push
            to={{
              pathname: '/signin',
              state: {redirectPath: location?.pathname},
            }}
          />
        )
      }
    />
  );
}

export function AuthRoute({component, ...rest}: any): React.ReactElement {
  const isAuthenticated = localStorage.getItem(ACCESS_TOKEN);

  return (
    <Route
      {...rest}
      render={props =>
        !isAuthenticated && component ? (
          React.createElement(component, props)
        ) : (
          <Redirect to={{pathname: '/'}} />
        )
      }
    />
  );
}

/**
 * @generated SignedSource<<e2f0eb0fa112ac44294931463421e85f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ValveConnectorInputValveConnectorsQuery$variables = {};
export type ValveConnectorInputValveConnectorsQueryVariables = ValveConnectorInputValveConnectorsQuery$variables;
export type ValveConnectorInputValveConnectorsQuery$data = {
  readonly valveConnectors: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      };
    }>;
  };
};
export type ValveConnectorInputValveConnectorsQueryResponse = ValveConnectorInputValveConnectorsQuery$data;
export type ValveConnectorInputValveConnectorsQuery = {
  variables: ValveConnectorInputValveConnectorsQueryVariables;
  response: ValveConnectorInputValveConnectorsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ValveConnectorConnection",
    "kind": "LinkedField",
    "name": "valveConnectors",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ValveConnectorEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ValveConnector",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ValveConnectorInputValveConnectorsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ValveConnectorInputValveConnectorsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "6ddfe727b9f8339a75cfc51ab5cca809",
    "id": null,
    "metadata": {},
    "name": "ValveConnectorInputValveConnectorsQuery",
    "operationKind": "query",
    "text": "query ValveConnectorInputValveConnectorsQuery {\n  valveConnectors {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6fe31cfb818066128334cd16cc4f9baa";

export default node;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {usePaginationFragment} from 'react-relay/hooks';

import {HistoryTabAssetList_assetRecords$key} from './__generated__/HistoryTabAssetList_assetRecords.graphql';
import {HistoryTabAssetsRecordsByAssetQuery} from './__generated__/HistoryTabAssetsRecordsByAssetQuery.graphql';
import ImageRecordItem from './ImageRecordItem';
import MeasurementRecordItem from './MeasurementRecordItem';
import NoteRecordItem from './NoteRecordItem';
import TaskCompletionRecordItem from './TaskCompletionRecordItem';

type Props = {
  onImageClick: (imageUrl: string) => void;
  assetRecordList: HistoryTabAssetList_assetRecords$key;
};

export default function HistoryTabAssetList(props: Props) {
  const {data, hasNext, loadNext, isLoadingNext} = usePaginationFragment<
    HistoryTabAssetsRecordsByAssetQuery,
    HistoryTabAssetList_assetRecords$key
  >(
    graphql`
      fragment HistoryTabAssetList_assetRecords on Query
        @argumentDefinitions(first: {type: "Int!"}, after: {type: "String"})
        @refetchable(queryName: "AssetRecordListPaginationQuery") {
        assetRecordsByAsset(assetId: $assetId, first: $first, after: $after)
          @connection(key: "AssetRecordList_assetRecordsByAsset") {
          edges {
            node {
              id
              __typename
              ...NoteRecordItem_noteRecord
              ...ImageRecordItem_imageRecord
              ...TaskCompletionRecordItem_taskCompletionRecord
              ...MeasurementRecordItem_measurementRecord
            }
          }
        }
      }
    `,
    props.assetRecordList,
  );

  if (data.assetRecordsByAsset.edges && !data.assetRecordsByAsset.edges.length) {
    return (
      <div className="flex flex-grow pt-40 justify-center text-gray-600">
        No records have been added for this asset.
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-grow p-6 bg-white">
      {renderRecords()}
      {hasNext && (
        <div className="flex justify-center">
          <div
            onClick={() => loadNext(10)}
            className="px-4 py-3 bg-blue-800 cursor-pointer hover:bg-blue-600 text-white font-semibold">
            {isLoadingNext ? 'LOADING' : 'LOAD MORE'}
          </div>
        </div>
      )}
    </div>
  );

  function renderRecords() {
    if (!data.assetRecordsByAsset || !data.assetRecordsByAsset.edges) {
      return null;
    }

    return data.assetRecordsByAsset.edges.map(recordEdge => {
      switch (recordEdge?.node?.__typename) {
        case 'NoteRecord': {
          return <NoteRecordItem key={recordEdge.node.id} record={recordEdge.node} />;
        }
        case 'ImageRecord': {
          return (
            <ImageRecordItem
              key={recordEdge.node.id}
              onImageClick={props.onImageClick}
              record={recordEdge.node}
            />
          );
        }
        case 'MeasurementRecord': {
          return <MeasurementRecordItem key={recordEdge.node.id} record={recordEdge.node} />;
        }
        case 'TaskCompletionRecord': {
          return <TaskCompletionRecordItem key={recordEdge.node.id} record={recordEdge.node} />;
        }
      }

      return <NoteRecordItem key={recordEdge.node.id} record={recordEdge.node} />;
    });
  }
}

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {usePerPage} from 'src/hooks/usePerPage';
import {withSuspense} from 'src/utils/withSuspense';

import {listPageValveConnectorsQuery} from './__generated__/listPageValveConnectorsQuery.graphql';
import ValveConnectorsTable from './ValveConnectorsTable';

function ListPage() {
  const {perPage} = usePerPage();
  const data = useLazyLoadQuery<listPageValveConnectorsQuery>(
    valveConnectors,
    {first: perPage * 2},
    {fetchPolicy: 'store-and-network'},
  );

  return <ValveConnectorsTable valveConnectors={data} />;
}

export default withSuspense(ListPage);

const valveConnectors = graphql`
  query listPageValveConnectorsQuery($first: Int!) {
    ...ValveConnectorsTable_valveConnectors @arguments(first: $first)
  }
`;

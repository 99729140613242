import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {withSuspense} from 'src/utils/withSuspense';

import {listPageUsersQuery} from './__generated__/listPageUsersQuery.graphql';
import UsersTable from './UsersTable';

function ListPage() {
  const data = useLazyLoadQuery<listPageUsersQuery>(users, {}, {fetchPolicy: 'store-and-network'});

  return <UsersTable users={data} />;
}

export default withSuspense(ListPage);

const users = graphql`
  query listPageUsersQuery {
    ...UsersTable_organizationUsers @arguments(first: 60)
  }
`;

import React from 'react';
import {useAuthContext} from 'src/libs/authentication';

import DropdownInput from './DropdownInput';

type Props = {
  value: string;
  onChange: (value: string) => void;
  error?: string;
};

function RoleInput(props: Props) {
  const {isOrganizationAdmin} = useAuthContext();
  const {value, error, onChange} = props;

  const options = [
    {label: 'Foreman', value: 'foreman'},
    {label: 'Project Manager', value: 'project_manager'},
    {label: 'Superintendent', value: 'superintendent'},
    {label: 'Other', value: 'other'},
    {label: 'Dispatcher', value: 'dispatcher'},
    {label: 'Operations Manager', value: 'operations_manager'},
    {label: 'Technician', value: 'technician'},
    ...(isOrganizationAdmin
      ? [
          {label: 'Organization Admin', value: 'organization_admin'},
          {label: 'Project Admin', value: 'project_admin'},
        ]
      : []),
  ];

  return (
    <DropdownInput
      name="roleInput"
      onChange={onChange}
      options={options}
      placeholder="E.g. Technician"
      label="Role"
      value={value}
      error={error}
    />
  );
}

export default RoleInput;

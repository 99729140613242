import {RequestParameters, Variables} from 'relay-runtime';
import {ACCESS_TOKEN} from 'src/constants';
import {UNAUTHORIZED_ERROR} from 'src/constants/errors';

export function getRequestBody(request: RequestParameters, variables: Variables) {
  return JSON.stringify({
    query: request.text,
    variables,
  });
}

export function getAuthorizationHeader() {
  const accessToken = getAccessToken();

  return {Authorization: !!accessToken ? `Bearer ${accessToken}` : ''};
}

function getAccessToken() {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);

  return accessToken ? accessToken : '';
}

export function getRefreshToken() {
  const refreshToken = localStorage.getItem('refreshToken');

  return refreshToken ? refreshToken : '';
}

export function signatureHasExpired(errors: [{message: string}]): boolean {
  return errors[0].message === 'ExpiredSignature';
}

export function isUnauthorized(errors: [{extensions?: {code: string}}]): boolean {
  return errors[0].extensions?.code === UNAUTHORIZED_ERROR;
}

export function isServerError(errors: [{message: string}]): boolean {
  return errors[0].message === 'Internal server error';
}

async function refreshTokens() {
  const refreshToken = getRefreshToken();

  const url = `${process.env.REACT_APP_API_URL}/refresh_tokens`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({refreshToken}),
  });

  const data = await response.json();

  return data;
}

export async function updateTokens() {
  const newTokens = await refreshTokens();

  localStorage.setItem('accessToken', newTokens.accessToken);
  localStorage.setItem('refreshToken', newTokens.refreshToken);
}

export async function clearTokens() {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');

  window.location.reload();
}

import React from 'react';
import AssetLocationInput from 'src/components/inputs/AssetLocationInput';

type Props = {
  values: any;
  errors: any;
  setFieldValue: any;
  submitCount: number;
};

function LocationTab(props: Props) {
  const {values, setFieldValue, errors, submitCount} = props;

  return (
    <AssetLocationInput
      longitude={values.coordinates.longitude}
      latitude={values.coordinates.latitude}
      setCoordinates={value => setFieldValue('coordinates', value)}
      error={
        !!submitCount && (!!errors.latitude || !!errors.longitude)
          ? 'Please provide a location.'
          : ''
      }
    />
  );
}

export default LocationTab;

import graphql from 'babel-plugin-relay/macro';
import React, {useCallback} from 'react';
import {usePaginationFragment} from 'react-relay/hooks';
import AssetStatusCell from 'src/components/table/cell-types/AssetStatusCell';
import CoordinateCell from 'src/components/table/cell-types/CoordinateCell';
import DateCell from 'src/components/table/cell-types/DateCell';
import GpmCell from 'src/components/table/cell-types/GpmCell';
import ImportantTextCell from 'src/components/table/cell-types/ImportantTextCell';
import MeasurementCell from 'src/components/table/cell-types/MeasurementCell';
import ReportTableFilters from 'src/components/table/ReportTableFilters';
import useFilters from 'src/hooks/useFilters';
import {
  buildMeasurementColumns,
  MeasurementTemplateEdge,
  buildExportableMeasurements,
} from 'src/utils/reportsHelper';

import Table from '../../components/table';
import SecondaryTextCell from '../../components/table/cell-types/SecondaryTextCell';
import {hydrantMeasurementReportPageassetsQuery} from './__generated__/hydrantMeasurementReportPageassetsQuery.graphql';
import {HydrantsTable_assets$key} from './__generated__/HydrantsTable_assets.graphql';

interface Props {
  assets: HydrantsTable_assets$key;
  measurementTemplates: readonly MeasurementTemplateEdge[];
}

type Filters = {
  text?: string;
  status?: string;
  createdBefore?: string;
  orderBy?: any;
};

function HydrantsTable(props: Props) {
  const {data, loadNext, hasNext, refetch} = usePaginationFragment<
    hydrantMeasurementReportPageassetsQuery,
    HydrantsTable_assets$key
  >(fragmentHydrantsQuery, props.assets);

  const {addFilters, setFilters, isTransitioning} = useFilters<Filters>({}, refetch);

  const assets = data.assets.edges || [];

  const columns = [
    ...idenfitifactionColumns,
    ...buildMeasurementColumns(props.measurementTemplates, (templateName, value) => (
      <MeasurementCell type={templateName} value={value} />
    )),
    ...detailColumns,
  ];

  const buildExportablePayload = useCallback(() => {
    const rows: {}[] = [];
    const fileName = `hydrants-measurement-report-${new Date().getTime()}`;

    assets.forEach(asset => {
      if (asset.node) {
        const {node} = asset;

        rows.push({
          ID: node.externalId,
          Serial: node.serial,
          Status: node.status?.toUpperCase(),
          ...buildExportableMeasurements(
            props.measurementTemplates,
            asset.node.currentMeasurements?.edges,
          ),
          'Turns to Open/Close': node.turnsToOpenClose || '',
          'Water Main Depth (Feet)': node.hydrantWaterMainDepth,
          'Water Main Diameter (Inches)': node.hydrantWaterMainDiameter,
          GPM: node.hydrantGallonsPerMinute,
          Installed: new Date(node.installedAt).toLocaleDateString(),
          'Last Serviced': new Date(node.lastServicedAt).toLocaleDateString(),
          'Warranty Expires': new Date(node.warrantyExpiresAt).toLocaleDateString(),
          Manufacturer: node.manufacturer?.name,
          Series: node.family?.name,
          Model: node.model?.name,
          Latitude: node.coordinates?.[1],
          Longitude: node.coordinates?.[0],
        });
      }
    });

    return {rows, fileName};
  }, [assets]);

  return (
    <Table
      filters={() => (
        <ReportTableFilters
          onApplyFilters={handleOnApplyFilter}
          onResetFilters={handleOnResetFilter}
          onExportAsCSV={buildExportablePayload}
        />
      )}
      isTransitioning={isTransitioning}
      actions={{show: false, delete: false, edit: false, create: false}}
      totalDataLength={data.assets.totalCount}
      columns={columns}
      onPaginate={() => hasNext && loadNext(50)}
      onSort={handleOnSort}
      data={assets}
    />
  );

  function handleOnApplyFilter(filters: {text: string; status: string; createdBefore: Date}) {
    addFilters({
      text: filters.text,
      status: filters.status,
      createdBefore: filters.createdBefore.toISOString(),
    });
  }

  function handleOnResetFilter() {
    setFilters({});
  }

  async function handleOnSort(sortByConfig: any[]) {
    if (!sortByConfig.length) {
      addFilters({orderBy: undefined});

      return;
    }

    const orderByDirection = sortByConfig[0].desc ? 'DESC' : 'ASC';
    const orderByField = sortByConfig[0].id.toUpperCase();

    addFilters({orderBy: {direction: orderByDirection, field: orderByField}});
  }
}

export default HydrantsTable;

const idenfitifactionColumns = [
  {
    Header: 'ID',
    accessor: 'node.externalId',
    id: 'external_id',
    Cell({cell: {value}}: any) {
      return <ImportantTextCell value={value} />;
    },
  },
  {
    Header: 'Serial',
    accessor: 'node.serial',
    id: 'serial',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'Status',
    accessor: 'node.status',
    id: 'status',
    Cell({cell: {value}}: any) {
      return <AssetStatusCell value={value} />;
    },
  },
];

const detailColumns = [
  {
    Header: 'Turns to Open/Close',
    accessor: 'node.turnsToOpenClose',
    id: 'turns_to_open_close',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'Water Main Depth (feet)',
    accessor: 'node.hydrantWaterMainDepth',
    id: 'hydrant_water_main_depth',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'Water Main Diameter (inches)',
    accessor: 'node.hydrantWaterMainDiameter',
    id: 'hydrant_water_main_diameter',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'GPM',
    accessor: 'node.hydrantGallonsPerMinute',
    id: 'hydrant_gallons_per_minute',
    Cell({cell: {value}}: any) {
      return <GpmCell value={value} />;
    },
  },
  {
    Header: 'Installed',
    accessor: 'node.installedAt',
    id: 'installed_at',
    Cell({cell: {value}}: any) {
      return <DateCell value={value} />;
    },
  },
  {
    Header: 'Last Serviced',
    accessor: 'node.lastServicedAt',
    id: 'last_serviced_at',
    Cell({cell: {value}}: any) {
      return <DateCell value={value} />;
    },
  },
  {
    Header: 'Warranty Expires',
    accessor: 'node.warrantyExpiresAt',
    id: 'warranty_expires_at',
    Cell({cell: {value}}: any) {
      return <DateCell value={value} />;
    },
  },
  {
    Header: 'Manufacturer',
    accessor: 'node.manufacturer.name',
    id: 'manufacturer_name',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'Series',
    accessor: 'node.family.name',
    id: 'family_name',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'Model',
    accessor: 'node.model.name',
    id: 'model_name',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'Latitude',
    accessor: 'node.coordinates',
    id: 'latitude',
    disableSortBy: true,
    Cell({cell: {value}}: any) {
      return <CoordinateCell value={value[1]} />;
    },
  },
  {
    Header: 'Longitude',
    accessor: 'node.coordinates',
    id: 'longitude',
    disableSortBy: true,
    Cell({cell: {value}}: any) {
      return <CoordinateCell value={value[0]} />;
    },
  },
];

const fragmentHydrantsQuery = graphql`
  fragment HydrantsTable_assets on Query
    @argumentDefinitions(
      first: {type: "Int"}
      after: {type: "String"}
      createdBefore: {type: "ISO8601DateTime"}
      status: {type: "AssetStatus"}
      text: {type: "String"}
      orderBy: {type: "AssetsOrder"}
    )
    @refetchable(queryName: "HydrantMeasurementReportPaginationQuery") {
    assets(
      first: $first
      after: $after
      kind: hydrant
      status: $status
      text: $text
      orderBy: $orderBy
    ) @connection(key: "HydrantsList_assets") {
      totalCount

      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }

      edges {
        node {
          ... on Hydrant {
            id
            createdAt
            serial
            externalId
            status
            turnsToOpenClose
            hydrantWaterMainDiameter
            hydrantWaterMainDepth
            hydrantGallonsPerMinute
            coordinates
            installedAt
            lastServicedAt
            warrantyExpiresAt
            model {
              id
              name
            }
            family {
              id
              name
            }
            manufacturer {
              id
              name
            }
            currentMeasurements(createdBefore: $createdBefore) {
              edges {
                node {
                  value
                  template {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

import graphql from 'babel-plugin-relay/macro';
import React, {useState, useRef} from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {NavLink} from 'react-router-dom';
import {useAuthContext} from 'src/libs/authentication';
import useClickAway from 'src/utils/useClickAway';
import {withSuspense} from 'src/utils/withSuspense';
import styled from 'styled-components';

import {ReactComponent as MoreIcon} from '../../../assets/icons/more-vertical.svg';
import {ReactComponent as SignOutIcon} from '../../../assets/icons/sign-out.svg';
import {ReactComponent as UserIcon} from '../../../assets/icons/user.svg';
import {NavigationUserActionsQuery} from './__generated__/NavigationUserActionsQuery.graphql';
import {NavigationAction} from './styledComponents';

const ICON_SIZE = '1.4em';

function NavigationUserActions() {
  const [isOpen, setIsOpen] = useState(false);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const data = useLazyLoadQuery<NavigationUserActionsQuery>(query, {});
  const {signOut} = useAuthContext();

  useClickAway(triggerRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  if (!data.currentUser) {
    return <NavigationUserActionsFallback />;
  }

  const {currentUser} = data;

  return (
    <NavigationOptionsContainer>
      {isOpen && (
        <DropdownContainer>
          <DropdownContentContainer>
            <NavLink to="/admin/user_settings">
              <DropdownAction>
                <UserIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-2 text-white" />
                Profile
              </DropdownAction>
            </NavLink>
            <DropdownAction onClick={signOut}>
              <SignOutIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-2" />
              Sign Out
            </DropdownAction>
          </DropdownContentContainer>
        </DropdownContainer>
      )}

      <NavigationOptionsButton ref={triggerRef} onClick={() => setIsOpen(!isOpen)}>
        <>
          <UserAvatar>{currentUser.firstName?.[0]}</UserAvatar>
          {currentUser.firstName} {currentUser.lastName}
        </>

        <MoreOptionsIcon />
      </NavigationOptionsButton>
    </NavigationOptionsContainer>
  );
}

function NavigationUserActionsFallback() {
  const {signOut} = useAuthContext();

  return (
    <NavigationAction onClick={signOut}>
      <SignOutIcon width={ICON_SIZE} height={ICON_SIZE} className="mr-2" />
      Sign Out
    </NavigationAction>
  );
}

const NavigationOptionsContainer = styled.div`
  position: relative;
`;

const NavigationOptionsButton = styled.button.attrs({
  role: 'button',
  className: 'flex flex-row items-center text-white hover:text-orange-400 px-6 py-2 w-full',
})``;

const UserAvatar = styled.div.attrs({
  className: 'bg-gray-600 rounded-full flex items-center justify-center text-xs font-bold mr-2',
})`
  color: #2d3748 !important;
  height: ${ICON_SIZE};
  width: ${ICON_SIZE};
`;

const MoreOptionsIcon = styled(MoreIcon).attrs({
  className: 'text-white opacity-25 ml-auto',
})`
  margin-right: -6px;
  height: ${ICON_SIZE};
`;

const DropdownContainer = styled.div.attrs({
  className: 'absolute w-full z-40',
})`
  top: -46px;
`;

const DropdownContentContainer = styled.div.attrs({
  className: 'bg-gray-900 rounded shadow-lg mx-2',
})``;

const DropdownAction = styled.button.attrs({
  className: 'flex text-white hover:text-orange-400 py-2 px-3 w-full z-40',
})``;

const query = graphql`
  query NavigationUserActionsQuery {
    currentUser {
      id
      firstName
      lastName
    }
  }
`;

export default withSuspense(NavigationUserActions, <NavigationUserActionsFallback />);

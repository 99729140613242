/**
 * @generated SignedSource<<f8aa97029ab35da421ef314f14eef338>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AssetStatus = "maintained" | "due_soon" | "overdue" | "out_of_service" | "%future added value";
export type updatePageValveQuery$variables = {
  valveId: string;
};
export type updatePageValveQueryVariables = updatePageValveQuery$variables;
export type updatePageValveQuery$data = {
  readonly valve: {
    readonly id: string;
    readonly createdAt: any;
    readonly serial: string;
    readonly externalId: string;
    readonly coordinates: ReadonlyArray<number>;
    readonly lastServicedAt: any;
    readonly warrantyExpiresAt: any;
    readonly installedAt: any;
    readonly status: AssetStatus;
    readonly turnsToOpenClose: number | null;
    readonly notes: string | null;
    readonly valveConnector: {
      readonly id: string;
      readonly name: string;
    };
    readonly model: {
      readonly id: string;
      readonly name: string;
    };
    readonly family: {
      readonly id: string;
      readonly name: string;
    };
    readonly manufacturer: {
      readonly id: string;
      readonly name: string;
    };
  } | null;
};
export type updatePageValveQueryResponse = updatePageValveQuery$data;
export type updatePageValveQuery = {
  variables: updatePageValveQueryVariables;
  response: updatePageValveQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "valveId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "valveId",
    "variableName": "valveId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serial",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coordinates",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastServicedAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "warrantyExpiresAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "installedAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "turnsToOpenClose",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = [
  (v2/*: any*/),
  (v13/*: any*/)
],
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "ValveConnector",
  "kind": "LinkedField",
  "name": "valveConnector",
  "plural": false,
  "selections": (v14/*: any*/),
  "storageKey": null
},
v16 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v2/*: any*/),
  (v13/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageValveQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Valve",
        "kind": "LinkedField",
        "name": "valve",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "model",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "family",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "manufacturer",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageValveQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Valve",
        "kind": "LinkedField",
        "name": "valve",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "model",
            "plural": false,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "family",
            "plural": false,
            "selections": (v16/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "manufacturer",
            "plural": false,
            "selections": (v16/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d103e93aeef67378ab7280b51799acde",
    "id": null,
    "metadata": {},
    "name": "updatePageValveQuery",
    "operationKind": "query",
    "text": "query updatePageValveQuery(\n  $valveId: ID!\n) {\n  valve(valveId: $valveId) {\n    id\n    createdAt\n    serial\n    externalId\n    coordinates\n    lastServicedAt\n    warrantyExpiresAt\n    installedAt\n    status\n    turnsToOpenClose\n    notes\n    valveConnector {\n      id\n      name\n    }\n    model {\n      __typename\n      id\n      name\n    }\n    family {\n      __typename\n      id\n      name\n    }\n    manufacturer {\n      __typename\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "da57def447224deb044a7d59ed5a3c8f";

export default node;

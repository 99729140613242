/**
 * @generated SignedSource<<29a471550d809402d444b9b4f3becee2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AssetStatus = "maintained" | "due_soon" | "overdue" | "out_of_service" | "%future added value";
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type UpdateGeneralAssetInput = {
  generalAssetId: string;
  updateGeneralAssetData: UpdateGeneralAssetData;
  clientMutationId?: string | null;
};
export type UpdateGeneralAssetData = {
  modelId?: string | null;
  serial?: string | null;
  externalId?: string | null;
  notes?: string | null;
  status?: AssetStatus | null;
  coordinates?: ReadonlyArray<number> | null;
  installedAt?: any | null;
  lastServicedAt?: any | null;
  warrantyExpiresAt?: any | null;
  generalAssetName?: string | null;
};
export type updatePageUpdateGeneralAssetMutation$variables = {
  input: UpdateGeneralAssetInput;
};
export type updatePageUpdateGeneralAssetMutationVariables = updatePageUpdateGeneralAssetMutation$variables;
export type updatePageUpdateGeneralAssetMutation$data = {
  readonly updateGeneralAsset: {
    readonly generalAsset: {
      readonly id: string;
      readonly createdAt: any;
      readonly serial: string;
      readonly externalId: string;
      readonly model: {
        readonly id: string;
        readonly name: string;
      };
      readonly family: {
        readonly id: string;
        readonly name: string;
      };
      readonly manufacturer: {
        readonly id: string;
        readonly name: string;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly fields: ReadonlyArray<{
        readonly fieldName: string;
        readonly message: string;
      }> | null;
    }>;
  } | null;
};
export type updatePageUpdateGeneralAssetMutationResponse = updatePageUpdateGeneralAssetMutation$data;
export type updatePageUpdateGeneralAssetMutation = {
  variables: updatePageUpdateGeneralAssetMutationVariables;
  response: updatePageUpdateGeneralAssetMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serial",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  (v6/*: any*/)
],
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "UserError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserFieldError",
      "kind": "LinkedField",
      "name": "fields",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v2/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageUpdateGeneralAssetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGeneralAssetPayload",
        "kind": "LinkedField",
        "name": "updateGeneralAsset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GeneralAsset",
            "kind": "LinkedField",
            "name": "generalAsset",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "model",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "family",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "manufacturer",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageUpdateGeneralAssetMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGeneralAssetPayload",
        "kind": "LinkedField",
        "name": "updateGeneralAsset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GeneralAsset",
            "kind": "LinkedField",
            "name": "generalAsset",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "model",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "family",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "manufacturer",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v8/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7a3ea8771f0c1a49d851bb6ea46a983d",
    "id": null,
    "metadata": {},
    "name": "updatePageUpdateGeneralAssetMutation",
    "operationKind": "mutation",
    "text": "mutation updatePageUpdateGeneralAssetMutation(\n  $input: UpdateGeneralAssetInput!\n) {\n  updateGeneralAsset(input: $input) {\n    generalAsset {\n      id\n      createdAt\n      serial\n      externalId\n      model {\n        __typename\n        id\n        name\n      }\n      family {\n        __typename\n        id\n        name\n      }\n      manufacturer {\n        __typename\n        id\n        name\n      }\n    }\n    errors {\n      code\n      fields {\n        fieldName\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5931b12499c8a659945fed35f26a993e";

export default node;

/**
 * @generated SignedSource<<358fca2d9d6c62ba53d2a3c117da302e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type organizationSettingsQuery$variables = {};
export type organizationSettingsQueryVariables = organizationSettingsQuery$variables;
export type organizationSettingsQuery$data = {
  readonly currentOrganization: {
    readonly id: string;
    readonly createdAt: any;
    readonly name: string;
    readonly city: string;
    readonly email: string;
    readonly state: string;
    readonly assetsUsage: {
      readonly typeA: number;
      readonly typeB: number;
      readonly plan: {
        readonly typeAAssetsLimit: number;
        readonly typeBAssetsLimit: number;
      };
    };
    readonly currentPlan: {
      readonly typeAAssetsLimit: number;
      readonly typeBAssetsLimit: number;
    } | null;
    readonly organizationConfig: {
      readonly daysBeforeDueSoon: number;
      readonly daysBeforeOverdue: number;
    };
  };
};
export type organizationSettingsQueryResponse = organizationSettingsQuery$data;
export type organizationSettingsQuery = {
  variables: organizationSettingsQueryVariables;
  response: organizationSettingsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeA",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeB",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeAAssetsLimit",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "typeBAssetsLimit",
  "storageKey": null
},
v10 = [
  (v8/*: any*/),
  (v9/*: any*/)
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "daysBeforeDueSoon",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "daysBeforeOverdue",
  "storageKey": null
},
v13 = [
  (v8/*: any*/),
  (v9/*: any*/),
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "organizationSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "currentOrganization",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationAssetsUsage",
            "kind": "LinkedField",
            "name": "assetsUsage",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Plan",
                "kind": "LinkedField",
                "name": "plan",
                "plural": false,
                "selections": (v10/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "currentPlan",
            "plural": false,
            "selections": (v10/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationConfig",
            "kind": "LinkedField",
            "name": "organizationConfig",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "organizationSettingsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "currentOrganization",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationAssetsUsage",
            "kind": "LinkedField",
            "name": "assetsUsage",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Plan",
                "kind": "LinkedField",
                "name": "plan",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Plan",
            "kind": "LinkedField",
            "name": "currentPlan",
            "plural": false,
            "selections": (v13/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationConfig",
            "kind": "LinkedField",
            "name": "organizationConfig",
            "plural": false,
            "selections": [
              (v11/*: any*/),
              (v12/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2e96b5078d4122b7175fab8ad4431b98",
    "id": null,
    "metadata": {},
    "name": "organizationSettingsQuery",
    "operationKind": "query",
    "text": "query organizationSettingsQuery {\n  currentOrganization {\n    id\n    createdAt\n    name\n    city\n    email\n    state\n    assetsUsage {\n      typeA\n      typeB\n      plan {\n        typeAAssetsLimit\n        typeBAssetsLimit\n        id\n      }\n    }\n    currentPlan {\n      typeAAssetsLimit\n      typeBAssetsLimit\n      id\n    }\n    organizationConfig {\n      daysBeforeDueSoon\n      daysBeforeOverdue\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fe4654216f60780a6a5d3fce03ecac3c";

export default node;

/**
 * @generated SignedSource<<250d815e3a1ac194c6a3509cbac9bcca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AssetStatus = "maintained" | "due_soon" | "overdue" | "out_of_service" | "%future added value";
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type CreateValveInput = {
  modelId: string;
  serial: string;
  externalId: string;
  notes?: string | null;
  status: AssetStatus;
  coordinates: ReadonlyArray<number>;
  installedAt: any;
  lastServicedAt: any;
  warrantyExpiresAt: any;
  valveConnectorId: string;
  turnsToOpenClose?: number | null;
  clientMutationId?: string | null;
};
export type createPageCreateValveMutation$variables = {
  input: CreateValveInput;
};
export type createPageCreateValveMutationVariables = createPageCreateValveMutation$variables;
export type createPageCreateValveMutation$data = {
  readonly createValve: {
    readonly valve: {
      readonly id: string;
      readonly createdAt: any;
      readonly serial: string;
      readonly externalId: string;
      readonly status: AssetStatus;
      readonly turnsToOpenClose: number | null;
      readonly model: {
        readonly id: string;
        readonly name: string;
      };
      readonly family: {
        readonly id: string;
        readonly name: string;
      };
      readonly manufacturer: {
        readonly id: string;
        readonly name: string;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly fields: ReadonlyArray<{
        readonly fieldName: string;
        readonly message: string;
      }> | null;
    }>;
  } | null;
};
export type createPageCreateValveMutationResponse = createPageCreateValveMutation$data;
export type createPageCreateValveMutation = {
  variables: createPageCreateValveMutationVariables;
  response: createPageCreateValveMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serial",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "turnsToOpenClose",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = [
  (v2/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "UserError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserFieldError",
      "kind": "LinkedField",
      "name": "fields",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v2/*: any*/),
  (v8/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createPageCreateValveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateValvePayload",
        "kind": "LinkedField",
        "name": "createValve",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Valve",
            "kind": "LinkedField",
            "name": "valve",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "model",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "family",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "manufacturer",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createPageCreateValveMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateValvePayload",
        "kind": "LinkedField",
        "name": "createValve",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Valve",
            "kind": "LinkedField",
            "name": "valve",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "model",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "family",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "manufacturer",
                "plural": false,
                "selections": (v11/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5fafcd73caa60a7decbcc75bd2c0d6de",
    "id": null,
    "metadata": {},
    "name": "createPageCreateValveMutation",
    "operationKind": "mutation",
    "text": "mutation createPageCreateValveMutation(\n  $input: CreateValveInput!\n) {\n  createValve(input: $input) {\n    valve {\n      id\n      createdAt\n      serial\n      externalId\n      status\n      turnsToOpenClose\n      model {\n        __typename\n        id\n        name\n      }\n      family {\n        __typename\n        id\n        name\n      }\n      manufacturer {\n        __typename\n        id\n        name\n      }\n    }\n    errors {\n      code\n      fields {\n        fieldName\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "35d05065e10c1f319ef48c304ae94c07";

export default node;

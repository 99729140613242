import jwt from 'jsonwebtoken';
import React, {createContext, useContext, useReducer} from 'react';
import {commitLocalUpdate} from 'relay-runtime';
import {ACCESS_TOKEN, REFRESH_TOKEN} from 'src/constants';
import environment from 'src/relay/environment';

type State = {
  isSignedIn: boolean;
  isOrganizationAdmin: boolean;
  isProjectAdmin: boolean;
};

type DecodedToken = {
  user_id: string;
  user_role?: string;
  user_type: 'OrganizationUser' | 'AdminUser';
};

export type Action =
  | {
      type: 'SignIn';
      payload: State;
    }
  | {
      type: 'SignOut';
      payload: State;
    };

type PublicAuthContext = State & {
  signIn: (accessToken: string, refreshToken: string) => Promise<any>;
  signOut: () => Promise<any>;
};

let initialState = {
  isSignedIn: false,
  isOrganizationAdmin: false,
  isProjectAdmin: false,
};

export const AuthContext = createContext<PublicAuthContext>({
  ...initialState,
  signIn: async () => null,
  signOut: async () => null,
});

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'SignIn':
      return action.payload;
    case 'SignOut':
      return action.payload;
    default:
      return state;
  }
}

export const AuthProvider = ({children}: {children: React.ReactNode}) => {
  const authToken = localStorage.getItem(ACCESS_TOKEN);

  if (authToken) {
    initialState = {...initialState, ...decodeToken(authToken)};
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  const authFunctions = React.useMemo(
    () => ({
      signIn: async (accessToken: string, refreshToken: string) => {
        try {
          localStorage.setItem(ACCESS_TOKEN, accessToken);
          localStorage.setItem(REFRESH_TOKEN, refreshToken);

          const newState = decodeToken(accessToken);

          dispatch({type: 'SignIn', payload: {...state, ...newState}});
        } catch (err) {
          throw new Error(JSON.stringify(err));
        }
      },
      signOut: async () => {
        // @ts-ignore
        commitLocalUpdate(environment, store => store.invalidateStore());

        localStorage.clear();

        dispatch({
          type: 'SignOut',
          payload: {
            isOrganizationAdmin: false,
            isSignedIn: false,
            isProjectAdmin: false,
          },
        });
      },
    }),
    [],
  );

  const authContext = Object.assign({}, state, authFunctions);

  return (
    <AuthContext.Provider
      key={state.isSignedIn ? 'signed-in' : 'not-signed-in'}
      value={authContext}>
      {children}
    </AuthContext.Provider>
  );
};

function decodeToken(token: string) {
  const decodedToken = jwt.decode(token) as DecodedToken;

  const isOrganizationAdmin =
    decodedToken?.user_type === 'OrganizationUser' &&
    decodedToken.user_role === 'organization_admin';
  const isProjectAdmin = decodedToken?.user_type === 'AdminUser' && decodedToken.user_role === null;

  return {isSignedIn: true, isOrganizationAdmin, isProjectAdmin};
}

export const useAuthContext = () => useContext(AuthContext);

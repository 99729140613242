/**
 * @generated SignedSource<<82948fc39aeaee45b7e5219fbc20cf3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetManufacturersOrderField = "CREATED_AT" | "NAME" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type AssetManufacturersOrder = {
  direction: OrderDirection;
  field: AssetManufacturersOrderField;
};
export type GeneralAssetManufacturersPaginationQuery$variables = {
  after?: string | null;
  first: number;
  name?: string | null;
  orderBy?: AssetManufacturersOrder | null;
};
export type GeneralAssetManufacturersPaginationQueryVariables = GeneralAssetManufacturersPaginationQuery$variables;
export type GeneralAssetManufacturersPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"GeneralAssetManufacturersTable_generalAssetManufacturers">;
};
export type GeneralAssetManufacturersPaginationQueryResponse = GeneralAssetManufacturersPaginationQuery$data;
export type GeneralAssetManufacturersPaginationQuery = {
  variables: GeneralAssetManufacturersPaginationQueryVariables;
  response: GeneralAssetManufacturersPaginationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderBy"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GeneralAssetManufacturersPaginationQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "GeneralAssetManufacturersTable_generalAssetManufacturers"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GeneralAssetManufacturersPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GeneralAssetManufacturerConnection",
        "kind": "LinkedField",
        "name": "generalAssetManufacturers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "GeneralAssetManufacturerEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GeneralAssetManufacturer",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "orderBy",
          "name"
        ],
        "handle": "connection",
        "key": "GeneralAssetManufacturersList_generalAssetManufacturers",
        "kind": "LinkedHandle",
        "name": "generalAssetManufacturers"
      }
    ]
  },
  "params": {
    "cacheID": "246b757abe540700e7c7104656d5f04a",
    "id": null,
    "metadata": {},
    "name": "GeneralAssetManufacturersPaginationQuery",
    "operationKind": "query",
    "text": "query GeneralAssetManufacturersPaginationQuery(\n  $after: String\n  $first: Int!\n  $name: String\n  $orderBy: AssetManufacturersOrder\n) {\n  ...GeneralAssetManufacturersTable_generalAssetManufacturers_4DjD4v\n}\n\nfragment GeneralAssetManufacturersTable_generalAssetManufacturers_4DjD4v on Query {\n  generalAssetManufacturers(first: $first, after: $after, orderBy: $orderBy, name: $name) {\n    totalCount\n    pageInfo {\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        id\n        createdAt\n        name\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5ba5bfdeb7d36c9771b0579ab60c6017";

export default node;

import React, {createContext, useReducer, useContext} from 'react';

import {
  RouteState,
  initialRouteState,
  routeReducer,
  RouteActions,
} from './routeState';

type State = {
  routeState: RouteState;
};

const initialState = {
  routeState: initialRouteState,
};

const initialStateContext: {
  state: State;
  dispatch: React.Dispatch<RouteActions>;
} = {
  state: initialState,
  dispatch: () => {},
};

const stateReducer: React.Reducer<State, RouteActions> = (
  {routeState},
  action,
) => ({
  routeState: routeReducer(routeState, action),
});

const StateContext = createContext(initialStateContext);

export const StateProvider = ({children}: {children: React.ReactElement}) => {
  const [state, dispatch] = useReducer(stateReducer, initialState);

  return (
    <StateContext.Provider value={{state, dispatch}}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateValue = () => useContext(StateContext);

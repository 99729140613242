/**
 * @generated SignedSource<<d0cf7c35f56546eced64f1ecb461c041>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type UpdateValveManufacturerInput = {
  valveManufacturerId: string;
  updateValveManufacturerData: UpdateValveManufacturerData;
  clientMutationId?: string | null;
};
export type UpdateValveManufacturerData = {
  name?: string | null;
};
export type updatePageUpdateValveManufacturerMutation$variables = {
  input: UpdateValveManufacturerInput;
};
export type updatePageUpdateValveManufacturerMutationVariables = updatePageUpdateValveManufacturerMutation$variables;
export type updatePageUpdateValveManufacturerMutation$data = {
  readonly updateValveManufacturer: {
    readonly valveManufacturer: {
      readonly id: string;
      readonly createdAt: any;
      readonly name: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly fields: ReadonlyArray<{
        readonly fieldName: string;
        readonly message: string;
      }> | null;
    }>;
  } | null;
};
export type updatePageUpdateValveManufacturerMutationResponse = updatePageUpdateValveManufacturerMutation$data;
export type updatePageUpdateValveManufacturerMutation = {
  variables: updatePageUpdateValveManufacturerMutationVariables;
  response: updatePageUpdateValveManufacturerMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateValveManufacturerPayload",
    "kind": "LinkedField",
    "name": "updateValveManufacturer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ValveManufacturer",
        "kind": "LinkedField",
        "name": "valveManufacturer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFieldError",
            "kind": "LinkedField",
            "name": "fields",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fieldName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageUpdateValveManufacturerMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageUpdateValveManufacturerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7ff3f5f7a55ffce7d43e914ef1e81ac9",
    "id": null,
    "metadata": {},
    "name": "updatePageUpdateValveManufacturerMutation",
    "operationKind": "mutation",
    "text": "mutation updatePageUpdateValveManufacturerMutation(\n  $input: UpdateValveManufacturerInput!\n) {\n  updateValveManufacturer(input: $input) {\n    valveManufacturer {\n      id\n      createdAt\n      name\n    }\n    errors {\n      code\n      fields {\n        fieldName\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9707fffa096ce13bd6b763946726383a";

export default node;

/**
 * @generated SignedSource<<d31e7ac9ae72a0ec725d8157b8bbf0c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetCountWidget_assetStatistics$data = {
  readonly totalCount: number;
  readonly maintainedCount: number;
  readonly dueSoonCount: number;
  readonly overdueCount: number;
  readonly outOfServiceCount: number;
  readonly " $fragmentType": "AssetCountWidget_assetStatistics";
};
export type AssetCountWidget_assetStatistics = AssetCountWidget_assetStatistics$data;
export type AssetCountWidget_assetStatistics$key = {
  readonly " $data"?: AssetCountWidget_assetStatistics$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetCountWidget_assetStatistics">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetCountWidget_assetStatistics",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "maintainedCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueSoonCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "overdueCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "outOfServiceCount",
      "storageKey": null
    }
  ],
  "type": "AssetStatistics",
  "abstractKey": null
};

(node as any).hash = "2eb62de184ba43c74f7e75a899cf0b4e";

export default node;

/**
 * @generated SignedSource<<c931435afe0fd685b8a669cf17fd28de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type DeleteValveModelInput = {
  valveModelId: string;
  clientMutationId?: string | null;
};
export type ValveModelsTableDeleteValveModelMutation$variables = {
  input: DeleteValveModelInput;
};
export type ValveModelsTableDeleteValveModelMutationVariables = ValveModelsTableDeleteValveModelMutation$variables;
export type ValveModelsTableDeleteValveModelMutation$data = {
  readonly deleteValveModel: {
    readonly valveModel: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly message: string;
    }>;
  } | null;
};
export type ValveModelsTableDeleteValveModelMutationResponse = ValveModelsTableDeleteValveModelMutation$data;
export type ValveModelsTableDeleteValveModelMutation = {
  variables: ValveModelsTableDeleteValveModelMutationVariables;
  response: ValveModelsTableDeleteValveModelMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteValveModelPayload",
    "kind": "LinkedField",
    "name": "deleteValveModel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ValveModel",
        "kind": "LinkedField",
        "name": "valveModel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ValveModelsTableDeleteValveModelMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ValveModelsTableDeleteValveModelMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fc510ceaaef79e6a4dfde1a87c92b742",
    "id": null,
    "metadata": {},
    "name": "ValveModelsTableDeleteValveModelMutation",
    "operationKind": "mutation",
    "text": "mutation ValveModelsTableDeleteValveModelMutation(\n  $input: DeleteValveModelInput!\n) {\n  deleteValveModel(input: $input) {\n    valveModel {\n      id\n      name\n    }\n    errors {\n      code\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e1582812ec7a9ff74341ceebe6433431";

export default node;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useFragment} from 'react-relay/hooks';
import {Link} from 'react-router-dom';
import MeasurementsSection from 'src/components/MeasurementsSection';
import styled from 'styled-components';

import HistorySection from '../../../components/HistorySection';
import IdentificationSection from '../../../components/IdentificationSection';
import ManufacturingSection from '../../../components/ManufacturingSection';
import {GeneralTab_hydrant$key} from './__generated__/GeneralTab_hydrant.graphql';
import DetailsSection from './DetailsSection';

type Props = {
  hydrant: GeneralTab_hydrant$key;
};

function GeneralTab(props: Props) {
  const asset = useFragment(
    graphql`
      fragment GeneralTab_hydrant on Hydrant {
        id
        ...IdentificationSection_asset
        ...HistorySection_asset
        ...ManufacturingSection_asset
        ...DetailsSection_hydrant

        currentMeasurements {
          ...MeasurementsSection_currentMeasurements
        }
      }
    `,
    props.hydrant,
  );

  const createJobLink = `/admin/jobs/create?assetId=${asset.id}`;
  const createMeasurementLink = `/admin/measurements/create?assetId=${asset.id}&assetType=hydrant`;
  const editLink = `/admin/hydrants/${asset.id}/edit`;

  return (
    <div className="p-6 pt-3 bg-white flex flex-grow flex-col">
      <div className="flex items-center space-x-2 pb-3">
        <PrimaryAction to={editLink}>EDIT</PrimaryAction>
        <SecondaryAction to={createJobLink}>CREATE JOB</SecondaryAction>
        <SecondaryAction to={createMeasurementLink}>ADD MEASUREMENTS</SecondaryAction>
      </div>

      <IdentificationSection asset={asset} />

      <MeasurementsSection currentMeasurements={asset.currentMeasurements} />

      <DetailsSection hydrant={asset} />

      <HistorySection asset={asset} />

      <ManufacturingSection asset={asset} />
    </div>
  );
}

const PrimaryAction = styled(Link).attrs({
  className:
    'bg-blue-800 cursor-pointer px-2 py-1 rounded text-white text-sm font-semibold hover:bg-blue-700',
})``;

const SecondaryAction = styled(Link).attrs({
  className:
    'bg-orange-700 cursor-pointer px-2 py-1 rounded text-white font-semibold text-sm hover:bg-orange-600',
})``;

export default GeneralTab;

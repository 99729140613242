/**
 * @generated SignedSource<<23500f5c5f1d1d4de9faa5fab098e798>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type updatePageOrganizationQuery$variables = {
  organizationId: string;
};
export type updatePageOrganizationQueryVariables = updatePageOrganizationQuery$variables;
export type updatePageOrganizationQuery$data = {
  readonly organization: {
    readonly id: string;
    readonly createdAt: any;
    readonly name: string;
    readonly city: string;
    readonly email: string;
    readonly state: string;
    readonly organizationConfig: {
      readonly daysBeforeDueSoon: number;
      readonly daysBeforeOverdue: number;
    };
  } | null;
};
export type updatePageOrganizationQueryResponse = updatePageOrganizationQuery$data;
export type updatePageOrganizationQuery = {
  variables: updatePageOrganizationQueryVariables;
  response: updatePageOrganizationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "daysBeforeDueSoon",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "daysBeforeOverdue",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageOrganizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationConfig",
            "kind": "LinkedField",
            "name": "organizationConfig",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageOrganizationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationConfig",
            "kind": "LinkedField",
            "name": "organizationConfig",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v9/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fe9e2852a7ca9ef928bb03428b532de0",
    "id": null,
    "metadata": {},
    "name": "updatePageOrganizationQuery",
    "operationKind": "query",
    "text": "query updatePageOrganizationQuery(\n  $organizationId: ID!\n) {\n  organization(organizationId: $organizationId) {\n    id\n    createdAt\n    name\n    city\n    email\n    state\n    organizationConfig {\n      daysBeforeDueSoon\n      daysBeforeOverdue\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cc380d1c23c751bbc1fa241867702354";

export default node;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {usePaginationFragment, useMutation} from 'react-relay/hooks';
import ConnectionHandler from 'relay-connection-handler-plus';
import PlanCell from 'src/components/table/cell-types/PlanCell';
import ProgressCell from 'src/components/table/cell-types/ProgressCell';
import useFilters from 'src/hooks/useFilters';

import Table from '../../../components/table';
import DateCell from '../../../components/table/cell-types/DateCell';
import SecondaryTextCell from '../../../components/table/cell-types/SecondaryTextCell';
import {listPageOrganizationsQuery} from './__generated__/listPageOrganizationsQuery.graphql';
import {OrganizationsOrder} from './__generated__/OrganizationsPaginationQuery.graphql';
import {OrganizationsTable_organizations$key} from './__generated__/OrganizationsTable_organizations.graphql';
import {OrganizationsTableDeleteOrganizationAsProjectAdminMutation} from './__generated__/OrganizationsTableDeleteOrganizationAsProjectAdminMutation.graphql';
import TableFilters from './TableFilters';

type Props = {
  organizations: OrganizationsTable_organizations$key;
};

type Filters = {
  name?: string;
  orderBy?: OrganizationsOrder;
};

function OrganizationsTable(props: Props) {
  const {data, loadNext, hasNext, refetch} = usePaginationFragment<
    listPageOrganizationsQuery,
    OrganizationsTable_organizations$key
  >(
    graphql`
      fragment OrganizationsTable_organizations on Query
        @argumentDefinitions(
          first: {type: "Int!"}
          after: {type: "String"}
          name: {type: "String"}
          orderBy: {type: "OrganizationsOrder"}
        )
        @refetchable(queryName: "OrganizationsPaginationQuery") {
        organizations(first: $first, after: $after, orderBy: $orderBy, name: $name)
          @connection(key: "OrganizationsList_organizations") {
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              createdAt
              name
              email
              city
              state
              assetsUsage {
                typeA
                typeB
                plan {
                  name
                  typeAAssetsLimit
                  typeBAssetsLimit
                  price
                }
              }
              currentPlan {
                typeAAssetsLimit
                typeBAssetsLimit
              }
            }
          }
        }
      }
    `,
    props.organizations,
  );

  const [commit] = useMutation<OrganizationsTableDeleteOrganizationAsProjectAdminMutation>(
    deleteOrganizationAsProjectAdminMutation,
  );

  const {addFilters, setFilters, isTransitioning} = useFilters<Filters>({}, refetch);

  return (
    <Table
      filters={() => (
        <TableFilters onApplyFilters={handleOnApplyFilter} onResetFilters={handleOnResetFilter} />
      )}
      actions={{show: false, delete: true, edit: true, create: true}}
      totalDataLength={data.organizations.totalCount}
      columns={columns}
      onPaginate={() => hasNext && loadNext(50)}
      onSort={handleOnSort}
      onDelete={handleDelete}
      isTransitioning={isTransitioning}
      data={data.organizations.edges}
    />
  );

  function handleOnApplyFilter(filters: {text: string}) {
    addFilters({name: filters.text});
  }

  function handleOnResetFilter() {
    setFilters({});
  }

  async function handleOnSort(sortByConfig: any[]) {
    if (!sortByConfig.length) {
      addFilters({orderBy: undefined});

      return;
    }

    const orderByDirection = sortByConfig[0].desc ? 'DESC' : 'ASC';
    const orderByField = sortByConfig[0].id.toUpperCase();

    addFilters({orderBy: {direction: orderByDirection, field: orderByField}});
  }

  function handleDelete(id: string) {
    commit({
      variables: {input: {organizationId: id}},
      updater: store => {
        const root = store.getRoot();
        const connections = ConnectionHandler.getConnections(
          root,
          'OrganizationsList_organizations',
        );
        if (!connections) {
          return;
        }
        connections.forEach(connection => {
          ConnectionHandler.deleteNode(connection, id);
        });
      },
    });
  }
}

export default OrganizationsTable;

const deleteOrganizationAsProjectAdminMutation = graphql`
  mutation OrganizationsTableDeleteOrganizationAsProjectAdminMutation(
    $input: DeleteOrganizationAsProjectAdminInput!
  ) {
    deleteOrganizationAsProjectAdmin(input: $input) {
      organization {
        id
      }
      errors {
        code
        message
      }
    }
  }
`;

const columns = [
  {
    Header: 'Name',
    accessor: 'node.name',
    id: 'name',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'City',
    accessor: 'node.city',
    id: 'city',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'State',
    accessor: 'node.state',
    id: 'state',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'Email',
    accessor: 'node.email',
    id: 'email',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'Type A Assets',
    headerClassName: 'w-40',
    accessor: 'node',
    id: 'typeAAssetsUsage',
    disableSortBy: true,
    Cell({cell: {value}}: any) {
      const currentPlan = value.currentPlan || value.assetsUsage.plan;

      return (
        <ProgressCell
          value={value.assetsUsage.typeA}
          referenceValue={currentPlan.typeAAssetsLimit}
          className="text-center"
        />
      );
    },
  },
  {
    Header: 'Type B Assets',
    headerClassName: 'w-40',
    accessor: 'node',
    id: 'typeBAssetsUsage',
    disableSortBy: true,
    Cell({cell: {value}}: any) {
      const currentPlan = value.currentPlan || value.assetsUsage.plan;

      return (
        <ProgressCell
          value={value.assetsUsage.typeB}
          referenceValue={currentPlan.typeBAssetsLimit}
          className="text-center"
        />
      );
    },
  },
  {
    Header: 'Est. Tier',
    accessor: 'node.assetsUsage.plan',
    id: 'price',
    disableSortBy: true,
    Cell({cell: {value}}: any) {
      return <PlanCell name={value.name} price={value.price} />;
    },
  },
  {
    Header: 'Created On',
    accessor: 'node.createdAt',
    id: 'created_at',
    Cell({cell: {value}}: any) {
      return <DateCell value={value} />;
    },
  },
];

/**
 * @generated SignedSource<<10d98a46d88fada3d9ec455d2ce08faf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetKind = "hydrant" | "valve" | "general_asset" | "%future added value";
export type AssetStatus = "maintained" | "due_soon" | "overdue" | "out_of_service" | "%future added value";
export type MapAssetsAsGeoJsonRefetchQuery$variables = {
  kind?: AssetKind | null;
  status?: AssetStatus | null;
  text?: string | null;
};
export type MapAssetsAsGeoJsonRefetchQueryVariables = MapAssetsAsGeoJsonRefetchQuery$variables;
export type MapAssetsAsGeoJsonRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Map_assetsAsGeoJson">;
};
export type MapAssetsAsGeoJsonRefetchQueryResponse = MapAssetsAsGeoJsonRefetchQuery$data;
export type MapAssetsAsGeoJsonRefetchQuery = {
  variables: MapAssetsAsGeoJsonRefetchQueryVariables;
  response: MapAssetsAsGeoJsonRefetchQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "kind"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "text"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "kind",
    "variableName": "kind"
  },
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  },
  {
    "kind": "Variable",
    "name": "text",
    "variableName": "text"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coordinates",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MapAssetsAsGeoJsonRefetchQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "Map_assetsAsGeoJson"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MapAssetsAsGeoJsonRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssetGeoJsonConnection",
        "kind": "LinkedField",
        "name": "assetsAsGeoJson",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AssetGeoJson",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetGeoJsonGeometry",
                "kind": "LinkedField",
                "name": "geometry",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AssetGeoJsonProperties",
                "kind": "LinkedField",
                "name": "properties",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "asset",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "serial",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "status",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastServicedAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "jobCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "icon",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e6f4535d5afa79da2d355e78d9353fb7",
    "id": null,
    "metadata": {},
    "name": "MapAssetsAsGeoJsonRefetchQuery",
    "operationKind": "query",
    "text": "query MapAssetsAsGeoJsonRefetchQuery(\n  $kind: AssetKind\n  $status: AssetStatus\n  $text: String\n) {\n  ...Map_assetsAsGeoJson_3uwwWM\n}\n\nfragment Map_assetsAsGeoJson_3uwwWM on Query {\n  assetsAsGeoJson(kind: $kind, status: $status, text: $text) {\n    nodes {\n      type\n      geometry {\n        type\n        coordinates\n      }\n      properties {\n        asset {\n          __typename\n          id\n          serial\n          status\n          coordinates\n          lastServicedAt\n          jobCount\n        }\n        icon\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0acf1716ddc7fe9c080796783ccb7db7";

export default node;

/**
 * @generated SignedSource<<d443c9d2f3e38aed3f415b4cf040b312>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HistoryTabAssetList_assetRecords$data = {
  readonly assetRecordsByAsset: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly __typename: string;
        readonly " $fragmentSpreads": FragmentRefs<"NoteRecordItem_noteRecord" | "ImageRecordItem_imageRecord" | "TaskCompletionRecordItem_taskCompletionRecord" | "MeasurementRecordItem_measurementRecord">;
      };
    }>;
  };
  readonly " $fragmentType": "HistoryTabAssetList_assetRecords";
};
export type HistoryTabAssetList_assetRecords = HistoryTabAssetList_assetRecords$data;
export type HistoryTabAssetList_assetRecords$key = {
  readonly " $data"?: HistoryTabAssetList_assetRecords$data;
  readonly " $fragmentSpreads": FragmentRefs<"HistoryTabAssetList_assetRecords">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "assetRecordsByAsset"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "assetId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./AssetRecordListPaginationQuery.graphql')
    }
  },
  "name": "HistoryTabAssetList_assetRecords",
  "selections": [
    {
      "alias": "assetRecordsByAsset",
      "args": [
        {
          "kind": "Variable",
          "name": "assetId",
          "variableName": "assetId"
        }
      ],
      "concreteType": "AssetRecordConnection",
      "kind": "LinkedField",
      "name": "__AssetRecordList_assetRecordsByAsset_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetRecordEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "NoteRecordItem_noteRecord"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ImageRecordItem_imageRecord"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "TaskCompletionRecordItem_taskCompletionRecord"
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "MeasurementRecordItem_measurementRecord"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "fbf0178d5a17675b84c12dbe41f82ff3";

export default node;

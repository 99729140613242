import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';

import {HistoryTabAssetsRecordsByAssetQuery} from './__generated__/HistoryTabAssetsRecordsByAssetQuery.graphql';
import HistoryTabAssetList from './HistoryTabAssetList';

type Props = {
  assetId: string;
  onImageClick: (imageUrl: string) => void;
};

export default function HistoryTab({assetId, onImageClick}: Props) {
  const data = useLazyLoadQuery<HistoryTabAssetsRecordsByAssetQuery>(
    graphql`
      query HistoryTabAssetsRecordsByAssetQuery($assetId: ID!) {
        ...HistoryTabAssetList_assetRecords @arguments(first: 10)
      }
    `,
    {assetId},
    {fetchPolicy: 'store-and-network'},
  );

  return <HistoryTabAssetList onImageClick={onImageClick} assetRecordList={data} />;
}

/**
 * @generated SignedSource<<c09a78296b2e2140fd7e787d439e8ed5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type DeleteHydrantInput = {
  hydrantId: string;
  clientMutationId?: string | null;
};
export type HydrantsTableDeleteHydrantMutation$variables = {
  input: DeleteHydrantInput;
};
export type HydrantsTableDeleteHydrantMutationVariables = HydrantsTableDeleteHydrantMutation$variables;
export type HydrantsTableDeleteHydrantMutation$data = {
  readonly deleteHydrant: {
    readonly hydrant: {
      readonly id: string;
      readonly serial: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly message: string;
    }>;
  } | null;
};
export type HydrantsTableDeleteHydrantMutationResponse = HydrantsTableDeleteHydrantMutation$data;
export type HydrantsTableDeleteHydrantMutation = {
  variables: HydrantsTableDeleteHydrantMutationVariables;
  response: HydrantsTableDeleteHydrantMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteHydrantPayload",
    "kind": "LinkedField",
    "name": "deleteHydrant",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Hydrant",
        "kind": "LinkedField",
        "name": "hydrant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serial",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HydrantsTableDeleteHydrantMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HydrantsTableDeleteHydrantMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f99d614973021acba0f28815629fb851",
    "id": null,
    "metadata": {},
    "name": "HydrantsTableDeleteHydrantMutation",
    "operationKind": "mutation",
    "text": "mutation HydrantsTableDeleteHydrantMutation(\n  $input: DeleteHydrantInput!\n) {\n  deleteHydrant(input: $input) {\n    hydrant {\n      id\n      serial\n    }\n    errors {\n      code\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "15da17d47ba17cb7a3123e2aad07c3b2";

export default node;

/**
 * @generated SignedSource<<9703541755880f5ff02bea9ffb7f6408>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type MeasurementAssetKind = "hydrant" | "valve" | "general_asset" | "%future added value";
export type MeasurementDataKind = "number" | "percentage" | "text" | "%future added value";
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type UpdateMeasurementTemplateInput = {
  measurementTemplateId: string;
  updateMeasurementTemplateData: UpdateMeasurementTemplateData;
  clientMutationId?: string | null;
};
export type UpdateMeasurementTemplateData = {
  name?: string | null;
  dataKind?: MeasurementDataKind | null;
  assetKind?: MeasurementAssetKind | null;
};
export type updatePageUpdateMeasurementTemplateMutation$variables = {
  input: UpdateMeasurementTemplateInput;
};
export type updatePageUpdateMeasurementTemplateMutationVariables = updatePageUpdateMeasurementTemplateMutation$variables;
export type updatePageUpdateMeasurementTemplateMutation$data = {
  readonly updateMeasurementTemplate: {
    readonly measurementTemplate: {
      readonly id: string;
      readonly createdAt: any;
      readonly name: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly fields: ReadonlyArray<{
        readonly fieldName: string;
        readonly message: string;
      }> | null;
    }>;
  } | null;
};
export type updatePageUpdateMeasurementTemplateMutationResponse = updatePageUpdateMeasurementTemplateMutation$data;
export type updatePageUpdateMeasurementTemplateMutation = {
  variables: updatePageUpdateMeasurementTemplateMutationVariables;
  response: updatePageUpdateMeasurementTemplateMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateMeasurementTemplatePayload",
    "kind": "LinkedField",
    "name": "updateMeasurementTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MeasurementTemplate",
        "kind": "LinkedField",
        "name": "measurementTemplate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFieldError",
            "kind": "LinkedField",
            "name": "fields",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fieldName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageUpdateMeasurementTemplateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageUpdateMeasurementTemplateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fbeca14cc7edab773844634611008be1",
    "id": null,
    "metadata": {},
    "name": "updatePageUpdateMeasurementTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation updatePageUpdateMeasurementTemplateMutation(\n  $input: UpdateMeasurementTemplateInput!\n) {\n  updateMeasurementTemplate(input: $input) {\n    measurementTemplate {\n      id\n      createdAt\n      name\n    }\n    errors {\n      code\n      fields {\n        fieldName\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "202ddcd367e8bb72ae04310f78aee1db";

export default node;

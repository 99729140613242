const assetTypeMap: {
  [key: string]: {
    title: string;
    measurementAssetKind: 'general_asset' | 'hydrant' | 'valve';
    underscore: 'general_asset' | 'hydrant' | 'valve';
    underscorePlural: 'general_assets' | 'hydrants' | 'valves';
  };
} = {
  Hydrant: {
    title: 'Hydrant',
    measurementAssetKind: 'hydrant',
    underscore: 'hydrant',
    underscorePlural: 'hydrants',
  },
  GeneralAsset: {
    title: 'General Asset',
    measurementAssetKind: 'general_asset',
    underscore: 'general_asset',
    underscorePlural: 'general_assets',
  },
  Valve: {
    title: 'Valve',
    measurementAssetKind: 'valve',
    underscore: 'valve',
    underscorePlural: 'valves',
  },
};

export default assetTypeMap;

import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import {NAVBAR_HEIGHT} from '../Header';

export const NavigationContainer = styled.nav.attrs({
  className: 'w-64 min-w-64 relative overflow-hidden flex flex-grow flex-col bg-gray-800',
})`
  height: calc(100vh - ${NAVBAR_HEIGHT}px);
`;

export const NavigationSectionContainer = styled.div.attrs({
  className: 'overflow-y-auto',
})``;

export const NavigationActionContainer = styled.div.attrs({
  className: 'flex flex-col border-t-2 pt-2 pb-2 border-gray-700 mt-auto',
})``;

export const NavigationLink = styled(NavLink).attrs({
  className:
    'focus:outline-none text-left flex items-center text-gray-200 px-6 pt-2 pb-2 hover:text-orange-400',
  activeClassName: 'bg-orange-400 font-semibold',
  activeStyle: {color: '#2D3748'},
})``;

export const NavigationAction = styled.button.attrs({
  className:
    'focus:outline-none text-left flex items-center text-gray-200 px-6 pt-2 pb-2 hover:text-orange-400',
})``;

export const NavigationSection = styled.div.attrs({
  className: 'flex flex-col border-b-2 pt-2 pb-2 border-gray-700',
})`
  &:last-of-type {
    border-bottom: none;
  }
`;

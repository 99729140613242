import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {usePaginationFragment, useMutation} from 'react-relay/hooks';
import {Link} from 'react-router-dom';
import ConnectionHandler from 'relay-connection-handler-plus';
import ImportantTextCell from 'src/components/table/cell-types/ImportantTextCell';
import useFilters from 'src/hooks/useFilters';
import {showEventNotification} from 'src/utils/notifications';

import Table from '../../../components/table';
import DateCell from '../../../components/table/cell-types/DateCell';
import SecondaryTextCell from '../../../components/table/cell-types/SecondaryTextCell';
import {listPageMeasurementTemplatesQuery} from './__generated__/listPageMeasurementTemplatesQuery.graphql';
import {MeasurementTemplatesOrder} from './__generated__/MeasurementTemplatesPaginationQuery.graphql';
import {MeasurementTemplatesTable_measurementTemplates$key} from './__generated__/MeasurementTemplatesTable_measurementTemplates.graphql';
import {MeasurementTemplatesTableDeleteMeasurementTemplateMutation} from './__generated__/MeasurementTemplatesTableDeleteMeasurementTemplateMutation.graphql';
import TableFilters from './TableFilters';

type Props = {
  measurementTemplates: MeasurementTemplatesTable_measurementTemplates$key;
};

type Filters = {
  name?: string;
  orderBy?: MeasurementTemplatesOrder;
};

function MeasurementTemplatesTable(props: Props) {
  const {data, loadNext, hasNext, refetch} = usePaginationFragment<
    listPageMeasurementTemplatesQuery,
    MeasurementTemplatesTable_measurementTemplates$key
  >(
    graphql`
      fragment MeasurementTemplatesTable_measurementTemplates on Query
        @argumentDefinitions(
          first: {type: "Int!"}
          after: {type: "String"}
          name: {type: "String"}
          orderBy: {type: "MeasurementTemplatesOrder"}
        )
        @refetchable(queryName: "MeasurementTemplatesPaginationQuery") {
        measurementTemplates(first: $first, after: $after, orderBy: $orderBy, name: $name)
          @connection(key: "MeasurementTemplatesList_measurementTemplates") {
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              createdAt
              name
              dataKind
              assetKind
              builtIn
            }
          }
        }
      }
    `,
    props.measurementTemplates,
  );

  const [commit] = useMutation<MeasurementTemplatesTableDeleteMeasurementTemplateMutation>(
    deleteMeasurementTemplateMutation,
  );

  const {addFilters, setFilters, isTransitioning} = useFilters<Filters>({}, refetch);

  return (
    <Table
      filters={() => (
        <TableFilters onApplyFilters={handleOnApplyFilter} onResetFilters={handleOnResetFilter} />
      )}
      actions={{show: false, delete: false, edit: false, create: true}}
      totalDataLength={data.measurementTemplates.totalCount}
      isTransitioning={isTransitioning}
      onPaginate={() => hasNext && loadNext(50)}
      onSort={handleOnSort}
      onDelete={handleDelete}
      data={data.measurementTemplates.edges}
      columns={[
        ...columns,
        {
          Header: 'Options',
          Cell(data: any) {
            const {row} = data;

            if (row.original.node.builtIn) {
              return (
                <td className="py-6 text-gray-800 text-sm">
                  <div className="flex justify-center">Built-in Template</div>
                </td>
              );
            }

            return (
              <td className="px-3 py-6 text-blue-800 text-sm">
                <div className="flex justify-center">
                  <Link
                    className="mr-1 ml-1"
                    to={`/admin/measurement_templates/${row.original.node.id}/edit`}>
                    Edit
                  </Link>
                  <p> | </p>
                  <p
                    className="ml-1 cursor-pointer"
                    onClick={() => confirmDeletion(row.original.node.id)}>
                    Delete
                  </p>
                </div>
              </td>
            );
          },
        },
      ]}
    />
  );

  function confirmDeletion(id: string) {
    const isDeletionConfirmed = window.confirm('Are you sure you want to delete this item?');

    isDeletionConfirmed && handleDelete(id);
  }

  function handleOnApplyFilter(filters: {text: string}) {
    addFilters({name: filters.text});
  }

  function handleOnResetFilter() {
    setFilters({});
  }

  async function handleOnSort(sortByConfig: any[]) {
    if (!sortByConfig.length) {
      addFilters({orderBy: undefined});

      return;
    }

    const orderByDirection = sortByConfig[0].desc ? 'DESC' : 'ASC';
    const orderByField = sortByConfig[0].id.toUpperCase();

    addFilters({orderBy: {direction: orderByDirection, field: orderByField}});
  }

  function handleDelete(id: string) {
    commit({
      variables: {input: {measurementTemplateId: id}},
      onCompleted: payload => {
        const entry = payload.deleteMeasurementTemplate?.measurementTemplate;

        if (entry) {
          showEventNotification('removed', 'Template', entry.name);
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connections = ConnectionHandler.getConnections(
          root,
          'MeasurementTemplatesList_measurementTemplates',
        );

        if (!connections) {
          return;
        }

        connections.forEach(connection => {
          ConnectionHandler.deleteNode(connection, id);
        });
      },
    });
  }
}

export default MeasurementTemplatesTable;

const deleteMeasurementTemplateMutation = graphql`
  mutation MeasurementTemplatesTableDeleteMeasurementTemplateMutation(
    $input: DeleteMeasurementTemplateInput!
  ) {
    deleteMeasurementTemplate(input: $input) {
      measurementTemplate {
        id
        name
      }
      errors {
        code
        message
      }
    }
  }
`;

const columns = [
  {
    Header: 'Name',
    accessor: 'node.name',
    id: 'name',
    Cell({cell: {value}}: any) {
      return <SecondaryTextCell value={value} />;
    },
  },
  {
    Header: 'Created On',
    accessor: 'node.createdAt',
    id: 'created_at',
    Cell({cell: {value}}: any) {
      return <DateCell value={value} />;
    },
  },
  {
    Header: 'Asset Type',
    accessor: 'node.assetKind',
    id: 'asset_kind',
    Cell({cell: {value}}: any) {
      return <ImportantTextCell value={value} />;
    },
  },
  {
    Header: 'Data Type',
    accessor: 'node.dataKind',
    id: 'data_kind',
    Cell({cell: {value}}: any) {
      return <ImportantTextCell value={value} />;
    },
  },
];

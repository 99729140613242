import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useHistory, useParams} from 'react-router';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/form';
import {FormValues} from '../types';
import {updatePageGeneralAssetQuery} from './__generated__/updatePageGeneralAssetQuery.graphql';
import {updatePageUpdateGeneralAssetMutation} from './__generated__/updatePageUpdateGeneralAssetMutation.graphql';

function UpdatePage() {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [commit, isInFlight] = useMutation<updatePageUpdateGeneralAssetMutation>(
    updateGeneralAssetMutation,
  );

  const data = useLazyLoadQuery<updatePageGeneralAssetQuery>(
    query,
    {
      generalAssetId: id,
    },
    {fetchPolicy: 'store-and-network'},
  );

  if (!data.generalAsset) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">The item you wish to update could not be found.</h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      title="Update General Asset "
      button="Update General Asset "
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleInitialValues() {
    if (!data || !data.generalAsset) {
      return;
    }

    const {generalAsset} = data;

    return {
      manufacturerId: generalAsset.manufacturer.id,
      familyId: generalAsset.family.id,
      coordinates: {
        longitude: generalAsset.coordinates[0].toString(),
        latitude: generalAsset.coordinates[1].toString(),
      },
      modelId: generalAsset.model.id,
      serial: generalAsset.serial,
      externalId: generalAsset.externalId,
      status: generalAsset.status,
      installedAt: new Date(generalAsset.installedAt),
      notes: generalAsset.notes || '',
      warrantyExpiresAt: new Date(generalAsset.warrantyExpiresAt),
      lastServicedAt: new Date(generalAsset.lastServicedAt),
      generalAssetName: generalAsset.generalAssetName,
    };
  }

  function handleSubmit(input: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          generalAssetId: id,
          updateGeneralAssetData: {
            coordinates: [
              parseFloat(input.coordinates.longitude),
              parseFloat(input.coordinates.latitude),
            ],
            modelId: input.modelId,
            serial: input.serial,
            externalId: input.externalId,
            status: input.status,
            installedAt: input.installedAt.toISOString(),
            warrantyExpiresAt: input.warrantyExpiresAt.toISOString(),
            lastServicedAt: input.lastServicedAt.toISOString(),
            generalAssetName: input.generalAssetName,
            ...(input.notes ? {notes: input.notes} : {}),
          },
        },
      },
      onCompleted: payload => {
        if (payload.updateGeneralAsset?.errors[0] && payload.updateGeneralAsset?.errors[0].fields) {
          payload.updateGeneralAsset?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.updateGeneralAsset?.generalAsset;

        if (entry) {
          showEventNotification('updated', 'Asset', entry.serial);

          if (history.location.search.includes('newTab')) {
            window.close();
          }

          return history.push('/admin/general_assets');
        }
      },
    });
  }
}

const query = graphql`
  query updatePageGeneralAssetQuery($generalAssetId: ID!) {
    generalAsset(generalAssetId: $generalAssetId) {
      id
      createdAt
      serial
      externalId
      coordinates
      lastServicedAt
      warrantyExpiresAt
      installedAt
      status
      externalId
      generalAssetName
      notes
      model {
        id
        name
      }
      family {
        id
        name
      }
      manufacturer {
        id
        name
      }
    }
  }
`;

const updateGeneralAssetMutation = graphql`
  mutation updatePageUpdateGeneralAssetMutation($input: UpdateGeneralAssetInput!) {
    updateGeneralAsset(input: $input) {
      generalAsset {
        id
        createdAt
        serial
        externalId
        model {
          id
          name
        }
        family {
          id
          name
        }
        manufacturer {
          id
          name
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(UpdatePage);

/**
 * @generated SignedSource<<0061ceae2af434234f1b06a893afced4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LocationTab_asset$data = {
  readonly id: string;
  readonly coordinates: ReadonlyArray<number>;
  readonly " $fragmentType": "LocationTab_asset";
};
export type LocationTab_asset = LocationTab_asset$data;
export type LocationTab_asset$key = {
  readonly " $data"?: LocationTab_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"LocationTab_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LocationTab_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coordinates",
      "storageKey": null
    }
  ],
  "type": "Asset",
  "abstractKey": "__isAsset"
};

(node as any).hash = "57fb8cc5e2a47c7a661429c274a4bc63";

export default node;

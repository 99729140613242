import React from 'react';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import {ReactComponent as AddIcon} from '../../../assets/icons/add.svg';
import {ReactComponent as HydrantIcon} from '../../../assets/icons/hydrant.svg';
import {ReactComponent as JobsIcon} from '../../../assets/icons/jobs.svg';
import {ReactComponent as ValveIcon} from '../../../assets/icons/pipe2.svg';
import {ReactComponent as GeneralAssetIcon} from '../../../assets/icons/settings.svg';

function ShortcutsWidget() {
  return (
    <div className="flex flex-col rounded-md shadow p-6">
      <h3 className="text-xl text-gray-800 font-semibold mb-4">Quick Access</h3>

      <div className="flex-1 grid grid-cols-2 gap-5">
        <Shortcut to="jobs/create">
          <IconContainer>
            <JobsIcon />
            <AddIcon />
          </IconContainer>

          <span>Add Job</span>
        </Shortcut>
        <Shortcut to="hydrants/create">
          <IconContainer>
            <HydrantIcon />
            <AddIcon />
          </IconContainer>

          <span>Add Hydrant</span>
        </Shortcut>
        <Shortcut to="valves/create">
          <IconContainer>
            <ValveIcon />
            <AddIcon />
          </IconContainer>

          <span>Add Valve</span>
        </Shortcut>
        <Shortcut to="general_assets/create">
          <IconContainer>
            <GeneralAssetIcon />
            <AddIcon />
          </IconContainer>

          <span>Add General Asset</span>
        </Shortcut>
      </div>
    </div>
  );
}

const Shortcut = styled(NavLink).attrs({
  className:
    'flex flex-col items-center justify-center bg-gray-200 rounded p-6 lg:py-0 text-gray-800 font-medium text-center hover:bg-orange-400',
})`
  div > svg:last-child {
    background: currentColor;
    stroke: white;
    border-radius: 100%;
  }

  :hover div > svg:first-child {
    fill: currentColor;
  }

  :hover div > svg:last-child {
    stroke: currentColor;
    background: #ffffff;
  }
`;

const IconContainer = styled.div.attrs({
  className: 'relative mb-2',
})`
  svg:first-child {
    fill: #718096;
    width: 1.8rem;
    height: 1.8rem;
  }

  svg:last-child {
    position: absolute;
    padding: 0.1rem;
    bottom: -0.42rem;
    right: -0.28rem;
    width: 1rem;
    height: 1rem;
  }
`;

export default ShortcutsWidget;

import React from 'react';
import { NavigationControl } from 'react-map-gl';
import styled from 'styled-components';

function NavigationControlPanel() {
  return (
    <NavigationControlContainer>
      <NavigationControl />
    </NavigationControlContainer>
  );
}

const NavigationControlContainer = styled.div`
  left: 10;
  padding: 10px;
  position: absolute;
  top: 10;
  z-index: 5;
`;

export default NavigationControlPanel;

import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useHistory, useParams} from 'react-router';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/Form';
import {FormValues} from '../types';
import {updatePageGeneralAssetManufacturerQuery} from './__generated__/updatePageGeneralAssetManufacturerQuery.graphql';
import {updatePageUpdateGeneralAssetManufacturerMutation} from './__generated__/updatePageUpdateGeneralAssetManufacturerMutation.graphql';

function UpdatePage() {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [commit, isInFlight] = useMutation<updatePageUpdateGeneralAssetManufacturerMutation>(
    updateGeneralAssetManufacturerMutation,
  );

  const data = useLazyLoadQuery<updatePageGeneralAssetManufacturerQuery>(query, {
    generalAssetManufacturerId: id,
  });

  if (!data.generalAssetManufacturer) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">The item you wish to update could not be found.</h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      title="Update General Asset Manufacturer"
      button="Update General Asset Manufacturer"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleInitialValues() {
    if (!data || !data.generalAssetManufacturer) {
      return;
    }

    const {generalAssetManufacturer} = data;

    return {
      name: generalAssetManufacturer.name,
    };
  }

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          generalAssetManufacturerId: id,
          updateGeneralAssetManufacturerData: values,
        },
      },
      onCompleted: payload => {
        if (
          payload.updateGeneralAssetManufacturer?.errors[0] &&
          payload.updateGeneralAssetManufacturer?.errors[0].fields
        ) {
          payload.updateGeneralAssetManufacturer?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.updateGeneralAssetManufacturer?.generalAssetManufacturer;

        if (entry) {
          showEventNotification('updated', 'Manufacturer', entry.name);

          if (history.location.search.includes('newTab')) {
            window.close();
          }

          return history.push('/admin/general_asset_manufacturers');
        }
      },
    });
  }
}

const query = graphql`
  query updatePageGeneralAssetManufacturerQuery($generalAssetManufacturerId: ID!) {
    generalAssetManufacturer(generalAssetManufacturerId: $generalAssetManufacturerId) {
      id
      createdAt
      name
    }
  }
`;

const updateGeneralAssetManufacturerMutation = graphql`
  mutation updatePageUpdateGeneralAssetManufacturerMutation(
    $input: UpdateGeneralAssetManufacturerInput!
  ) {
    updateGeneralAssetManufacturer(input: $input) {
      generalAssetManufacturer {
        id
        createdAt
        name
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(UpdatePage);

/**
 * @generated SignedSource<<406ab64e9d40523ddd5e9995c3c17d86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AssetStatus = "maintained" | "due_soon" | "overdue" | "out_of_service" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DetailsSection_valve$data = {
  readonly id: string;
  readonly status: AssetStatus;
  readonly turnsToOpenClose: number | null;
  readonly valveConnector: {
    readonly id: string;
    readonly name: string;
  };
  readonly " $fragmentType": "DetailsSection_valve";
};
export type DetailsSection_valve = DetailsSection_valve$data;
export type DetailsSection_valve$key = {
  readonly " $data"?: DetailsSection_valve$data;
  readonly " $fragmentSpreads": FragmentRefs<"DetailsSection_valve">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DetailsSection_valve",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "turnsToOpenClose",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ValveConnector",
      "kind": "LinkedField",
      "name": "valveConnector",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Valve",
  "abstractKey": null
};
})();

(node as any).hash = "b3e31b8ec4f488ffdd8d7d3779b66ab4";

export default node;

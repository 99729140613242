import React from 'react';

import RadioInput from './RadioInput';

type Props = {
  onChange: (value: string) => void;
  value: string;
};

const options = [
  {
    value: 'other',
    label: 'Other',
  },
  {
    value: 'measurement',
    label: 'Measurement',
  },
  {
    value: 'cleaning',
    label: 'Cleaning',
  },
];

function TaskKindInput({onChange, value}: Props) {
  return (
    <RadioInput
      title="Task Type"
      name="taskType"
      onChange={onChange}
      value={value}
      options={options}
    />
  );
}

export default TaskKindInput;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {usePerPage} from 'src/hooks/usePerPage';
import {withSuspense} from 'src/utils/withSuspense';

import {listPageHydrantManufacturersQuery} from './__generated__/listPageHydrantManufacturersQuery.graphql';
import HydrantManufacturersTable from './HydrantManufacturersTable';

function ListPage() {
  const {perPage} = usePerPage();
  const data = useLazyLoadQuery<listPageHydrantManufacturersQuery>(
    hydrantManufacturers,
    {first: perPage * 2},
    {fetchPolicy: 'store-and-network'},
  );

  return <HydrantManufacturersTable hydrantManufacturers={data} />;
}

export default withSuspense(ListPage);

const hydrantManufacturers = graphql`
  query listPageHydrantManufacturersQuery($first: Int!) {
    ...HydrantManufacturersTable_hydrantManufacturers @arguments(first: $first)
  }
`;

/**
 * @generated SignedSource<<8e68ba0ce4386a19524c6d885544236d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type NoteRecordItem_noteRecord$data = {
  readonly id: string;
  readonly createdAt: any;
  readonly author: {
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
  };
  readonly notes: string;
  readonly " $fragmentType": "NoteRecordItem_noteRecord";
};
export type NoteRecordItem_noteRecord = NoteRecordItem_noteRecord$data;
export type NoteRecordItem_noteRecord$key = {
  readonly " $data"?: NoteRecordItem_noteRecord$data;
  readonly " $fragmentSpreads": FragmentRefs<"NoteRecordItem_noteRecord">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NoteRecordItem_noteRecord",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationUser",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notes",
      "storageKey": null
    }
  ],
  "type": "NoteRecord",
  "abstractKey": null
};
})();

(node as any).hash = "701662430e6c71fcec707c1440f4aaa1";

export default node;

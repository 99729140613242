import graphql from 'babel-plugin-relay/macro';
import {formatToTimeZone} from 'date-fns-timezone';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {
  ComposedChart,
  XAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer,
  Line,
} from 'recharts';
import ChartTooltip from 'src/components/charts/ChartTooltip';
import {withSuspense} from 'src/utils/withSuspense';

import {TasksOverviewWidgettaskStatsQuery} from './__generated__/TasksOverviewWidgettaskStatsQuery.graphql';

const CHART_HEIGHT = 280;

function TasksOverviewWidget() {
  const data = useLazyLoadQuery<TasksOverviewWidgettaskStatsQuery>(
    query,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  const stats = data.taskStats || [];

  const chartStats = stats?.map(node => ({
    name: formatToTimeZone(new Date(node.date as string), 'dddd', {timeZone: 'Etc/UTC'}),
    completed: node.completedCount,
    created: node.createdCount,
  }));

  return (
    <div className="rounded-md shadow pb-4">
      <h2 className="text-2xl text-gray-800 font-semibold mb-4 p-4">Tasks Overview</h2>

      <ResponsiveContainer width="99%" height={CHART_HEIGHT}>
        <ComposedChart data={chartStats} margin={{top: 5, left: 0, right: 5}}>
          <defs>
            <linearGradient id="colorTasksCreated" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={'#cbd5e0'} stopOpacity={0.8} />
              <stop offset="95%" stopColor={'#cbd5e0'} stopOpacity={0} />
            </linearGradient>
          </defs>

          <XAxis
            dataKey="name"
            tick={{fontSize: 12, fill: '#718096'}}
            tickLine={false}
            scale="point"
          />
          <CartesianGrid vertical={false} stroke={'#E2E8F0'} />
          <Tooltip cursor={{stroke: '#a0aec0'}} content={ChartTooltip} />

          <Line type="monotone" dataKey="completed" stroke="#48bb78" strokeWidth={2} />

          <Area
            isAnimationActive={false}
            type="monotone"
            dataKey="created"
            stroke={'#cbd5e0'}
            fillOpacity={1}
            fill="url(#colorTasksCreated)"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
}

const query = graphql`
  query TasksOverviewWidgettaskStatsQuery {
    taskStats {
      date
      createdCount
      completedCount
    }
  }
`;

export default withSuspense(TasksOverviewWidget);

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useFragment} from 'react-relay/hooks';
import {formatDateTimeUS} from 'src/utils/formatDate';

import {ImageRecordItem_imageRecord$key} from './__generated__/ImageRecordItem_imageRecord.graphql';

type Props = {record: ImageRecordItem_imageRecord$key; onImageClick: (imageUrl: string) => void};

export default function ImageRecordItem(props: Props) {
  const record = useFragment(
    graphql`
      fragment ImageRecordItem_imageRecord on ImageRecord {
        id
        createdAt
        author {
          id
          firstName
          lastName
        }
        imageUrl
      }
    `,
    props.record,
  );

  const createdAt = formatDateTimeUS(record.createdAt);

  return (
    <div key={record.id} className="w-full border rounded shadow px-4 py-2 mb-4">
      <div style={{justifyContent: 'space-between'}}>
        <p className="text-gray-700 text-sm">
          <span className="text-gray-700 font-semibold">
            {record.author.firstName} {record.author.lastName}
          </span>{' '}
          added an
          <span className="text-gray-700 font-semibold"> image</span>
        </p>

        <div className="mt-1 mb-1">
          <img
            onClick={() => props.onImageClick(record.imageUrl)}
            style={{cursor: 'pointer', height: 60, width: 60}}
            src={record.imageUrl}
            alt=""
          />
        </div>
      </div>
      <div className="mt-2">
        <p className="text-xs text-gray-600">{createdAt}</p>
      </div>
    </div>
  );
}

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {usePerPage} from 'src/hooks/usePerPage';
import {withSuspense} from 'src/utils/withSuspense';

import {listPageMeasurementTemplatesQuery} from './__generated__/listPageMeasurementTemplatesQuery.graphql';
import MeasurementTemplatesTable from './MeasurementTemplatesTable';

function ListPage() {
  const {perPage} = usePerPage();
  const data = useLazyLoadQuery<listPageMeasurementTemplatesQuery>(
    measurementTemplates,
    {first: perPage * 2},
    {fetchPolicy: 'store-and-network'},
  );

  return <MeasurementTemplatesTable measurementTemplates={data} />;
}

export default withSuspense(ListPage);

const measurementTemplates = graphql`
  query listPageMeasurementTemplatesQuery($first: Int!) {
    ...MeasurementTemplatesTable_measurementTemplates @arguments(first: $first)
  }
`;

import './geocoder.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import {MapView} from '@deck.gl/core';
import DeckGL from '@deck.gl/react';
import bbox from '@turf/bbox';
import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {_MapContext, StaticMap, WebMercatorViewport, NavigationControl, Marker} from 'react-map-gl';
import Geocoder from 'react-map-gl-geocoder';
import {useRefetchableFragment} from 'react-relay/hooks';
import {withSuspense} from 'src/utils/withSuspense';

import {Map_assetsAsGeoJson$key} from './__generated__/Map_assetsAsGeoJson.graphql';
import Filters from './Filters';
import {renderIconLayer} from './renderIconLayer';

const MAP_VIEW = new MapView({repeat: true});

type Props = {
  onIconClick: (asset: any) => void;
  children?: React.ReactNode;
  assetsAsGeoJson: Map_assetsAsGeoJson$key;
};

function AssetMap(props: Props) {
  const [mapLoading, setMapLoading] = React.useState(true);

  const [assetInputState, setAssetInputState] = React.useState(false);

  const [markerCoordinates, setMarkerCoordinates] = React.useState<{
    latitude: number;
    longitude: number;
  }>({
    latitude: 0,
    longitude: 0,
  });

  const mapRef = React.useRef<StaticMap>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const [viewState, setViewState] = React.useState<any>({
    longitude: -35,
    latitude: 36.7,
    zoom: 1.8,
    maxZoom: 20,
    pitch: 0,
    bearing: 0,
    transitionDuration: 0,
    transitionInterpolator: undefined,
  });

  const [data, refetch] = useRefetchableFragment(
    graphql`
      fragment Map_assetsAsGeoJson on Query
        @argumentDefinitions(
          kind: {type: "AssetKind"}
          status: {type: "AssetStatus"}
          text: {type: "String"}
        )
        @refetchable(queryName: "MapAssetsAsGeoJsonRefetchQuery") {
        assetsAsGeoJson(kind: $kind, status: $status, text: $text) {
          nodes {
            type
            geometry {
              type
              coordinates
            }
            properties {
              asset {
                __typename
                id
                serial
                status
                coordinates
                lastServicedAt
                jobCount
              }
              icon
            }
          }
        }
      }
    `,
    props.assetsAsGeoJson,
  );

  React.useEffect(() => {
    if (assetInputState) {
      props.onIconClick(null);
      setMarkerCoordinates({longitude: viewState.longitude, latitude: viewState.latitude});
    }
  }, [assetInputState]);

  React.useEffect(() => {
    if (!data.assetsAsGeoJson.nodes || mapLoading || !data.assetsAsGeoJson.nodes.length) {
      return;
    }

    const newViewport = new WebMercatorViewport({...viewState, height: 600, width: 800});

    const [minLng, minLat, maxLng, maxLat] = bbox({
      type: 'FeatureCollection',
      features: data.assetsAsGeoJson.nodes,
    });

    const {latitude, longitude, zoom} = newViewport.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      {
        padding: 40,
      },
    );

    setViewState({
      ...viewState,
      latitude,
      longitude,
      zoom,
    });
  }, [data, mapLoading]);

  return (
    <div className="flex h-full">
      <Filters
        markerCoordinates={markerCoordinates}
        assetInputState={assetInputState}
        setAssetInputState={setAssetInputState}
        applyFilter={applyFilter}
      />
      <div ref={containerRef} style={{flex: 1, position: 'relative'}}>
        <DeckGL
          onLoad={() => setMapLoading(false)}
          layers={addIconLayer(assetInputState)}
          onViewStateChange={({viewState: nextViewState}: any) => {
            setViewState(nextViewState);
          }}
          onClick={(info: any) =>
            assetInputState &&
            setMarkerCoordinates({longitude: info.lngLat[0], latitude: info.lngLat[1]})
          }
          viewState={viewState}
          controller={{dragRotate: false}}
          ContextProvider={_MapContext.Provider}
          views={MAP_VIEW}>
          <StaticMap
            width="100%"
            height="100%"
            reuseMaps
            ref={mapRef}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            preventStyleDiffing={true}
            mapboxApiAccessToken="pk.eyJ1IjoiY2FsbGFyaWRlIiwiYSI6ImNqeWlvZ2ljODA0YWgzbm82d2FxM3BoeGIifQ.VgvqmFDyg4joDqMGtQJaDQ">
            {assetInputState && (
              <Marker
                latitude={markerCoordinates.latitude}
                longitude={markerCoordinates.longitude}
                offsetTop={-40}
                offsetLeft={-20}
                draggable>
                <svg height={40} stroke="none" viewBox="0 0 24 24" fill="#F56565">
                  <path
                    d="M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
            c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
            C20.1,15.8,20.2,15.8,20.2,15.7z"
                  />
                </svg>
              </Marker>
            )}
          </StaticMap>
          {assetInputState && (
            <div
              className="bg-white p-6 rounded-md"
              style={{
                position: 'absolute',
                left: '50%',
                width: 500,
                height: 170,
                bottom: 20,
                zIndex: 1,
                transform: 'translateX(-50%)',
              }}>
              <p className=" text-xs pb-4 text-gray-600">Standard map behavior has been limited.</p>
              <p className="text-gray-800 font-semibold text-red-500 pb-4">
                Click to select an asset location.
              </p>
              <p className="text-gray-800 pb-4">
                Then click the create button in the top left for the desired asset type to create an
                asset with its location already set.
              </p>
            </div>
          )}
          <div style={{position: 'absolute', left: 20, top: 20, zIndex: 1}}>
            <NavigationControl showCompass={false} />
          </div>
          {mapRef && mapRef.current && containerRef && (
            <Geocoder
              mapRef={mapRef}
              containerRef={containerRef}
              placeholder="Street Address"
              countries="US,BE"
              onViewportChange={({latitude, longitude}: any) =>
                setViewState({...viewState, latitude, longitude})
              }
              mapboxApiAccessToken="pk.eyJ1IjoiY2FsbGFyaWRlIiwiYSI6ImNqeWlvZ2ljODA0YWgzbm82d2FxM3BoeGIifQ.VgvqmFDyg4joDqMGtQJaDQ"
            />
          )}
        </DeckGL>
        {props.children}
      </div>
    </div>
  );

  function applyFilter({
    status,
    kind,
    text,
  }: {
    status: string | null;
    text: string | null;
    kind: string | null;
  }) {
    refetch({
      status,
      kind,
      text,
    });
  }

  function addIconLayer(isInputtingAsset: boolean) {
    return renderIconLayer({
      data: data.assetsAsGeoJson.nodes,
      onIconClick: (onClickData: any) => {
        if (isInputtingAsset) {
          return;
        }

        props.onIconClick(onClickData.object.properties.asset);

        setViewState({
          ...viewState,
          latitude: onClickData.object.geometry.coordinates[1],
          longitude: onClickData.object.geometry.coordinates[0],
          zoom: 18,
        });
      },
    });
  }
}

export default withSuspense<Props>(AssetMap);

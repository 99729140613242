/**
 * @generated SignedSource<<68a33196499a8ece84c8eb00bb9ee881>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AssetStatus = "maintained" | "due_soon" | "overdue" | "out_of_service" | "%future added value";
export type updatePageHydrantQuery$variables = {
  hydrantId: string;
};
export type updatePageHydrantQueryVariables = updatePageHydrantQuery$variables;
export type updatePageHydrantQuery$data = {
  readonly hydrant: {
    readonly id: string;
    readonly createdAt: any;
    readonly serial: string;
    readonly externalId: string;
    readonly coordinates: ReadonlyArray<number>;
    readonly lastServicedAt: any;
    readonly warrantyExpiresAt: any;
    readonly installedAt: any;
    readonly status: AssetStatus;
    readonly hydrantGallonsPerMinute: number;
    readonly hydrantWaterMainDepth: number;
    readonly hydrantWaterMainDiameter: number;
    readonly hydrantTurnsToOpenClose: number;
    readonly notes: string | null;
    readonly model: {
      readonly id: string;
      readonly name: string;
    };
    readonly family: {
      readonly id: string;
      readonly name: string;
    };
    readonly manufacturer: {
      readonly id: string;
      readonly name: string;
    };
  } | null;
};
export type updatePageHydrantQueryResponse = updatePageHydrantQuery$data;
export type updatePageHydrantQuery = {
  variables: updatePageHydrantQueryVariables;
  response: updatePageHydrantQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "hydrantId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "hydrantId",
    "variableName": "hydrantId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serial",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coordinates",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastServicedAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "warrantyExpiresAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "installedAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hydrantGallonsPerMinute",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hydrantWaterMainDepth",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hydrantWaterMainDiameter",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hydrantTurnsToOpenClose",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v17 = [
  (v2/*: any*/),
  (v16/*: any*/)
],
v18 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v2/*: any*/),
  (v16/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageHydrantQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Hydrant",
        "kind": "LinkedField",
        "name": "hydrant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "model",
            "plural": false,
            "selections": (v17/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "family",
            "plural": false,
            "selections": (v17/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "manufacturer",
            "plural": false,
            "selections": (v17/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageHydrantQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Hydrant",
        "kind": "LinkedField",
        "name": "hydrant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "model",
            "plural": false,
            "selections": (v18/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "family",
            "plural": false,
            "selections": (v18/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "manufacturer",
            "plural": false,
            "selections": (v18/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4e4ef97519c2dc0566e801b2683641ca",
    "id": null,
    "metadata": {},
    "name": "updatePageHydrantQuery",
    "operationKind": "query",
    "text": "query updatePageHydrantQuery(\n  $hydrantId: ID!\n) {\n  hydrant(hydrantId: $hydrantId) {\n    id\n    createdAt\n    serial\n    externalId\n    coordinates\n    lastServicedAt\n    warrantyExpiresAt\n    installedAt\n    status\n    hydrantGallonsPerMinute\n    hydrantWaterMainDepth\n    hydrantWaterMainDiameter\n    hydrantTurnsToOpenClose\n    notes\n    model {\n      __typename\n      id\n      name\n    }\n    family {\n      __typename\n      id\n      name\n    }\n    manufacturer {\n      __typename\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fbe0c85ad14b221aba433af36f6fce61";

export default node;

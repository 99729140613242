import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useHistory, useParams} from 'react-router';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/Form';
import {FormValues} from '../types';
import {updatePageJobQuery} from './__generated__/updatePageJobQuery.graphql';
import {updatePageUpdateJobWithTasksMutation} from './__generated__/updatePageUpdateJobWithTasksMutation.graphql';

function UpdatePage() {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [commit, isInFlight] = useMutation<updatePageUpdateJobWithTasksMutation>(
    updateJobWithTasksMutation,
  );

  const data = useLazyLoadQuery<updatePageJobQuery>(query, {
    jobId: id,
  });

  if (!data.job) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">The item you wish to update could not be found.</h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      title="Update Job"
      button="Update Job"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleInitialValues() {
    if (!data || !data.job) {
      return;
    }

    const {job} = data;

    return {
      asset: job.asset,
      assigneeId: job.assignee.id,
      dueAt: new Date(job.dueAt),
      notes: job.notes || '',
      taskTemplates: job.tasks.edges.map(taskEdge => ({
        value: taskEdge.node.template.id,
        label: taskEdge.node.template.name,
      })),
    };
  }

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          jobId: id,
          updateJobData: {
            dueAt: values.dueAt.toISOString(),
            assetId: values.asset.id,
            assigneeId: values.assigneeId,
            tasksToAddByTemplateIds: values.taskTemplates.map(taskTemplate => taskTemplate.value),
          },
        },
      },
      onCompleted: payload => {
        if (payload.updateJobWithTasks?.errors[0] && payload.updateJobWithTasks?.errors[0].fields) {
          payload.updateJobWithTasks?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.updateJobWithTasks?.job;

        if (entry) {
          showEventNotification('updated', 'Job', '#' + entry.jobNumber);

          if (history.location.search.includes('newTab')) {
            window.close();
          }

          return history.push('/admin/jobs');
        }
      },
    });
  }
}

const query = graphql`
  query updatePageJobQuery($jobId: ID!) {
    job(jobId: $jobId) {
      id
      createdAt
      dueAt
      completedAt
      jobNumber
      assignee {
        id
        firstName
        lastName
      }
      asset {
        id
        serial
      }
      notes
      tasks {
        edges {
          node {
            id
            template {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const updateJobWithTasksMutation = graphql`
  mutation updatePageUpdateJobWithTasksMutation($input: UpdateJobWithTasksInput!) {
    updateJobWithTasks(input: $input) {
      job {
        id
        createdAt
        dueAt
        completedAt
        jobNumber
        assignee {
          id
          firstName
          lastName
        }
        asset {
          id
          serial
        }
        notes
        tasks {
          edges {
            node {
              id
              template {
                id
                name
              }
            }
          }
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(UpdatePage);

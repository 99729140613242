import {library} from '@fortawesome/fontawesome-svg-core';
import {fab} from '@fortawesome/free-brands-svg-icons';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import {RelayEnvironmentProvider} from 'react-relay/hooks';
import {BrowserRouter} from 'react-router-dom';
import {toast} from 'react-toastify';
import relayEnvironment from 'src/relay/environment';
import 'react-toastify/dist/ReactToastify.css';

import AnalyticsProvider from './components/AnalyticsProvider';
import {ProvidePerPage} from './hooks/usePerPage';
import {AuthProvider} from './libs/authentication';
import {StateProvider} from './local-state';
import MainRouter from './routers/MainRouter';

import './styles/styles.css';
import 'mapbox-gl/dist/mapbox-gl.css';

library.add(fab);

toast.configure({
  closeButton: false,
  position: 'bottom-center',
});

Sentry.init({
  dsn: 'https://d5a699c796a64a8bbe24feb181017745@o414740.ingest.sentry.io/5644838',
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.5,
});

const root = document.getElementById('root') as HTMLElement;

ReactDOM.unstable_createRoot(root).render(
  <AuthProvider>
    <ProvidePerPage>
      <RelayEnvironmentProvider environment={relayEnvironment}>
        <StateProvider>
          <BrowserRouter>
            <AnalyticsProvider>
              <MainRouter />
            </AnalyticsProvider>
          </BrowserRouter>
        </StateProvider>
      </RelayEnvironmentProvider>
    </ProvidePerPage>
  </AuthProvider>,
);

import React from 'react';
import styled from 'styled-components';

type Props = {
  name: string;
  title: string;
  options: { label: string; value: string }[];
  value: string;
  onChange: (value: string) => void;
};

function RadioInput(props: Props) {
  const { name, options, title, value, onChange } = props;

  return (
    <Container>
      <Title htmlFor={name}>{title}</Title>

      <InputContainer>
        {options.map((option, index) => {
          return (
            <OptionLabel key={index}>
              <OptionInput
                type="radio"
                onChange={event => onChange(event.target.value)}
                checked={value === option.value}
                name={name}
                value={option.value}
              />
              {option.label}
            </OptionLabel>
          );
        })}
      </InputContainer>
    </Container>
  );
}

const Container = styled.div.attrs({
  className: 'mb-4',
})``;

const Title = styled.label.attrs({
  className: 'block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2',
})``;

const InputContainer = styled.div.attrs({
  className: 'flex flex-col pl-2',
})``;

const OptionLabel = styled.label.attrs({
  className: 'text-gray-700 text-sm mb-2',
})``;

const OptionInput = styled.input.attrs({
  className: 'mr-2',
})``;

export default RadioInput;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {usePerPage} from 'src/hooks/usePerPage';
import {withSuspense} from 'src/utils/withSuspense';

import {listPagevalveModelsQuery} from './__generated__/listPagevalveModelsQuery.graphql';
import ValveModelsTable from './ValveModelsTable';

function ListPage() {
  const {perPage} = usePerPage();
  const data = useLazyLoadQuery<listPagevalveModelsQuery>(
    valveModels,
    {first: perPage * 2},
    {fetchPolicy: 'store-and-network'},
  );

  return <ValveModelsTable valveModels={data} />;
}

export default withSuspense(ListPage);

const valveModels = graphql`
  query listPagevalveModelsQuery($first: Int!) {
    ...ValveModelsTable_valveModels @arguments(first: $first)
  }
`;

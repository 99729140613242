import React from 'react';

import RadioInput from './RadioInput';

type Props = {
  onChange: (value: string) => void;
  value: string;
  isFilter?: boolean;
};

const dataTypes = [
  {
    value: 'percentage',
    label: 'Percentage',
  },
  {
    value: 'number',
    label: 'Number',
  },
  {
    value: 'text',
    label: 'Text',
  },
];

function DataTypeInput({onChange, value, isFilter}: Props) {
  const options = isFilter ? [{value: 'ALL', label: 'All'}, ...dataTypes] : dataTypes;

  return (
    <RadioInput
      title="Data Type"
      name="dataType"
      onChange={onChange}
      value={value}
      options={options}
    />
  );
}

export default DataTypeInput;

/**
 * @generated SignedSource<<215f58bbd49f2b8c95b33b95b472cfd6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ImageItem_imageRecord$data = {
  readonly id: string;
  readonly createdAt: any;
  readonly author: {
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
  };
  readonly imageUrl: string;
  readonly " $fragmentType": "ImageItem_imageRecord";
};
export type ImageItem_imageRecord = ImageItem_imageRecord$data;
export type ImageItem_imageRecord$key = {
  readonly " $data"?: ImageItem_imageRecord$data;
  readonly " $fragmentSpreads": FragmentRefs<"ImageItem_imageRecord">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ImageItem_imageRecord",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationUser",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    }
  ],
  "type": "ImageRecord",
  "abstractKey": null
};
})();

(node as any).hash = "2a44928d0a5ca76405b088b364b9b78c";

export default node;

import {faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import {NavLink, useLocation} from 'react-router-dom';
import styled from 'styled-components';

type ChildNavLink = {
  to: string;
  title: string;
};

type MainNavLink = {
  title: string;
  Icon: React.ReactElement;
};

type Props = MainNavLink & {options?: ChildNavLink[]};

function NavLinkWithOptions(props: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!!props.options) {
      props.options.some(option => {
        if (option.to === location.pathname) {
          setIsOpen(true);
          return true;
        }

        return false;
      });
    }
  }, [location, props.options]);

  function toggleOptions() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <NavigationOptionsToggler onClick={toggleOptions}>
        <>
          {props.Icon} {props.title}
        </>

        <NavigationOptionsTogglerIcon
          className={`ml-auto opacity-25 transform ${isOpen ? 'rotate-90' : 'rotate-0'}`}
        />
      </NavigationOptionsToggler>

      {isOpen && !!props.options && (
        <OptionsList>
          {props.options.map(option => (
            <OptionsListItem key={option.title}>
              <OptionLink key={option.to} to={option.to}>
                <OptionLinkContent>{option.title}</OptionLinkContent>
              </OptionLink>
            </OptionsListItem>
          ))}
        </OptionsList>
      )}
    </>
  );
}

const NavigationOptionsToggler = styled.button.attrs({
  role: 'button',
  className: 'flex flex-row items-center text-white px-6 py-2 hover:text-orange-400',
})`
  outline: none !important;
`;

const NavigationOptionsTogglerIcon = styled(FontAwesomeIcon).attrs({
  icon: faChevronRight,
})`
  color: white;
  margin-right: -6px;
`;

const OptionsList = styled.ul.attrs({className: 'pb-2'})``;
const OptionsListItem = styled.li``;

const OptionLink = styled(NavLink).attrs({
  className: 'flex items-center text-white pl-8 hover:text-orange-400',
  activeClassName: 'bg-orange-400 font-semibold',
  activeStyle: {color: '#2D3748'},
})`
  &::before {
    background: #4a5568;
    border-radius: 2px;
    content: '';
    width: 4px;
    height: 4px;
  }
`;

const OptionLinkContent = styled.span.attrs({
  className: 'pl-5 py-2',
})`
  text-color: inherit;
`;

export default NavLinkWithOptions;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {withSuspense} from 'src/utils/withSuspense';
import styled from 'styled-components';

import {ReactComponent as InboxIcon} from '../../../assets/icons/inbox.svg';
import ProgressBar from '../../../components/ProgressBar';
import {JobsAssignedWidgetorganizationUsersWithJobsCountQuery} from './__generated__/JobsAssignedWidgetorganizationUsersWithJobsCountQuery.graphql';
import {
  Widget,
  WidgetTitle,
  WidgetBlankContainer,
  WidgetBlankTitle,
  WidgetBlankDescription,
  WidgetBlankIconContainer,
} from './stylesComponents';

function JobsAssignedWidget() {
  const data = useLazyLoadQuery<JobsAssignedWidgetorganizationUsersWithJobsCountQuery>(
    query,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  const users = data.organizationUsersWithJobsCount.edges;
  const noResults = users.length < 1;

  return (
    <Widget>
      <WidgetTitle>Jobs Assigned this Month</WidgetTitle>

      {noResults && (
        <WidgetBlankContainer>
          <WidgetBlankIconContainer>
            <InboxIcon />
          </WidgetBlankIconContainer>

          <WidgetBlankTitle>Empty Inbox</WidgetBlankTitle>
          <WidgetBlankDescription>There are no jobs assigned this month</WidgetBlankDescription>
        </WidgetBlankContainer>
      )}

      <ItensContainer>
        {users.map(({node}) => (
          <Item key={node.id}>
            <ItemLabel>
              {node.firstName} {node.lastName}
            </ItemLabel>
            <JobsProgressBar
              value={node.completedJobsCount}
              referenceValue={node.assignedJobsCount}
            />
          </Item>
        ))}
      </ItensContainer>
    </Widget>
  );
}

function JobsProgressBar({value, referenceValue}: {value: number; referenceValue: number}) {
  return (
    <ProgressBar
      value={value}
      referenceValue={referenceValue}
      progressBackgroundColor="bg-green-500">
      <ProgressLabel>
        <span>
          {value} <small>completed</small>
        </span>
        <span>
          {referenceValue} <small>assigned</small>
        </span>
      </ProgressLabel>
    </ProgressBar>
  );
}

const ItensContainer = styled.div.attrs({className: 'grid grid-cols-1 md:grid-cols-2 gap-10'})``;
const Item = styled.div.attrs({className: 'flex flex-col text-gray-700'})``;
const ItemLabel = styled.span.attrs({className: 'font-medium mb-2'})``;

const ProgressLabel = styled.span.attrs({
  className: 'flex justify-between text-xs text-gray-600 uppercase mb-1',
})``;

const query = graphql`
  query JobsAssignedWidgetorganizationUsersWithJobsCountQuery {
    organizationUsersWithJobsCount {
      edges {
        node {
          id
          firstName
          lastName
          assignedJobsCount
          completedJobsCount
        }
      }
    }
  }
`;

export default withSuspense(JobsAssignedWidget);

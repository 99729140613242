/**
 * @generated SignedSource<<f4f921eb8c30c5b1ceb96310d763c663>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type valveMeasurementReportPagemeasurementTemplatesQuery$variables = {};
export type valveMeasurementReportPagemeasurementTemplatesQueryVariables = valveMeasurementReportPagemeasurementTemplatesQuery$variables;
export type valveMeasurementReportPagemeasurementTemplatesQuery$data = {
  readonly measurementTemplates: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      };
    }>;
  };
};
export type valveMeasurementReportPagemeasurementTemplatesQueryResponse = valveMeasurementReportPagemeasurementTemplatesQuery$data;
export type valveMeasurementReportPagemeasurementTemplatesQuery = {
  variables: valveMeasurementReportPagemeasurementTemplatesQueryVariables;
  response: valveMeasurementReportPagemeasurementTemplatesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "assetType",
        "value": "valve"
      }
    ],
    "concreteType": "MeasurementTemplateConnection",
    "kind": "LinkedField",
    "name": "measurementTemplates",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MeasurementTemplateEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MeasurementTemplate",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": "measurementTemplates(assetType:\"valve\")"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "valveMeasurementReportPagemeasurementTemplatesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "valveMeasurementReportPagemeasurementTemplatesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "de1b5500cf812fb19dc4a858c3382db1",
    "id": null,
    "metadata": {},
    "name": "valveMeasurementReportPagemeasurementTemplatesQuery",
    "operationKind": "query",
    "text": "query valveMeasurementReportPagemeasurementTemplatesQuery {\n  measurementTemplates(assetType: valve) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dcc443b768fd74a02739bc155336c7d8";

export default node;

import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useMutation, useLazyLoadQuery} from 'react-relay/hooks';
import {useHistory} from 'react-router';
import {showEventNotification} from 'src/utils/notifications';

import Form from '../components/Form';
import {FormValues} from '../types';
import {createPageCreateMeasurementMutation} from './__generated__/createPageCreateMeasurementMutation.graphql';
import {
  createPageMeasurementTemplatesQuery,
  MeasurementAssetKind,
} from './__generated__/createPageMeasurementTemplatesQuery.graphql';

function CreatePage() {
  const history = useHistory();
  const {assetId, assetType} = getParams();

  const [commit, isInFlight] = useMutation<createPageCreateMeasurementMutation>(
    createMeasurementMutation,
  );

  const data = useLazyLoadQuery<createPageMeasurementTemplatesQuery>(
    query,
    {assetType: assetType},
    {fetchPolicy: 'store-and-network'},
  );

  if (!data.measurementTemplates || !assetId) {
    return null;
  }

  return (
    <Form
      title="Create Measurement"
      button="Create"
      measurementTemplates={data.measurementTemplates.edges}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function getParams() {
    const params = new URLSearchParams(window.location.search);

    return {
      assetId: params.get('assetId'),
      assetType: params.get('assetType') as MeasurementAssetKind,
    };
  }

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    const {addAnother, ...input} = values;

    commit({
      variables: {input: {...input, assetId: assetId as string}},
      onCompleted: payload => {
        if (payload.createMeasurement?.errors[0] && payload.createMeasurement?.errors[0].fields) {
          payload.createMeasurement?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.createMeasurement?.measurement;

        if (entry) {
          showEventNotification('added', 'Measurement for', entry.template.name);

          if (addAnother) {
            formikHelpers.resetForm();
          } else {
            return history.push(`/admin/${assetType}s/${assetId}/details`);
          }
        }
      },
    });
  }
}

const query = graphql`
  query createPageMeasurementTemplatesQuery($assetType: MeasurementAssetKind) {
    measurementTemplates(assetType: $assetType) {
      edges {
        node {
          id
          name
          assetKind
          dataKind
        }
      }
    }
  }
`;

const createMeasurementMutation = graphql`
  mutation createPageCreateMeasurementMutation($input: CreateMeasurementInput!) {
    createMeasurement(input: $input) {
      measurement {
        id
        createdAt
        value
        template {
          id
          name
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default CreatePage;

/**
 * @generated SignedSource<<f5e31bbd7ce57c11aa1ea32ab452c8d9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GeneralAssetModelInputGeneralAssetModelsQuery$variables = {};
export type GeneralAssetModelInputGeneralAssetModelsQueryVariables = GeneralAssetModelInputGeneralAssetModelsQuery$variables;
export type GeneralAssetModelInputGeneralAssetModelsQuery$data = {
  readonly generalAssetModels: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly family: {
          readonly id: string;
        };
      };
    }>;
  };
};
export type GeneralAssetModelInputGeneralAssetModelsQueryResponse = GeneralAssetModelInputGeneralAssetModelsQuery$data;
export type GeneralAssetModelInputGeneralAssetModelsQuery = {
  variables: GeneralAssetModelInputGeneralAssetModelsQueryVariables;
  response: GeneralAssetModelInputGeneralAssetModelsQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GeneralAssetModelInputGeneralAssetModelsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GeneralAssetModelConnection",
        "kind": "LinkedField",
        "name": "generalAssetModels",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GeneralAssetModelEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GeneralAssetModel",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "family",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GeneralAssetModelInputGeneralAssetModelsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GeneralAssetModelConnection",
        "kind": "LinkedField",
        "name": "generalAssetModels",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GeneralAssetModelEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GeneralAssetModel",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "family",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "32160e919b7d334f5fdcd158a8916d5c",
    "id": null,
    "metadata": {},
    "name": "GeneralAssetModelInputGeneralAssetModelsQuery",
    "operationKind": "query",
    "text": "query GeneralAssetModelInputGeneralAssetModelsQuery {\n  generalAssetModels {\n    edges {\n      node {\n        id\n        name\n        family {\n          __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "934acb409361bb438aca6843a08aea7f";

export default node;

import React from 'react';

import SelectInput from './SelectInput';

type Props = {
  label: string;
  value: string;
  name: string;
  placeholder: string;
  onChange: (value: string) => void;
  options: {label: string; value: string}[];
  error?: string;
  isDisabled?: boolean;
};

function DropdownInput(props: Props) {
  const {value, label, isDisabled, name, placeholder, onChange, error, options} = props;

  return (
    <div className="mb-6">
      <label
        className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
        htmlFor={name}>
        {label}
      </label>

      <SelectInput
        isDisabled={isDisabled}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        options={options}
      />

      {error && <p className="text-red-500 mt-1 text-xs italic">{error}</p>}
    </div>
  );
}

export default DropdownInput;

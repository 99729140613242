import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useHistory, useParams} from 'react-router';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';
import * as Yup from 'yup';

import Form from '../components/Form';
import {FormValues} from '../types';
import {updatePageUpdateUserMutation} from './__generated__/updatePageUpdateUserMutation.graphql';
import {updatePageUserQuery} from './__generated__/updatePageUserQuery.graphql';

function UpdatePage() {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [commit, isInFlight] = useMutation<updatePageUpdateUserMutation>(updateUserMutation);

  const data = useLazyLoadQuery<updatePageUserQuery>(query, {
    organizationUserId: id,
  });

  if (!data.organizationUser) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">The item you wish to update could not be found.</h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      title="Update User"
      button="Update User"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
      validationSchema={validationSchema}
    />
  );

  function handleInitialValues() {
    if (!data || !data.organizationUser) {
      return;
    }

    const {organizationUser} = data;

    return {
      firstName: organizationUser.firstName,
      lastName: organizationUser.lastName,
      email: organizationUser.email,
      phone: organizationUser.phone,
      password: '',
      confirmPassword: '',
      role: organizationUser.role,
    };
  }

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          organizationUserId: id,
          updateOrganizationUserData: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phone: values.phone,
            password: values.password,
            role: values.role,
          },
        },
      },
      onCompleted: payload => {
        if (
          payload.updateOrganizationUser?.errors[0] &&
          payload.updateOrganizationUser?.errors[0].fields
        ) {
          payload.updateOrganizationUser?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.updateOrganizationUser?.organizationUser;

        if (entry) {
          showEventNotification('updated', 'User', entry.firstName);
          return history.push('/admin/users');
        }
      },
    });
  }
}

const query = graphql`
  query updatePageUserQuery($organizationUserId: ID!) {
    organizationUser(organizationUserId: $organizationUserId) {
      id
      createdAt
      firstName
      lastName
      phone
      email
      role
    }
  }
`;

const updateUserMutation = graphql`
  mutation updatePageUpdateUserMutation($input: UpdateOrganizationUserInput!) {
    updateOrganizationUser(input: $input) {
      organizationUser {
        id
        createdAt
        firstName
        lastName
        phone
        email
        role
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

const validationSchema = Yup.object({
  firstName: Yup.string().required('Please provide a first name.'),
  role: Yup.string().required('Please select a user role.'),
  lastName: Yup.string().required('Please provide a last name.'),
  phone: Yup.string().required('Please provide a phone number.'),
  email: Yup.string()
    .email('Please provide a valid email address.')
    .required('Please provide a valid email address.'),
  password: Yup.string().min(6, 'Your password must contain at least 6 characters.'),
  confirmPassword: Yup.string().when(['password'], {
    is: val => !!val,
    then: Yup.string()
      .required('Please confirm your password.')
      .oneOf([Yup.ref('password')], 'Your passwords do not match.'),
    otherwise: Yup.string().max(0),
  }),
});

export default withSuspense(UpdatePage);

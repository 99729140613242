import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {withSuspense} from 'src/utils/withSuspense';

import {assetMapAssetsAsGeoJsonQuery} from './__generated__/assetMapAssetsAsGeoJsonQuery.graphql';
import Map from './Map';

type Props = {
  onIconClick: (asset: any) => void;
  children?: React.ReactNode;
};

function AssetMap(props: Props) {
  const data = useLazyLoadQuery<assetMapAssetsAsGeoJsonQuery>(
    graphql`
      query assetMapAssetsAsGeoJsonQuery {
        ...Map_assetsAsGeoJson
      }
    `,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  return (
    <Map onIconClick={props.onIconClick} assetsAsGeoJson={data}>
      {props.children}
    </Map>
  );
}

export default withSuspense<Props>(AssetMap);

import {camelize} from './formatString';

interface Measurement {
  readonly node: {
    readonly value: string;
    readonly template: MeasurementTemplate;
  };
}

interface MeasurementTemplate {
  readonly name: string;
}

export interface MeasurementTemplateEdge {
  readonly node: MeasurementTemplate;
}

export function buildMeasurementColumns(
  measurementTemplates: readonly MeasurementTemplateEdge[] | undefined | null,
  renderer: (templateName: string, value: string) => React.ReactElement,
) {
  if (!measurementTemplates) {
    return [];
  }

  return measurementTemplates?.map(measurementTemplate => {
    const templateName = measurementTemplate.node.name;

    return {
      Header: templateName,
      accessor: 'node.currentMeasurements.edges',
      disableSortBy: true,
      id: camelize(templateName),
      Cell({cell: {value}}: any) {
        const measurementNode = value.find(
          (entry: any) => entry?.node?.template?.name === templateName,
        );

        return renderer(templateName, measurementNode?.node?.value);
      },
    };
  });
}

export function buildExportableMeasurements(
  measurementTemplates: readonly MeasurementTemplateEdge[] | undefined | null,
  measurements?: readonly Measurement[],
) {
  if (!measurementTemplates) {
    return {};
  }

  return measurementTemplates?.reduce((acc, measurementTemplate) => {
    const templateName = measurementTemplate.node.name;

    const measurementNode = (measurements || []).find(
      (entry: any) => entry?.node?.template?.name === templateName,
    );

    return {
      ...acc,
      [templateName]: measurementNode?.node?.value,
    };
  }, {});
}

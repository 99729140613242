import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useHistory, useParams} from 'react-router';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/Form';
import {FormValues} from '../types';
import {updatePageHydrantManufacturerQuery} from './__generated__/updatePageHydrantManufacturerQuery.graphql';
import {updatePageUpdateHydrantManufacturerMutation} from './__generated__/updatePageUpdateHydrantManufacturerMutation.graphql';

function UpdatePage() {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [commit, isInFlight] = useMutation<updatePageUpdateHydrantManufacturerMutation>(
    updateHydrantManufacturerMutation,
  );

  const data = useLazyLoadQuery<updatePageHydrantManufacturerQuery>(query, {
    hydrantManufacturerId: id,
  });

  if (!data.hydrantManufacturer) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">The item you wish to update could not be found.</h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      title="Update General Asset Manufacturer"
      button="Update General Asset Manufacturer"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleInitialValues() {
    if (!data || !data.hydrantManufacturer) {
      return;
    }

    const {hydrantManufacturer} = data;

    return {
      name: hydrantManufacturer.name,
    };
  }

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          hydrantManufacturerId: id,
          updateHydrantManufacturerData: values,
        },
      },
      onCompleted: payload => {
        if (
          payload.updateHydrantManufacturer?.errors[0] &&
          payload.updateHydrantManufacturer?.errors[0].fields
        ) {
          payload.updateHydrantManufacturer?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.updateHydrantManufacturer?.hydrantManufacturer;

        if (entry) {
          showEventNotification('updated', 'Manufacturer', entry.name);

          if (history.location.search.includes('newTab')) {
            window.close();
          }

          return history.push('/admin/hydrant_manufacturers');
        }
      },
    });
  }
}

const query = graphql`
  query updatePageHydrantManufacturerQuery($hydrantManufacturerId: ID!) {
    hydrantManufacturer(hydrantManufacturerId: $hydrantManufacturerId) {
      id
      createdAt
      name
    }
  }
`;

const updateHydrantManufacturerMutation = graphql`
  mutation updatePageUpdateHydrantManufacturerMutation($input: UpdateHydrantManufacturerInput!) {
    updateHydrantManufacturer(input: $input) {
      hydrantManufacturer {
        id
        createdAt
        name
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(UpdatePage);

import React from 'react';
import {camelize} from 'src/utils/formatString';
import styled from 'styled-components';

import GpmCell from './GpmCell';

type Props = {
  type?: string;
  value: any;
};

function MeasurementCell({type, value}: Props) {
  if (!value) {
    return (
      <Cell>
        <Label>N/A</Label>
      </Cell>
    );
  }

  if (type && camelize(type) === 'gpm') {
    return <GpmCell value={value} />;
  }

  return <Cell>{value}</Cell>;
}

const Label = styled.div.attrs({
  className: 'bg-gray-200 font-semibold text-xs text-gray-600 rounded-lg px-2 py-1',
})``;

const Cell = styled.div.attrs({
  className: 'flex text-sm text-gray-700',
})``;

export default MeasurementCell;

import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';
import * as Yup from 'yup';

import {settingsPageQuery} from './__generated__/settingsPageQuery.graphql';
import {settingsUpdateSettingsMutation} from './__generated__/settingsUpdateSettingsMutation.graphql';
import Form from './Form';
import {FormValues} from './types';

function SettingsPage() {
  const [commit, isInFlight] = useMutation<settingsUpdateSettingsMutation>(updateSettingsMutation);
  const data = useLazyLoadQuery<settingsPageQuery>(query, {});

  const initialValues = handleInitialValues();

  return (
    <Form
      button="Update Settings"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
      validationSchema={validationSchema}
    />
  );

  function handleInitialValues() {
    if (!data || !data.settings) {
      return {minimumAppVersionIos: '', minimumAppVersionAndroid: ''};
    }

    return {
      minimumAppVersionIos: data.settings.minimumAppVersionIos || '',
      minimumAppVersionAndroid: data.settings.minimumAppVersionAndroid || '',
    };
  }

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          updateSettingsData: {
            minimumAppVersionIos: values.minimumAppVersionIos,
            minimumAppVersionAndroid: values.minimumAppVersionAndroid,
          },
        },
      },
      onCompleted: payload => {
        if (payload.updateSettings?.errors[0] && payload.updateSettings?.errors[0].fields) {
          payload.updateSettings?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.updateSettings;

        if (entry) {
          showEventNotification('updated', 'Settings');
        }
      },
    });
  }
}

const query = graphql`
  query settingsPageQuery {
    settings {
      id
      minimumAppVersionIos
      minimumAppVersionAndroid
    }
  }
`;

const updateSettingsMutation = graphql`
  mutation settingsUpdateSettingsMutation($input: UpdateSettingsInput!) {
    updateSettings(input: $input) {
      settings {
        id
        minimumAppVersionIos
        minimumAppVersionAndroid
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

const validationSchema = Yup.object({
  minimumAppVersionIos: Yup.string().required('Please provide a minimum app version.'),
});

export default withSuspense(SettingsPage);

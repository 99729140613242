/**
 * @generated SignedSource<<918128b6dd64a47fbcd5f225e4a0d28b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type UpdateOrganizationAsProjectAdminInput = {
  organizationId: string;
  updateOrganizationData: UpdateOrganizationAsProjectAdminData;
  clientMutationId?: string | null;
};
export type UpdateOrganizationAsProjectAdminData = {
  name?: string | null;
  email?: string | null;
  city?: string | null;
  organizationConfigAttributes?: UpdateOrganizationConfigData | null;
  state?: string | null;
};
export type UpdateOrganizationConfigData = {
  daysBeforeDueSoon?: number | null;
  daysBeforeOverdue?: number | null;
};
export type updatePageUpdateOrganizationAsProjectAdminMutation$variables = {
  input: UpdateOrganizationAsProjectAdminInput;
};
export type updatePageUpdateOrganizationAsProjectAdminMutationVariables = updatePageUpdateOrganizationAsProjectAdminMutation$variables;
export type updatePageUpdateOrganizationAsProjectAdminMutation$data = {
  readonly updateOrganizationAsProjectAdmin: {
    readonly organization: {
      readonly id: string;
      readonly createdAt: any;
      readonly name: string;
      readonly email: string;
      readonly city: string;
      readonly state: string;
      readonly organizationConfig: {
        readonly daysBeforeDueSoon: number;
        readonly daysBeforeOverdue: number;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly fields: ReadonlyArray<{
        readonly fieldName: string;
        readonly message: string;
      }> | null;
    }>;
  } | null;
};
export type updatePageUpdateOrganizationAsProjectAdminMutationResponse = updatePageUpdateOrganizationAsProjectAdminMutation$data;
export type updatePageUpdateOrganizationAsProjectAdminMutation = {
  variables: updatePageUpdateOrganizationAsProjectAdminMutationVariables;
  response: updatePageUpdateOrganizationAsProjectAdminMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "daysBeforeDueSoon",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "daysBeforeOverdue",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "UserError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserFieldError",
      "kind": "LinkedField",
      "name": "fields",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageUpdateOrganizationAsProjectAdminMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationAsProjectAdminPayload",
        "kind": "LinkedField",
        "name": "updateOrganizationAsProjectAdmin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationConfig",
                "kind": "LinkedField",
                "name": "organizationConfig",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageUpdateOrganizationAsProjectAdminMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateOrganizationAsProjectAdminPayload",
        "kind": "LinkedField",
        "name": "updateOrganizationAsProjectAdmin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationConfig",
                "kind": "LinkedField",
                "name": "organizationConfig",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "52e4ec22574bf2464be1ee8fe690236e",
    "id": null,
    "metadata": {},
    "name": "updatePageUpdateOrganizationAsProjectAdminMutation",
    "operationKind": "mutation",
    "text": "mutation updatePageUpdateOrganizationAsProjectAdminMutation(\n  $input: UpdateOrganizationAsProjectAdminInput!\n) {\n  updateOrganizationAsProjectAdmin(input: $input) {\n    organization {\n      id\n      createdAt\n      name\n      email\n      city\n      state\n      organizationConfig {\n        daysBeforeDueSoon\n        daysBeforeOverdue\n        id\n      }\n    }\n    errors {\n      code\n      fields {\n        fieldName\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a41cff786dd512d7db63b60c500e8756";

export default node;

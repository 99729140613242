/**
 * @generated SignedSource<<b50338e0710be81c0c1f4cd7640181ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserRole = "project_admin" | "organization_admin" | "foreman" | "project_manager" | "superintendent" | "other" | "dispatcher" | "operations_manager" | "technician" | "%future added value";
export type AssigneeInputUsersQuery$variables = {};
export type AssigneeInputUsersQueryVariables = AssigneeInputUsersQuery$variables;
export type AssigneeInputUsersQuery$data = {
  readonly organizationUsers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly firstName: string;
        readonly lastName: string;
        readonly role: UserRole;
      };
    }>;
  };
};
export type AssigneeInputUsersQueryResponse = AssigneeInputUsersQuery$data;
export type AssigneeInputUsersQuery = {
  variables: AssigneeInputUsersQueryVariables;
  response: AssigneeInputUsersQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OrganizationUserConnection",
    "kind": "LinkedField",
    "name": "organizationUsers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationUserEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationUser",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssigneeInputUsersQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AssigneeInputUsersQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "01b68cb167440e25e73ae9e185148256",
    "id": null,
    "metadata": {},
    "name": "AssigneeInputUsersQuery",
    "operationKind": "query",
    "text": "query AssigneeInputUsersQuery {\n  organizationUsers {\n    edges {\n      node {\n        id\n        firstName\n        lastName\n        role\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4ea5c72d2c3c2f09f131f478f0a21c15";

export default node;

/**
 * @generated SignedSource<<3442c9986c4eb4a65efbe13d815d1256>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HistorySection_asset$data = {
  readonly id: string;
  readonly installedAt: any;
  readonly warrantyExpiresAt: any;
  readonly lastServicedAt: any;
  readonly " $fragmentType": "HistorySection_asset";
};
export type HistorySection_asset = HistorySection_asset$data;
export type HistorySection_asset$key = {
  readonly " $data"?: HistorySection_asset$data;
  readonly " $fragmentSpreads": FragmentRefs<"HistorySection_asset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HistorySection_asset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "installedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "warrantyExpiresAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastServicedAt",
      "storageKey": null
    }
  ],
  "type": "Asset",
  "abstractKey": "__isAsset"
};

(node as any).hash = "f968dc8a87b612f9fe96be2479112d95";

export default node;

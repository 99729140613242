/**
 * @generated SignedSource<<e6f8dca0ccf51db2cc188bd2fe1a5316>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ValveFamilyInputValveFamiliesQuery$variables = {};
export type ValveFamilyInputValveFamiliesQueryVariables = ValveFamilyInputValveFamiliesQuery$variables;
export type ValveFamilyInputValveFamiliesQuery$data = {
  readonly valveFamilies: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly manufacturer: {
          readonly id: string;
        };
      };
    }>;
  };
};
export type ValveFamilyInputValveFamiliesQueryResponse = ValveFamilyInputValveFamiliesQuery$data;
export type ValveFamilyInputValveFamiliesQuery = {
  variables: ValveFamilyInputValveFamiliesQueryVariables;
  response: ValveFamilyInputValveFamiliesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ValveFamilyInputValveFamiliesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ValveFamilyConnection",
        "kind": "LinkedField",
        "name": "valveFamilies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ValveFamilyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ValveFamily",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "manufacturer",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ValveFamilyInputValveFamiliesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ValveFamilyConnection",
        "kind": "LinkedField",
        "name": "valveFamilies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ValveFamilyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ValveFamily",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "manufacturer",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "13409e7f7489f338643f02e8b668e655",
    "id": null,
    "metadata": {},
    "name": "ValveFamilyInputValveFamiliesQuery",
    "operationKind": "query",
    "text": "query ValveFamilyInputValveFamiliesQuery {\n  valveFamilies {\n    edges {\n      node {\n        id\n        name\n        manufacturer {\n          __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2a29d15e95393ef6b4acc206db5d2c72";

export default node;

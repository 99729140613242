import React from 'react';

import AssetStatusInput from '../../../../components/inputs/AssetStatusInput';
import DateInput from '../../../../components/inputs/DateInput';

type Props = {
  values: any;
  errors: any;
  setFieldValue: any;
  submitCount: number;
};

function HistoryTab(props: Props) {
  const {values, setFieldValue, submitCount, errors} = props;

  return (
    <div>
      <DateInput
        name="installedAt"
        label="Installed On"
        onChange={value => setFieldValue('installedAt', value)}
        placeholder="E.g. 02/23/2000"
        value={values.installedAt}
        error={!!submitCount && errors.installedAt ? errors.installedAt : ''}
      />

      <DateInput
        name="lastServicedAt"
        label="Last Serviced On"
        onChange={value => setFieldValue('lastServicedAt', value)}
        placeholder="E.g. 10/23/2019"
        value={values.lastServicedAt}
        error={!!submitCount && errors.lastServicedAt ? errors.lastServicedAt : ''}
      />

      <DateInput
        name="warrantyExpiresAt"
        label="Warranty Expires On"
        onChange={value => setFieldValue('warrantyExpiresAt', value)}
        placeholder="E.g. 11/01/2021"
        value={values.warrantyExpiresAt}
        error={!!submitCount && errors.warrantyExpiresAt ? errors.warrantyExpiresAt : ''}
      />

      <AssetStatusInput value={values.status} onChange={value => setFieldValue('status', value)} />
    </div>
  );
}

export default HistoryTab;

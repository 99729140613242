/**
 * @generated SignedSource<<9aeffdcef60ca9f1b81f2aedb74d200b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type updatePageAdminUserQuery$variables = {
  adminUserId: string;
};
export type updatePageAdminUserQueryVariables = updatePageAdminUserQuery$variables;
export type updatePageAdminUserQuery$data = {
  readonly adminUser: {
    readonly id: string;
    readonly createdAt: any;
    readonly firstName: string;
    readonly lastName: string;
    readonly phone: string;
    readonly email: string;
  } | null;
};
export type updatePageAdminUserQueryResponse = updatePageAdminUserQuery$data;
export type updatePageAdminUserQuery = {
  variables: updatePageAdminUserQueryVariables;
  response: updatePageAdminUserQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "adminUserId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "adminUserId",
        "variableName": "adminUserId"
      }
    ],
    "concreteType": "AdminUser",
    "kind": "LinkedField",
    "name": "adminUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phone",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageAdminUserQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageAdminUserQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "19a1e031b086a3ed20b42215bed040cb",
    "id": null,
    "metadata": {},
    "name": "updatePageAdminUserQuery",
    "operationKind": "query",
    "text": "query updatePageAdminUserQuery(\n  $adminUserId: ID!\n) {\n  adminUser(adminUserId: $adminUserId) {\n    id\n    createdAt\n    firstName\n    lastName\n    phone\n    email\n  }\n}\n"
  }
};
})();

(node as any).hash = "a286942aea1187ae9d8174295afc3cda";

export default node;

/**
 * @generated SignedSource<<164caca1a162a52fe5d6fe16a92d09a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type DeleteJobInput = {
  jobId: string;
  clientMutationId?: string | null;
};
export type JobsTableDeleteJobMutation$variables = {
  input: DeleteJobInput;
};
export type JobsTableDeleteJobMutationVariables = JobsTableDeleteJobMutation$variables;
export type JobsTableDeleteJobMutation$data = {
  readonly deleteJob: {
    readonly job: {
      readonly id: string;
      readonly jobNumber: number;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly message: string;
    }>;
  } | null;
};
export type JobsTableDeleteJobMutationResponse = JobsTableDeleteJobMutation$data;
export type JobsTableDeleteJobMutation = {
  variables: JobsTableDeleteJobMutationVariables;
  response: JobsTableDeleteJobMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteJobPayload",
    "kind": "LinkedField",
    "name": "deleteJob",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Job",
        "kind": "LinkedField",
        "name": "job",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobNumber",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "JobsTableDeleteJobMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "JobsTableDeleteJobMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ceb467fae01297941f6800525f02f920",
    "id": null,
    "metadata": {},
    "name": "JobsTableDeleteJobMutation",
    "operationKind": "mutation",
    "text": "mutation JobsTableDeleteJobMutation(\n  $input: DeleteJobInput!\n) {\n  deleteJob(input: $input) {\n    job {\n      id\n      jobNumber\n    }\n    errors {\n      code\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8812fe4f767f44ebb4f6532bc95893e4";

export default node;

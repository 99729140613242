import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {NavLink} from 'react-router-dom';
import {formatDateUS} from 'src/utils/formatDate';
import styled from 'styled-components';

import {ReactComponent as ThumbsUpIcon} from '../../../assets/icons/thumbs-up.svg';
import {DelayedJobsWidgetjobsQuery} from './__generated__/DelayedJobsWidgetjobsQuery.graphql';
import {
  Widget,
  WidgetTitle,
  WidgetBlankContainer,
  WidgetBlankTitle,
  WidgetBlankDescription,
  WidgetBlankIconContainer,
} from './stylesComponents';

function DelayedJobsWidget() {
  const data = useLazyLoadQuery<DelayedJobsWidgetjobsQuery>(
    query,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  const jobs = data.jobs.edges;
  const noResults = jobs.length < 1;

  return (
    <Widget>
      <WidgetTitle>Delayed Jobs</WidgetTitle>

      {noResults && (
        <WidgetBlankContainer>
          <WidgetBlankIconContainer>
            <ThumbsUpIcon />
          </WidgetBlankIconContainer>

          <WidgetBlankTitle>Congrats!</WidgetBlankTitle>
          <WidgetBlankDescription>We have no delayed jobs</WidgetBlankDescription>
        </WidgetBlankContainer>
      )}

      <ItemContainer>
        {jobs.map(({node}) => (
          <Item key={node.id}>
            <ItemLink to={`/admin/jobs/${node.id}/edit`}>
              <JobLabel>Job #{node.jobNumber}</JobLabel>
              <span>
                {node.assignee.firstName} {node.assignee.lastName}
              </span>
            </ItemLink>

            <DateLabel>{formatDateUS(node.dueAt)}</DateLabel>
          </Item>
        ))}
      </ItemContainer>
    </Widget>
  );
}

const ItemContainer = styled.ul`
  li:last-of-type {
    border: none;
  }
`;

const Item = styled.li.attrs({
  className: 'flex border-b border-gray-200 justify-between items-center pb-3 mb-3',
})``;

const ItemLink = styled(NavLink).attrs({
  className: 'flex flex-col text-gray-800 hover:text-orange-600',
})``;

const JobLabel = styled.span.attrs({
  className: 'text-xs font-semibold text-gray-600 uppercase',
})``;

const DateLabel = styled.span.attrs({
  className: 'text-xs font-medium bg-gray-200 text-gray-700 px-2 py-1 rounded-full ml-4',
})``;

const query = graphql`
  query DelayedJobsWidgetjobsQuery {
    jobs(delayed: true) {
      edges {
        node {
          id
          jobNumber
          dueAt
          assignee {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export default DelayedJobsWidget;

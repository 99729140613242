import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useFragment} from 'react-relay/hooks';
import {PieChart, Pie, Cell} from 'recharts';

import {HydrantGpmCountWidget_assetStatistics$key} from './__generated__/HydrantGpmCountWidget_assetStatistics.graphql';

type Props = {
  hydrantGpmStatistics: HydrantGpmCountWidget_assetStatistics$key;
  title: string;
};

export default function HydrantGpmCountWidget(props: Props) {
  const hydrantGpmStatistics = useFragment(
    graphql`
      fragment HydrantGpmCountWidget_assetStatistics on HydrantStatistics {
        gpmVeryGoodCount
        gpmGoodCount
        gpmAdequateCount
        gpmInadequateCount
      }
    `,
    props.hydrantGpmStatistics,
  );

  const pieChartData = [
    {name: 'Very good', value: hydrantGpmStatistics.gpmVeryGoodCount},
    {name: 'Good', value: hydrantGpmStatistics.gpmGoodCount},
    {name: 'Adequate', value: hydrantGpmStatistics.gpmAdequateCount},
    {name: 'Inadequate', value: hydrantGpmStatistics.gpmInadequateCount},
  ];

  const hasStats = pieChartData.reduce((acc, {value}) => acc + value, 0) > 0;

  return (
    <div className="bg-white rounded shadow border-gray-500 p-6">
      <div className="border-b border-gray-300 pb-2">
        <h2 className="text-xl text-gray-800 font-semibold ">{props.title}</h2>
      </div>
      <div className="flex mt-4">
        <div className="pr-4 w-40 mr-4">
          <h3 className="text-gray-700 text-lg font-semibold mb-1">
            <span className="text-blue-500 w-8 inline-block">
              {hydrantGpmStatistics.gpmVeryGoodCount}
            </span>
            <span className="text-xs uppercase font-semibold"> &gt; 1500 GPM</span>
          </h3>
          <h3 className="text-gray-700 text-lg font-semibold mb-1">
            <div className="text-green-500 w-8 inline-block">
              {hydrantGpmStatistics.gpmGoodCount}
            </div>
            <span className="text-xs uppercase font-semibold"> 1000 - 1499 GPM </span>
          </h3>
          <h3 className="text-gray-700 text-lg font-semibold mb-1">
            <span className="text-orange-500 w-8 inline-block ">
              {hydrantGpmStatistics.gpmAdequateCount}
            </span>
            <span className="text-xs uppercase font-semibold"> 501 - 999 GPM </span>
          </h3>
          <h3 className="text-gray-700 text-lg font-semibold mb-1">
            <span className="text-red-500 w-8 inline-block ">
              {hydrantGpmStatistics.gpmInadequateCount}
            </span>
            <span className="text-xs uppercase font-semibold"> &lt; 500 GPM</span>
          </h3>
        </div>

        <div className="flex flex-1 items-center justify-center">
          {hasStats ? (
            <PieChart width={160} height={160}>
              <Pie
                data={pieChartData}
                outerRadius={80}
                innerRadius={60}
                paddingAngle={2}
                dataKey="value">
                <Cell key="hydrant-gpm-very-good" fill="#4299E1" />
                <Cell key="hydrant-gpm-good" fill="#48BB78" />
                <Cell key="hydrant-gpm-adequate" fill="#ED8936" />
                <Cell key="hydrant-gpm-inadequate" fill="#F56565" />
              </Pie>
            </PieChart>
          ) : (
            <div className="bg-gray-200 rounded-full" style={{width: 160, height: 160}} />
          )}
        </div>
      </div>
    </div>
  );
}

import {useEffect, useState, unstable_useTransition} from 'react';
import {RefetchFnDynamic} from 'react-relay/lib/relay-experimental/useRefetchableFragmentNode';

type RefetchFn = RefetchFnDynamic<any, any>;

function useFilters<TFilters>(intialFilters: TFilters, refetch: RefetchFn) {
  const [filters, setFilters] = useState<TFilters>(intialFilters);
  const [startTransition, isTransitioning] = unstable_useTransition({timeoutMs: 3000});

  useEffect(() => {
    startTransition(() => {
      refetch(filters);
    });
  }, [filters]);

  function addFilters(newFilters: TFilters) {
    setFilters(
      state =>
        Object.fromEntries(
          Object.entries({...state, ...newFilters}).filter(([key, value]) => !!value),
        ) as TFilters,
    );
  }

  return {filters, setFilters, addFilters, isTransitioning};
}

export default useFilters;

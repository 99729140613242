import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {usePerPage} from 'src/hooks/usePerPage';

type Props = {
  canNextPage: boolean;
  canPreviousPage: boolean;
  nextPage: () => void;
  pageCount: number;
  pageIndex: number;
  previousPage: () => void;
};

type EntriesPerPageSelectorProps = {
  selectedValue?: string;
  options: number[];
  onChangeValue?: (value: number) => void;
};

type PaginationIconProps = {
  icon: IconProp;
  isDisabled?: boolean;
};

function Pagination(props: Props) {
  const {canNextPage, canPreviousPage, nextPage, pageCount, pageIndex, previousPage} = props;
  const {perPage, setPerPage} = usePerPage();

  return (
    <div className="grid grid-cols-3 bg-gray-300 text-sm text-gray-800 p-4">
      <div className="flex items-center justify-center col-start-2">
        <button disabled={!canPreviousPage} onClick={() => canPreviousPage && previousPage()}>
          <PaginationIcon icon={faChevronLeft} isDisabled={!canPreviousPage} />
        </button>

        <p className="w-24 text-center">
          Page {pageIndex + 1} of {pageCount || 1}
        </p>

        <button disabled={!canNextPage} onClick={() => canNextPage && nextPage()}>
          <PaginationIcon icon={faChevronRight} isDisabled={!canNextPage} />
        </button>
      </div>

      <div className="flex justify-end">
        <EntriesPerPageSelector selectedValue={String(perPage)} onChangeValue={setPerPage} />
        <p className="ml-2 text-gray-600">per page</p>
      </div>
    </div>
  );
}

function EntriesPerPageSelector({
  selectedValue,
  options,
  onChangeValue,
}: EntriesPerPageSelectorProps) {
  function handleChange({target}: React.ChangeEvent<HTMLSelectElement>) {
    onChangeValue?.(parseInt(target.value));
  }

  return (
    <select className="bg-transparent" onChange={handleChange}>
      {options.map(value => (
        <option key={value} value={value} selected={String(value) == selectedValue}>
          {value}
        </option>
      ))}
    </select>
  );
}

function PaginationIcon({icon, isDisabled}: PaginationIconProps) {
  return (
    <FontAwesomeIcon
      icon={icon}
      className={`mr-2 ${isDisabled ? 'text-gray-500' : 'text-orange-500'}`}
    />
  );
}

EntriesPerPageSelector.defaultProps = {
  options: [25, 50, 75, 100],
};

export default Pagination;

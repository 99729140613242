import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {withSuspense} from 'src/utils/withSuspense';

import {hydrantMeasurementReportPageassetsQuery} from './__generated__/hydrantMeasurementReportPageassetsQuery.graphql';
import {hydrantMeasurementReportPagemeasurementTemplatesQuery} from './__generated__/hydrantMeasurementReportPagemeasurementTemplatesQuery.graphql';
import HydrantsTable from './HydrantsTable';

function HydrantMeasurementReportPage() {
  const templatesData = useLazyLoadQuery<hydrantMeasurementReportPagemeasurementTemplatesQuery>(
    measurementTemplates,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  const data = useLazyLoadQuery<hydrantMeasurementReportPageassetsQuery>(
    hydrants,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  const templates = templatesData?.measurementTemplates.edges || [];

  return <HydrantsTable measurementTemplates={templates} assets={data} />;
}

export default withSuspense(HydrantMeasurementReportPage);

const hydrants = graphql`
  query hydrantMeasurementReportPageassetsQuery(
    $first: Int
    $createdBefore: ISO8601DateTime
    $status: AssetStatus
    $text: String
  ) {
    ...HydrantsTable_assets
      @arguments(first: $first, createdBefore: $createdBefore, status: $status, text: $text)
  }
`;

const measurementTemplates = graphql`
  query hydrantMeasurementReportPagemeasurementTemplatesQuery {
    measurementTemplates(assetType: hydrant) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

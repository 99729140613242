import React from 'react';
import {RouteComponentProps} from 'react-router';
import {Route, Switch} from 'react-router-dom';
import generalAssetMeasurementReport from 'src/pages/general-asset-measurement-report';
import hydrantMeasurementReport from 'src/pages/hydrant-measurement-report';
import UserSettingsPage from 'src/pages/user-settings';
import valveMeasurementReport from 'src/pages/valve-measurement-report';

import PageLayout from '../components/page-layout';
import AssetMapPage from '../pages/asset-map-page';
import DashboardPage from '../pages/dashboard-page';
import GeneralAssetFamilyRouter from '../pages/general-asset-families';
import GeneralAssetManufacturerRouter from '../pages/general-asset-manufacturers';
import GeneralAssetModelRouter from '../pages/general-asset-models';
import GeneralAssetRouter from '../pages/general-assets';
import HydrantFamilyRouter from '../pages/hydrant-families';
import HydrantManufacturerRouter from '../pages/hydrant-manufacturers';
import HydrantModelRouter from '../pages/hydrant-models';
import HydrantRouter from '../pages/hydrants';
import JobRouter from '../pages/jobs';
import MeasurementTemplateRouter from '../pages/measurement-templates';
import MeasurementsRouter from '../pages/measurements';
import OrganizationSettingsPage from '../pages/organization-settings';
import TaskTemplateRouter from '../pages/task-templates';
import UserRouter from '../pages/users';
import ValveConnectorRouter from '../pages/valve-connectors';
import ValveFamilyRouter from '../pages/valve-families';
import ValveManufacturerRouter from '../pages/valve-manufacturers';
import ValveModelRouter from '../pages/valve-models';
import ValveRouter from '../pages/valves';
import {OrganizationAdminRoute} from './customRoutes';

function OrganizationRouter({match}: RouteComponentProps): React.ReactElement {
  return (
    <PageLayout>
      <Switch>
        <Route exact path={`${match.path}/user_settings`} component={UserSettingsPage} />
        <Route exact path={`${match.path}/asset_map`} component={AssetMapPage} />
        <Route exact path={`${match.path}/dashboard`} component={DashboardPage} />
        <Route
          path={`${match.path}/general_asset_manufacturers`}
          component={GeneralAssetManufacturerRouter}
        />
        <Route path={`${match.path}/general_asset_models`} component={GeneralAssetModelRouter} />
        <Route path={`${match.path}/general_asset_types`} component={GeneralAssetFamilyRouter} />
        <Route
          path={`${match.path}/general_asset_measurement_report`}
          component={generalAssetMeasurementReport}
        />
        <Route path={`${match.path}/general_assets`} component={GeneralAssetRouter} />
        <Route path={`${match.path}/hydrant_manufacturers`} component={HydrantManufacturerRouter} />
        <Route path={`${match.path}/hydrant_models`} component={HydrantModelRouter} />
        <Route path={`${match.path}/hydrant_types`} component={HydrantFamilyRouter} />
        <Route
          path={`${match.path}/hydrant_measurement_report`}
          component={hydrantMeasurementReport}
        />
        <Route path={`${match.path}/hydrants`} component={HydrantRouter} />
        <Route path={`${match.path}/jobs`} component={JobRouter} />
        <Route path={`${match.path}/measurements`} component={MeasurementsRouter} />
        <Route path={`${match.path}/measurement_templates`} component={MeasurementTemplateRouter} />
        <OrganizationAdminRoute
          path={`${match.path}/settings`}
          component={OrganizationSettingsPage}
        />
        <Route path={`${match.path}/task_templates`} component={TaskTemplateRouter} />
        <OrganizationAdminRoute path={`${match.path}/users`} component={UserRouter} />
        <Route path={`${match.path}/valve_connection_types`} component={ValveConnectorRouter} />
        <Route path={`${match.path}/valve_manufacturers`} component={ValveManufacturerRouter} />
        <Route path={`${match.path}/valve_models`} component={ValveModelRouter} />
        <Route path={`${match.path}/valve_types`} component={ValveFamilyRouter} />
        <Route path={`${match.path}/valve_measurement_report`} component={valveMeasurementReport} />
        <Route path={`${match.path}/valves`} component={ValveRouter} />
      </Switch>
    </PageLayout>
  );
}

export default OrganizationRouter;

import React from 'react';
import CompletedFilter from 'src/components/inputs/CompletedFilter';
import {useQuery} from 'src/utils/useQuery';

import TextInput from '../../../components/inputs/TextInput';
import FilterWrapper from '../../../components/table/Filters';

type Props = {
  onApplyFilters: (filters: {text: string; completed: string}) => void;
  onResetFilters: () => void;
};

function TableFilters(props: Props) {
  const {onApplyFilters, onResetFilters} = props;

  const [searchQuery, setSearchQuery] = React.useState('');
  const [completed, setCompleted] = React.useState('all');

  return (
    <FilterWrapper
      onResetFilters={handleOnResetFilters}
      onApplyFilters={handleOnApplyFilters}
      areFiltersApplicable={!!searchQuery || !!completed}>
      <TextInput
        label="Search text fields"
        placeholder="E.g. name, ..."
        value={searchQuery}
        handleChange={value => setSearchQuery(value)}
        name="searchInput"
      />
      <div className="ml-4"></div>
      <CompletedFilter value={completed} onChange={setCompleted} />
    </FilterWrapper>
  );

  async function handleOnResetFilters() {
    setSearchQuery('');
    setCompleted('all');
    onResetFilters();
  }

  async function handleOnApplyFilters() {
    onApplyFilters({text: searchQuery, completed});
  }
}

export default TableFilters;

import {useFormik, FormikHelpers} from 'formik';
import React from 'react';
// import AssetInput from 'src/components/inputs/AssetInput';
import * as Yup from 'yup';

import AssigneeInput from '../../../components/inputs/AssigneeInput';
import DateInput from '../../../components/inputs/DateInput';
import TaskInput from '../../../components/inputs/TaskInput';
import {FormValues} from '../types';
import AssetInput from './AssetInput';

type Props = {
  selectedAsset?: any;
  initialValues?: FormValues;
  onSubmit: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void;
  title: string;
  button: string;
  isSubmitting: boolean;
};

function Form(props: Props) {
  const {initialValues, isSubmitting, title, selectedAsset, button, onSubmit} = props;

  const {submitCount, handleSubmit, errors, setFieldValue, values, isValid} = useFormik<FormValues>(
    {
      initialValues: Object.assign(
        {},
        {
          notes: '',
          assigneeId: '',
          asset: null,
          dueAt: '',
          taskTemplates: [],
        },
        {asset: selectedAsset},
        initialValues,
      ),
      isInitialValid: false,
      enableReinitialize: true,
      onSubmit,
      validationSchema,
    },
  );

  return (
    <div className="p-8">
      <div className="mb-6 pb-1 border-b-2 border-gray-400">
        <h2 className="text-2xl text-gray-800 uppercase font-semibold">{title}</h2>
      </div>

      <AssigneeInput
        value={values.assigneeId}
        onChange={assigneeId => setFieldValue('assigneeId', assigneeId)}
        error={!!submitCount && !!errors.assigneeId ? errors.assigneeId : ''}
      />

      <DateInput
        name="dueDate"
        label="Due Date"
        placeholder="E.g. 02/20/2028"
        value={values.dueAt}
        onChange={newDate => setFieldValue('dueAt', newDate)}
        range="future"
        error={!!submitCount && !!errors.dueAt ? (errors.dueAt as string) : ''}
      />

      <TaskInput
        value={values.taskTemplates}
        handleChange={taskTemplates => setFieldValue('taskTemplates', taskTemplates)}
        error={!!submitCount && !!errors.taskTemplates ? (errors.taskTemplates as string) : ''}
      />

      <AssetInput
        value={values.asset}
        handleChange={asset => setFieldValue('asset', asset)}
        error={!!submitCount && !!errors.asset ? (errors.asset as string) : ''}
      />

      <button
        onClick={() => handleSubmit()}
        disabled={isSubmitting}
        className={
          'px-5 py-2 text-sm rounded font-semibold text-white uppercase ' +
          (isValid ? 'bg-gray-700' : 'bg-gray-400')
        }>
        {button}
      </button>
    </div>
  );
}

const validationSchema = Yup.object({
  dueAt: Yup.string()
    .required('Please fill in a due date.')
    .nullable(),
  assigneeId: Yup.string().required('Please select a assignee.'),
  asset: Yup.object()
    .required('Please select an asset.')
    .nullable(),
  taskTemplates: Yup.array().required('Please add at least one task.'),
});

export default Form;

import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useMutation} from 'react-relay/hooks';
import {useHistory} from 'react-router';
import ConnectionHandler from 'relay-connection-handler-plus';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/form';
import {FormValues} from '../types';
import {createPageCreateGeneralAssetMutation} from './__generated__/createPageCreateGeneralAssetMutation.graphql';

function CreatePage() {
  const history = useHistory();

  const [commit, isInFlight] = useMutation<createPageCreateGeneralAssetMutation>(
    createGeneralAssetMutation,
  );

  return (
    <Form
      title="Create General Asset"
      button="Create General Asset"
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleSubmit(input: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          coordinates: [
            parseFloat(input.coordinates.longitude),
            parseFloat(input.coordinates.latitude),
          ],
          modelId: input.modelId,
          serial: input.serial,
          externalId: input.externalId,
          status: input.status,
          installedAt: input.installedAt.toISOString(),
          warrantyExpiresAt: input.warrantyExpiresAt.toISOString(),
          lastServicedAt: input.lastServicedAt.toISOString(),
          generalAssetName: input.generalAssetName,
          ...(input.notes ? {notes: input.notes} : {}),
        },
      },
      onCompleted: payload => {
        if (payload.createGeneralAsset?.errors[0] && payload.createGeneralAsset?.errors[0].fields) {
          payload.createGeneralAsset?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.createGeneralAsset?.generalAsset;

        if (entry) {
          showEventNotification('added', 'Asset', entry.serial);
          return history.push('/admin/general_assets');
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connectionRecords = ConnectionHandler.getConnections(
          root,
          'GeneralAssetsList_generalAssets',
        );

        if (!connectionRecords) {
          return;
        }

        const payload = store.getRootField('createGeneralAsset');

        if (!payload) {
          return;
        }

        const newRecord = payload.getLinkedRecord('generalAsset');

        if (!newRecord) {
          return;
        }

        connectionRecords.forEach(connectionRecord => {
          const newEdge = ConnectionHandler.createEdge(
            store,
            connectionRecord,
            newRecord,
            'generalAssetEdge',
          );

          ConnectionHandler.insertEdgeAfter(connectionRecord, newEdge);
        });
      },
    });
  }
}

const createGeneralAssetMutation = graphql`
  mutation createPageCreateGeneralAssetMutation($input: CreateGeneralAssetInput!) {
    createGeneralAsset(input: $input) {
      generalAsset {
        id
        createdAt
        serial
        externalId
        manufacturer {
          id
          name
        }
        model {
          id
          name
        }
        family {
          id
          name
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(CreatePage);

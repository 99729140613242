import React from 'react';
import styled from 'styled-components';

type Props = {
  value: any;
};

function SecondaryTextCell({ value }: Props) {
  return <CellContainer>{value}</CellContainer>;
}

const CellContainer = styled.div.attrs({
  className: 'text-sm text-gray-700',
})``;

export default SecondaryTextCell;

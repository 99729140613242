/**
 * @generated SignedSource<<0701a59b2ef7e74e8c7f9a60fc07085c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MeasurementAssetKind = "hydrant" | "valve" | "general_asset" | "%future added value";
export type MeasurementDataKind = "number" | "percentage" | "text" | "%future added value";
export type createPageMeasurementTemplatesQuery$variables = {
  assetType?: MeasurementAssetKind | null;
};
export type createPageMeasurementTemplatesQueryVariables = createPageMeasurementTemplatesQuery$variables;
export type createPageMeasurementTemplatesQuery$data = {
  readonly measurementTemplates: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly assetKind: MeasurementAssetKind;
        readonly dataKind: MeasurementDataKind;
      };
    }>;
  };
};
export type createPageMeasurementTemplatesQueryResponse = createPageMeasurementTemplatesQuery$data;
export type createPageMeasurementTemplatesQuery = {
  variables: createPageMeasurementTemplatesQueryVariables;
  response: createPageMeasurementTemplatesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assetType"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "assetType",
        "variableName": "assetType"
      }
    ],
    "concreteType": "MeasurementTemplateConnection",
    "kind": "LinkedField",
    "name": "measurementTemplates",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MeasurementTemplateEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MeasurementTemplate",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "assetKind",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dataKind",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createPageMeasurementTemplatesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createPageMeasurementTemplatesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "512f59deef3b802e343c90000f34d93c",
    "id": null,
    "metadata": {},
    "name": "createPageMeasurementTemplatesQuery",
    "operationKind": "query",
    "text": "query createPageMeasurementTemplatesQuery(\n  $assetType: MeasurementAssetKind\n) {\n  measurementTemplates(assetType: $assetType) {\n    edges {\n      node {\n        id\n        name\n        assetKind\n        dataKind\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2888fd4d2dceaaec141927bb1f5965f8";

export default node;

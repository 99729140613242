import {faChevronUp, faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

type Props = {
  headerGroups: any[];
};

function Header({headerGroups}: Props) {
  return (
    <thead className="border-b bg-gray-300">
      {headerGroups.map((headerGroup, groupIndex) => (
        <tr key={groupIndex} {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column: any, columnIndex: any) => (
            <Th
              key={columnIndex}
              className={column.headerClassName}
              {...column.getHeaderProps(column.getSortByToggleProps())}>
              <div className="flex justify-start items-center text-sm text-gray-700 uppercase font-semibold">
                {column.render('Header')}
                <span className="ml-1">
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <UpIcon />
                    ) : (
                      <DownIcon />
                    )
                  ) : (
                    <div style={{width: 14}} />
                  )}
                </span>
              </div>
            </Th>
          ))}
          <th />
        </tr>
      ))}
    </thead>
  );
}

const Th = styled.th.attrs({className: 'px-3 py-4'})`
  &:first-child {
    padding-left: 2rem;
  }
`;

const UpIcon = styled(FontAwesomeIcon).attrs({
  icon: faChevronUp,
  className: 'text-orange-500',
})``;

const DownIcon = styled(FontAwesomeIcon).attrs({
  icon: faChevronDown,
  className: 'text-orange-500',
})``;

export default Header;

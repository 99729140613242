/**
 * @generated SignedSource<<7d531c30a684e9ac3915cba3b350725e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type CreateOrganizationAsProjectAdminInput = {
  name: string;
  email: string;
  city: string;
  organizationConfigAttributes?: CreateOrganizationConfigData | null;
  state: string;
  adminUserEmail: string;
  firstName: string;
  lastName: string;
  password: string;
  phone: string;
  clientMutationId?: string | null;
};
export type CreateOrganizationConfigData = {
  daysBeforeDueSoon?: number | null;
  daysBeforeOverdue?: number | null;
};
export type createPageCreateOrganizationAsProjectAdminMutation$variables = {
  input: CreateOrganizationAsProjectAdminInput;
};
export type createPageCreateOrganizationAsProjectAdminMutationVariables = createPageCreateOrganizationAsProjectAdminMutation$variables;
export type createPageCreateOrganizationAsProjectAdminMutation$data = {
  readonly createOrganizationAsProjectAdmin: {
    readonly organization: {
      readonly id: string;
      readonly createdAt: any;
      readonly name: string;
      readonly email: string;
      readonly city: string;
      readonly state: string;
      readonly organizationConfig: {
        readonly daysBeforeDueSoon: number;
        readonly daysBeforeOverdue: number;
      };
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly fields: ReadonlyArray<{
        readonly fieldName: string;
        readonly message: string;
      }> | null;
    }>;
  } | null;
};
export type createPageCreateOrganizationAsProjectAdminMutationResponse = createPageCreateOrganizationAsProjectAdminMutation$data;
export type createPageCreateOrganizationAsProjectAdminMutation = {
  variables: createPageCreateOrganizationAsProjectAdminMutationVariables;
  response: createPageCreateOrganizationAsProjectAdminMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "city",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "daysBeforeDueSoon",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "daysBeforeOverdue",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "UserError",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserFieldError",
      "kind": "LinkedField",
      "name": "fields",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createPageCreateOrganizationAsProjectAdminMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOrganizationAsProjectAdminPayload",
        "kind": "LinkedField",
        "name": "createOrganizationAsProjectAdmin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationConfig",
                "kind": "LinkedField",
                "name": "organizationConfig",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createPageCreateOrganizationAsProjectAdminMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateOrganizationAsProjectAdminPayload",
        "kind": "LinkedField",
        "name": "createOrganizationAsProjectAdmin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationConfig",
                "kind": "LinkedField",
                "name": "organizationConfig",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fd0b15c1f41b9f525585b777cf9a15e7",
    "id": null,
    "metadata": {},
    "name": "createPageCreateOrganizationAsProjectAdminMutation",
    "operationKind": "mutation",
    "text": "mutation createPageCreateOrganizationAsProjectAdminMutation(\n  $input: CreateOrganizationAsProjectAdminInput!\n) {\n  createOrganizationAsProjectAdmin(input: $input) {\n    organization {\n      id\n      createdAt\n      name\n      email\n      city\n      state\n      organizationConfig {\n        daysBeforeDueSoon\n        daysBeforeOverdue\n        id\n      }\n    }\n    errors {\n      code\n      fields {\n        fieldName\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "84194d6e58822b8fd21b64226ab4e8f7";

export default node;

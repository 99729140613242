import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useMutation} from 'react-relay/hooks';
import {useHistory} from 'react-router';
import ConnectionHandler from 'relay-connection-handler-plus';
import {showEventNotification} from 'src/utils/notifications';

import Form from '../components/Form';
import {FormValues} from '../types';
import {createPageCreateTaskTemplateMutation} from './__generated__/createPageCreateTaskTemplateMutation.graphql';

function CreatePage() {
  const history = useHistory();

  const [commit, isInFlight] = useMutation<createPageCreateTaskTemplateMutation>(
    createTaskTemplateMutation,
  );

  return (
    <Form
      title="Create Task Template"
      button="Create Task Template"
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleSubmit(data: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    const input = {
      name: data.name,
      taskKind: data.taskKind,
      measurementTemplateId: data.measurementTemplateId || null,
    };

    commit({
      variables: {input},
      onCompleted: payload => {
        if (payload.createTaskTemplate?.errors[0] && payload.createTaskTemplate?.errors[0].fields) {
          payload.createTaskTemplate?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.createTaskTemplate?.taskTemplate;

        if (entry) {
          showEventNotification('added', 'Template', entry.name);
          return history.push('/admin/task_templates');
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connectionRecords = ConnectionHandler.getConnections(
          root,
          'TaskTemplatesList_taskTemplates',
        );

        if (!connectionRecords) {
          return;
        }

        const payload = store.getRootField('createTaskTemplate');

        if (!payload) {
          return;
        }

        const newRecord = payload.getLinkedRecord('taskTemplate');

        if (!newRecord) {
          return;
        }

        connectionRecords.forEach(connectionRecord => {
          const newEdge = ConnectionHandler.createEdge(
            store,
            connectionRecord,
            newRecord,
            'taskTemplateEdge',
          );

          ConnectionHandler.insertEdgeAfter(connectionRecord, newEdge);
        });
      },
    });
  }
}

const createTaskTemplateMutation = graphql`
  mutation createPageCreateTaskTemplateMutation($input: CreateTaskTemplateInput!) {
    createTaskTemplate(input: $input) {
      taskTemplate {
        id
        createdAt
        name
        taskKind
        measurementTemplate {
          id
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default CreatePage;

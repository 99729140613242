/**
 * @generated SignedSource<<9ac07adcbfa7d3b36ea8f5312de97d53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type UserRole = "project_admin" | "organization_admin" | "foreman" | "project_manager" | "superintendent" | "other" | "dispatcher" | "operations_manager" | "technician" | "%future added value";
export type updatePageUserQuery$variables = {
  organizationUserId: string;
};
export type updatePageUserQueryVariables = updatePageUserQuery$variables;
export type updatePageUserQuery$data = {
  readonly organizationUser: {
    readonly id: string;
    readonly createdAt: any;
    readonly firstName: string;
    readonly lastName: string;
    readonly phone: string;
    readonly email: string;
    readonly role: UserRole;
  } | null;
};
export type updatePageUserQueryResponse = updatePageUserQuery$data;
export type updatePageUserQuery = {
  variables: updatePageUserQueryVariables;
  response: updatePageUserQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationUserId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "organizationUserId",
        "variableName": "organizationUserId"
      }
    ],
    "concreteType": "OrganizationUser",
    "kind": "LinkedField",
    "name": "organizationUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phone",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageUserQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageUserQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f567a23f5603e6e2af28121658dbe4d2",
    "id": null,
    "metadata": {},
    "name": "updatePageUserQuery",
    "operationKind": "query",
    "text": "query updatePageUserQuery(\n  $organizationUserId: ID!\n) {\n  organizationUser(organizationUserId: $organizationUserId) {\n    id\n    createdAt\n    firstName\n    lastName\n    phone\n    email\n    role\n  }\n}\n"
  }
};
})();

(node as any).hash = "e3ac83be957fafd0c83a40a3d516f504";

export default node;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';

import {GeneralAssetManufacturerInputGeneralAssetManufacturersQuery} from './__generated__/GeneralAssetManufacturerInputGeneralAssetManufacturersQuery.graphql';
import DropdownInput from './DropdownInput';

type Props = {
  value: string;
  onChange: (value: string) => void;
  error?: string;
};

function GeneralAssetManufacturerInput(props: Props) {
  const data = useLazyLoadQuery<GeneralAssetManufacturerInputGeneralAssetManufacturersQuery>(
    generalAssetManufacturersQuery,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  const {error, value, onChange} = props;

  const options = !!data
    ? data.generalAssetManufacturers.edges.map(generalAssetManufacturerEdge => ({
        label: generalAssetManufacturerEdge.node.name,
        value: generalAssetManufacturerEdge.node.id,
      }))
    : [];

  return (
    <DropdownInput
      name="generalAssetManufacturerInput"
      onChange={onChange}
      options={options}
      placeholder="E.g. Kennedy"
      label="Manufacturer"
      value={value}
      error={error}
    />
  );
}

const generalAssetManufacturersQuery = graphql`
  query GeneralAssetManufacturerInputGeneralAssetManufacturersQuery {
    generalAssetManufacturers {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default GeneralAssetManufacturerInput;

import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useMutation} from 'react-relay/hooks';
import {useHistory} from 'react-router';
import ConnectionHandler from 'relay-connection-handler-plus';
import {showEventNotification} from 'src/utils/notifications';

import Form from '../components/Form';
import {FormValues} from '../types';
import {createPageCreateGeneralAssetManufacturerMutation} from './__generated__/createPageCreateGeneralAssetManufacturerMutation.graphql';

function CreatePage() {
  const history = useHistory();

  const [commit, isInFlight] = useMutation<createPageCreateGeneralAssetManufacturerMutation>(
    createGeneralAssetManufacturerMutation,
  );

  return (
    <Form
      title="Create General Asset Manufacturer"
      button="Create General Asset Manufacturer"
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleSubmit(input: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {input},
      onCompleted: payload => {
        if (
          payload.createGeneralAssetManufacturer?.errors[0] &&
          payload.createGeneralAssetManufacturer?.errors[0].fields
        ) {
          payload.createGeneralAssetManufacturer?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.createGeneralAssetManufacturer?.generalAssetManufacturer;

        if (entry) {
          showEventNotification('added', 'Manufacturer', entry.name);
          return history.push('/admin/general_asset_manufacturers');
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connectionRecords = ConnectionHandler.getConnections(
          root,
          'GeneralAssetManufacturersList_generalAssetManufacturers',
        );

        if (!connectionRecords) {
          return;
        }

        const payload = store.getRootField('createGeneralAssetManufacturer');

        if (!payload) {
          return;
        }

        const newRecord = payload.getLinkedRecord('generalAssetManufacturer');

        if (!newRecord) {
          return;
        }

        connectionRecords.forEach(connectionRecord => {
          const newEdge = ConnectionHandler.createEdge(
            store,
            connectionRecord,
            newRecord,
            'generalAssetManufacturerEdge',
          );

          ConnectionHandler.insertEdgeAfter(connectionRecord, newEdge);
        });
      },
    });
  }
}

const createGeneralAssetManufacturerMutation = graphql`
  mutation createPageCreateGeneralAssetManufacturerMutation(
    $input: CreateGeneralAssetManufacturerInput!
  ) {
    createGeneralAssetManufacturer(input: $input) {
      generalAssetManufacturer {
        id
        createdAt
        name
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default CreatePage;

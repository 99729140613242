import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';

import {HydrantModelInputHydrantModelsQuery} from './__generated__/HydrantModelInputHydrantModelsQuery.graphql';
import DropdownInput from './DropdownInput';

type Props = {
  value: string;
  familyId: string;
  onChange: (value: string) => void;
  error?: string;
};

function HydrantModelInput(props: Props) {
  const {value, familyId, onChange, error} = props;

  const data = useLazyLoadQuery<HydrantModelInputHydrantModelsQuery>(
    hydrantModelsQuery,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  const options =
    !!data && familyId
      ? data.hydrantModels.edges
          .filter(hydrantModelEdge => hydrantModelEdge.node.family.id === familyId)
          .map(hydrantModelEdge => ({
            label: hydrantModelEdge.node.name,
            value: hydrantModelEdge.node.id,
          }))
      : [];

  return (
    <DropdownInput
      name="hydrantModelInput"
      onChange={onChange}
      options={options}
      placeholder="E.g. Dry Barrel"
      label="Type"
      value={value}
      error={error}
    />
  );
}

const hydrantModelsQuery = graphql`
  query HydrantModelInputHydrantModelsQuery {
    hydrantModels {
      edges {
        node {
          id
          name
          family {
            id
          }
        }
      }
    }
  }
`;

export default HydrantModelInput;

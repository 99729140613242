/**
 * @generated SignedSource<<2870ca16b71dcb199ae97cbde3b4a83c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type DeleteAdminUserInput = {
  adminUserId: string;
  clientMutationId?: string | null;
};
export type UsersTableDeleteAdminUserMutation$variables = {
  input: DeleteAdminUserInput;
};
export type UsersTableDeleteAdminUserMutationVariables = UsersTableDeleteAdminUserMutation$variables;
export type UsersTableDeleteAdminUserMutation$data = {
  readonly deleteAdminUser: {
    readonly adminUser: {
      readonly id: string;
      readonly firstName: string;
      readonly createdAt: any;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly message: string;
    }>;
  } | null;
};
export type UsersTableDeleteAdminUserMutationResponse = UsersTableDeleteAdminUserMutation$data;
export type UsersTableDeleteAdminUserMutation = {
  variables: UsersTableDeleteAdminUserMutationVariables;
  response: UsersTableDeleteAdminUserMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteAdminUserPayload",
    "kind": "LinkedField",
    "name": "deleteAdminUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminUser",
        "kind": "LinkedField",
        "name": "adminUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UsersTableDeleteAdminUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UsersTableDeleteAdminUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d0a05d2d297309697a1963f4558ddecc",
    "id": null,
    "metadata": {},
    "name": "UsersTableDeleteAdminUserMutation",
    "operationKind": "mutation",
    "text": "mutation UsersTableDeleteAdminUserMutation(\n  $input: DeleteAdminUserInput!\n) {\n  deleteAdminUser(input: $input) {\n    adminUser {\n      id\n      firstName\n      createdAt\n    }\n    errors {\n      code\n      message\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c9cc0ea4527ee5ab6c33041fe52ac653";

export default node;

import graphql from 'babel-plugin-relay/macro';
import {useFormik} from 'formik';
import React, {useState} from 'react';
import {useMutation} from 'react-relay/hooks';
import {NavLink} from 'react-router-dom';
import AlertDanger from 'src/components/alerts/AlertDanger';
import AlertSuccess from 'src/components/alerts/AlertSuccess';
import {AUTHENTICATION_ERROR} from 'src/constants/errors';
import styled from 'styled-components';
import * as Yup from 'yup';

import logo from '../../assets/img/AF-Logo-Mark.png';
import TextInput from '../../components/inputs/TextInput';
import {requestPasswordResetPageRequestPasswordResetMutation} from './__generated__/requestPasswordResetPageRequestPasswordResetMutation.graphql';

function RequestPasswordResetPage() {
  const [formError, setFormError] = React.useState('');

  const {
    values,
    setFieldValue,
    handleSubmit,
    submitCount,
    errors,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: {
      email: '',
    },
    isInitialValid: false,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const [commit] = useMutation<requestPasswordResetPageRequestPasswordResetMutation>(mutation);
  const [resetInstructionsSent, setResetInstructionsSent] = useState(false);

  return (
    <div className="bg-gray-900 flex flex-grow items-center pt-40 flex-col">
      <div>
        <img className="h-20 mb-10" src={logo} alt="header logo" />
      </div>

      <div className="bg-white shadow-md rounded p-8 mb-4">
        <h1 className="text-xl font-semibold text-gray-800">Can't sign in?</h1>
        <p className="antialiased text-gray-600 mb-6">Restore access to your account</p>

        {resetInstructionsSent ? (
          <div>
            <AlertSuccess
              title="Instructions Sent"
              description="Please, check your mailbox for more instructions about how to reset your password."
              className="w-80"
            />
            <BackButton>OK</BackButton>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            {formError && <AlertDanger description={formError} className="mb-4 w-80" />}

            <div className="mb-4">
              <TextInput
                name="email"
                type="email"
                label="Email"
                value={values.email}
                handleChange={value => setFieldValue('email', value)}
                placeholder="E.g. johnsmith@example.com"
                error={!!submitCount && errors.email ? errors.email : ''}
              />
            </div>

            <div className="flex justify-center">
              <SubmitButton disabled={isSubmitting || !isValid} type="submit">
                Send Password Reset Instructions
              </SubmitButton>
            </div>
          </form>
        )}
      </div>

      <div className="m-4">
        <BacktoSignInButton>Back to sign in</BacktoSignInButton>
      </div>
    </div>
  );

  async function onSubmit(formValues: any) {
    setFormError('');

    const formattedEmail = formValues.email.toLowerCase();

    commit({
      variables: {
        input: {email: formattedEmail},
      },
      onCompleted: async payload => {
        if (payload?.requestPasswordReset?.sent) {
          setResetInstructionsSent(true);
        } else if (payload.requestPasswordReset?.errors) {
          const [error] = payload.requestPasswordReset?.errors;

          if (error.code === AUTHENTICATION_ERROR) {
            setFormError('Incorrect email address');
          }
        }
      },
    });
  }
}

const mutation = graphql`
  mutation requestPasswordResetPageRequestPasswordResetMutation(
    $input: RequestPasswordResetInput!
  ) {
    requestPasswordReset(input: $input) {
      sent
      errors {
        code
      }
    }
  }
`;

const BackButton = styled(NavLink).attrs({
  to: 'signin',
  className:
    'btn block text-sm text-center text-gray-600 border border-gray-500 hover:border-gray-700 hover:text-gray-800 mt-5',
})``;

const SubmitButton = styled.button.attrs<{disabled: boolean}>(({disabled}) => {
  const backgroundColor = disabled ? 'bg-gray-400' : 'bg-blue-700 hover:bg-blue-500';

  return {
    className:
      'flex-1 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ' +
      backgroundColor,
  };
})<{disabled: boolean}>``;

const BacktoSignInButton = styled(NavLink).attrs({
  to: '/signin',
  className: 'text-gray-700 hover:text-gray-500 p-4',
})``;

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Please provide a valid email address.')
    .required('Please provide a valid email address.'),
});

export default RequestPasswordResetPage;

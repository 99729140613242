/**
 * @generated SignedSource<<b419ea6a906f903d03ad9ce9dec0f096>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type updatePageValveConnectorQuery$variables = {
  valveConnectorId: string;
};
export type updatePageValveConnectorQueryVariables = updatePageValveConnectorQuery$variables;
export type updatePageValveConnectorQuery$data = {
  readonly valveConnector: {
    readonly id: string;
    readonly createdAt: any;
    readonly name: string;
  } | null;
};
export type updatePageValveConnectorQueryResponse = updatePageValveConnectorQuery$data;
export type updatePageValveConnectorQuery = {
  variables: updatePageValveConnectorQueryVariables;
  response: updatePageValveConnectorQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "valveConnectorId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "valveConnectorId",
        "variableName": "valveConnectorId"
      }
    ],
    "concreteType": "ValveConnector",
    "kind": "LinkedField",
    "name": "valveConnector",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageValveConnectorQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageValveConnectorQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d86c2af3d024b418d15569c428b99f00",
    "id": null,
    "metadata": {},
    "name": "updatePageValveConnectorQuery",
    "operationKind": "query",
    "text": "query updatePageValveConnectorQuery(\n  $valveConnectorId: ID!\n) {\n  valveConnector(valveConnectorId: $valveConnectorId) {\n    id\n    createdAt\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "4e7f9c11efd308253ad04bda08eeba95";

export default node;

import React from 'react';

type TooltipPayload = {dataKey: string; value: string};
type Props = {
  active?: boolean;
  label: string;
  payload: TooltipPayload[];
};

function ChartTooltip({active, payload, label}: Props) {
  if (!active) {
    return null;
  }

  return (
    <div className="bg-white shadow rounded-lg p-4">
      <p className="text-xs text-gray-600 font-medium uppercase">{label}</p>

      {payload.map(entry => (
        <p
          key={entry.dataKey}
          className="text-sm text-gray-700 capitalize">{`${entry.dataKey}: ${entry.value}`}</p>
      ))}
    </div>
  );
}

export default ChartTooltip;

/**
 * @generated SignedSource<<53c37cc727ee6a03045dc7524be3382a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GeneralAssetFamilyInputGeneralAssetFamiliesQuery$variables = {};
export type GeneralAssetFamilyInputGeneralAssetFamiliesQueryVariables = GeneralAssetFamilyInputGeneralAssetFamiliesQuery$variables;
export type GeneralAssetFamilyInputGeneralAssetFamiliesQuery$data = {
  readonly generalAssetFamilies: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly manufacturer: {
          readonly id: string;
        };
      };
    }>;
  };
};
export type GeneralAssetFamilyInputGeneralAssetFamiliesQueryResponse = GeneralAssetFamilyInputGeneralAssetFamiliesQuery$data;
export type GeneralAssetFamilyInputGeneralAssetFamiliesQuery = {
  variables: GeneralAssetFamilyInputGeneralAssetFamiliesQueryVariables;
  response: GeneralAssetFamilyInputGeneralAssetFamiliesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GeneralAssetFamilyInputGeneralAssetFamiliesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GeneralAssetFamilyConnection",
        "kind": "LinkedField",
        "name": "generalAssetFamilies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GeneralAssetFamilyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GeneralAssetFamily",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "manufacturer",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GeneralAssetFamilyInputGeneralAssetFamiliesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GeneralAssetFamilyConnection",
        "kind": "LinkedField",
        "name": "generalAssetFamilies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GeneralAssetFamilyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GeneralAssetFamily",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "manufacturer",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a98167631cd2e4845a11845d9a74231a",
    "id": null,
    "metadata": {},
    "name": "GeneralAssetFamilyInputGeneralAssetFamiliesQuery",
    "operationKind": "query",
    "text": "query GeneralAssetFamilyInputGeneralAssetFamiliesQuery {\n  generalAssetFamilies {\n    edges {\n      node {\n        id\n        name\n        manufacturer {\n          __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2042e1fd096125fcfd12d0f56b8bbab1";

export default node;

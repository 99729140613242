import React from 'react';
import styled from 'styled-components';

import ProgressBar from '../../ProgressBar';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  value: number;
  referenceValue: number;
};

function ProgressCell({value, referenceValue, ...props}: Props) {
  return (
    <CellContainer {...props}>
      <ProgressBar value={value} referenceValue={referenceValue} />
    </CellContainer>
  );
}

const CellContainer = styled.div``;

export default ProgressCell;

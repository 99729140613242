/**
 * @generated SignedSource<<e02696ee5b414c9c28bfdb0fd99adf9a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type listPageMeasurementTemplatesQuery$variables = {
  first: number;
};
export type listPageMeasurementTemplatesQueryVariables = listPageMeasurementTemplatesQuery$variables;
export type listPageMeasurementTemplatesQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MeasurementTemplatesTable_measurementTemplates">;
};
export type listPageMeasurementTemplatesQueryResponse = listPageMeasurementTemplatesQuery$data;
export type listPageMeasurementTemplatesQuery = {
  variables: listPageMeasurementTemplatesQueryVariables;
  response: listPageMeasurementTemplatesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "listPageMeasurementTemplatesQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "MeasurementTemplatesTable_measurementTemplates"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "listPageMeasurementTemplatesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MeasurementTemplateConnection",
        "kind": "LinkedField",
        "name": "measurementTemplates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MeasurementTemplateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MeasurementTemplate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dataKind",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assetKind",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "builtIn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "orderBy",
          "name"
        ],
        "handle": "connection",
        "key": "MeasurementTemplatesList_measurementTemplates",
        "kind": "LinkedHandle",
        "name": "measurementTemplates"
      }
    ]
  },
  "params": {
    "cacheID": "fe7f7c3cb58f543b1d6f346f17a115d6",
    "id": null,
    "metadata": {},
    "name": "listPageMeasurementTemplatesQuery",
    "operationKind": "query",
    "text": "query listPageMeasurementTemplatesQuery(\n  $first: Int!\n) {\n  ...MeasurementTemplatesTable_measurementTemplates_3ASum4\n}\n\nfragment MeasurementTemplatesTable_measurementTemplates_3ASum4 on Query {\n  measurementTemplates(first: $first) {\n    totalCount\n    pageInfo {\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        id\n        createdAt\n        name\n        dataKind\n        assetKind\n        builtIn\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6f69b54ca9fffd236fde0c1c8fce19f5";

export default node;

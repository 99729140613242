/**
 * @generated SignedSource<<021e5cb60bb84fdbc3b389d7b2fd20ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeneralTab_valve$data = {
  readonly id: string;
  readonly currentMeasurements: {
    readonly " $fragmentSpreads": FragmentRefs<"MeasurementsSection_currentMeasurements">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"IdentificationSection_asset" | "HistorySection_asset" | "ManufacturingSection_asset" | "DetailsSection_valve">;
  readonly " $fragmentType": "GeneralTab_valve";
};
export type GeneralTab_valve = GeneralTab_valve$data;
export type GeneralTab_valve$key = {
  readonly " $data"?: GeneralTab_valve$data;
  readonly " $fragmentSpreads": FragmentRefs<"GeneralTab_valve">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GeneralTab_valve",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IdentificationSection_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HistorySection_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ManufacturingSection_asset"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DetailsSection_valve"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MeasurementConnection",
      "kind": "LinkedField",
      "name": "currentMeasurements",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MeasurementsSection_currentMeasurements"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Valve",
  "abstractKey": null
};

(node as any).hash = "dc85819a4cb5cbf94609dc00f232c7dd";

export default node;

import {RequestParameters, Variables} from 'relay-runtime';

import {
  getAuthorizationHeader,
  getRequestBody,
  updateTokens,
  signatureHasExpired,
  isUnauthorized,
  clearTokens,
  isServerError,
} from './helpers';

export async function fetchQuery(request: RequestParameters, variables: Variables): Promise<any> {
  try {
    const authorizationHeader = getAuthorizationHeader();

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...authorizationHeader,
    };

    const body = getRequestBody(request, variables);

    const url = `${process.env.REACT_APP_API_URL}/graphql`;

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body,
    });

    const data = await response.json();

    if (data.errors) {
      if (signatureHasExpired(data.errors)) {
        await updateTokens();
        return fetchQuery(request, variables);
      }

      if (isUnauthorized(data.errors)) {
        clearTokens();
      }

      throw data.errors;
    }

    return data;
  } catch (error) {
    console.log('error: ', error);

    if (isServerError(error)) {
      clearTokens();
    }

    throw new Error(JSON.stringify(error));
  }
}

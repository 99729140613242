import {IconLayer} from '@deck.gl/layers';
import assetIconSet from 'src/assets/icons/assets_sprite_map.png';

type Props = {
  data: any;
  onIconClick: (onClickData: any) => void;
};

export function renderIconLayer(props: Props) {
  const {data, onIconClick} = props;

  const layer = new IconLayer({
    data,
    getIcon: (item: any) => item.properties.icon,
    getPosition: (item: any) => item.geometry.coordinates,
    getSize: () => 50,
    iconAtlas: assetIconSet,
    iconMapping: ICON_MAPPING,
    id: 'icon-layer',
    onClick: onIconClick,
    pickable: true,
    sizeMaxPixels: 70,
    sizeMinPixels: 20,
    sizeScale: 1,
    sizeUnits: 'meters',
  });

  return [layer];
}

const ICON_MAPPING = {
  hydrant_due_soon: {x: 0, y: 0, width: 128, height: 128},
  hydrant_maintained: {x: 128, y: 0, width: 128, height: 128},
  hydrant_out_of_service: {x: 256, y: 0, width: 128, height: 128},
  hydrant_overdue: {x: 384, y: 0, width: 128, height: 128},
  valve_due_soon: {x: 512, y: 0, width: 128, height: 128},
  valve_maintained: {x: 640, y: 0, width: 128, height: 128},
  valve_out_of_service: {x: 768, y: 0, width: 128, height: 128},
  valve_overdue: {x: 896, y: 0, width: 128, height: 128},
  general_asset_due_soon: {x: 1024, y: 0, width: 128, height: 128},
  general_asset_maintained: {x: 1152, y: 0, width: 128, height: 128},
  general_asset_out_of_service: {x: 1280, y: 0, width: 128, height: 128},
  general_asset_overdue: {x: 1408, y: 0, width: 128, height: 128},
};

/**
 * @generated SignedSource<<49ec9d4bcda3202845c77cccea42d976>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type CreateValveManufacturerInput = {
  name: string;
  clientMutationId?: string | null;
};
export type createPageCreateValveManufacturerMutation$variables = {
  input: CreateValveManufacturerInput;
};
export type createPageCreateValveManufacturerMutationVariables = createPageCreateValveManufacturerMutation$variables;
export type createPageCreateValveManufacturerMutation$data = {
  readonly createValveManufacturer: {
    readonly valveManufacturer: {
      readonly id: string;
      readonly createdAt: any;
      readonly name: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly fields: ReadonlyArray<{
        readonly fieldName: string;
        readonly message: string;
      }> | null;
    }>;
  } | null;
};
export type createPageCreateValveManufacturerMutationResponse = createPageCreateValveManufacturerMutation$data;
export type createPageCreateValveManufacturerMutation = {
  variables: createPageCreateValveManufacturerMutationVariables;
  response: createPageCreateValveManufacturerMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateValveManufacturerPayload",
    "kind": "LinkedField",
    "name": "createValveManufacturer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ValveManufacturer",
        "kind": "LinkedField",
        "name": "valveManufacturer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFieldError",
            "kind": "LinkedField",
            "name": "fields",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fieldName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createPageCreateValveManufacturerMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createPageCreateValveManufacturerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c6dc1583fcb36f6a1e2b1533bbdf4281",
    "id": null,
    "metadata": {},
    "name": "createPageCreateValveManufacturerMutation",
    "operationKind": "mutation",
    "text": "mutation createPageCreateValveManufacturerMutation(\n  $input: CreateValveManufacturerInput!\n) {\n  createValveManufacturer(input: $input) {\n    valveManufacturer {\n      id\n      createdAt\n      name\n    }\n    errors {\n      code\n      fields {\n        fieldName\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6494191bf2f7b2e7fe97bba997466993";

export default node;

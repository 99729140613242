import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {usePaginationFragment} from 'react-relay/hooks';

import {ImagesTabImageRecordsQuery} from './__generated__/ImagesTabImageRecordsQuery.graphql';
import {ImagesTabImagesList_imageRecords$key} from './__generated__/ImagesTabImagesList_imageRecords.graphql';
import ImageItem from './ImageItem';

type Props = {
  imagesList: ImagesTabImagesList_imageRecords$key;
  onImageClick: (imageUrl: string) => void;
};

export default function ImagesTab(props: Props) {
  const {data, hasNext, loadNext, isLoadingNext} = usePaginationFragment<
    ImagesTabImageRecordsQuery,
    ImagesTabImagesList_imageRecords$key
  >(
    graphql`
      fragment ImagesTabImagesList_imageRecords on Query
        @argumentDefinitions(first: {type: "Int!"}, after: {type: "String"})
        @refetchable(queryName: "ImageRecordListPaginationQuery") {
        imageRecordsByAsset(assetId: $assetId, first: $first, after: $after)
          @connection(key: "ImageRecordList_imageRecordsByAsset") {
          edges {
            node {
              id
              ...ImageItem_imageRecord
            }
          }
        }
      }
    `,
    props.imagesList,
  );

  if (data.imageRecordsByAsset && !data.imageRecordsByAsset.edges.length) {
    return (
      <div className="flex flex-grow pt-40 justify-center text-gray-600">
        No images have been added for this asset.
      </div>
    );
  }

  return (
    <div className="flex flex-wrap">
      {data.imageRecordsByAsset.edges.map(imageRecordEdge => {
        return (
          <ImageItem
            onImageClick={props.onImageClick}
            key={imageRecordEdge.node.id}
            record={imageRecordEdge.node}
          />
        );
      })}

      {hasNext && (
        <div className="w-56 m-4">
          <div className="cursor-pointer w-56 h-56" />
          <div
            onClick={() => loadNext(10)}
            className="px-4 flex justify-center py-3 bg-blue-800 cursor-pointer hover:bg-blue-600 text-white font-semibold">
            {isLoadingNext ? 'LOADING' : 'LOAD MORE'}
          </div>
        </div>
      )}
    </div>
  );
}

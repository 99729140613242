/**
 * @generated SignedSource<<a9e18e870df99f5f9dbca46a8e61051b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type UpdateHydrantManufacturerInput = {
  hydrantManufacturerId: string;
  updateHydrantManufacturerData: UpdateHydrantManufacturerData;
  clientMutationId?: string | null;
};
export type UpdateHydrantManufacturerData = {
  name?: string | null;
};
export type updatePageUpdateHydrantManufacturerMutation$variables = {
  input: UpdateHydrantManufacturerInput;
};
export type updatePageUpdateHydrantManufacturerMutationVariables = updatePageUpdateHydrantManufacturerMutation$variables;
export type updatePageUpdateHydrantManufacturerMutation$data = {
  readonly updateHydrantManufacturer: {
    readonly hydrantManufacturer: {
      readonly id: string;
      readonly createdAt: any;
      readonly name: string;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly fields: ReadonlyArray<{
        readonly fieldName: string;
        readonly message: string;
      }> | null;
    }>;
  } | null;
};
export type updatePageUpdateHydrantManufacturerMutationResponse = updatePageUpdateHydrantManufacturerMutation$data;
export type updatePageUpdateHydrantManufacturerMutation = {
  variables: updatePageUpdateHydrantManufacturerMutationVariables;
  response: updatePageUpdateHydrantManufacturerMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateHydrantManufacturerPayload",
    "kind": "LinkedField",
    "name": "updateHydrantManufacturer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "HydrantManufacturer",
        "kind": "LinkedField",
        "name": "hydrantManufacturer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFieldError",
            "kind": "LinkedField",
            "name": "fields",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fieldName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageUpdateHydrantManufacturerMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageUpdateHydrantManufacturerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "55e4529fb006d841cd34b3ed36155a8e",
    "id": null,
    "metadata": {},
    "name": "updatePageUpdateHydrantManufacturerMutation",
    "operationKind": "mutation",
    "text": "mutation updatePageUpdateHydrantManufacturerMutation(\n  $input: UpdateHydrantManufacturerInput!\n) {\n  updateHydrantManufacturer(input: $input) {\n    hydrantManufacturer {\n      id\n      createdAt\n      name\n    }\n    errors {\n      code\n      fields {\n        fieldName\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "23eb61546dda17e8405ba533edefae3d";

export default node;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';

import {ImagesTabImageRecordsQuery} from './__generated__/ImagesTabImageRecordsQuery.graphql';
import ImagesTabImagesList from './ImagesTabImagesList';

type Props = {
  assetId: string;
  onImageClick: (imageUrl: string) => void;
};

export default function ImagesTab({assetId, onImageClick}: Props) {
  const data = useLazyLoadQuery<ImagesTabImageRecordsQuery>(
    graphql`
      query ImagesTabImageRecordsQuery($assetId: ID!) {
        ...ImagesTabImagesList_imageRecords @arguments(first: 10)
      }
    `,
    {assetId},
    {fetchPolicy: 'store-and-network'},
  );

  return <ImagesTabImagesList onImageClick={onImageClick} imagesList={data} />;
}

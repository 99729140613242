import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import {organizationSettingsMutation} from './__generated__/organizationSettingsMutation.graphql';
import {organizationSettingsQuery} from './__generated__/organizationSettingsQuery.graphql';
import Form from './components/Form';
import {FormValues} from './types';

function OrganizationSettings() {
  const [commit, isInFlight] = useMutation<organizationSettingsMutation>(mutation);
  const data = useLazyLoadQuery<organizationSettingsQuery>(
    query,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  if (!data.currentOrganization) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">
          The organization you wish to update could not be found.
        </h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      title="Settings"
      button="Update Organization"
      organization={data.currentOrganization}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleInitialValues() {
    if (!data || !data.currentOrganization) {
      return;
    }

    const organization = data.currentOrganization;

    return {
      email: organization.email,
      city: organization.city,
      state: organization.state,
      organizationConfigAttributes: organization.organizationConfig,
    };
  }

  function handleSubmit(values: FormValues) {
    commit({
      variables: {
        input: {
          organizationId: data.currentOrganization.id,
          updateOrganizationData: values,
        },
      },
      onCompleted: payload => {
        if (payload.updateOrganization?.organization) {
          showEventNotification('updated', 'Your organization');
        }
      },
    });
  }
}

const query = graphql`
  query organizationSettingsQuery {
    currentOrganization {
      id
      createdAt
      name
      city
      email
      state
      assetsUsage {
        typeA
        typeB
        plan {
          typeAAssetsLimit
          typeBAssetsLimit
        }
      }
      currentPlan {
        typeAAssetsLimit
        typeBAssetsLimit
      }
      organizationConfig {
        daysBeforeDueSoon
        daysBeforeOverdue
      }
    }
  }
`;

const mutation = graphql`
  mutation organizationSettingsMutation($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
        createdAt
        name
        email
        city
        state
        organizationConfig {
          daysBeforeDueSoon
          daysBeforeOverdue
        }
      }
      errors {
        code
      }
    }
  }
`;

export default withSuspense(OrganizationSettings);

/**
 * @generated SignedSource<<f4724e1aca43fd37e5e19bc762940f94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type UserRole = "project_admin" | "organization_admin" | "foreman" | "project_manager" | "superintendent" | "other" | "dispatcher" | "operations_manager" | "technician" | "%future added value";
export type UpdateOrganizationUserInput = {
  organizationUserId: string;
  updateOrganizationUserData: UpdateOrganizationUserData;
  clientMutationId?: string | null;
};
export type UpdateOrganizationUserData = {
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  password?: string | null;
  phone?: string | null;
  role?: UserRole | null;
};
export type updatePageUpdateUserMutation$variables = {
  input: UpdateOrganizationUserInput;
};
export type updatePageUpdateUserMutationVariables = updatePageUpdateUserMutation$variables;
export type updatePageUpdateUserMutation$data = {
  readonly updateOrganizationUser: {
    readonly organizationUser: {
      readonly id: string;
      readonly createdAt: any;
      readonly firstName: string;
      readonly lastName: string;
      readonly phone: string;
      readonly email: string;
      readonly role: UserRole;
    } | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
      readonly fields: ReadonlyArray<{
        readonly fieldName: string;
        readonly message: string;
      }> | null;
    }>;
  } | null;
};
export type updatePageUpdateUserMutationResponse = updatePageUpdateUserMutation$data;
export type updatePageUpdateUserMutation = {
  variables: updatePageUpdateUserMutationVariables;
  response: updatePageUpdateUserMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateOrganizationUserPayload",
    "kind": "LinkedField",
    "name": "updateOrganizationUser",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationUser",
        "kind": "LinkedField",
        "name": "organizationUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserFieldError",
            "kind": "LinkedField",
            "name": "fields",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fieldName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageUpdateUserMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageUpdateUserMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "990d54ed102c81b4711771fa3044214b",
    "id": null,
    "metadata": {},
    "name": "updatePageUpdateUserMutation",
    "operationKind": "mutation",
    "text": "mutation updatePageUpdateUserMutation(\n  $input: UpdateOrganizationUserInput!\n) {\n  updateOrganizationUser(input: $input) {\n    organizationUser {\n      id\n      createdAt\n      firstName\n      lastName\n      phone\n      email\n      role\n    }\n    errors {\n      code\n      fields {\n        fieldName\n        message\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "86a2ae59648eeba7bdf9fa6f53ec4c8f";

export default node;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';

import {HydrantFamilyInputHydrantFamiliesQuery} from './__generated__/HydrantFamilyInputHydrantFamiliesQuery.graphql';
import DropdownInput from './DropdownInput';

type Props = {
  value: string;
  manufacturerId: string;
  onChange: (value: string) => void;
  error?: string;
};

function HydrantFamilyInput(props: Props) {
  const {value, manufacturerId, onChange, error} = props;

  const data = useLazyLoadQuery<HydrantFamilyInputHydrantFamiliesQuery>(
    hydrantFamiliesQuery,
    {manufacturerId: props.manufacturerId},
    {fetchPolicy: 'store-and-network'},
  );

  const options =
    !!data && manufacturerId
      ? data.hydrantFamilies.edges
          .filter(hydrantFamilyEdge => hydrantFamilyEdge.node.manufacturer.id === manufacturerId)
          .map(hydrantFamilyEdge => ({
            label: hydrantFamilyEdge.node.name,
            value: hydrantFamilyEdge.node.id,
          }))
      : [];

  return (
    <DropdownInput
      name="hydrantFamilyInput"
      onChange={onChange}
      options={options}
      placeholder="E.g. TX-1"
      label="Model"
      value={value}
      error={error}
    />
  );
}

const hydrantFamiliesQuery = graphql`
  query HydrantFamilyInputHydrantFamiliesQuery {
    hydrantFamilies {
      edges {
        node {
          id
          name
          manufacturer {
            id
          }
        }
      }
    }
  }
`;

export default HydrantFamilyInput;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {usePerPage} from 'src/hooks/usePerPage';
import {withSuspense} from 'src/utils/withSuspense';

import {listPageGeneralAssetFamiliesQuery} from './__generated__/listPageGeneralAssetFamiliesQuery.graphql';
import GeneralAssetFamiliesTable from './GeneralAssetFamiliesTable';

function ListPage() {
  const {perPage} = usePerPage();
  const data = useLazyLoadQuery<listPageGeneralAssetFamiliesQuery>(
    generalAssetFamilies,
    {first: perPage * 2},
    {fetchPolicy: 'store-and-network'},
  );

  return <GeneralAssetFamiliesTable generalAssetFamilies={data} />;
}

export default withSuspense(ListPage);

const generalAssetFamilies = graphql`
  query listPageGeneralAssetFamiliesQuery($first: Int!) {
    ...GeneralAssetFamiliesTable_generalAssetFamilies @arguments(first: $first)
  }
`;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';

import {HydrantManufacturerInputHydrantManufacturersQuery} from './__generated__/HydrantManufacturerInputHydrantManufacturersQuery.graphql';
import DropdownInput from './DropdownInput';

type Props = {
  value: string;
  onChange: (value: string) => void;
  error?: string;
};

function HydrantManufacturerInput(props: Props) {
  const data = useLazyLoadQuery<HydrantManufacturerInputHydrantManufacturersQuery>(
    hydrantManufacturersQuery,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  const {error, value, onChange} = props;

  const options = !!data
    ? data.hydrantManufacturers.edges.map(hydrantManufacturerEdge => ({
        label: hydrantManufacturerEdge.node.name,
        value: hydrantManufacturerEdge.node.id,
      }))
    : [];

  return (
    <DropdownInput
      name="hydrantManufacturerInput"
      onChange={onChange}
      options={options}
      placeholder="E.g. Kennedy"
      label="Manufacturer"
      value={value}
      error={error}
    />
  );
}

const hydrantManufacturersQuery = graphql`
  query HydrantManufacturerInputHydrantManufacturersQuery {
    hydrantManufacturers {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default HydrantManufacturerInput;

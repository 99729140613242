/**
 * @generated SignedSource<<e3d855f204eb3b86d51ebbc7f9df1dba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ImageRecordItem_imageRecord$data = {
  readonly id: string;
  readonly createdAt: any;
  readonly author: {
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
  };
  readonly imageUrl: string;
  readonly " $fragmentType": "ImageRecordItem_imageRecord";
};
export type ImageRecordItem_imageRecord = ImageRecordItem_imageRecord$data;
export type ImageRecordItem_imageRecord$key = {
  readonly " $data"?: ImageRecordItem_imageRecord$data;
  readonly " $fragmentSpreads": FragmentRefs<"ImageRecordItem_imageRecord">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ImageRecordItem_imageRecord",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OrganizationUser",
      "kind": "LinkedField",
      "name": "author",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "firstName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    }
  ],
  "type": "ImageRecord",
  "abstractKey": null
};
})();

(node as any).hash = "d1a6331bae60aea0c58cb781d2b2f807";

export default node;

/**
 * @generated SignedSource<<5bc5abe3dc3d015c28c0eece11bc733f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type updatePageGeneralAssetModelQuery$variables = {
  generalAssetModelId: string;
};
export type updatePageGeneralAssetModelQueryVariables = updatePageGeneralAssetModelQuery$variables;
export type updatePageGeneralAssetModelQuery$data = {
  readonly generalAssetModel: {
    readonly id: string;
    readonly createdAt: any;
    readonly name: string;
    readonly family: {
      readonly id: string;
      readonly name: string;
    };
    readonly manufacturer: {
      readonly id: string;
      readonly name: string;
    };
  } | null;
};
export type updatePageGeneralAssetModelQueryResponse = updatePageGeneralAssetModelQuery$data;
export type updatePageGeneralAssetModelQuery = {
  variables: updatePageGeneralAssetModelQueryVariables;
  response: updatePageGeneralAssetModelQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "generalAssetModelId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "generalAssetModelId",
    "variableName": "generalAssetModelId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  (v2/*: any*/),
  (v4/*: any*/)
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v2/*: any*/),
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageGeneralAssetModelQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GeneralAssetModel",
        "kind": "LinkedField",
        "name": "generalAssetModel",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "family",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "manufacturer",
            "plural": false,
            "selections": (v5/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageGeneralAssetModelQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GeneralAssetModel",
        "kind": "LinkedField",
        "name": "generalAssetModel",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "family",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "manufacturer",
            "plural": false,
            "selections": (v6/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e24c585cf930f3e9b4e67f74f4a4d940",
    "id": null,
    "metadata": {},
    "name": "updatePageGeneralAssetModelQuery",
    "operationKind": "query",
    "text": "query updatePageGeneralAssetModelQuery(\n  $generalAssetModelId: ID!\n) {\n  generalAssetModel(generalAssetModelId: $generalAssetModelId) {\n    id\n    createdAt\n    name\n    family {\n      __typename\n      id\n      name\n    }\n    manufacturer {\n      __typename\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "acfebc5fff83d19f98dd7b8adcc6bc69";

export default node;

/**
 * @generated SignedSource<<2f900ed6d0638c863d4b31f13b932d82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserErrorCode = "INVALID_CREDENTIALS" | "INVALID_ARGUMENTS" | "UNAUTHORIZED_ACCESS" | "CONFLICTING_ORGANIZATIONS" | "RECORD_NOT_FOUND" | "%future added value";
export type RequestPasswordResetInput = {
  email: string;
  clientMutationId?: string | null;
};
export type requestPasswordResetPageRequestPasswordResetMutation$variables = {
  input: RequestPasswordResetInput;
};
export type requestPasswordResetPageRequestPasswordResetMutationVariables = requestPasswordResetPageRequestPasswordResetMutation$variables;
export type requestPasswordResetPageRequestPasswordResetMutation$data = {
  readonly requestPasswordReset: {
    readonly sent: boolean | null;
    readonly errors: ReadonlyArray<{
      readonly code: UserErrorCode;
    }>;
  } | null;
};
export type requestPasswordResetPageRequestPasswordResetMutationResponse = requestPasswordResetPageRequestPasswordResetMutation$data;
export type requestPasswordResetPageRequestPasswordResetMutation = {
  variables: requestPasswordResetPageRequestPasswordResetMutationVariables;
  response: requestPasswordResetPageRequestPasswordResetMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RequestPasswordResetPayload",
    "kind": "LinkedField",
    "name": "requestPasswordReset",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sent",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "requestPasswordResetPageRequestPasswordResetMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "requestPasswordResetPageRequestPasswordResetMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8737558b395ce9a0a5199eefbb9b4376",
    "id": null,
    "metadata": {},
    "name": "requestPasswordResetPageRequestPasswordResetMutation",
    "operationKind": "mutation",
    "text": "mutation requestPasswordResetPageRequestPasswordResetMutation(\n  $input: RequestPasswordResetInput!\n) {\n  requestPasswordReset(input: $input) {\n    sent\n    errors {\n      code\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "830dbfbdc93f4aefaa20bcb5ddc0f2a0";

export default node;

/**
 * @generated SignedSource<<277ceaee2c93403446ed4aea4782a2a8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MeasurementTemplatesOrderField = "DATA_KIND" | "ASSET_KIND" | "CREATED_AT" | "NAME" | "%future added value";
export type OrderDirection = "ASC" | "DESC" | "%future added value";
export type MeasurementTemplatesOrder = {
  direction: OrderDirection;
  field: MeasurementTemplatesOrderField;
};
export type MeasurementTemplatesPaginationQuery$variables = {
  after?: string | null;
  first: number;
  name?: string | null;
  orderBy?: MeasurementTemplatesOrder | null;
};
export type MeasurementTemplatesPaginationQueryVariables = MeasurementTemplatesPaginationQuery$variables;
export type MeasurementTemplatesPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"MeasurementTemplatesTable_measurementTemplates">;
};
export type MeasurementTemplatesPaginationQueryResponse = MeasurementTemplatesPaginationQuery$data;
export type MeasurementTemplatesPaginationQuery = {
  variables: MeasurementTemplatesPaginationQueryVariables;
  response: MeasurementTemplatesPaginationQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderBy"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  },
  {
    "kind": "Variable",
    "name": "orderBy",
    "variableName": "orderBy"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MeasurementTemplatesPaginationQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "MeasurementTemplatesTable_measurementTemplates"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MeasurementTemplatesPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "MeasurementTemplateConnection",
        "kind": "LinkedField",
        "name": "measurementTemplates",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasPreviousPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "MeasurementTemplateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MeasurementTemplate",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dataKind",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assetKind",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "builtIn",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "orderBy",
          "name"
        ],
        "handle": "connection",
        "key": "MeasurementTemplatesList_measurementTemplates",
        "kind": "LinkedHandle",
        "name": "measurementTemplates"
      }
    ]
  },
  "params": {
    "cacheID": "55c24f7d5fb09b44ac1d2b84fd1978cd",
    "id": null,
    "metadata": {},
    "name": "MeasurementTemplatesPaginationQuery",
    "operationKind": "query",
    "text": "query MeasurementTemplatesPaginationQuery(\n  $after: String\n  $first: Int!\n  $name: String\n  $orderBy: MeasurementTemplatesOrder\n) {\n  ...MeasurementTemplatesTable_measurementTemplates_4DjD4v\n}\n\nfragment MeasurementTemplatesTable_measurementTemplates_4DjD4v on Query {\n  measurementTemplates(first: $first, after: $after, orderBy: $orderBy, name: $name) {\n    totalCount\n    pageInfo {\n      hasPreviousPage\n      hasNextPage\n      startCursor\n      endCursor\n    }\n    edges {\n      node {\n        id\n        createdAt\n        name\n        dataKind\n        assetKind\n        builtIn\n        __typename\n      }\n      cursor\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9054f2aec6b3874c24dd8c624fac7df0";

export default node;

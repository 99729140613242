import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useFragment} from 'react-relay/hooks';
import AssetStatusLabel from 'src/components/AssetStatusLabel';

import {DetailsSection_valve$key} from './__generated__/DetailsSection_valve.graphql';

type Props = {
  valve: DetailsSection_valve$key;
};

export default function DetailsSection(props: Props) {
  const valve = useFragment(
    graphql`
      fragment DetailsSection_valve on Valve {
        id
        status
        turnsToOpenClose
        valveConnector {
          id
          name
        }
      }
    `,
    props.valve,
  );

  return (
    <div className="border-b pb-4 pt-4">
      <div className="flex">
        <div className="w-40">
          <h3 className="text-gray-700 text-sm uppercase font-semibold">Details</h3>
        </div>
        <div>
          <div className="flex items-end mb-2">
            <div className="w-40 flex justify-start mr-1">
              <h3 className="text-gray-600 text-sm">Status</h3>
            </div>
            <AssetStatusLabel status={valve.status} />
          </div>

          {valve.turnsToOpenClose && (
            <div className="flex items-end mb-2">
              <div className="w-40 flex justify-start mr-1">
                <h3 className="text-gray-600 text-sm">Turns to Open / Close</h3>
              </div>
              <h3 className="text-gray-800 leading-snug text-base">{valve.turnsToOpenClose}</h3>
            </div>
          )}

          <div className="flex items-end mb-2">
            <div className="w-40 flex justify-start mr-1">
              <h3 className="text-gray-600 text-sm">Connection Type</h3>
            </div>
            <h3 className="text-gray-800 leading-snug text-base">{valve.valveConnector.name}</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

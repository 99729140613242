import {useFormik, FormikHelpers} from 'formik';
import React from 'react';
import StateInput from 'src/components/inputs/StateInput';
import {
  DEFAULT_DAYS_BEFORE_DUE_SOON,
  DEFAULT_DAYS_BEFORE_OVERDUE,
} from 'src/constants/organization';
import * as Yup from 'yup';

import TextInput from '../../../components/inputs/TextInput';
import {UpdateFormValues} from '../types';

type Props = {
  initialValues?: UpdateFormValues;
  onSubmit: (values: UpdateFormValues, formikHelpers: FormikHelpers<UpdateFormValues>) => void;
  title: string;
  button: string;
  isSubmitting: boolean;
};

function Form(props: Props) {
  const {isSubmitting, initialValues, title, button, onSubmit} = props;

  const {submitCount, handleSubmit, errors, setFieldValue, values, isValid} = useFormik<
    UpdateFormValues
  >({
    initialValues: Object.assign(
      {},
      {
        name: '',
        email: '',
        city: '',
        state: '',
        organizationConfigAttributes: {
          daysBeforeDueSoon: DEFAULT_DAYS_BEFORE_DUE_SOON,
          daysBeforeOverdue: DEFAULT_DAYS_BEFORE_OVERDUE,
        },
      },
      initialValues,
    ),
    isInitialValid: false,
    enableReinitialize: true,
    onSubmit,
    validationSchema,
  });

  return (
    <div className="p-8">
      <div className="mb-6 pb-1 border-b-2 border-gray-400">
        <h2 className="text-2xl text-gray-800 uppercase font-semibold">{title}</h2>
      </div>

      <TextInput
        name="name"
        label="Name"
        placeholder="E.g. Maple Falls Waterworks"
        handleChange={value => setFieldValue('name', value)}
        value={values.name}
        error={!!submitCount && !!errors.name ? errors.name : ''}
      />

      <TextInput
        name="email"
        label="Email"
        placeholder="E.g. waterworks@maplefalls.gov"
        handleChange={value => setFieldValue('email', value)}
        value={values.email}
        error={!!submitCount && !!errors.email ? errors.email : ''}
      />

      <TextInput
        name="city"
        label="City"
        placeholder="E.g. Maple Falls"
        handleChange={value => setFieldValue('city', value)}
        value={values.city}
        error={!!submitCount && !!errors.city ? errors.city : ''}
      />

      <StateInput value={values.state} onChange={value => setFieldValue('state', value)} />

      <TextInput
        name="organizationConfigAttributes.daysBeforeDueSoon"
        type="number"
        label="# Days before due soon"
        placeholder={String(DEFAULT_DAYS_BEFORE_DUE_SOON)}
        handleChange={value =>
          setFieldValue('organizationConfigAttributes.daysBeforeDueSoon', parseInt(value))
        }
        value={String(values.organizationConfigAttributes?.daysBeforeDueSoon)}
      />

      <TextInput
        name="organizationConfigAttributes.daysBeforeOverdue"
        type="number"
        label="# Days before Overdue"
        placeholder={String(DEFAULT_DAYS_BEFORE_OVERDUE)}
        handleChange={value =>
          setFieldValue('organizationConfigAttributes.daysBeforeOverdue', parseInt(value))
        }
        value={String(values.organizationConfigAttributes?.daysBeforeOverdue)}
      />

      <button
        disabled={isSubmitting}
        onClick={() => handleSubmit()}
        className={
          'px-5 py-2 text-sm rounded font-semibold text-white uppercase ' +
          (isValid ? 'bg-gray-700' : 'bg-gray-400')
        }>
        {button}
      </button>
    </div>
  );
}

const validationSchema = Yup.object({
  name: Yup.string().required('Please provide a manufacturer name.'),
});

export default Form;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {usePerPage} from 'src/hooks/usePerPage';
import {withSuspense} from 'src/utils/withSuspense';

import {listPageTaskTemplatesQuery} from './__generated__/listPageTaskTemplatesQuery.graphql';
import TaskTemplatesTable from './TaskTemplatesTable';

function ListPage() {
  const {perPage} = usePerPage();
  const data = useLazyLoadQuery<listPageTaskTemplatesQuery>(
    taskTemplates,
    {first: perPage * 2},
    {fetchPolicy: 'store-and-network'},
  );

  return <TaskTemplatesTable taskTemplates={data} />;
}

export default withSuspense(ListPage);

const taskTemplates = graphql`
  query listPageTaskTemplatesQuery($first: Int!) {
    ...TaskTemplatesTable_taskTemplates @arguments(first: $first)
  }
`;

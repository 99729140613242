import React from 'react';
import {Link} from 'react-router-dom';

import AssetTypeInput from '../../components/inputs/AssetKindInput';
import AssetStatusInput from '../../components/inputs/AssetStatusInput';

type Props = {
  markerCoordinates: {latitude: number; longitude: number};
  assetInputState: boolean;
  setAssetInputState: (assetInputState: boolean) => void;
  applyFilter: (filters: {status: string | null; kind: string | null; text: string | null}) => void;
};

function Filters(props: Props) {
  const {assetInputState, setAssetInputState, markerCoordinates} = props;

  const [textQuery, setTextQuery] = React.useState<string>('');
  const [assetStatus, setAssetStatus] = React.useState<string>('ALL');
  const [assetType, setAssetType] = React.useState<string>('ALL');

  const locationQueryString =
    assetInputState && markerCoordinates
      ? `?latitude=${markerCoordinates.latitude}&longitude=${markerCoordinates.longitude}`
      : '';

  const hydrantCreateLink = `/admin/hydrants/create${locationQueryString}`;
  const valveCreateLink = `/admin/valves/create${locationQueryString}`;
  const generalAssetCreateLink = `/admin/general_assets/create${locationQueryString}`;

  return (
    <div className="h-full w-64 p-4">
      <div className="mb-4">
        <p className="uppercase text-gray-800 text-xs font-bold mb-2">Quick Access</p>

        <button className="btn text-sm text-white w-56 shadow mb-2 bg-blue-800 hover:bg-blue-900 focus:outline-none ">
          <Link to={hydrantCreateLink}>CREATE HYDRANT</Link>
        </button>
        <button className="btn text-sm text-white w-56 shadow mb-2 bg-blue-800 hover:bg-blue-900 focus:outline-none ">
          <Link to={valveCreateLink}>CREATE VALVE</Link>
        </button>
        <button className="btn text-sm text-white w-56 shadow mb-2 bg-blue-800 hover:bg-blue-900 focus:outline-none ">
          <Link to={generalAssetCreateLink}>CREATE GENERAL ASSET</Link>
        </button>
      </div>

      <div className="mb-4">
        <label
          className="block uppercase text-gray-800 text-xs font-bold mb-2"
          htmlFor="textFields">
          Search Text Fields
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="textFields"
          type="text"
          placeholder="Serial, model, type, ..."
          onChange={event => setTextQuery(event.target.value)}
          value={textQuery}
        />
      </div>

      <AssetTypeInput isFilter value={assetType} onChange={setAssetType} />

      <AssetStatusInput isFilter value={assetStatus} onChange={setAssetStatus} />

      <div className="flex flex-col">
        <button
          onClick={applyFilters}
          className="btn text-sm mb-2 text-white bg-gray-700 hover:bg-gray-800 focus:outline-none">
          FILTER
        </button>

        <button
          onClick={resetFilters}
          className="btn text-sm text-gray-600 border border-gray-500 hover:border-gray-700 hover:text-gray-800 focus:outline-none">
          RESET
        </button>
      </div>
      <div className="flex flex-col mt-4 pt-4 border-t">
        <button
          onClick={() => setAssetInputState(!assetInputState)}
          className={`btn text-sm border border-red-500 focus:outline-none ${
            assetInputState
              ? 'bg-red-500 text-white'
              : 'text-red-500 hover:border-red-700 hover:text-red-800'
          }`}>
          {assetInputState ? 'CANCEL LOCATION INPUT' : 'SHOW LOCATION INPUT'}
        </button>
      </div>
    </div>
  );

  function applyFilters() {
    const status = assetStatus === 'ALL' ? null : assetStatus;
    const kind = assetType === 'ALL' ? null : assetType;
    const text = textQuery ? textQuery : null;

    props.applyFilter({status, kind, text});
  }

  function resetFilters() {
    setTextQuery('');
    setAssetStatus('ALL');
    setAssetType('ALL');

    props.applyFilter({status: null, kind: null, text: null});
  }
}

export default Filters;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';

import {ValveConnectorInputValveConnectorsQuery} from './__generated__/ValveConnectorInputValveConnectorsQuery.graphql';
import DropdownInput from './DropdownInput';

type Props = {
  value: string;
  onChange: (value: string) => void;
  error?: string;
};

function ValveConnectorInput(props: Props) {
  const data = useLazyLoadQuery<ValveConnectorInputValveConnectorsQuery>(
    valveConnectorsQuery,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  const {error, value, onChange} = props;

  const options = !!data
    ? data.valveConnectors.edges.map(valveConnectorEdge => ({
        label: valveConnectorEdge.node.name,
        value: valveConnectorEdge.node.id,
      }))
    : [];

  return (
    <DropdownInput
      name="valveConnectorInput"
      onChange={onChange}
      options={options}
      placeholder="E.g. MJxMJ"
      label="Connection"
      value={value}
      error={error}
    />
  );
}

const valveConnectorsQuery = graphql`
  query ValveConnectorInputValveConnectorsQuery {
    valveConnectors {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export default ValveConnectorInput;

import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

type Props = {
  gpm: number;
};

function GpmLabel({gpm}: Props) {
  let color;

  if (gpm >= 1500) {
    color = 'text-blue-500';
  } else if (gpm <= 1499 && gpm >= 1000) {
    color = 'text-green-500';
  } else if (gpm <= 999 && gpm >= 500) {
    color = 'text-orange-500';
  } else {
    color = 'text-red-500';
  }

  return (
    <LabelContainer>
      <StatusIndicator icon={faCircle} color={color} />
      <span className="ml-1">{gpm}</span>
    </LabelContainer>
  );
}

const StatusIndicator = styled(FontAwesomeIcon).attrs<{color: string}>(({color}) => ({
  className: 'text-xs ' + color,
}))<{color: string}>``;

const LabelContainer = styled.div.attrs({
  className: 'text-gray-800 text-base flex items-center',
})``;

export default GpmLabel;

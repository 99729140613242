import React from 'react';

import TextInput from '../../../../components/inputs/TextInput';

type Props = {
  values: any;
  errors: any;
  setFieldValue: any;
  submitCount: number;
  isCustomerInputDisabled: boolean;
};

function GeneralTab(props: Props) {
  const {values, setFieldValue, submitCount, errors} = props;

  return (
    <div>
      <TextInput
        label="Serial"
        placeholder="E.g. XS45HY78"
        value={values.serial}
        handleChange={value => setFieldValue('serial', value)}
        name="serial"
        error={!!submitCount && errors.serial ? errors.serial : ''}
      />

      <TextInput
        label="Id"
        placeholder="E.g. A0012"
        value={values.externalId}
        handleChange={value => setFieldValue('externalId', value)}
        name="externalId"
        error={!!submitCount && errors.externalId ? errors.externalId : ''}
      />

      <TextInput
        label="Water Main Diameter (inches)"
        placeholder="E.g. 13"
        value={values.hydrantWaterMainDiameter}
        handleChange={value => setFieldValue('hydrantWaterMainDiameter', value)}
        name="hydrantWaterMainDiameter"
        error={
          !!submitCount && errors.hydrantWaterMainDiameter ? errors.hydrantWaterMainDiameter : ''
        }
      />

      <TextInput
        label="Water Main Depth (feet)"
        placeholder="E.g. 34"
        value={values.hydrantWaterMainDepth}
        handleChange={value => setFieldValue('hydrantWaterMainDepth', value)}
        name="hydrantWaterMainDepth"
        error={!!submitCount && errors.hydrantWaterMainDepth ? errors.hydrantWaterMainDepth : ''}
      />

      <TextInput
        label="Gallons Per Minute"
        placeholder="E.g. 1200"
        value={values.hydrantGallonsPerMinute}
        handleChange={value => setFieldValue('hydrantGallonsPerMinute', value)}
        name="hydrantGallonsPerMinute"
        error={
          !!submitCount && errors.hydrantGallonsPerMinute ? errors.hydrantGallonsPerMinute : ''
        }
      />

      <TextInput
        label="Turns To open / close"
        placeholder="E.g. 3"
        value={values.hydrantTurnsToOpenClose}
        handleChange={value => setFieldValue('hydrantTurnsToOpenClose', value)}
        name="hydrantTurnsToOpenClose"
        error={
          !!submitCount && errors.hydrantTurnsToOpenClose ? errors.hydrantTurnsToOpenClose : ''
        }
      />

      <TextInput
        label="Notes (Optional)"
        placeholder="E.g. The top nut is rusted."
        value={values.notes}
        handleChange={value => setFieldValue('notes', value)}
        name="notes"
      />
    </div>
  );
}

export default GeneralTab;

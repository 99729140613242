import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useHistory, useParams} from 'react-router';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/UpdateForm';
import {UpdateFormValues} from '../types';
import {updatePageOrganizationQuery} from './__generated__/updatePageOrganizationQuery.graphql';
import {updatePageUpdateOrganizationAsProjectAdminMutation} from './__generated__/updatePageUpdateOrganizationAsProjectAdminMutation.graphql';

function UpdatePage() {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [commit, isInFlight] = useMutation<updatePageUpdateOrganizationAsProjectAdminMutation>(
    updateOrganizationAsProjectAdminMutation,
  );

  const data = useLazyLoadQuery<updatePageOrganizationQuery>(query, {
    organizationId: id,
  });

  if (!data.organization) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">The item you wish to update could not be found.</h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      title="Update Organization"
      button="Update Organization"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleInitialValues() {
    if (!data || !data.organization) {
      return;
    }

    const {organization} = data;

    return {
      name: organization.name,
      email: organization.email,
      city: organization.city,
      state: organization.state,
      organizationConfigAttributes: organization.organizationConfig,
    };
  }

  function handleSubmit(values: UpdateFormValues, formikHelpers: FormikHelpers<UpdateFormValues>) {
    commit({
      variables: {
        input: {
          organizationId: id,
          updateOrganizationData: values,
        },
      },
      onCompleted: payload => {
        if (
          payload.updateOrganizationAsProjectAdmin?.errors[0] &&
          payload.updateOrganizationAsProjectAdmin?.errors[0].fields
        ) {
          payload.updateOrganizationAsProjectAdmin?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        if (payload.updateOrganizationAsProjectAdmin?.organization) {
          return history.push('/admin/organizations');
        }
      },
    });
  }
}

const query = graphql`
  query updatePageOrganizationQuery($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      id
      createdAt
      name
      city
      email
      state
      organizationConfig {
        daysBeforeDueSoon
        daysBeforeOverdue
      }
    }
  }
`;

const updateOrganizationAsProjectAdminMutation = graphql`
  mutation updatePageUpdateOrganizationAsProjectAdminMutation(
    $input: UpdateOrganizationAsProjectAdminInput!
  ) {
    updateOrganizationAsProjectAdmin(input: $input) {
      organization {
        id
        createdAt
        name
        email
        city
        state
        organizationConfig {
          daysBeforeDueSoon
          daysBeforeOverdue
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(UpdatePage);

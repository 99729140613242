import React from 'react';
import MapGL, {ViewState, ExtraState} from 'react-map-gl';

import NavigationControlPanel from '../map-layer/NavigationControlPanel';
import GeocoderPanel from './GeocoderPanel';

type Props = {
  viewport: {latitude: number; longitude: number; zoom: number};
  setViewport: (viewport: {latitude: number; longitude: number; zoom: number}) => void;
  transitionDuration: number;
  setTransitionDuration: (transitionDuration: number) => void;
  children: React.ReactNode;
};

const ViewportContext = React.createContext<{
  viewport: {latitude: number; longitude: number; zoom: number} | null;
  setViewport: (viewport: {latitude: number; longitude: number; zoom: number}) => void;
}>({
  viewport: null,
  setViewport: () => {},
});

function MapLayer(props: Props) {
  const {viewport, children, setViewport, transitionDuration, setTransitionDuration} = props;

  const mapRef = React.useRef<MapGL>(null);

  return (
    <MapGL
      {...viewport}
      height="100%"
      maxZoom={20}
      mapboxApiAccessToken="pk.eyJ1IjoiY2FsbGFyaWRlIiwiYSI6ImNqeWlvZ2ljODA0YWgzbm82d2FxM3BoeGIifQ.VgvqmFDyg4joDqMGtQJaDQ"
      onViewportChange={handleViewportChange}
      ref={mapRef}
      style={{flex: 1}}
      transitionDuration={transitionDuration}
      mapStyle="mapbox://styles/mapbox/streets-v11"
      width="100%">
      <GeocoderPanel
        mapRef={mapRef}
        onViewStateChange={(viewState: any) => handleViewportChange(viewState, {})}
      />
      <NavigationControlPanel />
      <ViewportContext.Provider value={{viewport, setViewport}}>
        {children}
      </ViewportContext.Provider>
    </MapGL>
  );

  function handleViewportChange(viewState: ViewState, interactionState: ExtraState) {
    const {latitude, longitude, zoom} = viewState;

    if (interactionState && !interactionState.inTransition) {
      setTransitionDuration(0);
    }

    setViewport({latitude, longitude, zoom});
  }
}

export default MapLayer;

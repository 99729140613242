import React from 'react';

type Props = {
  value: string;
  name: string;
  handleBlur?: () => void;
  helperText?: string;
  handleChange: (value: string) => void;
  error?: string;
  placeholder: string;
  label: string;
  readonly?: boolean;
  type?: string;
};

function TextInput(props: Props) {
  const {
    value,
    label,
    handleBlur,
    handleChange,
    type,
    helperText,
    error,
    name,
    placeholder,
    readonly,
  } = props;

  return (
    <div className="w-80 mb-6">
      <label
        className="block uppercase tracking-wide text-gray-800 text-xs font-bold mb-2"
        htmlFor={name}>
        {label}
      </label>

      <input
        className={`block w-full appearance-none w-full ${
          readonly ? 'bg-gray-100 cursor-not-allowed' : 'bg-white focus:bg-white'
        } border border-gray-500 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:border-gray-500`}
        id={name}
        name={name}
        onBlur={handleBlur}
        placeholder={placeholder}
        value={value}
        onChange={event => handleChange(event.target.value)}
        type={type || 'text'}
        readOnly={readonly}
      />
      {helperText && <p className="mt-2 text-sm text-gray-500">{helperText}</p>}

      {error && <p className="text-red-500 text-xs mt-1 italic">{error}</p>}
    </div>
  );
}
export default TextInput;

/**
 * @generated SignedSource<<b45c1fb5fa8d05f43ef84fe6b3da7695>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AssetStatus = "maintained" | "due_soon" | "overdue" | "out_of_service" | "%future added value";
export type updatePageGeneralAssetQuery$variables = {
  generalAssetId: string;
};
export type updatePageGeneralAssetQueryVariables = updatePageGeneralAssetQuery$variables;
export type updatePageGeneralAssetQuery$data = {
  readonly generalAsset: {
    readonly id: string;
    readonly createdAt: any;
    readonly serial: string;
    readonly externalId: string;
    readonly coordinates: ReadonlyArray<number>;
    readonly lastServicedAt: any;
    readonly warrantyExpiresAt: any;
    readonly installedAt: any;
    readonly status: AssetStatus;
    readonly generalAssetName: string;
    readonly notes: string | null;
    readonly model: {
      readonly id: string;
      readonly name: string;
    };
    readonly family: {
      readonly id: string;
      readonly name: string;
    };
    readonly manufacturer: {
      readonly id: string;
      readonly name: string;
    };
  } | null;
};
export type updatePageGeneralAssetQueryResponse = updatePageGeneralAssetQuery$data;
export type updatePageGeneralAssetQuery = {
  variables: updatePageGeneralAssetQueryVariables;
  response: updatePageGeneralAssetQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "generalAssetId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "generalAssetId",
    "variableName": "generalAssetId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serial",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "externalId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "coordinates",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastServicedAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "warrantyExpiresAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "installedAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "generalAssetName",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "notes",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = [
  (v2/*: any*/),
  (v13/*: any*/)
],
v15 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  },
  (v2/*: any*/),
  (v13/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "updatePageGeneralAssetQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GeneralAsset",
        "kind": "LinkedField",
        "name": "generalAsset",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "model",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "family",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "manufacturer",
            "plural": false,
            "selections": (v14/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "updatePageGeneralAssetQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GeneralAsset",
        "kind": "LinkedField",
        "name": "generalAsset",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "model",
            "plural": false,
            "selections": (v15/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "family",
            "plural": false,
            "selections": (v15/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "manufacturer",
            "plural": false,
            "selections": (v15/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8d6a9204dcdbc3dba25e7a9ac275bb83",
    "id": null,
    "metadata": {},
    "name": "updatePageGeneralAssetQuery",
    "operationKind": "query",
    "text": "query updatePageGeneralAssetQuery(\n  $generalAssetId: ID!\n) {\n  generalAsset(generalAssetId: $generalAssetId) {\n    id\n    createdAt\n    serial\n    externalId\n    coordinates\n    lastServicedAt\n    warrantyExpiresAt\n    installedAt\n    status\n    generalAssetName\n    notes\n    model {\n      __typename\n      id\n      name\n    }\n    family {\n      __typename\n      id\n      name\n    }\n    manufacturer {\n      __typename\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "45877786fc7b367fa4b4bc0e2e51f446";

export default node;

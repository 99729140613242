import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';

import {AssigneeInputUsersQuery} from './__generated__/AssigneeInputUsersQuery.graphql';
import DropdownInput from './DropdownInput';

type Props = {
  value: string;
  onChange: (value: string) => void;
  error?: string;
};

function AssigneeInput(props: Props) {
  const {value, onChange, error} = props;

  const data = useLazyLoadQuery<AssigneeInputUsersQuery>(
    usersQuery,
    {},
    {fetchPolicy: 'store-and-network'},
  );

  const options = !!data
    ? data.organizationUsers.edges.map(organizationUserEdge => ({
        label: `${organizationUserEdge.node.firstName} ${organizationUserEdge.node.lastName}`,
        value: organizationUserEdge.node.id,
      }))
    : [];

  return (
    <DropdownInput
      name="assigneeInput"
      onChange={onChange}
      options={options}
      placeholder={'E.g. John Mclane'}
      label="Assign To"
      value={value}
      error={error}
    />
  );
}

const usersQuery = graphql`
  query AssigneeInputUsersQuery {
    organizationUsers {
      edges {
        node {
          id
          firstName
          lastName
          role
        }
      }
    }
  }
`;

export default AssigneeInput;

import React from 'react';
import styled from 'styled-components';

type Props = {
  title?: string;
  description: string;
  className?: string;
};

function AlertSuccess({title, description, className}: Props) {
  return (
    <Container extraClassName={className}>
      <div>
        {title && <Title>{title}</Title>}
        <Description>{description}</Description>
      </div>
    </Container>
  );
}

const Container = styled.div.attrs(({extraClassName}: {extraClassName?: string}) => ({
  className: `bg-green-100 p-4 flex flex-row rounded-lg ${extraClassName}`,
}))<{extraClassName?: string}>``;

const Title = styled.p.attrs({className: 'text-green-800 font-medium mb-1'})``;
const Description = styled.p.attrs({className: 'text-green-800 text-sm font-light'})``;

export default AlertSuccess;

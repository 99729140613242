/**
 * @generated SignedSource<<430180e34372c42966b221216c50516d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type pageLayoutCurrentUserQuery$variables = {};
export type pageLayoutCurrentUserQueryVariables = pageLayoutCurrentUserQuery$variables;
export type pageLayoutCurrentUserQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Header_currentUserQuery">;
};
export type pageLayoutCurrentUserQueryResponse = pageLayoutCurrentUserQuery$data;
export type pageLayoutCurrentUserQuery = {
  variables: pageLayoutCurrentUserQueryVariables;
  response: pageLayoutCurrentUserQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "pageLayoutCurrentUserQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Header_currentUserQuery"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "pageLayoutCurrentUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Organization",
                "kind": "LinkedField",
                "name": "organization",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "OrganizationUser",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3cda732636a57fbfd4183e8f5f7e2583",
    "id": null,
    "metadata": {},
    "name": "pageLayoutCurrentUserQuery",
    "operationKind": "query",
    "text": "query pageLayoutCurrentUserQuery {\n  ...Header_currentUserQuery\n}\n\nfragment Header_currentUserQuery on Query {\n  currentUser {\n    __typename\n    id\n    ... on OrganizationUser {\n      organization {\n        name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "aea0e8032235abf2a715cd7999c0fdc3";

export default node;

import graphql from 'babel-plugin-relay/macro';
import {useFormik} from 'formik';
import React, {useState} from 'react';
import {useMutation} from 'react-relay/hooks';
import {NavLink, useLocation} from 'react-router-dom';
import AlertDanger from 'src/components/alerts/AlertDanger';
import AlertSuccess from 'src/components/alerts/AlertSuccess';
import {AUTHENTICATION_ERROR, INVALID_ARGUMENTS_ERROR} from 'src/constants/errors';
import styled from 'styled-components';
import * as Yup from 'yup';

import logo from '../../assets/img/AF-Logo-Mark.png';
import TextInput from '../../components/inputs/TextInput';
import {resetPasswordPageResetPasswordMutation} from './__generated__/resetPasswordPageResetPasswordMutation.graphql';

function ResetPasswordPage() {
  const [formError, setFormError] = React.useState('');

  const queryParams = new URLSearchParams(useLocation().search);
  const token = queryParams.get('token') || '';

  const {
    values,
    setFieldValue,
    handleSubmit,
    submitCount,
    errors,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    isInitialValid: false,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const [commit] = useMutation<resetPasswordPageResetPasswordMutation>(mutation);
  const [resetInstructionsSent, setResetInstructionsSent] = useState(false);

  return (
    <div className="bg-gray-900 flex flex-grow items-center pt-40 flex-col">
      <div>
        <img className="h-20 mb-10" src={logo} alt="header logo" />
      </div>

      <div className="bg-white shadow-md rounded p-8 mb-4">
        <h1 className="text-xl font-semibold text-gray-800">Reset Password</h1>
        <p className="antialiased text-gray-600 mb-6">Enter a new password for your account</p>

        {resetInstructionsSent ? (
          <div>
            <AlertSuccess
              title="Password Updated"
              description="You're now able to sign in using your new password."
              className="w-80"
            />
            <BackButton>OK</BackButton>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            {formError && <AlertDanger description={formError} className="mb-4 w-80" />}

            <div className="mb-4">
              <TextInput
                name="password"
                type="password"
                label="New Password"
                value={values.password}
                handleChange={value => setFieldValue('password', value)}
                placeholder="********"
                error={!!submitCount && errors.password ? errors.password : ''}
              />
            </div>

            <div className="mb-4">
              <TextInput
                name="passwordConfirmation"
                type="password"
                label="Re-enter New Password"
                value={values.passwordConfirmation}
                handleChange={value => setFieldValue('passwordConfirmation', value)}
                placeholder="********"
                error={
                  !!submitCount && errors.passwordConfirmation ? errors.passwordConfirmation : ''
                }
              />
            </div>

            <div className="flex justify-center">
              <SubmitButton disabled={isSubmitting || !isValid} type="submit">
                Confirm
              </SubmitButton>
            </div>
          </form>
        )}
      </div>
    </div>
  );

  async function onSubmit(formValues: any) {
    setFormError('');

    commit({
      variables: {
        input: {
          password: formValues.password,
          token,
        },
      },
      onCompleted: async payload => {
        if (payload?.resetPassword?.updated) {
          setResetInstructionsSent(true);
        } else if (payload.resetPassword?.errors) {
          const [error] = payload.resetPassword?.errors;

          if (error.code === AUTHENTICATION_ERROR) {
            setFormError('Your password reset token is not valid.');
          } else if (error.code === INVALID_ARGUMENTS_ERROR) {
            setFormError('Your password reset token has expired.');
          }
        }
      },
    });
  }
}

const mutation = graphql`
  mutation resetPasswordPageResetPasswordMutation($input: ResetPasswordInput!) {
    resetPassword(input: $input) {
      updated
      errors {
        code
      }
    }
  }
`;

const BackButton = styled(NavLink).attrs({
  to: 'signin',
  className:
    'btn block text-sm text-center text-gray-600 border border-gray-500 hover:border-gray-700 hover:text-gray-800 mt-5',
})``;

const SubmitButton = styled.button.attrs<{disabled: boolean}>(({disabled}) => {
  const backgroundColor = disabled ? 'bg-gray-400' : 'bg-blue-700 hover:bg-blue-500';

  return {
    className:
      'flex-1 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ' +
      backgroundColor,
  };
})<{disabled: boolean}>``;

const validationSchema = Yup.object({
  password: Yup.string().required('Password is required'),
  passwordConfirmation: Yup.string()
    .required('Password confirmation is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

export default ResetPasswordPage;

import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useHistory, useParams} from 'react-router';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/Form';
import {FormValues} from '../types';
import {updatePageGeneralAssetFamilyQuery} from './__generated__/updatePageGeneralAssetFamilyQuery.graphql';
import {updatePageUpdateGeneralAssetFamilyMutation} from './__generated__/updatePageUpdateGeneralAssetFamilyMutation.graphql';

function UpdatePage() {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [commit, isInFlight] = useMutation<updatePageUpdateGeneralAssetFamilyMutation>(
    updateGeneralAssetFamilyMutation,
  );

  const data = useLazyLoadQuery<updatePageGeneralAssetFamilyQuery>(query, {
    generalAssetFamilyId: id,
  });

  if (!data.generalAssetFamily) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">The item you wish to update could not be found.</h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      title="Update General Asset Type"
      button="Update General Asset Type"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleInitialValues() {
    if (!data || !data.generalAssetFamily) {
      return;
    }

    const {generalAssetFamily} = data;

    return {
      name: generalAssetFamily.name,
      manufacturerId: generalAssetFamily.manufacturer.id,
    };
  }

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          generalAssetFamilyId: id,
          updateGeneralAssetFamilyData: values,
        },
      },
      onCompleted: payload => {
        if (
          payload.updateGeneralAssetFamily?.errors[0] &&
          payload.updateGeneralAssetFamily?.errors[0].fields
        ) {
          payload.updateGeneralAssetFamily?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.updateGeneralAssetFamily?.generalAssetFamily;

        if (entry) {
          showEventNotification('updated', 'Type', entry.name);

          if (history.location.search.includes('newTab')) {
            window.close();
          }

          return history.push('/admin/general_asset_types');
        }
      },
    });
  }
}

const query = graphql`
  query updatePageGeneralAssetFamilyQuery($generalAssetFamilyId: ID!) {
    generalAssetFamily(generalAssetFamilyId: $generalAssetFamilyId) {
      id
      createdAt
      name
      manufacturer {
        id
        name
      }
    }
  }
`;

const updateGeneralAssetFamilyMutation = graphql`
  mutation updatePageUpdateGeneralAssetFamilyMutation($input: UpdateGeneralAssetFamilyInput!) {
    updateGeneralAssetFamily(input: $input) {
      generalAssetFamily {
        id
        createdAt
        name
        manufacturer {
          id
          name
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(UpdatePage);

/**
 * @generated SignedSource<<ac822f862291733379bd15b7a4896e89>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TaskInputTaskTemplatesQuery$variables = {};
export type TaskInputTaskTemplatesQueryVariables = TaskInputTaskTemplatesQuery$variables;
export type TaskInputTaskTemplatesQuery$data = {
  readonly taskTemplates: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
      };
    }>;
  };
};
export type TaskInputTaskTemplatesQueryResponse = TaskInputTaskTemplatesQuery$data;
export type TaskInputTaskTemplatesQuery = {
  variables: TaskInputTaskTemplatesQueryVariables;
  response: TaskInputTaskTemplatesQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TaskTemplateConnection",
    "kind": "LinkedField",
    "name": "taskTemplates",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TaskTemplateEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TaskTemplate",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskInputTaskTemplatesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TaskInputTaskTemplatesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "56103d76040a82957e7aceac49628b2d",
    "id": null,
    "metadata": {},
    "name": "TaskInputTaskTemplatesQuery",
    "operationKind": "query",
    "text": "query TaskInputTaskTemplatesQuery {\n  taskTemplates {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "75b24a9c63e0384b1c94de9fa66bbd63";

export default node;

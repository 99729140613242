import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useMutation} from 'react-relay/hooks';
import {useHistory} from 'react-router';
import ConnectionHandler from 'relay-connection-handler-plus';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/Form';
import {FormValues} from '../types';
import {createPageCreateValveModelMutation} from './__generated__/createPageCreateValveModelMutation.graphql';

function CreatePage() {
  const history = useHistory();

  const [commit, isInFlight] = useMutation<createPageCreateValveModelMutation>(
    createValveModelMutation,
  );

  return (
    <Form
      title="Create Valve Model"
      button="Create Valve Model"
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleSubmit(input: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          name: input.name,
          familyId: input.familyId,
        },
      },
      onCompleted: payload => {
        if (payload.createValveModel?.errors[0] && payload.createValveModel?.errors[0].fields) {
          payload.createValveModel?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.createValveModel?.valveModel;

        if (entry) {
          showEventNotification('added', 'Model', entry.name);
          return history.push('/admin/valve_models');
        }
      },
      updater: store => {
        const root = store.getRoot();

        const connectionRecords = ConnectionHandler.getConnections(
          root,
          'ValveModelsList_valveModels',
        );

        if (!connectionRecords) {
          return;
        }

        const payload = store.getRootField('createValveModel');

        if (!payload) {
          return;
        }

        const newRecord = payload.getLinkedRecord('valveModel');

        if (!newRecord) {
          return;
        }

        connectionRecords.forEach(connectionRecord => {
          const newEdge = ConnectionHandler.createEdge(
            store,
            connectionRecord,
            newRecord,
            'valveModelEdge',
          );

          ConnectionHandler.insertEdgeAfter(connectionRecord, newEdge);
        });
      },
    });
  }
}

const createValveModelMutation = graphql`
  mutation createPageCreateValveModelMutation($input: CreateValveModelInput!) {
    createValveModel(input: $input) {
      valveModel {
        id
        createdAt
        name
        manufacturer {
          id
          name
        }
        family {
          id
          name
        }
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(CreatePage);

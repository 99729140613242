import graphql from 'babel-plugin-relay/macro';
import {FormikHelpers} from 'formik';
import React from 'react';
import {useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useHistory, useParams} from 'react-router';
import {showEventNotification} from 'src/utils/notifications';
import {withSuspense} from 'src/utils/withSuspense';

import Form from '../components/Form';
import {FormValues} from '../types';
import {updatePageUpdateValveConnectorMutation} from './__generated__/updatePageUpdateValveConnectorMutation.graphql';
import {updatePageValveConnectorQuery} from './__generated__/updatePageValveConnectorQuery.graphql';

function UpdatePage() {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [commit, isInFlight] = useMutation<updatePageUpdateValveConnectorMutation>(
    updateValveConnectorMutation,
  );

  const data = useLazyLoadQuery<updatePageValveConnectorQuery>(query, {
    valveConnectorId: id,
  });

  if (!data.valveConnector) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <h4 className="text-lg text-gray-700">The item you wish to update could not be found.</h4>
      </div>
    );
  }

  const initialValues = handleInitialValues();

  return (
    <Form
      title="Update Valve Connection Type"
      button="Update Valve Connection Type"
      initialValues={initialValues}
      onSubmit={handleSubmit}
      isSubmitting={isInFlight}
    />
  );

  function handleInitialValues() {
    if (!data || !data.valveConnector) {
      return;
    }

    const {valveConnector} = data;

    return {
      name: valveConnector.name,
    };
  }

  function handleSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>) {
    commit({
      variables: {
        input: {
          valveConnectorId: id,
          updateValveConnectorData: values,
        },
      },
      onCompleted: payload => {
        if (
          payload.updateValveConnector?.errors[0] &&
          payload.updateValveConnector?.errors[0].fields
        ) {
          payload.updateValveConnector?.errors[0].fields.forEach(fieldError => {
            formikHelpers.setFieldError(fieldError.fieldName, fieldError.message);
          });
        }

        const entry = payload.updateValveConnector?.valveConnector;

        if (entry) {
          showEventNotification('updated', 'Connector', entry.name);

          if (history.location.search.includes('newTab')) {
            window.close();
          }

          return history.push('/admin/valve_connection_types');
        }
      },
    });
  }
}

const query = graphql`
  query updatePageValveConnectorQuery($valveConnectorId: ID!) {
    valveConnector(valveConnectorId: $valveConnectorId) {
      id
      createdAt
      name
    }
  }
`;

const updateValveConnectorMutation = graphql`
  mutation updatePageUpdateValveConnectorMutation($input: UpdateValveConnectorInput!) {
    updateValveConnector(input: $input) {
      valveConnector {
        id
        createdAt
        name
      }
      errors {
        code
        fields {
          fieldName
          message
        }
      }
    }
  }
`;

export default withSuspense(UpdatePage);

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {Marker} from 'react-map-gl';
import {useFragment} from 'react-relay/hooks';

import {LocationTab_asset$key} from './__generated__/LocationTab_asset.graphql';
import MapLayer from './map-layer';

type Props = {
  asset: LocationTab_asset$key;
};

function LocationTab(props: Props) {
  const [transitionDuration, setTransitionDuration] = React.useState<number>(0);
  const [viewport, setViewport] = React.useState<{
    latitude: number;
    longitude: number;
    zoom: number;
  }>({
    latitude: 0,
    longitude: 0,
    zoom: 13,
  });

  const asset = useFragment(
    graphql`
      fragment LocationTab_asset on Asset {
        id
        coordinates
      }
    `,
    props.asset,
  );

  React.useEffect(() => {
    if (!asset) {
      return;
    }

    const [longitude, latitude] = asset.coordinates;

    setViewport({...viewport, latitude, longitude});
  }, [asset]);

  return (
    <div className="flex flex-col w-full bg-gray-600 h-full flex-1">
      <MapLayer
        setTransitionDuration={setTransitionDuration}
        setViewport={setViewport}
        transitionDuration={transitionDuration}
        viewport={viewport}>
        <Marker
          longitude={asset.coordinates[0]}
          latitude={asset.coordinates[1]}
          offsetTop={-20}
          offsetLeft={-10}>
          <svg height={30} stroke="none" viewBox="0 0 24 24" fill={'#00e639'}>
            <path
              d="M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
            c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
            C20.1,15.8,20.2,15.8,20.2,15.7z"
            />
          </svg>
        </Marker>
      </MapLayer>
    </div>
  );
}

export default LocationTab;

import {useFormik} from 'formik';
import React from 'react';
import {useHistory} from 'react-router';
import {NavLink} from 'react-router-dom';
import AlertDanger from 'src/components/alerts/AlertDanger';
import {AUTHENTICATION_ERROR} from 'src/constants/errors';
import {useAuthContext} from 'src/libs/authentication';
import styled from 'styled-components';
import * as Yup from 'yup';

import logo from '../../assets/img/AF-Logo-Mark.png';
import TextInput from '../../components/inputs/TextInput';

function SignInPage() {
  const history = useHistory<{redirectPath?: string}>();
  const {signIn} = useAuthContext();

  const [formError, setFormError] = React.useState('');

  const {
    values,
    setFieldValue,
    handleSubmit,
    submitCount,
    errors,
    isSubmitting,
    isValid,
  } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    isInitialValid: false,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="bg-gray-900 flex flex-grow items-center pt-40 flex-col">
      <div>
        <img className="h-20 mb-10" src={logo} alt="header logo" />
      </div>

      <form onSubmit={handleSubmit} className="bg-white shadow-md rounded p-8 mb-4">
        {formError && <AlertDanger description={formError} className="mb-4 w-80" />}

        <div className="mb-4">
          <TextInput
            name="email"
            type="email"
            label="Email"
            value={values.email}
            handleChange={value => setFieldValue('email', value)}
            placeholder="E.g. johnsmith@example.com"
            error={!!submitCount && errors.email ? errors.email : ''}
          />
        </div>

        <div>
          <TextInput
            name="password"
            type="password"
            label="Password"
            value={values.password}
            handleChange={value => setFieldValue('password', value)}
            placeholder="***********"
            error={!!submitCount && errors.password ? errors.password : ''}
          />
        </div>

        <div className="flex items-center justify-center">
          <SubmitButton disabled={isSubmitting || !isValid} type="submit">
            Sign In
          </SubmitButton>
        </div>
      </form>

      <div className="m-4">
        <ResetPasswordButton>Can't sign in?</ResetPasswordButton>
      </div>
    </div>
  );

  async function onSubmit(formValues: any) {
    setFormError('');

    const {email, password} = formValues;
    const formattedEmail = email.toLowerCase();

    const url = `${process.env.REACT_APP_API_URL}/sign_in_by_email`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({email: formattedEmail, password}),
    });

    const data = await response.json();

    if (data.accessToken && data.refreshToken) {
      await signIn(data.accessToken, data.refreshToken);

      if (history.location.state.redirectPath) {
        history.push(history.location.state.redirectPath);
      } else {
        history.push(`/`);
      }
    }

    if (!!data.errors.length) {
      const [error] = data.errors;

      if (error.code === AUTHENTICATION_ERROR) {
        setFormError('Incorrect email or password');
      }
    }
  }
}

const SubmitButton = styled.button.attrs<{disabled: boolean}>(({disabled}) => {
  const backgroundColor = disabled ? 'bg-gray-400' : 'bg-blue-700 hover:bg-blue-500';

  return {
    className:
      'text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ' +
      backgroundColor,
  };
})<{disabled: boolean}>``;

const ResetPasswordButton = styled(NavLink).attrs({
  to: '/request-password-reset',
  className: 'text-gray-700 hover:text-gray-500 p-4',
})``;

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Please provide a valid email address.')
    .required('Please provide a valid email address.'),
  password: Yup.string().required('Please provide a password.'),
});

export default SignInPage;

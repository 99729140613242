import React from 'react';
import styled from 'styled-components';

type Props = {
  progressBackgroundColor?: string;
  referenceValue: number;
  value: number;
};

export default function ProgressBar({
  children,
  progressBackgroundColor,
  referenceValue,
  value,
}: React.PropsWithChildren<Props>) {
  const completion = (value / referenceValue) * 100;
  const defaultProgressBarColor = 'bg-orange-500';

  return (
    <Container>
      {children ? (
        children
      ) : (
        <Label>
          {value} / {referenceValue}
        </Label>
      )}

      <BarContainer>
        <Bar
          backgroundColor={progressBackgroundColor || defaultProgressBarColor}
          style={{width: `${completion > 100 ? 100 : completion}%`}}
        />
      </BarContainer>
    </Container>
  );
}

const Container = styled.div.attrs({className: ''})``;

const Label = styled.span.attrs({
  className: 'block text-xs text-gray-600 mb-1',
})``;

const BarContainer = styled.div.attrs({
  className: 'bg-gray-300 rounded h-1',
})``;

const Bar = styled.div.attrs(({backgroundColor}: {backgroundColor: string}) => ({
  className: `${backgroundColor} rounded h-1`,
}))<{backgroundColor: string}>``;

import graphql from 'babel-plugin-relay/macro';
import React from 'react';
import {usePaginationFragment, useMutation} from 'react-relay/hooks';
import {Link} from 'react-router-dom';
import ConnectionHandler from 'relay-connection-handler-plus';
import ImportantTextCell from 'src/components/table/cell-types/ImportantTextCell';
import assetTypeMap from 'src/constants/assetType';
import useFilters from 'src/hooks/useFilters';
import {showEventNotification} from 'src/utils/notifications';

import Table from '../../../components/table';
import DateCell from '../../../components/table/cell-types/DateCell';
import SecondaryTextCell from '../../../components/table/cell-types/SecondaryTextCell';
import {JobsOrder} from './__generated__/JobsPaginationQuery.graphql';
import {JobsTable_jobs$key} from './__generated__/JobsTable_jobs.graphql';
import {JobsTableDeleteJobMutation} from './__generated__/JobsTableDeleteJobMutation.graphql';
import TableFilters from './TableFilters';

type Props = {
  jobs: JobsTable_jobs$key;
};

type Filters = {
  text?: string | null;
  completed?: boolean | null;
  orderBy?: JobsOrder;
};

function JobsTable(props: Props) {
  const {data, loadNext, hasNext, refetch} = usePaginationFragment(
    graphql`
      fragment JobsTable_jobs on Query
        @argumentDefinitions(
          first: {type: "Int!"}
          after: {type: "String"}
          text: {type: "String"}
          assetSerial: {type: "String"}
          completed: {type: "Boolean"}
          orderBy: {type: "JobsOrder"}
        )
        @refetchable(queryName: "JobsPaginationQuery") {
        jobs(
          first: $first
          after: $after
          orderBy: $orderBy
          text: $text
          assetSerial: $assetSerial
          completed: $completed
        ) @connection(key: "JobsList_jobs") {
          totalCount
          pageInfo {
            hasPreviousPage
            hasNextPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              createdAt
              jobNumber
              dueAt
              completedAt
              assignee {
                id
                firstName
                lastName
              }
              asset {
                __typename
                id
                serial
              }
              notes
              tasks {
                edges {
                  node {
                    id
                    template {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    props.jobs,
  );

  const [commit] = useMutation<JobsTableDeleteJobMutation>(deleteJobMutation);
  const {addFilters, setFilters, isTransitioning} = useFilters<Filters>({}, refetch);

  return (
    <Table
      filters={() => (
        <TableFilters onApplyFilters={handleOnApplyFilter} onResetFilters={handleOnResetFilter} />
      )}
      actions={{show: false, delete: true, edit: true, create: true}}
      totalDataLength={data.jobs.totalCount}
      columns={columns}
      isTransitioning={isTransitioning}
      onPaginate={() => hasNext && loadNext(50)}
      onSort={handleOnSort}
      onDelete={handleDelete}
      data={data.jobs.edges}
    />
  );

  function handleOnApplyFilter(selectedFilters: {text: string; completed: string}) {
    let completed: boolean | null = null;

    switch (selectedFilters.completed) {
      case 'all': {
        completed = null;
        break;
      }
      case 'complete': {
        completed = true;
        break;
      }
      case 'not_complete': {
        completed = false;
        break;
      }
    }

    addFilters({text: selectedFilters.text, completed});
  }

  function handleOnResetFilter() {
    setFilters({text: null});
  }

  async function handleOnSort(sortByConfig: any[]) {
    if (!sortByConfig.length) {
      addFilters({orderBy: undefined});

      return;
    }

    const orderByDirection = sortByConfig[0].desc ? 'DESC' : 'ASC';
    const orderByField = sortByConfig[0].id.toUpperCase();

    addFilters({orderBy: {direction: orderByDirection, field: orderByField}});
  }

  function handleDelete(id: string) {
    commit({
      variables: {input: {jobId: id}},
      onCompleted: payload => {
        const entry = payload.deleteJob?.job;

        if (entry) {
          showEventNotification('removed', 'Job', '#' + entry.jobNumber);
        }
      },
      updater: store => {
        const root = store.getRoot();
        const mainConnection = ConnectionHandler.getConnection(root, 'JobsList_jobs');
        const connections = ConnectionHandler.getConnections(root, 'JobsList_jobs');

        if (!connections || !mainConnection) {
          return;
        }

        ConnectionHandler.deleteNode(mainConnection, id);

        connections.forEach(connection => {
          ConnectionHandler.deleteNode(connection, id);
        });
      },
    });
  }
}

export default JobsTable;

const deleteJobMutation = graphql`
  mutation JobsTableDeleteJobMutation($input: DeleteJobInput!) {
    deleteJob(input: $input) {
      job {
        id
        jobNumber
      }
      errors {
        code
        message
      }
    }
  }
`;

const columns = [
  {
    Header: 'Job Number',
    accessor: 'node.jobNumber',
    id: 'job_number',
    Cell({cell: {value}}: any) {
      return <ImportantTextCell value={value} />;
    },
  },
  {
    Header: 'Asset Serial',
    accessor: 'node.asset.serial',
    id: 'asset_serial',
    Cell({cell}: any) {
      const assetId = cell.row.original.node.asset.id;
      const assetType = cell.row.original.node.asset.__typename;
      const assetTypeSlugified = assetTypeMap[assetType].underscorePlural;

      return (
        <Link to={`/admin/${assetTypeSlugified}/${assetId}/details`}>
          <div className="text-sm font-bold text-orange-600 uppercase">{cell.value}</div>
        </Link>
      );
    },
  },
  {
    Header: 'Asset Type',
    accessor: 'node.asset.__typename',
    id: 'asset_type',
    Cell({cell: {value}}: any) {
      return <ImportantTextCell value={assetTypeMap[value].title} />;
    },
  },
  {
    Header: 'Created On',
    accessor: 'node.createdAt',
    id: 'created_at',
    Cell({cell: {value}}: any) {
      return <DateCell value={value} />;
    },
  },
  {
    Header: 'Due On',
    accessor: 'node.dueAt',
    id: 'due_at',
    Cell({cell: {value}}: any) {
      return <DateCell value={value} />;
    },
  },
  {
    Header: 'Completed On',
    accessor: 'node.completedAt',
    id: 'completed_at',
    Cell({cell: {value}}: any) {
      return <DateCell value={value} />;
    },
  },
  {
    Header: 'Assigned To',
    accessor: 'node.assignee.lastName',
    id: 'assigned_to',
    Cell({row}: any) {
      const {firstName, lastName} = row.original.node.assignee;

      return <SecondaryTextCell value={`${firstName[0]}. ${lastName}`} />;
    },
  },
];

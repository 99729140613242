import {useFormik, FormikHelpers} from 'formik';
import React from 'react';

import TextInput from '../../../components/inputs/TextInput';
import {FormValues} from '../types';

type Props = {
  initialValues?: FormValues;
  onSubmit: (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => void;
  title: string;
  button: string;
  validationSchema: object;
  isCustomerInputDisabled?: boolean;
  isSubmitting: boolean;
};

function Form(props: Props) {
  const {initialValues, title, button, onSubmit, validationSchema} = props;

  const {submitCount, handleSubmit, errors, setFieldValue, values, isValid} = useFormik<FormValues>(
    {
      initialValues: Object.assign(
        {},
        {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          password: '',
          confirmPassword: '',
          customerId: '',
        },
        initialValues,
      ),
      isInitialValid: false,
      enableReinitialize: true,
      onSubmit,
      validationSchema,
    },
  );

  return (
    <form className="p-8" onSubmit={handleSubmit}>
      <div className="mb-6 pb-1 border-b-2 border-gray-400">
        <h2 className="text-2xl text-gray-800 uppercase font-semibold">{title}</h2>
      </div>

      <TextInput
        name="firstName"
        label="First Name"
        placeholder="E.g. Benjamin"
        handleChange={value => setFieldValue('firstName', value)}
        value={values.firstName}
        error={!!submitCount && !!errors.firstName ? errors.firstName : ''}
      />

      <TextInput
        name="lastName"
        label="Last Name"
        placeholder="E.g. Franklin"
        handleChange={value => setFieldValue('lastName', value)}
        value={values.lastName}
        error={!!submitCount && !!errors.lastName ? errors.lastName : ''}
      />

      <TextInput
        name="email"
        label="Email"
        placeholder="E.g. bfranklin@example.com"
        handleChange={value => setFieldValue('email', value)}
        value={values.email}
        type="email"
        error={!!submitCount && !!errors.email ? errors.email : ''}
      />

      <TextInput
        name="phone"
        label="Phone"
        placeholder="E.g. 7543010"
        handleChange={value => setFieldValue('phone', value)}
        handleBlur={() => setFieldValue('phone', values.phone.replace(/\D/g, ''))}
        value={values.phone}
        error={!!submitCount && !!errors.phone ? errors.phone : ''}
        helperText="Phone numers are automatically formatted."
      />

      <TextInput
        name="password"
        label="Password"
        placeholder="Pick something secure!"
        handleChange={value => setFieldValue('password', value)}
        value={values.password}
        type="password"
        error={!!submitCount && !!errors.password ? errors.password : ''}
      />

      <TextInput
        name="confirmPassword"
        label="Confirm Password"
        placeholder=""
        handleChange={value => setFieldValue('confirmPassword', value)}
        value={values.confirmPassword}
        type="password"
        error={!!submitCount && !!errors.confirmPassword ? errors.confirmPassword : ''}
      />

      <button
        type="submit"
        disabled={props.isSubmitting}
        className={
          'px-5 py-2 text-sm rounded font-semibold text-white uppercase ' +
          (isValid ? 'bg-gray-700' : 'bg-gray-400')
        }>
        {button}
      </button>
    </form>
  );
}

export default Form;

import React from 'react';
import styled from 'styled-components';

import ProgressBar from '../../../components/ProgressBar';

type Props = React.HTMLAttributes<HTMLDivElement> & {
  typeA: number;
  typeALimit: number;
  typeB: number;
  typeBLimit: number;
};

function AssetsUsageCard({typeA, typeALimit, typeB, typeBLimit, ...props}: Props) {
  return (
    <CardContainer {...props}>
      <Card>
        <CardTitle>Usage Metrics</CardTitle>

        <ItemRow>
          <ItemLabel>Type A Assets</ItemLabel>
          <ItemDescription>Represents the total number of Hydrants tracked.</ItemDescription>

          <ProgressBar value={typeA} referenceValue={typeALimit} />
        </ItemRow>

        <ItemRow>
          <ItemLabel>Type B Assets</ItemLabel>
          <ItemDescription>
            It is a sum of all your Valves and General Assets being tracked.
          </ItemDescription>

          <ProgressBar value={typeB} referenceValue={typeBLimit} />
        </ItemRow>
      </Card>
    </CardContainer>
  );
}

const CardContainer = styled.div``;

const Card = styled.div.attrs({
  className: 'p-6 pt-0 mb-auto',
})``;

const CardTitle = styled.h2.attrs({
  className: 'antialiased text-gray-600 text-lg mb-4',
})``;

const ItemRow = styled.div.attrs({
  className: 'mb-6',
})``;

const ItemLabel = styled.p.attrs({
  className: 'block uppercase tracking-wide text-gray-800 text-xs font-bold mb-1',
})``;

const ItemDescription = styled.p.attrs({
  className: 'block text-gray-700 text-sm mb-4',
})``;

export default AssetsUsageCard;

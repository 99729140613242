/**
 * @generated SignedSource<<8d0b11abca3433febd980549c8d92b7a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type userSettingsPageCurrentUserQuery$variables = {};
export type userSettingsPageCurrentUserQueryVariables = userSettingsPageCurrentUserQuery$variables;
export type userSettingsPageCurrentUserQuery$data = {
  readonly currentUser: {
    readonly id: string;
    readonly firstName: string;
    readonly lastName: string;
    readonly phone: string;
    readonly email: string;
  };
};
export type userSettingsPageCurrentUserQueryResponse = userSettingsPageCurrentUserQuery$data;
export type userSettingsPageCurrentUserQuery = {
  variables: userSettingsPageCurrentUserQueryVariables;
  response: userSettingsPageCurrentUserQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "userSettingsPageCurrentUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "userSettingsPageCurrentUserQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "currentUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7610c0fe79bf0ffe49cb02b1ec158fb9",
    "id": null,
    "metadata": {},
    "name": "userSettingsPageCurrentUserQuery",
    "operationKind": "query",
    "text": "query userSettingsPageCurrentUserQuery {\n  currentUser {\n    __typename\n    id\n    firstName\n    lastName\n    phone\n    email\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f8c83a2437832db749c0a43d35c0d57";

export default node;

import React from 'react';

import HydrantFamilyInput from '../../../../components/inputs/HydrantFamilyInput';
import HydrantManufacturerInput from '../../../../components/inputs/HydrantManufacturerInput';
import HydrantModelInput from '../../../../components/inputs/HydrantModelInput';

type Props = {
  values: any;
  errors: any;
  setFieldValue: any;
  submitCount: number;
};

function ManufacturingTab(props: Props) {
  const {values, setFieldValue, submitCount, errors} = props;

  return (
    <div>
      <HydrantManufacturerInput
        value={values.manufacturerId}
        onChange={value => setFieldValue('manufacturerId', value)}
        error={!!submitCount && errors.manufacturerId ? errors.manufacturerId : ''}
      />

      <HydrantFamilyInput
        manufacturerId={values.manufacturerId}
        value={values.familyId}
        onChange={value => setFieldValue('familyId', value)}
        error={!!submitCount && errors.familyId ? errors.familyId : ''}
      />

      <HydrantModelInput
        familyId={values.familyId}
        value={values.modelId}
        onChange={value => setFieldValue('modelId', value)}
        error={!!submitCount && errors.modelId ? errors.modelId : ''}
      />
    </div>
  );
}

export default ManufacturingTab;

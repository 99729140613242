import {faFileCsv} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import exportFromJSON from 'export-from-json';
import React from 'react';
import {toast} from 'react-toastify';
import AssetStatusSelectInput from 'src/components/inputs/AssetStatusSelectInput';
import DateInput from 'src/components/inputs/DateInput';
import styled from 'styled-components';

import TextInput from '../inputs/TextInput';
import FilterWrapper from './Filters';

type Props = {
  onApplyFilters: (filters: {text: string; status: string; createdBefore: Date}) => void;
  onResetFilters: () => void;
  onExportAsCSV?: () => {rows: object; fileName: string};
};

function ReportTableFilters(props: Props) {
  const {onApplyFilters, onResetFilters} = props;

  const [searchQuery, setSearchQuery] = React.useState('');
  const [assetStatus, setAssetStatus] = React.useState('');
  const [createdBefore, setCreatedBefore] = React.useState(new Date());

  async function handleExportAsCSV() {
    if (props.onExportAsCSV) {
      try {
        const {rows, fileName} = await props.onExportAsCSV();
        exportFromJSON({data: rows, fileName, exportType: 'csv'});
      } catch {
        toast('Sorry, something bad happened. Please, try again!');
      }
    }
  }

  return (
    <Container>
      <FilterWrapper
        onResetFilters={handleOnResetFilters}
        onApplyFilters={handleOnApplyFilters}
        areFiltersApplicable={true}>
        <div className="flex space-x-4">
          <TextInput
            label="Search text fields"
            placeholder="E.g. name, ..."
            value={searchQuery}
            handleChange={value => setSearchQuery(value)}
            name="searchInput"
          />

          <AssetStatusSelectInput value={assetStatus} onChange={value => setAssetStatus(value)} />

          <DateInput
            name="createdBefore"
            label="Measurement data as of"
            placeholder=""
            value={createdBefore}
            onChange={value => setCreatedBefore(value)}
            range="past"
          />
        </div>
      </FilterWrapper>

      {props.onExportAsCSV && (
        <ExportButton onClick={handleExportAsCSV}>
          <ExportIcon />
          <span>Export</span>
        </ExportButton>
      )}
    </Container>
  );

  async function handleOnResetFilters() {
    setSearchQuery('');
    setAssetStatus('');
    setCreatedBefore(new Date());

    onResetFilters();
  }

  async function handleOnApplyFilters() {
    onApplyFilters({text: searchQuery, status: assetStatus, createdBefore});
  }
}

const Container = styled.div.attrs({
  className: 'flex items-center justify-between space-x-2 overflow-auto w-full',
})``;

const ExportButton = styled.button.attrs({
  type: 'button',
  className:
    'flex items-center text-gray-600 uppercase leading-loose font-medium text-sm rounded border border-gray-500 hover:border-gray-700 hover:text-gray-800 focus:outline-none px-4 py-2 space-x-2',
})``;

const ExportIcon = styled(FontAwesomeIcon).attrs({
  icon: faFileCsv,
})``;

export default ReportTableFilters;
